import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import {
  FormControl, Select, InputLabel, MenuItem, TextField, FormHelperText,
  Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CompaniesFormValidation } from '../../../utils/validationSchema'
import { useStyles } from '../Styles'
import { lg } from '../../../utils/ResponsiveUtility'

const CompaniesFilter = ({ submitForm }) => {
  const classes = useStyles()
  const filterForm = useFormik({
    initialValues: {
      taxpayer_name: '',
      name: '',
      tax_id: '',
      role_name: '',
      active: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CompaniesFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })
  const statusItems = [
    {
      id: 0,
      name: <FormattedMessage id='CompanyHeader.UserStatus'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='CompanyData.UserStatus.active'/>,
      value: '1'
    },
    {
      id: 2,
      name: <FormattedMessage id='CompanyData.UserStatus.notActive'/>,
      value: '0'
    }
  ]

  const RoleItems = [
    {
      id: 0,
      name: <FormattedMessage id='CompanyHeader.UserRole'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='CompanyData.UserRole.owner'/>,
      value: 'owner'
    },
    {
      id: 2,
      name: <FormattedMessage id='CompanyData.UserRole.delegate'/>,
      value: 'delegate'
    }
  ]

  const handleStatusChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('active', event.target.value.toString())
  }

  const handleRoleChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('role_name', event.target.value.toString())
  }

  const [expanded, setExpanded] = React.useState(false)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (

    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.taxpayer_name"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='CompanyHeader.TaxpayerName'/>}
              variant="outlined"
              name='taxpayer_name'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.taxpayer_name}
              helperText={filterForm.errors.taxpayer_name && filterForm.touched.taxpayer_name && filterForm.errors.taxpayer_name}
              error={filterForm.errors.taxpayer_name && filterForm.touched.taxpayer_name !== undefined}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.name"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='CompanyHeader.CompanyName'/>}
              variant="outlined"
              name='name'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.name}
              helperText={filterForm.errors.name && filterForm.touched.name && filterForm.errors.name}
              error={filterForm.errors.name && filterForm.touched.name !== undefined}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.tax_id"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='CompanyHeader.UniqueTaxId'/>}
              variant="outlined"
              name='tax_id'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.tax_id}
              helperText={filterForm.errors.tax_id && filterForm.touched.tax_id && filterForm.errors.tax_id}
              error={filterForm.errors.tax_id && filterForm.touched.tax_id !== undefined}
            />
          </FormControl>
          <FormControl fullWidth margin={'dense'} variant='outlined'
                       className={classes.formControl + ' ' + classes.formSelectControl}>
            <InputLabel id="role_name"> {<FormattedMessage id='CompanyHeader.UserRole'/>} </InputLabel>
            <Select
              fullWidth
              labelId="role_name"
              id="role_name"
              margin="dense"
              MenuProps={{ disableScrollLock: !!desktop }}
              name="role_name"
              value={filterForm.values.role_name}
              onChange={handleRoleChange}
              onBlur={filterForm.handleBlur}
              error={filterForm.errors.role_name && filterForm.touched.role_name !== undefined}
              label={<FormattedMessage id='CompanyHeader.UserRole'/>}
            >
              {RoleItems.map((item) => (<MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
            </Select>
            {filterForm.errors.role_name && filterForm.touched.role_name && (
              <FormHelperText error={true}>{filterForm.errors.role_name}</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth margin={'dense'} variant='outlined'
                       className={classes.formControl + ' ' + classes.formSelectControl}>
            <InputLabel id="active"> {<FormattedMessage id='CompanyHeader.UserStatus'/>} </InputLabel>
            <Select
              fullWidth
              labelId="active"
              id="active"
              margin="dense"
              MenuProps={{ disableScrollLock: !!desktop }}
              name="active"
              value={filterForm.values.active}
              onChange={handleStatusChange}
              onBlur={filterForm.handleBlur}
              error={filterForm.errors.active && filterForm.touched.active !== undefined}
              label={<FormattedMessage id='CompanyHeader.UserStatus'/>}
            >
              {statusItems.map((item) => (<MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
            </Select>
            {filterForm.errors.active && filterForm.touched.active && (
              <FormHelperText error={true}>{filterForm.errors.active}</FormHelperText>
            )}
          </FormControl>
          <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                  className={classes.submitButton}>
            <FormattedMessage id='Filter.button.submit'/>
          </button>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export default CompaniesFilter
