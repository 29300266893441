/**
 * Responsive utility
 *
 *

 **/
export const xs = 0
export const sm = 600
export const md = 960
export const lg = 1280
export const xl = 1920
export const maxXl = 1919
export const maxLg = 1279
export const maxMd = 959
export const maxSm = 599
