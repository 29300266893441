/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useStyles} from './Styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'

const SocialIcons = () => {
  const classes = useStyles()

  return (
    <div className={classes.socialIcons}>
      <a href='#' className={`${classes.socialIconsLink} linkedIn`}><LinkedInIcon/></a>
      <a href='#' className={`${classes.socialIconsLink} twitter`}><TwitterIcon/></a>
      <a href='#' className={`${classes.socialIconsLink} youtube`}><YouTubeIcon/></a>
      <a href='#' className={`${classes.socialIconsLink} facebook`}><FacebookIcon/></a>
    </div>
  )
}

export default SocialIcons
