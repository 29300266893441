import React from 'react'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import PaymentOrderCreateError from '../../PaymentOrderCreateError'
import { LanguageConsumer } from '../../../../../../LanguageContext'

const PaymentOrderStep3 = () => {
  const [viewPaymentOrderCreateError, setViewPaymentOrderCreateError] = React.useState(true)
  const [openPaymentOrderCreateError, setOpenPaymentOrderCreateError] = React.useState(true)
  const handlePaymentOrderCreateErrorDialog = () => {
    if (!openPaymentOrderCreateError) {
      setViewPaymentOrderCreateError(true)
    } else {
      setTimeout(() => {
        setViewPaymentOrderCreateError(false)
      }, 500)
    }
    setOpenPaymentOrderCreateError(!openPaymentOrderCreateError)
  }
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <PaymentOrderConsumer>
          {({ paymentTransactionData, paymentTransactionFailure }) => (
            <>
              {!!paymentTransactionFailure && <PaymentOrderCreateError
                requestValidityCode={5}
                handleClose={() => handlePaymentOrderCreateErrorDialog()}
                open={openPaymentOrderCreateError}
                view={viewPaymentOrderCreateError}/>
              }
              {(!!paymentTransactionData && !!Object.keys(paymentTransactionData).length) &&
              <>
                <form id="etax_checkout"
                      action={paymentTransactionData.payment_URL}
                      method="POST">
                  <input type="hidden" name="SenderID" value={paymentTransactionData.SenderID}/>
                  <input type="hidden" name="RandomSecret"
                         value={paymentTransactionData.RandomSecret}/>
                  <input type="hidden" name="HasedRequestObject"
                         value={paymentTransactionData.HasedRequestObject}/>
                  <input type="hidden" name="RequestObject"
                         value={paymentTransactionData.RequestObject}/>
                  <input type="hidden" name="LanguageId"
                         value={locale === 'ar' ? 1 : 2}/>
                </form>
              </>
              }
            </>
          )}
        </PaymentOrderConsumer>
      )}
    </LanguageConsumer>
  )
}

export default PaymentOrderStep3
