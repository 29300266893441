/**
 * General Styles For The Layout
 * @type {function(*): {}}
 */
import { ArabicFont, EnglishFont } from '../../utils/Fonts'
import { fade } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/ar-ly'

export const useStyles = ((theme) => ({
  '@global': {
    html: { height: '100%' },
    body: {
      height: '100%',
      backgroundColor: '#EBF6FD',
      textAlign: 'left'
    },
    '#root': {
      height: '100%',
      whiteSpace: 'pre-line'
    },
    '.MuiTypography-h5': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem'
      }
    },
    '.MuiTypography-h6': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
      }
    },
    'a': {
      textDecoration: 'none',
      transition: 'all 0.3s ease-in-out',
      color: theme.palette.primary.light,
      '&:hover, &:focus': {
        color: theme.palette.primary.hover
      }
    },
    'button': {
      fontFamily: theme.direction === 'ltr' ? EnglishFont : ArabicFont,
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      outline: 'none !important'
    },
    'img': {
      maxWidth: '100%',
      height: 'auto',
      display: 'inline-block'
    },
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: theme.direction === 'ltr' ? EnglishFont : ArabicFont,
      fontWeight: 'bold'
    },
    '.MuiButton-root': {
      textTransform: 'none'
    },
    '.MuiDrawer-paper': {
      backgroundColor: theme.palette.common.black
    },
    '.MuiBackdrop-root': {
      backgroundColor: fade(theme.palette.common.black, 0.75),
      backdropFilter: 'blur(2px)'
    },
    '.MuiPickersDay-daySelected, .MuiPickersToolbarText-toolbarBtnSelected': {
      color: theme.palette.common.white
    },
    '.MuiButton-containedPrimary': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.hover
      }
    },
    '.MuiInputBase-root.Mui-disabled': {
      backgroundColor: theme.palette.secondary.main
    },
    // '.MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline,.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //   borderColor: theme.palette.primary.hover
    // },
    '.back-white': {
      backgroundColor: theme.palette.common.white
    },
    '.p-xs-1': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1)
      }
    },
    '.MuiPickersMonthSelection-container': {
      counterReset: 'section',
      '& .MuiPickersMonth-root': {
        position: 'relative',
        visibility: 'hidden',
        '&:before': {
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          visibility: 'visible'
        },
        '&:nth-child(1):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(0).format('MMMM').toString()}"`
        },
        '&:nth-child(2):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(1).format('MMMM').toString()}"`
        },
        '&:nth-child(3):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(2).format('MMMM').toString()}"`
        },
        '&:nth-child(4):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(3).format('MMMM').toString()}"`
        },
        '&:nth-child(5):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(4).format('MMMM').toString()}"`
        },
        '&:nth-child(6):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(5).format('MMMM').toString()}"`
        },
        '&:nth-child(7):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(6).format('MMMM').toString()}"`
        },
        '&:nth-child(8):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(7).format('MMMM').toString()}"`
        },
        '&:nth-child(9):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(8).format('MMMM').toString()}"`
        },
        '&:nth-child(10):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(9).format('MMMM').toString()}"`
        },
        '&:nth-child(11):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(10).format('MMMM').toString()}"`
        },
        '&:nth-child(12):before': {
          content: `"${moment().locale(theme.direction === 'rtl' ? 'ar-ly' : 'en-us').month(11).format('MMMM').toString()}"`
        }
      }
    }
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '&>div:first-child': {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  content: {
    flex: '1 1 auto'
  }
}))
