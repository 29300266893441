import React from 'react'
import { Container } from '@material-ui/core'
import { useStyles } from './Styles'
import WorkspaceTable from '../Table'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { SearchLoader } from '../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../EmptyResult'
import CompaniesFilter from './CompaniesFilter'
import UnAuthorize from '../../UnAuthorize'


const CompaniesContent = ({ getUserCompanies, userCompanies, resetSelectedCompany, shouldRemoveCompanies, isCompaniesFiltered }) => {

  const classes = useStyles()
  React.useEffect(() => {
      resetSelectedCompany()
      if (!!getUserCompanies && (!userCompanies.length || !!shouldRemoveCompanies || !!isCompaniesFiltered)) {
        getUserCompanies(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <>
      <Container maxWidth="lg" className={classes.CompaniesContentContainer}>
        <AuthenticatedUserConsumer>
          {({
              userCompaniesLoading, userCompanies, CompanyHeaderColumns, hasMoreUserCompanies,
              userCompaniesFailure, userCompaniesFailure403, handleSubmitFilters
            }) => (
            <>
              {!userCompaniesFailure403 && <CompaniesFilter submitForm={handleSubmitFilters}/>}
              {!!userCompaniesLoading ? <SearchLoader loading={true}/> :
                !!userCompaniesFailure ?
                  (!!userCompaniesFailure403 ? <UnAuthorize/> :
                    <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>)
                  :
                  <>
                    {(userCompanies === undefined || userCompanies.length === 0) ?
                      <EmptyResult/> :
                      <>
                        <WorkspaceTable
                          loadRows={getUserCompanies}
                          hasMoreRows={hasMoreUserCompanies}
                          rows={userCompanies}
                          columns={CompanyHeaderColumns}
                          size={'medium'}
                        />
                      </>
                    }
                  </>
              }
            </>
          )}
        </AuthenticatedUserConsumer>
      </Container>
    </>
  )
}

export default CompaniesContent
