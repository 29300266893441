import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  TitleContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.25,2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  dashboardNavigationContainer: {
    height: '100%'
  },
  dashboardNavigation: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& ul': {
      marginBottom: theme.spacing(0)
    },
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1, 0),
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 0)
    }
  }
}))
