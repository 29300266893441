import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import {
  Dialog,
  DialogContent, IconButton,
  Slide
} from '@material-ui/core'
import { useStyles } from '../../CompaniesPayments/Styles'
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentReceiptValidity = ({ open, view, handleClose, validityCode }) => {
  const classes = useStyles()

  return (
    !!view &&
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={open}
      classes={{ paper: classes.ErrorDialogPaper }}
      TransitionComponent={Transition}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogContent className={classes.ErrorDialogContent + ' with-lines'}>
        <IconButton
          edge={false}
          className={classes.ErrorDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
        {!!validityCode ?
          {
            1: <Alert severity="warning"><FormattedMessage id='PaymentOrderValidity.receipt.NotPaidYetAlert'/></Alert>,
            2: <Alert severity="success"><FormattedMessage id='PaymentOrderValidity.receipt.PaidAlert'/></Alert>,
            3: <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.FailedAlert'/></Alert>,
            4: <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.ErrorAlert'/></Alert>
          }[validityCode]
          :
          <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.ErrorAlert'/></Alert>
        }
      </DialogContent>
    </Dialog>
  )
}

export default PaymentReceiptValidity
