import React from 'react'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../CompanyContext'
import CompaniesInfo from './CompaniesInfo'
import CompanyContentWrapper from './CompanyContentWrapper'
import { companies } from '../../../utils/pages-meta'
import { SEO } from '../../Seo/seo'
import { useIntl } from 'react-intl'
import InActiveCompanyContent from '../../InActiveCompanyContent'

const CompanyContent = ({ allUserCompaniesList, checkCompanyData, cid }) => {
  const intl = useIntl()
  return (

    <CompanyContentWrapper allUserCompaniesList={allUserCompaniesList} checkCompanyData={checkCompanyData} cid={cid} checkUPTCP>
      <AuthenticatedUserConsumer>
        {({ selectedCompany }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                   image={companies.image}
                   description={selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                <CompanyConsumer>
                  {({ setBreadCrumbLinks, setBreadCrumbCurrent }) => (
                    <CompaniesInfo selectedCompany={selectedCompany}
                                   setBreadCrumbLinks={setBreadCrumbLinks}
                                   setBreadCrumbCurrent={setBreadCrumbCurrent}/>
                  )}
                </CompanyConsumer>
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default CompanyContent
