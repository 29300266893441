/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { useStyles } from './Styles'
import { useKeycloak } from '@react-keycloak/web'

const Anonymous = ({ locale }) => {
  const classes = useStyles()
  const { keycloak, initialized } = useKeycloak()
  const login = useCallback(() => {
    let refs = keycloak?.createAccountUrl().split('?')[1]
    let newUrl = keycloak?.createLoginUrl({ locale: locale }) + '&' + refs
    window.location.href = newUrl
  }, [keycloak, locale])
  const register = useCallback(() => {
      let refs = keycloak?.createAccountUrl().split('?')[1]
      let newUrl = keycloak?.createRegisterUrl({ locale: locale }) + '&' + refs
      window.location.href = newUrl
    }
    , [keycloak, locale])

  return (
    <div className={classes.headerAuthWrapper + (!initialized ? ' hidden' : '')}>
      <Button disableElevation disableRipple onClick={login} className={classes.headerAuthLink}>
        <FormattedMessage id='HeaderAuth.link.login'/>
      </Button>
      <Button disableElevation disableRipple onClick={register} className={classes.headerAuthLink}>
        <FormattedMessage id='HeaderAuth.link.register'/>
      </Button>
    </div>
  )
}

export default Anonymous
