import React from 'react'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { useStyles } from '../Styles'

const PayOthersMessages = ({isForeign}) => {
  const classes = useStyles()
  return (
    <div className={classes.PaymentOrderStepMessage}>
      <p><FormattedMessage id={`PayOthersMessages.Message1${!!isForeign?'.foreign':''}`}/></p>
      <p><FormattedMessage id='PayOthersMessages.Message2'/></p>
      <Alert severity="warning"><FormattedMessage id='PayOthersMessages.Message3'/></Alert>
    </div>
  )
}

export default PayOthersMessages
