import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from '@material-ui/core'
import { useStyles } from './Styles'
import LazyImage from '../LazyImage'
import eFinance from './../../images/e-finance.png'

const Copyrights = () => {
  const classes = useStyles()
  return (
    <div className={classes.footerCopyrights}>
      <Container maxWidth="lg">
        <p><FormattedMessage id='copyrights' values={{ symbol: '©' }}/></p>
        <div className={classes.footerCopyrightsPoweredByWrapper}>
          <h3 className={classes.footerCopyrightsPoweredBy}><FormattedMessage id='footer.copyrights.poweredBy'/></h3>
          <LazyImage src={eFinance} alt='e-finance logo' width={100}/>
        </div>
      </Container>
    </div>
  )
}

export default Copyrights
