import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import UserServices from './Services/Consumers/UserServices'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from './routes'
import { filterData } from './utils/helpers'
import { CompanyFiltersPayload, RegisterCompanyPayload } from './Services/Payloads/AuthenticatedUserPayload'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import Configuration from './Services/Api/Configuration'
import CompanyServices from './Services/Consumers/CompanyServices'

let { User_Config } = Configuration

const AuthenticatedUserContext = createContext({})

const AuthenticatedUserProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak()
  const intl = useIntl()
  const [userDataLoading, setUserDataLoading] = React.useState(false)
  const [userDataFailure, setUserDataFailure] = React.useState(false)
  const [userData, setUserData] = React.useState({})
  const [isUserActive, setIsUserActive] = React.useState(true)
  const [inactiveUserData, setInactiveUserData] = React.useState({})

  const CompanyHeaderColumns = [
    { id: 'linkedTaxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'linkedName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 170 },
    { id: 'role', label: <FormattedMessage id='CompanyHeader.UserRole'/>, minWidth: 170 },
    { id: 'userStatus', label: <FormattedMessage id='CompanyHeader.UserStatus'/>, minWidth: 100 },
    { id: 'subscription_end_date', label: <FormattedMessage id='CompanyHeader.subscriptionEndDate'/>, minWidth: 170 }
  ]
  const [userCompaniesLoading, setUserCompaniesLoading] = React.useState(false)
  const [userCompaniesFailure, setUserCompaniesFailure] = React.useState(false)
  const [userCompaniesFailure403, setUserCompaniesFailure403] = React.useState(false)
  const [hasMoreUserCompanies, setHasMoreUserCompanies] = React.useState(false)
  const [userCompaniesPage, setUserCompaniesPage] = React.useState(1)
  const [userCompanies, setUserCompanies] = React.useState([])
  const [isUserCompaniesRetrieved, setIsUserCompaniesRetrieved] = React.useState(false)
  const [isCompaniesFiltered, setIsCompaniesFiltered] = React.useState(false)
  const [companiesFilterData, setCompaniesFilterData] = React.useState(null)
  const [allUserCompaniesList, setAllUserCompaniesList] = React.useState([])
  const [allUserCompaniesListIncentives, setAllUserCompaniesListIncentives] = React.useState(null)

  const [selectedCompanyLoading, setSelectedCompanyLoading] = React.useState(false)
  const [selectedCompanyFailure, setSelectedCompanyFailure] = React.useState(false)
  const [selectedCompanyFailure403, setSelectedCompanyFailure403] = React.useState(false)
  const [selectedCompanyNotFound, setSelectedCompanyNotFound] = React.useState(false)
  const [selectedCompany, setSelectedCompany] = React.useState({})
  const [notActiveCompany, setNotActiveCompany] = React.useState(false)
  const [shouldRemoveCompanies, setShouldRemoveCompanies] = React.useState(false)

  const [verifyNewPhoneLoading, setVerifyNewPhoneLoading] = React.useState(false)
  const [verifyNewPhoneFailure, setVerifyNewPhoneFailure] = React.useState(false)
  const [verifyNewPhoneFailureMessage, setVerifyNewPhoneFailureMessage] = React.useState('')
  const [verifyNewPhoneSuccess, setVerifyNewPhoneSuccess] = React.useState(false)
  const [confirmNewPhoneSuccess, setConfirmNewPhoneSuccess] = React.useState(false)
  const [newPhoneNumber, setNewPhoneNumber] = React.useState(null)
  const [newPhoneNumberUUID, setNewPhoneNumberUUID] = React.useState(null)

  const [updateProfileFailure, setUpdateProfileFailure] = React.useState(false)
  const [updateProfileFailureMessage, setUpdateProfileFailureMessage] = React.useState('')
  const [updateProfileSuccess, setUpdateProfileSuccess] = React.useState(false)

  const [linkCompanyFailure, setLinkCompanyFailure] = React.useState(false)
  const [linkCompanyFailureMessage, setLinkCompanyFailureMessage] = React.useState('')
  const [linkCompanySuccess, setLinkCompanySuccess] = React.useState(false)
  const [linkCompanyData, setLinkCompanyData] = React.useState({})

  const [countries, setCountries] = React.useState([])
  const [countriesFailure, setCountriesFailure] = React.useState(false)
  const [governmentsLoader, setGovernmentsLoader] = React.useState(false)
  const [governments, setGovernments] = React.useState([])
  const [citiesLoader, setCitiesLoader] = React.useState(false)
  const [cities, setCities] = React.useState([])

  const [taxServicesError, setTaxServicesError] = React.useState(false)


  const getUserData = React.useCallback(() => {
    if (!!keycloak?.authenticated) {
      setUserDataFailure(false)
      setUserDataLoading(true)
      UserServices.getProfileData({ headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if ((!!result.response && result.response.status === 423)) {
            setIsUserActive(false)
            let user = keycloak?.loadUserInfo()
            user.then((result) => {
              if (!!result) {
                console.log(result)
                setInactiveUserData(result)
              }
            })
          } else {
            setUserDataFailure(true)
          }
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setUserData(result.data.data)
          } else {
            setUserDataFailure(true)
          }
        }
      }).then(() => {
        setUserDataLoading(false)
      })
    }
  }, [keycloak])

  const handleSubmitFilters = (values, FormikActions) => {
    setUserCompanies([])
    setHasMoreUserCompanies(false)
    setCompaniesFilterData({
      values: values,
      FormikActions: FormikActions
    })
    setUserCompaniesPage(1)
  }


  const resetSelectedCompany = () => {
    setSelectedCompany({})
  }

  const getAllCompaniesDataForList = React.useCallback(() => {
    if (!!keycloak?.authenticated) {
      setSelectedCompanyLoading(true)
      setSelectedCompanyFailure(false)
      setSelectedCompanyFailure403(false)
      UserServices.getCompanies({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setSelectedCompanyFailure(true)
          if (!!result.response && result.response.status === 403) {
            setSelectedCompanyFailure403(true)
          }
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            let newDataIncentives = []
            result.data.data.forEach((company) => {
              if (!!company.active && !!company.company_user_active) {
                newData.push(company)
              }
              if (!!company.company_user_active && !!company.tax_id) {
                newDataIncentives.push(company)
              }
            })
            setAllUserCompaniesList(newData)
            setAllUserCompaniesListIncentives(newDataIncentives)
          }
        }
      }).then(() => {
        setSelectedCompanyLoading(false)
      })
    }
  }, [keycloak])

  const getUserCompanies = (reset) => {
    if (!!reset) {
      setUserCompanies([])
      setHasMoreUserCompanies(false)
      setUserCompaniesPage(1)
      setCompaniesFilterData({})
    } else {
      getUserCompaniesList()
    }
  }
  const getUserCompaniesList = () => {
    if (!!keycloak?.authenticated) {
      setNotActiveCompany(false)
      setUserCompaniesFailure(false)
      setUserCompaniesFailure403(false)
      if (userCompaniesPage === 1) {
        if (!!shouldRemoveCompanies) {
          setUserCompanies([])
          setShouldRemoveCompanies(false)
        }
        setUserCompaniesLoading(true)
      }
      UserServices.getCompanies({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(CompanyFiltersPayload(userCompaniesPage, User_Config.userCompaniesLimit, companiesFilterData.values || {}))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setUserCompaniesFailure(true)
          if (!!result.response && result.response.status === 403) {
            setUserCompaniesFailure403(true)
          }
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((company) => {
              company['linkedName'] = !!company.active && !!company.company_user_active && company.name ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.name}</NavLink> : company['name'] || 'N/A'
              company['linkedTaxpayerName'] = !!company.active && !!company.company_user_active ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.taxpayer_name}</NavLink> : company['taxpayer_name']
              company['userStatus'] = !!company.company_user_active ?
                <FormattedMessage id='CompanyData.UserStatus.active'/> :
                <FormattedMessage id='CompanyData.UserStatus.notActive'/>
              if (!company.name) {
                company['name'] = 'N/A'
              }
              newData.push(company)
            })
            setUserCompanies((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (userCompaniesPage < result.data.meta.last_page) {
              setUserCompaniesPage(userCompaniesPage + 1)
              setHasMoreUserCompanies(true)
            } else {
              setCompaniesFilterData(null)
              setUserCompaniesPage(1)
              setHasMoreUserCompanies(false)
            }
          }
        }
      }).then(() => {
        setUserCompaniesLoading(false)
        setIsUserCompaniesRetrieved(true)
        if (!!companiesFilterData.FormikActions) {
          setIsCompaniesFiltered(true)
          companiesFilterData.FormikActions.setSubmitting(false)
        } else {
          setIsCompaniesFiltered(false)
        }
      })
    }
  }

  React.useEffect(() => {
      if (!!companiesFilterData && userCompaniesPage === 1) {
        getUserCompaniesList()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [companiesFilterData, userCompaniesPage])

  const checkCompanyData = (cid, reload) => {
    setNotActiveCompany(false)
    if (!reload) {
      setSelectedCompany({})
    }
    if (!!keycloak?.authenticated) {
      setSelectedCompanyFailure(false)
      setSelectedCompanyNotFound(false)
      setSelectedCompanyFailure403(false)
      if (!reload && !!userCompanies && !!userCompanies.length && !!userCompanies.filter((company) => (company.id === parseInt(cid))).length) {
        setSelectedCompany(userCompanies.filter((company) => (company.id === parseInt(cid)))[0])
      } else {
        if (!reload) {
          setSelectedCompany({})
          setSelectedCompanyLoading(true)
        }
        UserServices.getCompanyData(cid, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            if ((!!result.response && result.response.status === 404)) {
              setSelectedCompanyNotFound(true)
            } else if ((!!result.response && result.response.status === 403)) {
              setSelectedCompanyFailure403(true)
            } else {
              setSelectedCompanyFailure(true)
            }
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              let company = result.data.data
              company['linkedName'] = !!company.active && !!company.company_user_active && company.name ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.name}</NavLink> : company['name'] || 'N/A'
              company['linkedTaxpayerName'] = !!company.active && !!company.company_user_active ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.taxpayer_name}</NavLink> : company['taxpayer_name']
              company['userStatus'] = !!company.company_user_active ?
                <FormattedMessage id='CompanyData.UserStatus.active'/> :
                <FormattedMessage id='CompanyData.UserStatus.notActive'/>
              if (!company.name) {
                company['name'] = 'N/A'
              }
              setSelectedCompany(company)
              if (!!reload && !!userCompanies.length) {
                let companies = userCompanies?.map(com => com.id === company.id ? company : com) || []
                setUserCompanies(companies)
              }
              if (!userCompanies.length || !!shouldRemoveCompanies) {
                setUserCompanies([company])
                setShouldRemoveCompanies(true)
              }
            } else {
              setNotActiveCompany(true)
            }
          }
        }).then(() => {
          setSelectedCompanyLoading(false)
        })
      }
    }
  }

  const [companyUPTCPRegistrationLoading, setCompanyUPTCPRegistrationLoading] = React.useState(false)
  const [companyUPTCPRegistration, setCompanyUPTCPRegistration] = React.useState(false)
  const [companyUPTCPRegistrationFailure, setCompanyUPTCPRegistrationFailure] = React.useState(false)
  const [companyUPTCPRegistrationFailureMessage, setCompanyUPTCPRegistrationFailureMessage] = React.useState('')
  const [companyUPTCPRegistrationDialog, setCompanyUPTCPRegistrationDialog] = React.useState(false)
  const resetCompanyUPTCPRegistrationStatus = () => {
    setCompanyUPTCPRegistrationLoading(false)
    setCompanyUPTCPRegistrationFailure(false)
    setCompanyUPTCPRegistrationFailureMessage('')
    setCompanyUPTCPRegistration(false)
  }
  const closeCompanyUPTCPRegistrationDialog = () => {
    setCompanyUPTCPRegistrationDialog(false)
    resetCompanyUPTCPRegistrationStatus()
  }
  const checkCompanyUPTCPRegistration = (cid, noSubscription) => {
    setCompanyUPTCPRegistrationLoading(true)
    setCompanyUPTCPRegistration(null)
    setCompanyUPTCPRegistrationFailure(false)
    UserServices.registerCompanyUPTCP(cid, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
      setCompanyUPTCPRegistrationLoading, setCompanyUPTCPRegistrationFailure).then((result) => {
      setCompanyUPTCPRegistrationDialog(true)
      if ((!!result.response && result.response.status !== 200) || !result) {
        setCompanyUPTCPRegistrationFailure(true)
        let message = intl.formatMessage({ id: 'UPTCPUserMessage.FormErrorAlert' })
        if (!!result.response && result.response.status === 405) {
          message = intl.formatMessage({ id: `Underage.taxService.ErrorAlert${!!noSubscription ? '.noSubscription' : ''}` }, {
            a: chunks => <NavLink
              onClick={closeCompanyUPTCPRegistrationDialog}
              to={formatRoute(NamedRoutes.workspace.company.delegates, { id: cid })}>{chunks}</NavLink>
          })
        } else if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
          if (result.response.data.status.errors) {
            message = result.response.data.status.errors
          } else if (result.response.data.status.message) {
            message = result.response.data.status.message
          }
        }
        setCompanyUPTCPRegistrationFailureMessage(message)
      } else {
        if (!!result && !!result.data && !!result.data.data) {
          setCompanyUPTCPRegistration(result.data.data)
        }
      }
    }).then(() => {
      setCompanyUPTCPRegistrationLoading(false)
    })
  }

  const resetLinkCompanyStatus = () => {
    setLinkCompanyData({})
    setLinkCompanyFailure(false)
    setLinkCompanyFailureMessage('')
    setLinkCompanySuccess(false)
  }
  const linkCompanySubmitForm = (values, FormikActions, closeDialog) => {
    if (!!keycloak?.authenticated) {
      resetVerifyCompanyContactStatus()
      setLinkCompanyFailure(false)
      setLinkCompanySuccess(false)
      FormikActions.setSubmitting(true)
      let data = {
        'tax_id': values.tax_id.toString(),
        // 'email': values.email.toString(),
        // 'mobile': values.mobile.toString(),
        'main_partner_name': values.main_partner_name.toString(),
        'verification_code1': values.verification_code1.toString(),
        'verification_code2': values.verification_code2.toString(),
        'is_accountant': !!values.is_accountant ? 1 : 0
      }
      UserServices.linkCompany(data, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setLinkCompanyFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setLinkCompanyFailure(true)
          let message = intl.formatMessage({ id: 'LinkCompany.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          } else if (!!result.response && result.response.status === 404) {
            message = intl.formatMessage({ id: 'LinkCompany.FormErrorAlert404' })
          }
          setLinkCompanyFailureMessage(message)

        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let company = result.data.data
            company['linkedName'] = !!company.active && !!company.company_user_active && company.name ?
              <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.name}</NavLink> : company['name'] || 'N/A'
            company['linkedTaxpayerName'] = !!company.active && !!company.company_user_active ?
              <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>{company.taxpayer_name}</NavLink> : company['taxpayer_name']
            company['userStatus'] = !!company.company_user_active ?
              <FormattedMessage id='CompanyData.UserStatus.active'/> :
              <FormattedMessage id='CompanyData.UserStatus.notActive'/>
            setLinkCompanyData(company)
            setLinkCompanySuccess(true)
            getUserCompanies(true)
            getAllCompaniesContacts()
            getAllCompaniesDataForList()
            setTimeout(() => {
              closeDialog()
            }, 1500)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const [registerCompanyFailure, setRegisterCompanyFailure] = React.useState(false)
  const [registerCompanyFailureMessage, setRegisterCompanyFailureMessage] = React.useState('')
  const [registerCompanySuccess, setRegisterCompanySuccess] = React.useState(false)
  const [updateCompanyRegistrationSuccess, setUpdateCompanyRegistrationSuccess] = React.useState(false)
  const resetRegisterCompanyStatus = () => {
    setRegisterCompanyFailure(false)
    setRegisterCompanyFailureMessage('')
    setRegisterCompanySuccess(false)
    setUpdateCompanyRegistrationSuccess(false)
  }
  const registerCompanySubmitForm = (values, FormikActions, closeDialog, registerPlan, locale = 'ar', isLegalUpdate) => {
    if (!!keycloak?.authenticated) {
      resetVerifyCompanyContactStatus()
      resetRegisterCompanyStatus()
      FormikActions.setSubmitting(true)
      UserServices.registerCompany(RegisterCompanyPayload(values, registerPlan), registerPlan, isLegalUpdate, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setRegisterCompanyFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setRegisterCompanyFailure(true)
          let message = intl.formatMessage({ id: 'LinkCompany.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setRegisterCompanyFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            if (!!isLegalUpdate) {
              setUpdateCompanyRegistrationSuccess(true)
              setTimeout(() => {
                closeDialog()
                setTimeout(() => {
                  getUserCompanies(true)
                  getAllCompaniesContacts(0)
                }, 500)
              }, 1000)
            } else {
              setRegisterCompanySuccess(true)
              setTimeout(() => {
                window.location.href = `${Configuration.DashBoardActions_CreatTaxLink}&sap-language=${locale}`
              }, 1000)
            }
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const getCountries = () => {
    if (!!keycloak?.authenticated) {
      setCountriesFailure(false)
      UserServices.getCountries({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setCountriesFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setCountries(result.data.data)
          }
        }
      })
    }
  }
  const getGovernments = (country) => {
    if (!!keycloak?.authenticated && !!country) {
      setGovernmentsLoader(true)
      setCountriesFailure(false)
      UserServices.getGovernments(country, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setCountriesFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setGovernments(result.data.data)
          }
        }
      }).then(() => {
        setGovernmentsLoader(false)
      })
    }
  }
  const getCities = (government) => {
    if (!!keycloak?.authenticated && !!government) {
      setCitiesLoader(true)
      setCountriesFailure(false)
      UserServices.getCities(government, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setCountriesFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setCities(result.data.data)
          }
        }
      }).then(() => {
        setCitiesLoader(false)
      })
    }
  }

  const [paymentsFiltersData, setPaymentsFiltersData] = React.useState({})
  const [paymentsFiltersFailure, setPaymentsFiltersFailure] = React.useState(false)
  const getFiltersData = () => {
    if (!!keycloak?.authenticated && !Object.keys(paymentsFiltersData).length) {
      setPaymentsFiltersFailure(false)
      CompanyServices.getPaymentsFilterData({ headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setPaymentsFiltersFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setPaymentsFiltersData(result.data.data)
          }
        }
      })
    }
  }

  const updateAdditionalInfo = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      resetAdditionalInfoStatus()
      FormikActions.setSubmitting(true)
      let nationalID = { 'national_id': values.socialNumber.toString() || '' }
      if (values.country?.toString() !== '68') {
        nationalID = { 'passport_id': values.passport_id.toString() || '' }
      }
      let data = {
        'address_line1': values.address_line1.toString(),
        'address_line2': values.address_line2.toString(),
        'country_id': parseInt(values.country),
        'government_id': parseInt(values.government) || '',
        'city_id': parseInt(values.city) || '',
        'contact_channel': values.contactChannel.toString(),
        ...nationalID
      }
      UserServices.updateProfileData(data, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setVerifyNewPhoneLoading, setVerifyNewPhoneFailure, FormikActions).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setUpdateProfileFailure(true)
          let message = intl.formatMessage({ id: 'ChangePhone.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setUpdateProfileFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setUserData(result.data.data)
            setUpdateProfileSuccess(true)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }
  const resetAdditionalInfoStatus = () => {
    setUpdateProfileFailureMessage('')
    setUpdateProfileFailure(false)
    setUpdateProfileSuccess(false)
  }

  const verifyNewPhoneNumber = (newPhoneNumber, isCompanyContact) => {
    if (!!keycloak?.authenticated) {
      setNewPhoneNumber(newPhoneNumber)
      setVerifyNewPhoneFailure(false)
      setVerifyNewPhoneFailureMessage('')
      setVerifyNewPhoneSuccess(false)
      setVerifyNewPhoneLoading(true)

      UserServices.verifyPhone(newPhoneNumber, isCompanyContact, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setVerifyNewPhoneLoading, setVerifyNewPhoneFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setVerifyNewPhoneFailure(true)
          let message = intl.formatMessage({ id: 'ChangePhone.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setVerifyNewPhoneFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setNewPhoneNumberUUID(result.data.data.uuid)
            setVerifyNewPhoneSuccess(true)
          }
        }
      }).then(() => {
        setVerifyNewPhoneLoading(false)
      })
    }
  }
  const handleChangePhoneNumber = (verificationCode, FormikActions, closeDialog, isCompanyContact) => {
    if (!!keycloak?.authenticated) {
      setNewPhoneNumber(newPhoneNumber)
      setVerifyNewPhoneFailure(false)
      setVerifyNewPhoneFailureMessage('')
      setConfirmNewPhoneSuccess(false)
      setVerifyNewPhoneLoading(true)
      let data = {
        'phone': '+' + newPhoneNumber.toString(),
        'uuid': newPhoneNumberUUID.toString(),
        'otp': verificationCode.toString()
      }

      UserServices.confirmPhone(data, isCompanyContact, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setVerifyNewPhoneLoading, setVerifyNewPhoneFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setVerifyNewPhoneFailure(true)
          let message = intl.formatMessage({ id: 'UpdateProfile.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setVerifyNewPhoneFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setConfirmNewPhoneSuccess(true)
            if (!isCompanyContact) {
              setUserData(result.data.data)
            }
            FormikActions.setFieldValue('phone', '+' + newPhoneNumber.toString())
            setTimeout(() => {
              closeDialog()
              FormikActions.setFieldValue('newPhone', '')
              FormikActions.setFieldValue('VerificationCode', '')
              resetPhoneStatus()
            }, 1000)
          }
        }
      }).then(() => {
        setVerifyNewPhoneLoading(false)
      })
    }
  }

  const resetPhoneStatus = () => {
    setVerifyNewPhoneFailure(false)
    setVerifyNewPhoneFailureMessage('')
    setVerifyNewPhoneSuccess(false)
    setVerifyNewPhoneLoading(false)
    setConfirmNewPhoneSuccess(false)
  }

  React.useEffect(() => {
    // eslint-disable-next-line
    if (!!initialized) {
      getUserData()
    }
  }, [initialized, getUserData])

  React.useEffect(() => {
    // eslint-disable-next-line
    if (!!userData && !!Object.keys(userData).length) {
      getAllCompaniesDataForList()
    }
  }, [userData, getAllCompaniesDataForList])


  const [companiesContactsLoading, setCompaniesContactsLoading] = React.useState(false)
  const [companiesContactsFailure, setCompaniesContactsFailure] = React.useState(false)
  const [companiesContactsFailure403, setCompaniesContactsFailure403] = React.useState(false)
  const [hasMoreCompaniesContacts, setHasMoreCompaniesContacts] = React.useState(false)
  const [companiesContactsPage, setCompaniesContactsPage] = React.useState(1)
  const [companiesContacts, setCompaniesContacts] = React.useState([])
  const [allCompaniesContacts, setAllCompaniesContacts] = React.useState([])
  const [verifiedCompaniesContacts, setVerifiedCompaniesContacts] = React.useState([])
  const [allCompaniesContactsWithoutTaxId, setAllCompaniesContactsWithoutTaxId] = React.useState([])
  const [verifiedCompaniesContactsWithoutTaxId, setVerifiedCompaniesContactsWithoutTaxId] = React.useState([])

  const getCompaniesContacts = (forceLoad, reset) => {
    if (!!keycloak?.authenticated) {
      setCompaniesContactsFailure(false)
      setCompaniesContactsFailure403(false)
      if (!!forceLoad) {
        setCompaniesContactsPage(1)
      }
      if (!!reset) {
        setCompaniesContacts([])
      }
      if (companiesContactsPage === 1 || !!forceLoad) {
        setCompaniesContactsLoading(true)
      }
      UserServices.getCompaniesContacts({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(CompanyFiltersPayload(!!forceLoad ? 1 : companiesContactsPage, User_Config.userCompaniesContactsLimit, {}))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setCompaniesContactsFailure(true)
          if (!!result.response && result.response.status === 403) {
            setCompaniesContactsFailure403(true)
          }
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setCompaniesContacts((prevState) =>
              result.data.meta.current_page === 1 ? result.data.data :
                [...prevState, ...result.data.data])

            setUnlinkedCompaniesContactsCount(parseInt(result.data.meta.unlinked_count))
            if (companiesContactsPage < result.data.meta.last_page) {
              setCompaniesContactsPage(companiesContactsPage + 1)
              setHasMoreCompaniesContacts(true)
            } else {
              setCompaniesFilterData(null)
              setCompaniesContactsPage(1)
              setHasMoreCompaniesContacts(false)
            }
          }
        }
      }).then(() => {
        setCompaniesContactsLoading(false)
      })
    }
  }

  const [showCompaniesContactForm, setShowCompaniesContactForm] = React.useState(false)
  const [unlinkedCompaniesContactsCount, setUnlinkedCompaniesContactsCount] = React.useState(-1)
  const [configuredUnlinkedCompaniesContactsCount, setConfiguredUnlinkedCompaniesContactsCount] = React.useState(0)
  const getConfiguredUnlinkedCompaniesContactsCount = () => {
    if (!!keycloak?.authenticated) {
      CompanyServices.getSettingContent('workspace_client_taxpayer_max_unlinked_contact_info', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if (!!result && !!result.data && !!result.data.data) {
          setConfiguredUnlinkedCompaniesContactsCount(parseInt(result.data.data.value))
        }
      })
    }
  }

  React.useEffect(() => {
    if (unlinkedCompaniesContactsCount > -1 && unlinkedCompaniesContactsCount < configuredUnlinkedCompaniesContactsCount) {
      setShowCompaniesContactForm(true)
    } else {
      setShowCompaniesContactForm(false)
    }
  }, [unlinkedCompaniesContactsCount, configuredUnlinkedCompaniesContactsCount])


  const [addCompanyContactFailure, setAddCompanyContactFailure] = React.useState(false)
  const [addCompanyContactSuccess, setAddCompanyContactSuccess] = React.useState(false)
  const [addCompanyContactSuccessTaxId, setAddCompanyContactSuccessTaxId] = React.useState('')
  const [addCompanyContactFailureMessage, setAddCompanyContactFailureMessage] = React.useState('')

  const handleAddCompanyContact = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      resetAddCompanyContact()
      FormikActions.setSubmitting(true)
      let data = filterData({
        'has_tax_id': values.has_tax_id || false,
        'tax_id': !!values.has_tax_id ? values.tax_id.toString() || '' : '',
        'email': values.email.toString(),
        'phone': '+' + values.phone.toString().replace(/-|\+|\s/g, ''),
        'use_same_personal_info': values.use_same_personal_info || false
      })
      UserServices.addCompanyContact(data, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setAddCompanyContactFailure, FormikActions).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setAddCompanyContactFailure(true)
          let message = intl.formatMessage({ id: 'ChangePhone.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setAddCompanyContactFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setAddCompanyContactSuccess(true)
            setAddCompanyContactSuccessTaxId(result.data.data.tax_id)
            FormikActions.resetForm()
            getCompaniesContacts(true, true)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }
  const resetAddCompanyContact = () => {
    setAddCompanyContactFailureMessage('')
    setAddCompanyContactSuccessTaxId('')
    setAddCompanyContactFailure(false)
    setAddCompanyContactSuccess(false)
  }

  const getAllCompaniesContacts = (hasTaxId = 1) => {
    if (!!keycloak?.authenticated) {
      setCompaniesContactsLoading(true)
      setCompaniesContactsFailure(false)
      UserServices.getCompaniesContacts({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'has_tax_id': hasTaxId, 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setCompaniesContactsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            if (!hasTaxId) {
              setAllCompaniesContactsWithoutTaxId(result.data.data)
            } else {
              setAllCompaniesContacts(result.data.data)
            }
            let newData = []
            result.data.data.forEach((companyContact) => {
              if (!!companyContact.email_verified && !!companyContact.phone_verified && !companyContact.company) {
                newData.push(companyContact)
              }
            })
            if (!hasTaxId) {
              setVerifiedCompaniesContactsWithoutTaxId(newData)
            } else {
              setVerifiedCompaniesContacts(newData)
            }
          }
        }
      }).then(() => {
        setCompaniesContactsLoading(false)
      })
    }
  }

  const [emailVerificationLoading, setEmailVerificationLoading] = React.useState(false)
  const [emailVerificationFailure, setEmailVerificationFailure] = React.useState(false)
  const [emailVerificationSuccess, setEmailVerificationSuccess] = React.useState(false)
  const [ridToSelect, setRidToSelect] = React.useState(null)

  const resetVerifyCompanyContactStatus = () => {
    setEmailVerificationLoading(false)
    setEmailVerificationFailure(false)
    setEmailVerificationSuccess(false)
    setRidToSelect(null)
  }
  const verifyCompanyContactEmail = (ccid, params) => {
    if (!!keycloak?.authenticated) {
      resetVerifyCompanyContactStatus()
      setEmailVerificationLoading(true)
      UserServices.verifyCompanyContactEmail(ccid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: params
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setEmailVerificationFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setEmailVerificationSuccess(true)
            setTimeout(() => setRidToSelect(ccid), 300)
          }
        }
      }).then(() => {
        setVerifiedCompaniesContactsWithoutTaxId([])
        setVerifiedCompaniesContacts([])
        getAllCompaniesContacts(0)
        getAllCompaniesContacts()
        setEmailVerificationLoading(false)
      })
    }
  }

  const [userPopupAfterLoginContent, setUserPopupAfterLoginContent] = React.useState({})
  const [userPopupAfterLoginContentLoading, setUserPopupAfterLoginContentLoading] = React.useState(false)
  const [userPopupAfterLoginContentFailure, setUserPopupAfterLoginContentFailure] = React.useState(false)
  const getUserPopupAfterLoginContent = () => {
    if (!!keycloak?.authenticated) {
      setUserPopupAfterLoginContent({})
      setUserPopupAfterLoginContentLoading(true)
      setUserPopupAfterLoginContentFailure(false)
      CompanyServices.getPageContent('payment-notice-popup', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setUserPopupAfterLoginContentFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setUserPopupAfterLoginContent(result.data.data)
          } else {
            setUserPopupAfterLoginContentFailure(true)
          }
        }
      }).then(() => {
        setUserPopupAfterLoginContentLoading(false)
      })
    }
  }

  const [showUserPopupAfterLogin, setShowUserPopupAfterLogin] = React.useState(false)
  const [showUserPopupAfterLoginFailure, setShowUserPopupAfterLoginFailure] = React.useState(false)
  const checkUserPopupAfterLogin = (handleUserPopupDialog) => {
    if (!!keycloak?.authenticated) {
      setUserPopupAfterLoginContent({})
      setShowUserPopupAfterLoginFailure(false)
      CompanyServices.getSettingContent('workspace_client_taxpayer_payment_notes_popup_enabled', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setShowUserPopupAfterLoginFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setShowUserPopupAfterLogin(result.data.data.value.toString() === '1')
            if (result.data.data.value.toString() === '1' && !!handleUserPopupDialog) {
              handleUserPopupDialog()
            }
          } else {
            setShowUserPopupAfterLoginFailure(true)
          }
        }
      })
    }
  }


  const [incentiveLinkSuccess, setIncentiveLinkSuccess] = React.useState(false)
  const [incentiveLinkFailure, setIncentiveLinkFailure] = React.useState(false)
  const [incentiveLinkFailureMessage, setIncentiveLinkFailureMessage] = React.useState('')
  const [incentivesTaxIdValidityLoading, setIncentivesTaxIdValidityLoading] = React.useState(false)
  const [incentivesTaxIdValidityFailure, setIncentivesTaxIdValidityFailure] = React.useState(false)
  const [incentivesTaxIdValidityFailureMessage, setIncentivesTaxIdValidityFailureMessage] = React.useState('')

  const resetIncentivesFormStatus = () => {
    setIncentiveLinkSuccess(false)
    setIncentiveLinkFailure(false)
    setIncentiveLinkFailureMessage('')
    setIncentivesTaxIdValidityFailure(false)
    setIncentivesTaxIdValidityFailureMessage('')
  }

  const incentivesSubmitForm = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      resetIncentivesFormStatus()
      let data = filterData({
        'tax_id': values.tax_id || '',
        'verification_code1': values.verification_code1 || '',
        'verification_code2': values.verification_code2 || '',
        'contact_info_id': values.contact_info_id || ''
      })
      UserServices.incentiveLinkCompany(data, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setIncentiveLinkFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setIncentiveLinkFailure(true)
          let message = intl.formatMessage({ id: 'ChangePhone.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setIncentiveLinkFailureMessage(message)
        } else {
          if (!!result && !!result.data) {
            setIncentiveLinkSuccess(true)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }
  const checkIncentivesTaxIdValidity = (tax_id) => {
    if (!!keycloak?.authenticated && !!tax_id) {
      setIncentivesTaxIdValidityFailure(false)
      setIncentivesTaxIdValidityFailureMessage('')
      setIncentivesTaxIdValidityLoading(true)
      UserServices.checkIncentiveTaxIdValidity(tax_id, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { tax_id: tax_id }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setIncentivesTaxIdValidityFailure(true)
          let message = intl.formatMessage({ id: 'IncentivesTaxId.ValidityFailure.Message' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setIncentivesTaxIdValidityFailureMessage(message)
        } else if ([200, 201].includes(result?.status)) {
          window.location.href = Configuration.INCENTIVE_URL
        }
      }).then(() => {
        setIncentivesTaxIdValidityLoading(false)
      })
    }
  }
  const closeTaxServicesError = () => {
    setTaxServicesError(false)
  }
  const checkTaxServiceLink = (link) => {
    if (!!link && !!selectedCompany.tax_services_eligibility) {
      window.open(link, '_blank', 'noreferrer')
    } else {
      setTaxServicesError(true)
    }
  }

  React.useEffect(() => {
      if (!!showUserPopupAfterLogin && !!Object.keys(userData).length) {
        getUserPopupAfterLoginContent()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [showUserPopupAfterLogin, userData])

  return (
    <AuthenticatedUserContext.Provider value={{
      userDataLoading: userDataLoading,
      userDataFailure: userDataFailure,
      countriesFailure: countriesFailure,
      isUserActive: isUserActive,
      inactiveUserData: inactiveUserData,
      userData: userData,
      countries: countries,
      getCountries,
      governmentsLoader: governmentsLoader,
      governments: governments,
      getGovernments,
      citiesLoader: citiesLoader,
      cities: cities,
      getCities,

      userCompaniesLoading: userCompaniesLoading,
      userCompaniesFailure: userCompaniesFailure,
      userCompaniesFailure403: userCompaniesFailure403,
      userCompanies: userCompanies,
      shouldRemoveCompanies: shouldRemoveCompanies,
      allUserCompaniesList: allUserCompaniesList,
      allUserCompaniesListIncentives: allUserCompaniesListIncentives,
      CompanyHeaderColumns: CompanyHeaderColumns,
      hasMoreUserCompanies: hasMoreUserCompanies,
      isCompaniesFiltered: isCompaniesFiltered,
      isUserCompaniesRetrieved: isUserCompaniesRetrieved,
      getUserCompanies,
      handleSubmitFilters,
      resetSelectedCompany,

      selectedCompanyLoading: selectedCompanyLoading,
      selectedCompanyFailure: selectedCompanyFailure,
      selectedCompanyFailure403: selectedCompanyFailure403,
      selectedCompanyNotFound: selectedCompanyNotFound,
      selectedCompany: selectedCompany,
      notActiveCompany: notActiveCompany,
      checkCompanyData,

      verifyNewPhoneLoading: verifyNewPhoneLoading,
      verifyNewPhoneFailure: verifyNewPhoneFailure,
      verifyNewPhoneFailureMessage: verifyNewPhoneFailureMessage,
      verifyNewPhoneSuccess: verifyNewPhoneSuccess,
      confirmNewPhoneSuccess: confirmNewPhoneSuccess,
      handleChangePhoneNumber,
      verifyNewPhoneNumber,
      resetPhoneStatus,


      updateProfileSuccess: updateProfileSuccess,
      updateProfileFailure: updateProfileFailure,
      updateProfileFailureMessage: updateProfileFailureMessage,
      updateAdditionalInfo,
      resetAdditionalInfoStatus,

      paymentsFiltersData: paymentsFiltersData,
      paymentsFiltersFailure: paymentsFiltersFailure,
      getFiltersData,

      linkCompanySuccess: linkCompanySuccess,
      linkCompanyFailure: linkCompanyFailure,
      linkCompanyData: linkCompanyData,
      linkCompanyFailureMessage: linkCompanyFailureMessage,
      linkCompanySubmitForm,
      resetLinkCompanyStatus,

      registerCompanySuccess: registerCompanySuccess,
      registerCompanyFailure: registerCompanyFailure,
      registerCompanyFailureMessage: registerCompanyFailureMessage,
      resetRegisterCompanyStatus,
      registerCompanySubmitForm,

      updateCompanyRegistrationSuccess: updateCompanyRegistrationSuccess,

      companiesContactsLoading: companiesContactsLoading,
      companiesContactsFailure: companiesContactsFailure,
      companiesContactsFailure403: companiesContactsFailure403,
      hasMoreCompaniesContacts: hasMoreCompaniesContacts,
      companiesContacts: companiesContacts,
      getCompaniesContacts,
      getConfiguredUnlinkedCompaniesContactsCount,

      addCompanyContactFailure: addCompanyContactFailure,
      addCompanyContactFailureMessage: addCompanyContactFailureMessage,
      addCompanyContactSuccess: addCompanyContactSuccess,
      addCompanyContactSuccessTaxId: addCompanyContactSuccessTaxId,
      showCompaniesContactForm: showCompaniesContactForm,
      configuredUnlinkedCompaniesContactsCount: configuredUnlinkedCompaniesContactsCount,
      handleAddCompanyContact,
      resetAddCompanyContact,

      allCompaniesContacts: allCompaniesContacts,
      verifiedCompaniesContacts: verifiedCompaniesContacts,
      getAllCompaniesContacts,
      allCompaniesContactsWithoutTaxId: allCompaniesContactsWithoutTaxId,
      verifiedCompaniesContactsWithoutTaxId: verifiedCompaniesContactsWithoutTaxId,

      emailVerificationLoading: emailVerificationLoading,
      emailVerificationFailure: emailVerificationFailure,
      emailVerificationSuccess: emailVerificationSuccess,
      ridToSelect: ridToSelect,
      verifyCompanyContactEmail,
      resetVerifyCompanyContactStatus,

      showUserPopupAfterLogin: showUserPopupAfterLogin,
      showUserPopupAfterLoginFailure: showUserPopupAfterLoginFailure,
      checkUserPopupAfterLogin,

      userPopupAfterLoginContent: userPopupAfterLoginContent,
      userPopupAfterLoginContentLoading: userPopupAfterLoginContentLoading,
      userPopupAfterLoginContentFailure: userPopupAfterLoginContentFailure,

      companyUPTCPRegistrationLoading: companyUPTCPRegistrationLoading,
      companyUPTCPRegistration: companyUPTCPRegistration,
      companyUPTCPRegistrationFailure: companyUPTCPRegistrationFailure,
      companyUPTCPRegistrationFailureMessage: companyUPTCPRegistrationFailureMessage,
      checkCompanyUPTCPRegistration,
      resetCompanyUPTCPRegistrationStatus,

      companyUPTCPRegistrationDialog: companyUPTCPRegistrationDialog,
      closeCompanyUPTCPRegistrationDialog,

      incentiveLinkSuccess: incentiveLinkSuccess,
      incentiveLinkFailure: incentiveLinkFailure,
      incentiveLinkFailureMessage: incentiveLinkFailureMessage,
      incentivesSubmitForm,
      resetIncentivesFormStatus,

      incentivesTaxIdValidityLoading: incentivesTaxIdValidityLoading,
      incentivesTaxIdValidityFailure: incentivesTaxIdValidityFailure,
      incentivesTaxIdValidityFailureMessage: incentivesTaxIdValidityFailureMessage,
      checkIncentivesTaxIdValidity,

      taxServicesError: taxServicesError,
      closeTaxServicesError,
      checkTaxServiceLink
    }}>
      {children}
    </AuthenticatedUserContext.Provider>
  )
}
const AuthenticatedUserConsumer = AuthenticatedUserContext.Consumer

export { AuthenticatedUserProvider, AuthenticatedUserConsumer }
