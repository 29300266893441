import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { SEO } from '../../../components/Seo/seo'
import { companies } from '../../../utils/pages-meta.json'
import CompanyContent from '../../../components/Workspace/CompanyPage'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { NamedRoutes } from '../../../routes'
import { FormattedMessage, useIntl } from 'react-intl'
import { SearchLoader } from '../../../components/Loader/SearchLoader'
import InActiveUserContent from '../../../components/InActiveUserContent'
import { Container } from '@material-ui/core'
import { useStyles } from './Styles'

const Company = () => {
  let { id } = useParams()
  const intl = useIntl()
  const classes = useStyles()
  return (
    <>
      {!!id && !!parseInt(id) ? <>
          <SEO title={intl.formatMessage({ id: 'PageTitle.Company' })}
               pathname={window.location.origin}
               titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
               image={companies.image}
               description={intl.formatMessage({ id: 'PageTitle.Company' })}
          />
          <AuthenticatedUserConsumer>
            {({ userDataLoading, isUserActive, userData, checkCompanyData, allUserCompaniesList }) => (
              <>
                <Container maxWidth="lg" className={classes.TitleContainer}>
                  <h1><FormattedMessage id='workspaceMenu.link.item4'/></h1>
                </Container>
                {!!userDataLoading && <SearchLoader loading={true}/>}
                {(!!Object.keys(userData).length && !!isUserActive) ?
                  <CompanyContent allUserCompaniesList={allUserCompaniesList} checkCompanyData={checkCompanyData}
                                  cid={id}/>
                  : (!userDataLoading && <InActiveUserContent/>)
                }
              </>
            )}
          </AuthenticatedUserConsumer>
        </> :
        <Redirect to={NamedRoutes.workspace.dashboard}/>
      }
    </>
  )
}

export default Company
