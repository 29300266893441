import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  Typography, Checkbox, FormControlLabel
} from '@material-ui/core'
import { useStyles } from './Styles'
import { FormLoader } from '../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const UserMessageDialog = ({
                             userPopupAfterLoginContent, userPopupAfterLoginContentLoading,
                             userPopupAfterLoginContentFailure, handleUserPopupDialog,
                             viewUserPopupDialog, openUserPopupDialog
                           }) => {
  const classes = useStyles()
  const [isAcceptUserPopup, setIsAcceptUserPopup] = React.useState(false)
  const handleAcceptValidTimeChange = (event) => {
    setIsAcceptUserPopup(event.target.checked)
  }
  return (
    <>
      {!!viewUserPopupDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'md'}
        disableEscapeKeyDown
        disableBackdropClick
        open={openUserPopupDialog}
        classes={{ paper: classes.NewOrderDialogPaper }}
        TransitionComponent={Transition}
        onClose={handleUserPopupDialog}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
          <Typography variant="h5"><FormattedMessage id='userPopup.Title'/></Typography>
        </DialogTitle>
        <DialogContent className={classes.NewOrderDialogContent}>
          <Box py={1} px={4} className={classes.NewOrderStepsWrapper}>
              {!!userPopupAfterLoginContentLoading &&
              <Box p={5}><FormLoader loading={true}/></Box>}
              {!!userPopupAfterLoginContentFailure &&
              <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>}
              {!!Object.keys(userPopupAfterLoginContent).length && !!userPopupAfterLoginContent.content &&
              <div dangerouslySetInnerHTML={{
                __html: userPopupAfterLoginContent.content
              }}></div>
              }
          </Box>
        </DialogContent>
        <DialogActions className={classes.NewOrderDialogFooter}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAcceptUserPopup}
                onChange={handleAcceptValidTimeChange}
                color="primary"
              />
            }
            label={<span className={classes.userPopupAcceptTitle}><FormattedMessage id='userPopup.AcceptTitle'/></span>}
          />
          <Button variant={'contained'} size={'large'} color={'primary'} disabled={!isAcceptUserPopup}
                  onClick={handleUserPopupDialog}>
            <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
          </Button>
        </DialogActions>
      </Dialog>
      }
    </>
  )
}

export default UserMessageDialog
