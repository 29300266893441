import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Slide, Paper } from '@material-ui/core'
import PaymentsBalanceTableScroll from './TableScroll'
import PaymentsBalanceDialog from './BalanceDialog'
import { useStyles } from './Styles'
import { CompanyConsumer } from '../../../../../CompanyContext'

const PaymentsBalanceTable = ({ rows, columns, availableBalance, Mobile, pagesCount, handleChangePage, page, loading, totalCount }) => {
  const classes = useStyles()
  const [paymentsToPay, setPaymentsToPay] = React.useState([])
  const [allIsValid, setAllIsValid] = React.useState(true)
  const [calculatedBalance, setCalculatedBalance] = React.useState(availableBalance)
  const [paymentsToPayCount, setPaymentsToPayCount] = React.useState(0)

  const handlePaymentToPay = (id, row, isAdd) => {
    let payments = paymentsToPay
    if (!!isAdd) {
      payments.push({
        id: id,
        payment: row,
        isPartial: false,
        partialAmount: 0,
        isValid: true
      })
    } else if (!!paymentsToPay.length) {
      let index = paymentsToPay.findIndex(payment => !!payment && payment.id === id)
      if (index > -1) {
        payments.splice(index, 1)
      }
    }
    setPaymentsToPay(payments)
    setTimeout(() => {
      calculateTheCurrentAccount()
    }, 200)

  }
  const updatePaymentToPay = (id, values, isValid) => {
    let payments = paymentsToPay
    // setPaymentsToPayCount(payments.length)
    let index = paymentsToPay.findIndex(payment => !!payment && payment.id === id)
    if (index > -1) {
      payments[index] = {
        ...payments[index],
        isPartial: values.payPartial,
        partialAmount: values.amount,
        isValid: isValid
      }
      setPaymentsToPay(payments)
    }
    setTimeout(() => {
      calculateTheCurrentAccount()
    }, 200)
  }

  const calculateTheCurrentAccount = () => {
    setPaymentsToPayCount(paymentsToPay.length)
    let amount = 0
    let isValid = true
    if (!!paymentsToPay.length) {
      paymentsToPay.forEach((payment) => {
        if (!!payment.isPartial) {
          amount += (!!payment.isValid ? parseFloat(payment.partialAmount || 0) : 0)
        } else {
          amount += parseFloat(payment.payment.due_amount || 0)
        }
        isValid = (isValid && payment.isValid)
      })
    }
    setAllIsValid(isValid)
    setCalculatedBalance(parseFloat(availableBalance) - amount)
  }
  return (
    <>
      <PaymentsBalanceTableScroll rows={rows}
                                  size={'small'}
                                  columns={columns}
                                  calculatedBalance={calculatedBalance}
                                  availableBalance={availableBalance}
                                  Mobile={Mobile}
                                  pagesCount={pagesCount}
                                  handleChangePage={handleChangePage}
                                  page={page}
                                  loading={loading}
                                  totalCount={totalCount}
                                  handlePaymentToPay={handlePaymentToPay}
                                  updatePaymentToPay={updatePaymentToPay}
      />
      {parseFloat(availableBalance?.toString()) > 0 &&
      <Slide direction="up" in={!!paymentsToPayCount} mountOnEnter unmountOnExit>
        <Paper elevation={4} className={classes.BalanceRowWrapper}>
          <div className={classes.BalancePaymentsCountWrapper}>
            <FormattedMessage id='PaymentsBalance.paymentsToPayCount'
                              values={{
                                strong: chunks => <strong>{chunks}</strong>,
                                count: paymentsToPayCount
                              }}/>
          </div>
          <div className={classes.BalancePaymentsAmountWrapper}>
            <div className={classes.availableBalance + (calculatedBalance < 0 ? ' error' : '')}>
              <FormattedMessage id='workspaceMenu.calculatedBalance'
                                values={{
                                  strong: chunks => <strong>{chunks}</strong>,
                                  availableBalance: <FormattedNumber
                                    value={calculatedBalance}/>
                                }}/>
            </div>
            <CompanyConsumer>
              {({ resetHandlePaymentsBalanceStatues, handleBalanceForceRefresh, handleSubmitBalancePaymentsFilter, currentBalanceFilters }) => (
                <PaymentsBalanceDialog
                  allIsValid={allIsValid}
                  calculatedBalance={calculatedBalance}
                  paymentsToPay={paymentsToPay}
                  handleBalanceForceRefresh={handleBalanceForceRefresh}
                  resetHandlePaymentsBalanceStatues={resetHandlePaymentsBalanceStatues}
                  handleSubmitBalancePaymentsFilter={() => handleSubmitBalancePaymentsFilter(currentBalanceFilters)}
                />
              )}
            </CompanyConsumer>
          </div>
        </Paper>
      </Slide>
      }
    </>
  )
}
export default PaymentsBalanceTable