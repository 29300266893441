import React from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Fade } from 'react-reveal'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { useKeycloak } from '@react-keycloak/web'
import { useStyles } from './Styles'

const InActiveUserContent = () => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  return (
    <Fade>
      <section>
        <Container maxWidth="lg">
          <Grid container justify='center'>
            <Grid item xs={12}>
              <div className={classes.notAuthorizeWrapper}>
                <NotInterestedIcon/>
                <h1 className={classes.notAuthorizeTitle}><FormattedMessage id='UnAuthorized.title'/></h1>
                <p><FormattedMessage id='User.inActive.Message'/></p>
                <div className='item-cta-wrapper'>
                  <Button variant={'contained'} color={'primary'} className={classes.buttonLink}
                          onClick={() => keycloak.logout()}>
                    <FormattedMessage
                      id='UserMenu.logout'/></Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fade>
  )
}

export default InActiveUserContent
