import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  AccordionSummary,
  AccordionDetails, Accordion
} from '@material-ui/core'
import { PaymentClaimsFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { lg } from '../../../../utils/ResponsiveUtility'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LanguageConsumer } from '../../../../LanguageContext'

const Filter = ({ paymentsFiltersData, submitForm }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null)
  const [selectedDateTo, setSelectedDateTo] = React.useState(null)

  const filterForm = useFormik({
    initialValues: {
      tax_type: '',
      payment_item: '',
      claim_state: '',
      tax_period_from: '',
      tax_period_to: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: PaymentClaimsFilterFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const [paymentItems, setPaymentItems] = React.useState([])
  const handleClaimTypeChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('tax_type', event.target.value.toString())

    setPaymentItems([])
    filterForm.setFieldValue('TaxPaymentItemType', '')
    switch (parseInt(event.target.value)) {
      case 1:
        !!Object.keys(paymentsFiltersData).length &&
        !!paymentsFiltersData['payment_item_types.income'].length &&
        setPaymentItems(paymentsFiltersData['payment_item_types.income'])
        break
      case 2:
        !!Object.keys(paymentsFiltersData).length &&
        !!paymentsFiltersData['payment_item_types.withholding'].length &&
        setPaymentItems(paymentsFiltersData['payment_item_types.withholding'])
        break
      case 3:
        !!Object.keys(paymentsFiltersData).length &&
        !!paymentsFiltersData['payment_item_types.vat'].length &&
        setPaymentItems(paymentsFiltersData['payment_item_types.vat'])
        break
      default:
        setPaymentItems([])
    }
  }
  const handleClaimPaymentItemChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('payment_item', event.target.value.toString())
  }

  const PaymentClaimStates = [
    {
      id: 0,
      name: <FormattedMessage id='PaymentHeader.claimState'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='PaymentHeader.requestState.pending'/>,
      value: 'pending'
    },
    {
      id: 2,
      name: <FormattedMessage id='PaymentHeader.requestState.paidAndWaiting'/>,
      value: 'paid_and_waiting_acknowledgement'
    },
    {
      id: 3,
      name: <FormattedMessage id='PaymentHeader.requestState.paid'/>,
      value: 'paid'
    }
  ]
  const handleClaimStateChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('claim_state', event.target.value.toString())
  }

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date)
    filterForm.setFieldValue('tax_period_from', date || '')
  }
  const handleDateToChange = (date) => {
    setSelectedDateTo(date)
    filterForm.setFieldValue('tax_period_to', date || '')
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <LanguageConsumer>
          {({ locale }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
              <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
                <FormControl fullWidth margin={'dense'} variant='outlined'
                             className={classes.formControl + ' ' + classes.formSelectControl}>
                  <InputLabel id="tax-type-label"><FormattedMessage id='PaymentHeader.climeType'/></InputLabel>
                  <Select
                    fullWidth
                    labelId="tax-type-label"
                    id="tax_type"
                    name="tax_type"
                    MenuProps={{ disableScrollLock: !!desktop }}
                    value={filterForm.values.tax_type}
                    label={intl.formatMessage({ id: 'PaymentHeader.climeType' })}
                    onChange={handleClaimTypeChange}
                    onBlur={filterForm.handleBlur}
                    error={filterForm.errors.tax_type && filterForm.touched.tax_type !== undefined}
                  >
                    <MenuItem value=''><FormattedMessage id='PaymentHeader.climeType'/></MenuItem>
                    {!!paymentsFiltersData?.payment_tax_types?.length && paymentsFiltersData?.payment_tax_types.map((item) => (
                      <MenuItem key={item.key} value={item.code}>{item.name}</MenuItem>))}
                  </Select>
                  {filterForm.errors.tax_type && filterForm.touched.tax_type && (
                    <FormHelperText error={true}>{filterForm.errors.tax_type}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin={'dense'} variant='outlined'
                             className={classes.formControl + ' ' + classes.formSelectControl}>
                  <InputLabel id="payment-item-label"><FormattedMessage id='PaymentHeader.paymentItem'/></InputLabel>
                  <Select
                    fullWidth
                    labelId="tax-type-label"
                    id="payment_item"
                    name="payment_item"
                    MenuProps={{ disableScrollLock: !!desktop }}
                    value={filterForm.values.payment_item}
                    label={intl.formatMessage({ id: 'PaymentHeader.paymentItem' })}
                    onChange={handleClaimPaymentItemChange}
                    onBlur={filterForm.handleBlur}
                    error={filterForm.errors.payment_item && filterForm.touched.payment_item !== undefined}
                  >
                    <MenuItem value=''><FormattedMessage id='PaymentHeader.paymentItem'/></MenuItem>
                    {!!Object.keys(paymentsFiltersData).length &&
                    paymentItems.length &&
                    paymentItems.map((item) => (
                      <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                  </Select>
                  {filterForm.errors.payment_item && filterForm.touched.payment_item && (
                    <FormHelperText error={true}>{filterForm.errors.payment_item}</FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth margin={'dense'} variant='outlined'
                             className={classes.formControl + ' ' + classes.formSelectControl}>
                  <InputLabel id="claim-state-label"><FormattedMessage id='PaymentHeader.claimState'/></InputLabel>
                  <Select
                    fullWidth
                    labelId="claim-state-label"
                    id="claim_state"
                    name="claim_state"
                    MenuProps={{ disableScrollLock: !!desktop }}
                    value={filterForm.values.claim_state}
                    label={intl.formatMessage({ id: 'PaymentHeader.claimState' })}
                    onChange={handleClaimStateChange}
                    onBlur={filterForm.handleBlur}
                    error={filterForm.errors.claim_state && filterForm.touched.claim_state !== undefined}
                  >
                    {PaymentClaimStates.map((item) => (
                      <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
                  </Select>
                  {filterForm.errors.claim_state && filterForm.touched.claim_state && (
                    <FormHelperText error={true}>{filterForm.errors.claim_state}</FormHelperText>
                  )}
                </FormControl>

                <KeyboardDatePicker
                  fullWidth
                  className={classes.dateFormControl}
                  disableToolbar
                  variant="outlined"
                  format="dd-MM-yyyy"
                  margin='dense'
                  id="tax_period_from"
                  name='tax_period_from'
                  label={intl.formatMessage({ id: 'Fields.placeholder.dateFrom' })}
                  value={selectedDateFrom}
                  onChange={handleDateFromChange}
                  onBlur={filterForm.handleBlur}
                  helperText={filterForm.errors.tax_period_from && filterForm.touched.tax_period_from &&
                  <FormattedMessage id='validationSchema.date'/>}
                  error={filterForm.errors.tax_period_from && filterForm.touched.tax_period_from !== undefined}
                  cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                  okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                  KeyboardButtonProps={{
                    'aria-label': 'date from'
                  }}
                />

                <KeyboardDatePicker
                  fullWidth
                  className={classes.dateFormControl}
                  disableToolbar
                  variant="outlined"
                  format="dd-MM-yyyy"
                  margin='dense'
                  id="tax_period_to"
                  name='tax_period_to'
                  label={intl.formatMessage({ id: 'Fields.placeholder.dateTo' })}
                  value={selectedDateTo}
                  onChange={handleDateToChange}
                  onBlur={filterForm.handleBlur}
                  helperText={filterForm.errors.tax_period_to && filterForm.touched.tax_period_to &&
                  <FormattedMessage id='validationSchema.date'/>}
                  error={filterForm.errors.tax_period_to && filterForm.touched.tax_period_to !== undefined}
                  cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                  okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                  KeyboardButtonProps={{
                    'aria-label': 'date to'
                  }}
                />

                <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                        className={classes.submitButton}>
                  <FormattedMessage id='Filter.button.submit'/>
                </button>
              </form>
            </MuiPickersUtilsProvider>
          )}
        </LanguageConsumer>
      </AccordionDetails>
    </Accordion>
  )
}

export default Filter
