import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { FormattedMessage, useIntl } from 'react-intl'
import CompanyServices from './Services/Consumers/CompanyServices'
import UserServices from './Services/Consumers/UserServices'
import { filterData } from './utils/helpers'

const InsuranceServicesContext = createContext({})

const InsuranceServicesProvider = ({ children, has_taxation_file }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const InsuranceOrganizationBranchesHeaderColumns = [
    {
      id: 'organization_name',
      label: <FormattedMessage id='InsuranceOrganizationBranchesHeader.OrganizationName'/>,
      minWidth: 200
    },
    {
      id: 'organization_number',
      label: <FormattedMessage id='InsuranceOrganizationBranchesHeader.OrganizationNumber'/>,
      minWidth: 200
    },
    { id: 'insuranceActions', label: '' }
  ]
  const InsuranceOrganizationBranchFormTypes = [
    {
      id: 0,
      name: intl.formatMessage({ id: 'InsuranceOrganizationBranchFormTypes.Type' }),
      value: ''
    },
    {
      id: 1,
      name: intl.formatMessage({ id: 'InsuranceOrganizationBranchFormTypes.Type.1' }),
      value: '1'
    },
    {
      id: 2,
      name: intl.formatMessage({ id: 'InsuranceOrganizationBranchFormTypes.Type.2' }),
      value: '2'
    },
    {
      id: 6,
      name: intl.formatMessage({ id: 'InsuranceOrganizationBranchFormTypes.Type.6' }),
      value: '6'
    }
  ]


  const [showUserInsuranceRegistration, setShowUserInsuranceRegistration] = React.useState(!!has_taxation_file)
  const [showUserInsuranceRegistrationFailure, setShowUserInsuranceRegistrationFailure] = React.useState(false)
  const checkUserInsuranceRegistration = () => {
    if (!!keycloak?.authenticated) {
      setShowUserInsuranceRegistrationFailure(false)
      CompanyServices.getSettingContent('insurance_organization_linking_status', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setShowUserInsuranceRegistrationFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setShowUserInsuranceRegistration(result.data.data.value.toString() === '1')
          } else {
            setShowUserInsuranceRegistrationFailure(true)
          }
        }
      })
    }
  }

  const [insuranceOrganization, setInsuranceOrganization] = React.useState(null)
  const [insuranceOrganizationsLoading, setInsuranceOrganizationsLoading] = React.useState(false)
  const [insuranceOrganizationsFailure, setInsuranceOrganizationsFailure] = React.useState(false)
  const [insuranceOrganizationsFailure403, setInsuranceOrganizationsFailure403] = React.useState(false)
  const getAllInsuranceOrganizations = () => {
    if (!!keycloak?.authenticated) {
      setInsuranceOrganizationsLoading(true)
      setInsuranceOrganizationsFailure(false)
      setInsuranceOrganizationsFailure403(false)
      UserServices.getInsuranceOrganizations({
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setInsuranceOrganizationsFailure(true)
          if (!!result.response && result.response.status === 403) {
            setInsuranceOrganizationsFailure403(true)
          }
          setInsuranceOrganizationsLoading(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            if (!!result.data.data.length) {
              let org = result.data.data.shift()
              setInsuranceOrganization(org)
              getInsuranceOrganizationBranches(org.id)
            } else {
              setInsuranceOrganization({})
              setInsuranceOrganizationsLoading(false)
            }
          }
        }
      })
    }
  }

  const [insuranceOrganizationBranchesFiltered, setInsuranceOrganizationBranchesFiltered] = React.useState([])
  const [insuranceOrganizationBranches, setInsuranceOrganizationBranches] = React.useState([])

  const handleSubmitFilters = (values, FormikActions) => {
    let filters = filterData(values)
    let newData = insuranceOrganizationBranches
    setInsuranceOrganizationsLoading(true)
    if (!!Object.keys(filters).length) {
      Object.keys(filters).forEach((filterKey) => {
        newData = newData.filter((branch) => branch[filterKey].includes(filters[filterKey]))
      })
    }
    setTimeout(() => {
      setInsuranceOrganizationBranchesFiltered(newData)
      FormikActions.setSubmitting(false)
      setInsuranceOrganizationsLoading(false)
    }, 300)
  }
  const getInsuranceOrganizationBranches = (organization_id = insuranceOrganization?.id || '') => {
    if (!!keycloak?.authenticated) {
      setInsuranceOrganizationsLoading(true)
      UserServices.getInsuranceOrganizationBranches(organization_id, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'page': 0, 'limit': 0 }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setInsuranceOrganizationsFailure(true)
          if (!!result.response && result.response.status === 403) {
            setInsuranceOrganizationsFailure403(true)
          }
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setInsuranceOrganizationBranches(result.data.data)
            setInsuranceOrganizationBranchesFiltered(result.data.data)
          }
        }
      }).then(() => {
        setInsuranceOrganizationsLoading(false)
      })
    }
  }

  const [linkInsuranceOrganizationFailure, setLinkInsuranceOrganizationFailure] = React.useState(false)
  const [linkInsuranceOrganizationFailureMessage, setLinkInsuranceOrganizationFailureMessage] = React.useState('')
  const [linkInsuranceOrganizationSuccess, setLinkInsuranceOrganizationSuccess] = React.useState(false)
  const resetLinkInsuranceOrganizationStatus = () => {
    setLinkInsuranceOrganizationFailure(false)
    setLinkInsuranceOrganizationFailureMessage('')
    setLinkInsuranceOrganizationSuccess(false)
  }
  const linkInsuranceOrganizationSubmitForm = (values, FormikActions, closeDialog) => {
    if (!!keycloak?.authenticated) {
      resetLinkInsuranceOrganizationStatus()
      FormikActions.setSubmitting(true)
      UserServices.linkInsuranceOrganization(values, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setLinkInsuranceOrganizationFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setLinkInsuranceOrganizationFailure(true)
          let message = intl.formatMessage({ id: 'linkInsuranceOrganization.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          } else if (!!result.response && result.response.status === 404) {
            message = intl.formatMessage({ id: 'linkInsuranceOrganization.FormErrorAlert404' })
          }
          setLinkInsuranceOrganizationFailureMessage(message)

        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setLinkInsuranceOrganizationSuccess(true)
            let org = result.data.data
            getInsuranceOrganizationBranches(org.id)
            setTimeout(() => {
              closeDialog()
              setTimeout(() => setInsuranceOrganization(org), 500)
            }, 1500)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const [insuranceOrganizationBranchDownloadFailure, setInsuranceOrganizationBranchDownloadFailure] = React.useState(false)
  const [insuranceOrganizationBranchDownloadFailureMessage, setInsuranceOrganizationBranchDownloadFailureMessage] = React.useState('')
  const [insuranceOrganizationBranchDownloadSuccess, setInsuranceOrganizationBranchDownloadSuccess] = React.useState(false)

  const resetInsuranceOrganizationBranchDownloadStatus = () => {
    setInsuranceOrganizationBranchDownloadFailure(false)
    setInsuranceOrganizationBranchDownloadFailureMessage('')
    setInsuranceOrganizationBranchDownloadSuccess(false)
  }

  const handleInsuranceOrganizationBranchDownloadFormSubmit = (values, FormikActions, closeDialog, extension = 'xls') => {
    if (!!keycloak?.authenticated) {
      resetInsuranceOrganizationBranchDownloadStatus()
      let data = {
        insurance_organization_number: values.insurance_organization_number || '',
        person_insurance_number: ['1', '6'].includes(values.type.toString()) ? values.person_insurance_number : '',
        year: ['2'].includes(values.type.toString()) ? values.year : ''
      }
      UserServices.insuranceOrganizationBranchDownload(insuranceOrganization?.id, values.type, filterData(data),
        {
          headers: {
            'Authorization': `Bearer ${keycloak?.token ?? ''}`
          },
          responseType: 'blob'
        },
        FormikActions.setSubmitting, setInsuranceOrganizationBranchDownloadFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setLinkInsuranceOrganizationFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setLinkInsuranceOrganizationFailureMessage(message)

        } else {
          if (!!result && !!result.data) {
            setInsuranceOrganizationBranchDownloadSuccess(true)
            const type = result.headers['content-type']
            const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            const contentDisposition = result.headers['content-disposition']
            let typeObj = InsuranceOrganizationBranchFormTypes.filter((type) => type.value.toString() === values.type.toString())
            typeObj = typeObj.length ? typeObj[0] : {
              name: intl.formatMessage({ id: 'Fields.label.InsuranceFormType.download' })
            }
            switch (result.data.type) {
              // case 'text/csv':
              // case 'text/plain':
              //   extension = 'csv'
              //   break
              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                extension = 'xlsx'
                break
              case 'application/vnd.ms-excel':
                extension = 'xls'
                break
              default:
                extension = 'xls'
                break
            }
            let fileName = `${typeObj.name}.${extension}`
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
              if (fileNameMatch.length === 2)
                fileName = fileNameMatch[1]
            }
            link.setAttribute('download', fileName) //or any other extension
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
            setTimeout(() => {
              closeDialog()
            }, 2000)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }


  const [insuranceOrganizationBranchUploadFailure, setInsuranceOrganizationBranchUploadFailure] = React.useState(false)
  const [insuranceOrganizationBranchUploadFailureMessage, setInsuranceOrganizationBranchUploadFailureMessage] = React.useState('')
  const [insuranceOrganizationBranchUploadSuccess, setInsuranceOrganizationBranchUploadSuccess] = React.useState(false)
  const [requestUploadProgress, setRequestUploadProgress] = React.useState(0)
  const resetInsuranceOrganizationBranchUploadStatus = () => {
    setInsuranceOrganizationBranchUploadFailure(false)
    setInsuranceOrganizationBranchUploadFailureMessage('')
    setInsuranceOrganizationBranchUploadSuccess(false)
    setRequestUploadProgress(0)
  }

  const handleInsuranceOrganizationBranchUploadFormSubmit = (values, FormikActions, closeDialog) => {
    if (!!keycloak?.authenticated) {
      resetInsuranceOrganizationBranchUploadStatus()
      FormikActions.setSubmitting(true)
      let data = new FormData()
      data.append('insurance_organization_number', values.insurance_organization_number || '')
      if (!!values.insurance_form) {
        data.append('insurance_form', values.insurance_form)
      }
      UserServices.insuranceOrganizationBranchUpload(insuranceOrganization?.id, values.type, data,
        {
          headers: {
            'Authorization': `Bearer ${keycloak?.token ?? ''}`,
            'content-type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent) {
            setRequestUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }
        },
        FormikActions.setSubmitting, setInsuranceOrganizationBranchUploadFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setInsuranceOrganizationBranchUploadFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setInsuranceOrganizationBranchUploadFailureMessage(message)

        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setInsuranceOrganizationBranchUploadSuccess(true)
            setTimeout(() => {
              closeDialog()
            }, 2000)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }


  React.useEffect(() => {
      getAllInsuranceOrganizations()
      if (!has_taxation_file) {
        checkUserInsuranceRegistration()
      }

    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])


  return (
    <InsuranceServicesContext.Provider value={{
      intl: intl,
      showUserInsuranceRegistration: showUserInsuranceRegistration,
      showUserInsuranceRegistrationFailure: showUserInsuranceRegistrationFailure,

      insuranceOrganization: insuranceOrganization,
      insuranceOrganizationBranches: insuranceOrganizationBranches,
      insuranceOrganizationBranchesFiltered: insuranceOrganizationBranchesFiltered,
      insuranceOrganizationsLoading: insuranceOrganizationsLoading,
      insuranceOrganizationsFailure: insuranceOrganizationsFailure,
      insuranceOrganizationsFailure403: insuranceOrganizationsFailure403,
      InsuranceOrganizationBranchesHeaderColumns: InsuranceOrganizationBranchesHeaderColumns,
      getInsuranceOrganizationBranches,
      handleSubmitFilters,

      linkInsuranceOrganizationFailure: linkInsuranceOrganizationFailure,
      linkInsuranceOrganizationFailureMessage: linkInsuranceOrganizationFailureMessage,
      linkInsuranceOrganizationSuccess: linkInsuranceOrganizationSuccess,
      resetLinkInsuranceOrganizationStatus,
      linkInsuranceOrganizationSubmitForm,

      InsuranceOrganizationBranchFormTypes: InsuranceOrganizationBranchFormTypes,
      insuranceOrganizationBranchDownloadFailure: insuranceOrganizationBranchDownloadFailure,
      insuranceOrganizationBranchDownloadFailureMessage: insuranceOrganizationBranchDownloadFailureMessage,
      insuranceOrganizationBranchDownloadSuccess: insuranceOrganizationBranchDownloadSuccess,
      handleInsuranceOrganizationBranchDownloadFormSubmit,
      resetInsuranceOrganizationBranchDownloadStatus,

      requestUploadProgress: requestUploadProgress,
      insuranceOrganizationBranchUploadFailure: insuranceOrganizationBranchUploadFailure,
      insuranceOrganizationBranchUploadFailureMessage: insuranceOrganizationBranchUploadFailureMessage,
      insuranceOrganizationBranchUploadSuccess: insuranceOrganizationBranchUploadSuccess,
      handleInsuranceOrganizationBranchUploadFormSubmit,
      resetInsuranceOrganizationBranchUploadStatus

    }}>
      {children}
    </InsuranceServicesContext.Provider>
  )
}
const InsuranceServicesConsumer = InsuranceServicesContext.Consumer

export { InsuranceServicesProvider, InsuranceServicesConsumer }
