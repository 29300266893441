/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClickAwayListener,
  Divider,
  Tooltip,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Alert from '@material-ui/lab/Alert'
import LinkIcon from '@material-ui/icons/Link'
import { withStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../../routes'
import { formatRoute } from 'react-router-named-routes'
import { CompanyContactConsumer } from '../../../../CompanyContactContext'
import { useStyles } from '../Styles'
import CompanyContactForm from '../CompanyContactForm'
import { FormLoader } from '../../../Loader/FormLoader'

const CompanyContact = () => {
  const classes = useStyles()
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const ToggleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const [accordionOpen, setAccordionOpen] = React.useState(false)
  const handleAccordionClose = () => {
    setAccordionOpen(false)
  }

  const ToggleAccordionOpen = () => {
    setAccordionOpen(!accordionOpen)
  }

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500,
      zIndex: 50
    }
  }))(Tooltip)
  return (
    <CompanyContactConsumer>
      {({
          editCompanyContactFailure, editCompanyContactFailureMessage, editCompanyContactSuccess,
          handleEditCompanyContact, companyContact, resendEmailVerificationLoading, resendEmailVerificationFailure,
          resendEmailVerificationFailureMessage, resendEmailVerificationSuccess, editCompanyContactSuccessFlashForm
        }) => (
        <>
          <div className={classes.CompaniesContactsWrapper + ' ' + classes.SingleCompanyContactWrapper}>
            <Accordion variant={'outlined'} expanded={accordionOpen}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
                onClick={ToggleAccordionOpen}
              >
                <div className={classes.SettingHeader}>
                  <Typography variant="h6">
                    <FormattedMessage id={`CompanyContact.ProfileTitle${!companyContact.tax_id ? '.noTax' : ''}`}
                                      values={{ taxId: companyContact.tax_id }}/>
                    {!!companyContact.company && !!Object.keys(companyContact.company).length &&
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span onMouseEnter={ToggleTooltipOpen}>
                        <CustomTooltip className={classes.LinkingHint}
                                       PopperProps={{
                                         disablePortal: true
                                       }}
                                       open={tooltipOpen}
                                       onClose={handleTooltipClose}
                                       interactive
                                       disableFocusListener
                                       disableHoverListener
                                       disableTouchListener
                                       title={<FormattedMessage id='CompanyContact.linking.Hint'
                                                                values={{
                                                                  taxpayerName: !!companyContact.company.active ?
                                                                    <NavLink
                                                                      to={formatRoute(NamedRoutes.workspace.company.view, { id: companyContact.company.id })}>{companyContact.company.taxpayer_name}</NavLink> :
                                                                    companyContact.company.taxpayer_name
                                                                }}/>}
                                       arrow>
                          <LinkIcon onClick={ToggleTooltipOpen}/>
                        </CustomTooltip>
                      </span>
                    </ClickAwayListener>
                    }
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.CompaniesContactsWrapper}>
                  {!!editCompanyContactFailure && !!editCompanyContactFailureMessage &&
                  <Alert severity="error">{editCompanyContactFailureMessage}</Alert>
                  }
                  {!!editCompanyContactSuccess &&
                  <Alert severity="success"><FormattedMessage id='CompanyContact.Edit.FormSuccessAlert'
                                                              values={{ taxId: companyContact.tax_id }}/></Alert>
                  }

                  {!!resendEmailVerificationLoading && <FormLoader loading={true}/>}
                  {!!resendEmailVerificationFailure && !!resendEmailVerificationFailureMessage &&
                  <Alert severity="error">{resendEmailVerificationFailureMessage}</Alert>
                  }
                  {!!resendEmailVerificationSuccess &&
                  <Alert severity="success"><FormattedMessage id='ResendEmailVerification.FormSuccessAlert'/></Alert>
                  }

                  {!editCompanyContactSuccessFlashForm && <CompanyContactForm companyContact={companyContact} onSubmit={handleEditCompanyContact}
                                      handleAccordionClose={handleAccordionClose}/>}
                </div>
              </AccordionDetails>
              <Divider/>
            </Accordion>
          </div>
        </>
      )}
    </CompanyContactConsumer>
  )
}

export default CompanyContact
