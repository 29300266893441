import { makeStyles } from '@material-ui/core/styles'
import { TableRowBackground } from '../../../../utils/colors'

export const useNotificationsStyles = makeStyles((theme) => ({
  NotificationsContentContainer: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  itemWrapper: {
    border: `1px solid ${theme.palette.text.hint}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    margin: theme.spacing(1.25, 0),
    width: '100%',
    backgroundColor: TableRowBackground,
    textTransform: 'unset',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      }
    }
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(18)
  },
  itemValue: {},
  itemDate: {
    textAlign: 'right',
    fontSize: theme.typography.pxToRem(12),
    color: '#a198ad'
  }
}))
