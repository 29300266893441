import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  Box, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormHelperText,
  IconButton,
  InputLabel,
  MenuItem, Select, Slide, TextField,
  Typography
} from '@material-ui/core'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { FormattedMessage, useIntl } from 'react-intl'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { NamedRoutes } from '../../../routes'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../Workspace/DashboardActionLinks/Styles'
import { useFormik } from 'formik'
import { incentivesFormValidation } from '../../../utils/validationSchema'
import Configuration from '../../../Services/Api/Configuration'
import { LanguageConsumer } from '../../../LanguageContext'
import PhoneInput from 'react-phone-input-2'
import ar from 'react-phone-input-2/lang/ar.json'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const IncentivesForm = ({
                          resetIncentivesFormStatus, incentivesSubmitForm, verifiedCompaniesContacts, checkIncentivesTaxIdValidity, className,
                          getAllCompaniesContacts, userCompanies, previousIncentiveCompanies, incentiveLinkSuccess, userCompaniesLoading, companiesContactsLoading
                        }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [viewIncentivesFormDialog, setViewIncentivesFormDialog] = React.useState(false)
  const [openIncentivesFormDialog, setOpenIncentivesFormDialog] = React.useState(false)
  const [allCompanies, setAllCompanies] = React.useState([...(userCompanies || []), ...(previousIncentiveCompanies || [])])
  const handleIncentivesFormDialog = () => {
    if (!!userCompanies?.length || !!previousIncentiveCompanies?.length) {
      let companies = [...(userCompanies || []), ...(previousIncentiveCompanies || [])]
      checkIncentivesTaxIdValidity(companies[0]?.tax_id)
    } else {
      if (!openIncentivesFormDialog) {
        if (!!getAllCompaniesContacts) {
          getAllCompaniesContacts()
        }
        setViewIncentivesFormDialog(true)
      } else {
        setTimeout(() => {
          setViewIncentivesFormDialog(false)
          resetIncentiveForm()
        }, 500)
      }
      setOpenIncentivesFormDialog(!openIncentivesFormDialog)
    }
  }

  const incentivesForm = useFormik({
    initialValues: {
      tax_id: '',
      email: '',
      mobile: '',
      verification_code1: '',
      verification_code2: '',
      contact_info_id: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: incentivesFormValidation,
    onSubmit: ((values, actions) => {
      incentivesSubmitForm(values, actions, handleIncentivesFormDialog)
    })
  })

  const [timeInterval, setTimeInterval] = React.useState(null)
  const [seconds, setSeconds] = React.useState(5)

  const resetIncentiveForm = () => {
    incentivesForm.resetForm()
    if (!!timeInterval) {
      clearInterval(timeInterval)
    }
    setSeconds(5)
    resetIncentivesFormStatus()
  }

  React.useEffect(() => {
      return resetIncentiveForm
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  React.useEffect(() => {
      setAllCompanies([...(userCompanies || []), ...(previousIncentiveCompanies || [])])
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCompanies])


  React.useEffect(() => {
      if (!!incentiveLinkSuccess) {
        let interval = setInterval(() => {
          setSeconds(prevState => prevState - 1)
        }, 1000)
        setTimeInterval(interval)
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [incentiveLinkSuccess])

  React.useEffect(() => {
      if (seconds < 1) {
        clearInterval(timeInterval)
        if (!!incentiveLinkSuccess) {
          window.location.href = Configuration.INCENTIVE_URL
          setTimeout(handleIncentivesFormDialog, 1000)
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [seconds])

  const handleCompanyContactChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    incentivesForm.setFieldValue('contact_info_id', event.target.value.toString())
    if (!!event.target.value.toString()) {
      let companyContact = verifiedCompaniesContacts.filter((contact) => contact.id.toString() === event.target.value.toString())[0]
      incentivesForm.setFieldValue('tax_id', companyContact.tax_id)
      incentivesForm.setFieldValue('email', companyContact.email)
      incentivesForm.setFieldValue('mobile', companyContact.phone)
    } else {
      incentivesForm.setFieldValue('tax_id', '')
      incentivesForm.setFieldValue('email', '')
      incentivesForm.setFieldValue('mobile', '20')
    }
    setTimeout(() => incentivesForm.setFieldTouched('tax_id', true, true), 50)
  }

  return (
    <div className={className}>
      <h4><FormattedMessage
        id={`DashboardContent.ActionLinks.Incentives.title${allCompanies?.length ? '.withTax' : ''}`}
        values={{ taxId: allCompanies[0]?.tax_id }}/></h4>
      <Button size="large" disabled={!!userCompaniesLoading} onClick={handleIncentivesFormDialog} variant="contained"
              color="primary">
        <FormattedMessage id={`DashboardContent.ActionLinks.Incentives${allCompanies?.length ? '.withTax' : ''}`}/>
      </Button>
      {!!viewIncentivesFormDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'sm'}
        open={openIncentivesFormDialog}
        classes={{ paper: classes.LinkCompanyDialogPaper }}
        TransitionComponent={Transition}
        onClose={handleIncentivesFormDialog}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <AuthenticatedUserConsumer>
          {({ incentiveLinkFailure, incentiveLinkFailureMessage }) => (
            <>
              <DialogTitle disableTypography className={classes.LinkCompanyDialogHeader}>
                <Typography variant="h5"><FormattedMessage
                  id='Incentives.Dialog.Title'/></Typography>
                <IconButton
                  edge={false}
                  className={classes.LinkCompanyDialogClose}
                  color="inherit"
                  aria-label="close dialog"
                  onClick={handleIncentivesFormDialog}
                >
                  <CloseIcon/>
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.LinkCompanyDialogContent}>
                <Box px={4} pt={4} pb={0.5} className={classes.LinkCompanyWrapper}>

                  <form onSubmit={incentivesForm.handleSubmit} noValidate autoComplete="off"
                        className={classes.formWrapper}>

                    {!companiesContactsLoading && !verifiedCompaniesContacts?.length &&
                    <Alert severity="error">
                      <FormattedMessage id='Incentives.Contacts.NotExistError'
                                        values={{
                                          a: chunks => <NavLink
                                            to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                        }}/>
                    </Alert>
                    }

                    {!!incentiveLinkFailure && !!incentiveLinkFailureMessage &&
                    <Alert severity="error">{incentiveLinkFailureMessage}</Alert>
                    }
                    {!!incentiveLinkSuccess &&
                    <Alert severity="success">
                      <FormattedMessage id='IncentivesForm.Success' values={{
                        seconds: seconds
                      }}/></Alert>
                    }

                    <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                      <InputLabel id="incentivesForm.tax_id"><FormattedMessage
                        id='Fields.label.companyTaxId'/></InputLabel>
                      <FormControl variant='outlined'>
                        <Select
                          fullWidth
                          id="linkCompanyForm.tax_id"
                          margin="dense"
                          displayEmpty
                          MenuProps={{ disableScrollLock: true }}
                          name="contact_info_id"
                          value={incentivesForm.values.contact_info_id}
                          onChange={handleCompanyContactChange}
                          onBlur={incentivesForm.handleBlur}
                          error={incentivesForm.errors.tax_id && incentivesForm.touched.tax_id !== undefined}
                        >
                          <MenuItem value=''><FormattedMessage
                            id='incentivesForm.placeholder.companyTaxId'/></MenuItem>
                          {!!verifiedCompaniesContacts.length && verifiedCompaniesContacts.map((companyContact) => (
                            <MenuItem key={companyContact.id}
                                      value={companyContact.id}>{companyContact.tax_id}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          <FormattedMessage id='linkCompanyForm.addCompanyContact'
                                            values={{
                                              a: chunks => <NavLink
                                                to={NamedRoutes.workspace.editCompanyContact}
                                                onClick={handleIncentivesFormDialog}>{chunks}</NavLink>
                                            }}/>
                        </FormHelperText>
                        {incentivesForm.errors.tax_id && incentivesForm.touched.tax_id && (
                          <FormHelperText error={true}>{incentivesForm.errors.tax_id}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="incentivesForm.email"> {<FormattedMessage
                        id='Fields.label.taxEmail'/>} </InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.email' })}
                          id="incentivesForm.email"
                          type={'email'}
                          disabled={true}
                          margin="dense"
                          variant="outlined"
                          name='email'
                          value={incentivesForm.values.email}
                        />
                      </FormControl>
                    </div>

                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="incentivesForm.mobile"><FormattedMessage
                        id='Fields.label.mobile'/></InputLabel>
                      <LanguageConsumer>
                        {({ locale }) => (
                          <div className={classes.PhoneInputWrapper}>
                            <PhoneInput
                              disabled={true}
                              enableSearch={false}
                              jumpCursorToEnd={true}
                              country={'eg'}
                              localization={locale === 'ar' ? ar : ''}
                              specialLabel={''}
                              excludeCountries={['il']}
                              value={incentivesForm.values.mobile}
                            />
                          </div>
                        )}
                      </LanguageConsumer>
                    </div>

                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="incentivesForm.verification_code1"> {<FormattedMessage
                        id='Fields.label.VerificationCode1'/>} </InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.VerificationCode1' })}
                          id="incentivesForm.verification_code1"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='verification_code1'
                          onChange={incentivesForm.handleChange}
                          onBlur={incentivesForm.handleBlur}
                          value={incentivesForm.values.verification_code1}
                          helperText={incentivesForm.errors.verification_code1 && incentivesForm.touched.verification_code1 && incentivesForm.errors.verification_code1}
                          error={incentivesForm.errors.verification_code1 && incentivesForm.touched.verification_code1 !== undefined}
                        />
                      </FormControl>
                    </div>

                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="incentivesForm.verification_code2"> {<FormattedMessage
                        id='Fields.label.VerificationCode2'/>} </InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.VerificationCode2' })}
                          id="incentivesForm.verification_code2"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='verification_code2'
                          onChange={incentivesForm.handleChange}
                          onBlur={incentivesForm.handleBlur}
                          value={incentivesForm.values.verification_code2}
                          helperText={incentivesForm.errors.verification_code2 && incentivesForm.touched.verification_code2 && incentivesForm.errors.verification_code2}
                          error={incentivesForm.errors.verification_code2 && incentivesForm.touched.verification_code2 !== undefined}
                        />
                      </FormControl>
                    </div>

                    {incentivesForm.isSubmitting && <FormLoader loading={true}/>}
                  </form>
                </Box>
              </DialogContent>
              <DialogActions className={classes.LinkCompanyDialogFooter}>
                <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                        onClick={incentivesForm.handleSubmit}
                        disabled={incentivesForm.isSubmitting || !incentivesForm.isValid || !!incentiveLinkSuccess}
                        className={classes.LinkCompanyFormSubmit}>
                  <FormattedMessage id={`incentivesForm.button.submit`}/>
                </Button>
                <Button onClick={handleIncentivesFormDialog} color="primary">
                  <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
                </Button>
              </DialogActions>
            </>
          )}
        </AuthenticatedUserConsumer>
      </Dialog>
      }
    </div>
  )
}

export default IncentivesForm
