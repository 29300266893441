import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  formWrapper: {
    position: 'relative'
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogFooter: {
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    position: 'relative',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  }
}))
