import React from 'react'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { parse, isDate } from 'date-fns'

function parseDateString (value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd-MM-yyyy', new Date())

  return parsedDate
}

export const partialAmountFormValidation = yup.object().shape({
  amount: yup.number().when(['payPartial', 'minimum', 'maximum'], (val, min, max) => {
    return (!!val) ? yup.number()
      .required(<FormattedMessage id='validationSchema.required'/>)
      .positive(<FormattedMessage id='validationSchema.claimAmountPartialPositive'/>)
      .min(min, <FormattedMessage id='validationSchema.moreThanMinimum'
                                  values={{ min: min }}/>)
      .max(max, <FormattedMessage id='validationSchema.lessThanMaximum'
                                  values={{ max: max }}/>) : yup.number()
  }),
  payPartial: yup.boolean()
})
export const downPaymentFormValidation = yup.object().shape({
  amount: yup.number().when(['minimum', 'maximum'], (min, max) => {
    return (!!min && !!max) ? yup.number()
      .required(<FormattedMessage id='validationSchema.required'/>)
      .positive(<FormattedMessage id='validationSchema.downPaymentAmountPositive'/>)
      .min(min, <FormattedMessage id='validationSchema.downPaymentMoreThanMinimum'
                                  values={{ min: min }}/>)
      .max(max, <FormattedMessage id='validationSchema.downPaymentLessThanMaximum'
                                  values={{ max: max }}/>) : yup.number()
  })
})
export const additionalInfoFormValidation = yup.object().shape({
  newPhone: yup.string().trim(),
  socialNumber: yup.lazy(value => !!value ? yup.string().trim()
      .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
      .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
        message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
      })
      .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
      .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>)
    : yup.string().trim()),
  passport_id: yup.string().trim(),
  country: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  government: yup.string().trim(),
  city: yup.string().trim(),
  address_line1: yup.string().trim(),
  address_line2: yup.string().trim()
})

export const CompanyContactFormValidation = yup.object().shape({
  has_tax_id: yup.boolean(),
  tax_id: yup.string().when('has_tax_id', (val) => {
    return !!val ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
      .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>)
      .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>) : yup.string()
  }),
  newPhone: yup.string().trim(),
  phoneCountryCode: yup.string().trim(),
  email: yup.string().trim()
    .required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>),
  phone: yup.string().when('phoneCountryCode', (countryCode) => {
    return (countryCode === 'eg') ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      .min(13, <FormattedMessage id='validationSchema.required'/>)
      .max(17, <FormattedMessage id='validationSchema.required'/>) : yup.string().trim()
  }),
  use_same_personal_info: yup.boolean()
})

export const linkCompanyFormValidation = yup.object().shape({
  tax_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
    .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>)
    .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>),
  main_partner_name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  verification_code1: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  verification_code2: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  is_accountant: yup.boolean()
})

export const incentivesFormValidation = yup.object().shape({
  tax_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
        .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>),
        // .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>),
  verification_code1: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  verification_code2: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  contact_info_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
})

export const registerCompanyFormValidation = yup.object().shape({
  tax_registration_type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  tax_registration_category: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),

  full_name: yup.string().when(['tax_registration_category', 'locale'], (cat, locale) => {
    return cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches((locale === 'ar') ? /^[\p{Script=Arabic}\s]+$/u : /^[a-zA-Z\s]+$/u, {
          message: <FormattedMessage id='validationSchema.full_name'/>
        })
      : yup.string()
  }),

  code_iso3: yup.string().when(['tax_registration_type', 'tax_registration_category'], (type, cat) => {
    return type === 'voluntary' && cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
      : yup.string()
  }),

  resident_country: yup.string().when(['tax_registration_type', 'tax_registration_category'], (type, cat) => {
    return type === 'voluntary' && cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
      : yup.string()
  }),

  national_id: yup.string().when(['tax_registration_type', 'tax_registration_category', 'code_iso3'], (type, cat, nationality) => {
    return type === 'voluntary' && cat === 'natural_person' && nationality === 'EGY' ? yup.string().trim().required(
      <FormattedMessage
        id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
        .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
          message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
        })
        .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
        .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>)
      : yup.string()
  }),

  passport_id: yup.string().when(['tax_registration_type', 'tax_registration_category', 'code_iso3'], (type, cat, nationality) => {
    return type === 'voluntary' && cat === 'natural_person' && nationality !== 'EGY' ? yup.string().trim().required(
      <FormattedMessage
        id='validationSchema.required'/>)
        .matches(/^[a-zA-Z0-9]+$/, { message: <FormattedMessage id='validationSchema.passportNumberOnlyNumbers'/> })
      : yup.string()
  }),

  previous_tax_id: yup.string().when(['tax_registration_type', 'tax_registration_category', 'newOrUpdateStatus'], (type, cat, newOrUpdateStatus) => {
    return (type === 'voluntary' && cat === 'juridical_person' && newOrUpdateStatus === 'legal_update')
      ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
        .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>)
        .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>)
      : yup.string()
  }),

  previous_registration_group: yup.string().when(['tax_registration_type', 'tax_registration_category', 'newOrUpdateStatus'], (type, cat, newOrUpdateStatus) => {
    return (type === 'voluntary' && cat === 'juridical_person' && newOrUpdateStatus === 'legal_update')
      ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  }),

  company_name: yup.string().when(['tax_registration_category', 'locale'], (cat, locale) => {
    return cat === 'juridical_person'
      ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches((locale === 'ar') ? /^[\p{Script=Arabic}\s\p{N}]+$/u : /^[a-zA-Z0-9\s]+$/u, {
          message: <FormattedMessage id='validationSchema.company_name'/>
        })
      : yup.string()
  }),

  foundation_id: yup.string().when(['tax_registration_type', 'tax_registration_category'], (type, cat) => {
    return type === 'voluntary' && cat === 'juridical_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
      : yup.string()
  }),
  foundation_year: yup.string().when(['tax_registration_type', 'tax_registration_category'], (type, cat) => {
    return type === 'voluntary' && cat === 'juridical_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
        .min(4, <FormattedMessage id='validationSchema.invalidYear'/>)
        .max(4, <FormattedMessage id='validationSchema.invalidYear'/>)
      : yup.string()
  }),


  contact_info_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),

  verification_code1: yup.string().when('tax_registration_type', (type) => {
    return type === 'suomoto' ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  }),
  verification_code2: yup.string().when('tax_registration_type', (type) => {
    return type === 'suomoto' ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  }),
  verification_code3: yup.string().when('tax_registration_type', (type) => {
    return type === 'suomoto' ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  })

})
export const simpleRegisterQuestionnaireFormValidation = yup.object().shape({
  reason_for_registration: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  need_tax_card: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  practice_activity: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  commercial_register: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
})
export const simpleRegisterCompanyFormValidation = yup.object().shape({
  tax_registration_category: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),

  full_name: yup.string().when(['tax_registration_category'], (cat) => {
    return cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/(^[\p{Script=Arabic}\s]+$)|(^[a-zA-Z\s]+$)/u, {
          message: <FormattedMessage id='validationSchema.simple.full_name'/>
        })
      : yup.string()
  }),

  code_iso3: yup.string().when('tax_registration_category', (cat) => {
    return cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
      : yup.string()
  }),

  resident_country: yup.string().when('tax_registration_category', (cat) => {
    return cat === 'natural_person' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
      : yup.string()
  }),

  national_id: yup.string().when(['tax_registration_category', 'code_iso3'], (cat, nationality) => {
    return cat === 'natural_person' && nationality === 'EGY' ? yup.string().trim().required(
      <FormattedMessage
        id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
        .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
          message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
        })
        .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
        .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>)
      : yup.string()
  }),

  passport_id: yup.string().when(['tax_registration_category', 'code_iso3'], (cat, nationality) => {
    return cat === 'natural_person' && nationality !== 'EGY' ? yup.string().trim().required(
      <FormattedMessage
        id='validationSchema.required'/>)
        .matches(/^[a-zA-Z0-9]+$/, { message: <FormattedMessage id='validationSchema.passportNumberOnlyNumbers'/> })
      : yup.string()
  }),

  company_name: yup.string().when(['tax_registration_category'], (cat) => {
    return cat === 'juridical_person'
      ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/(^[\p{Script=Arabic}\s\p{N}]+$)|(^[a-zA-Z\s\p{N}]+$)/u, {
          message: <FormattedMessage id='validationSchema.simple.company_name'/>
        })
      : yup.string()
  }),

  foundation_id: yup.string(),
  foundation_year: yup.lazy(value => !!value ?
    yup.string().when('tax_registration_category', (cat) => {
      return cat === 'juridical_person' ? yup.string().trim()
          .min(4, <FormattedMessage id='validationSchema.invalidYear'/>)
          .max(4, <FormattedMessage id='validationSchema.invalidYear'/>)
        : yup.string()
    })
    : yup.string()),

  contact_info_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const linkInsuranceOrganizationFormValidation = yup.object().shape({
  tax_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
    .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>)
    .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>),
  organization_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.organizationNumberOnlyNumbers'/> })
    .max(9, <FormattedMessage id='validationSchema.organizationNumberLengthMax'/>),
  owner_national_id: yup.string().trim().required(<FormattedMessage
    id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
    .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
      message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
    })
    .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
    .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>),
  delegate_national_id: yup.string().trim().required(<FormattedMessage
    id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
    .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
      message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
    })
    .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
    .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>),
  delegate_phone: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(13, <FormattedMessage id='validationSchema.required'/>)
    .max(17, <FormattedMessage id='validationSchema.required'/>)
})
export const FilterFormValidation = yup.object().shape({
  dateFrom: yup.date(),
  dateTo: yup.date()
})
export const CompaniesFormValidation = yup.object().shape({
  taxpayer_name: yup.string(),
  name: yup.string(),
  tax_id: yup.string().trim()
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxOnlyNumbers'/> })
    .min(9, <FormattedMessage id='validationSchema.taxLengthMin'/>)
    .max(10, <FormattedMessage id='validationSchema.taxLengthMax'/>),
  role_name: yup.string(),
  active: yup.string()
})
export const CompanyRequestsFormValidation = yup.object().shape({
  request_number: yup.string(),
  payment_claim_number: yup.string(),
  payment_source_type: yup.string(),
  request_state: yup.string(),
  created_from: yup.date().transform(parseDateString),
  created_to: yup.date().transform(parseDateString)
})
export const CompanyReceiptsFormValidation = yup.object().shape({
  receipt_number: yup.string(),
  request_number: yup.string(),
  payment_claim_number: yup.string(),
  payment_item: yup.string(),
  payment_tax_period_from: yup.date().transform(parseDateString),
  payment_tax_period_to: yup.date().transform(parseDateString),
  created_from: yup.date().transform(parseDateString),
  created_to: yup.date().transform(parseDateString)
})

export const CompaniesNotificationsFilterFormValidation = yup.object().shape({
  dateFrom: yup.date(),
  dateTo: yup.date()
})
export const CompaniesPaymentsFilterFormValidation = yup.object().shape({
  ClaimtStatus: yup.string().trim(),
  TaxPaymentType: yup.string().trim(),
  TaxPaymentItemType: yup.string().trim(),
  RegistrationPaymentType: yup.string().trim(),
  Recent: yup.string().trim(),
  QuarterNumber: yup.string().trim(),
  TaxationPeriod_From: yup.date().transform(parseDateString),
  TaxationPeriod_To: yup.date().transform(parseDateString)
})
export const CompaniesBalancePaymentsFilterFormValidation = yup.object().shape({
  ClaimtStatus: yup.string().trim(),
  TaxPaymentType: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
  // TaxType: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
})
export const PaymentClaimsFilterFormValidation = yup.object().shape({
  tax_type: yup.string().trim(),
  payment_item: yup.string().trim(),
  claim_state: yup.string().trim(),
  tax_period_from: yup.date().transform(parseDateString),
  tax_period_to: yup.date().transform(parseDateString)
})

export const DownPaymentFilterFormValidation = yup.object().shape({
  request_number: yup.string().trim(),
  request_state: yup.string().trim(),
  payment_tax_period_from: yup.date().transform(parseDateString),
  payment_tax_period_to: yup.date().transform(parseDateString),
  created_from: yup.date().transform(parseDateString),
  created_to: yup.date().transform(parseDateString)
})

export const paymentClaimFormValidation = yup.object().shape({
  inquiry_request_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  tax_type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  claim_amount: yup.number().required(<FormattedMessage id='validationSchema.required'/>)
    .positive(<FormattedMessage id='validationSchema.claimAmountPositive'/>),
  payment_item: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  tax_period_from: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 1) ? yup.date().transform(parseDateString).required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),
  tax_period_to: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 1) ? yup.date().transform(parseDateString).required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),
  owner_tax_registration_number: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 1) ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  }),
  tax_period_year: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 2 || parseInt(val) === 3) ?
      yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .min(4, <FormattedMessage id='validationSchema.invalidYear'/>)
        .max(4, <FormattedMessage id='validationSchema.invalidYear'/>)
      : yup.string().trim()
  }),
  tax_period_quarter_number: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 2) ? yup.number().required(<FormattedMessage id='validationSchema.required'/>)
        .min(1, <FormattedMessage id='validationSchema.invalidQuarter'/>)
        .max(4, <FormattedMessage id='validationSchema.invalidQuarter'/>)
      : yup.string()
  }),
  tax_period_month: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 3) ?
      yup.number().required(<FormattedMessage id='validationSchema.required'/>)
        .min(1, <FormattedMessage id='validationSchema.invalidMonth'/>)
        .max(12, <FormattedMessage id='validationSchema.invalidMonth'/>)
      : yup.string()
  }),
  registration_payment_type: yup.string().when('tax_type', (val) => {
    return (parseInt(val) === 3) ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
      : yup.string()
  })
})

export const payNonCoreFormValidation = yup.object().shape({
  core_payment_tax_payment_type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  core_payment_tax_payment_item: yup.string().trim(),
  core_payment_claim_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  payment_source: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  claim_id: yup.string().when('payment_source', (val) => {
    return (parseInt(val) === 1) ? yup.string().trim().required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),
  transaction_number: yup.string().when('payment_source', (val) => {
    return ([2, 3].includes(parseInt(val)))
      ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.transactionNumberOnlyNumbers'/> })
      : yup.string()
  }),
  payment_method: yup.string().when('payment_source', (val) => {
    return (parseInt(val) === 4) ? yup.string().trim().required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),
  taxation_period_from: yup.string().when('payment_source', (val) => {
    return (parseInt(val) === 4) ? yup.date().transform(parseDateString).required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),
  taxation_period_to: yup.string().when('payment_source', (val) => {
    return (parseInt(val) === 4) ? yup.date().transform(parseDateString).required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  })
})

export const DelegatesFilterFormValidation = yup.object().shape({
  username: yup.string(),
  company_role_id: yup.string()
})

export const DelegateFormValidation = yup.object().shape({
  email: yup.string().trim()
    .required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>),
  phone: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  roles: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  is_accountant: yup.boolean(),
  nid: yup.string().when('is_accountant', (val) => {
    return (!!val) ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
        .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|06|07|08|09|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
          message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
        })
        .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
        .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>)
      : yup.string()
  }),
  accountant_ref_number: yup.string().when('is_accountant', (val) => {
    return (!!val) ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.taxAccountNumbers'/> })
      : yup.string()
  })
})

export const EcommerceFormValidation = yup.object().shape({
  name_en: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z\s]+$/,{ message:<FormattedMessage id='validationSchema.onlyEnglishLetters'/>})
    .required(<FormattedMessage id='validationSchema.required'/>),

    // name_ar: yup
    // .string()
    // .trim()
    // .matches(/^[\u0600-\u06FF\s]+$/, {message:<FormattedMessage id='validationSchema.onlyArabicLetters'/>})
    // .required(<FormattedMessage id='validationSchema.required'/>), 
    url: yup
    .string()
    .trim()
    .matches(/^(https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/\S*)?$/,{ message: <FormattedMessage id='validationSchema.invalidUrlFormat'/> })
    .required(<FormattedMessage id='validationSchema.required'/>),
  
    services:yup.array().min(1).required(<FormattedMessage id='validationSchema.required'/>),
})

export const insuranceOrganizationBranchDownloadFormFormValidation = yup.object().shape({
  type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  insurance_organization_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),

  person_insurance_number: yup.string().when('type', (type) => {
    return ['1', '6'].includes(type) ? yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
        .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.insuranceNumberOnlyNumbers'/> })
        .max(9, <FormattedMessage id='validationSchema.insuranceNumberLengthMax'/>)
      : yup.string()
  }),
  year: yup.string().when('type', (type) => {
    return type === '2' ? yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
        .min(4, <FormattedMessage id='validationSchema.invalidYear'/>)
        .max(4, <FormattedMessage id='validationSchema.invalidYear'/>)
      : yup.string()
  })
})

export const insuranceOrganizationBranchUploadFormFormValidation = yup.object().shape({
  type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  insurance_organization_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  insurance_form: yup.mixed().required(<FormattedMessage id='validationSchema.required'/>)
})
