import React from 'react'
import {FormattedMessage} from 'react-intl'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {useStyles} from './Styles'
import {FormLoader} from '../../../../Loader/FormLoader'
import CopyClipped from '../CopyClipped'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import WarningIcon from '@material-ui/icons/Warning'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteEcommerce = ({
                           handleClientSecretGeneration,
                           viewSecretEcommerceDialog,
                           openViewClientSecret,
                           loadingClientSecretData,
                           ecommerceSecretFailure,
                           ecommerceSecretFailureMessage,
                           handleReGenerationClientSecret,
                           clientSecretData
                         }) => {
  const classes = useStyles()
  React.useEffect(() => {
    handleReGenerationClientSecret()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {!!viewSecretEcommerceDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'md'}
        open={openViewClientSecret}
        classes={{paper: classes.NewOrderDialogPaper}}
        TransitionComponent={Transition}
        onClose={handleClientSecretGeneration}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <IconButton
          edge={false}
          className={classes.NewOrderDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleClientSecretGeneration}
        >
          <CloseIcon/>
        </IconButton>
        <DialogContent className={classes.NewOrderDialogContent}>
          {
            loadingClientSecretData ?
              <FormLoader/>
              :


              <Box px={4} pt={4} pb={1}>

                <div className={classes.DeleteOrderWrapper}>
                  { !ecommerceSecretFailure ? <CheckCircleOutlineIcon
                    className={classes.SuccessWarningIcon}
                  /> : <WarningIcon className={classes.DeleteWarningIcon} />}
                  <p>
                    {!!ecommerceSecretFailure? <p>{ecommerceSecretFailureMessage}</p  > :<FormattedMessage id="Ecommerce.ClientSecret.message"/>}
                  </p>
                  { !ecommerceSecretFailure&& <div className={classes.contentWrapper}>
                    <p><FormattedMessage id="EcommerceHeader.Success.ClientSecret"/></p>
                    <div className={classes.contentResult}><span>{clientSecretData?.client_secret}</span>
                      <CopyClipped text={clientSecretData?.client_secret}/>
                    </div>
                  </div>}
                    </div>
              </Box>
          }
        </DialogContent>

      </Dialog>
      }
    </>
  )
}

export default DeleteEcommerce
