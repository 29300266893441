import React from 'react'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import CompanyDelegates from './CompanyDelegates'
import { SEO } from '../../../Seo/seo'
import { delegates } from '../../../../utils/pages-meta'
import { useIntl } from 'react-intl'
import InActiveCompanyContent from '../../../InActiveCompanyContent'
import UnAuthorize from '../../../UnAuthorize'


const DelegatesContent = ({ checkCompanyData, cid }) => {
  const intl = useIntl()
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany, getFiltersData, paymentsFiltersData, paymentsFiltersFailure, checkCompanyData }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={intl.formatMessage({ id: 'PageTitle.Delegates' }) + ' - ' + selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                   image={delegates.image}
                   description={intl.formatMessage({ id: 'PageTitle.Delegates' }) + ' - ' + selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                (!!selectedCompany.is_ecommerce ? <UnAuthorize/> :
                  <CompanyConsumer>
                    {({ getDelegates, setBreadCrumbLinks, setBreadCrumbCurrent, setBreadCrumbButton }) => (
                      <CompanyDelegates selectedCompany={selectedCompany}
                                        getDelegates={getDelegates}
                                        getFiltersData={getFiltersData}
                                        paymentsFiltersData={paymentsFiltersData}
                                        paymentsFiltersFailure={paymentsFiltersFailure}
                                        setBreadCrumbLinks={setBreadCrumbLinks}
                                        setBreadCrumbCurrent={setBreadCrumbCurrent}
                                        setBreadCrumbButton={setBreadCrumbButton}
                                        checkCompanyData={checkCompanyData}/>
                    )}
                  </CompanyConsumer>)
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default DelegatesContent
