import { makeStyles } from '@material-ui/core/styles'
import { BreadCrumbLink, BreadCrumbLinkActive } from '../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  BreadCrumbContainer: {
    position: 'relative',
    zIndex: 10,
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(7),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .MuiBreadcrumbs-separator': {
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      },
      '& svg': {
        transform: theme.direction === 'ltr' ? 'scaleX(1)' : 'scaleX(-1)'
      }
    },
    '& a': {
      padding: theme.spacing(1.25),
      color: BreadCrumbLink,
      fontWeight: theme.typography.fontWeightBold,
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        fontSize: theme.typography.pxToRem(14)
      },
      '& svg': {
        verticalAlign: 'bottom'
      }
    }
  },
  BreadCrumbActiveLink: {
    padding: theme.spacing(1.25),
    color: BreadCrumbLinkActive,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(14)
    },
  },
  dashboardNavigation: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& ul': {
      marginBottom: theme.spacing(0)
    },
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1, 0),
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 0)
    }
  },

  CompaniesListBtn: {
    position: 'absolute',
    top: '100%',
    left: theme.spacing(2),
    borderRadius: theme.spacing(1),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(0.375, 2),
    backgroundColor: theme.palette.primary.dark + '!important',
    '&:hover': {
      backgroundColor: theme.palette.primary.hover + '!important'
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(16),
      marginRight: theme.spacing(1)
    }
  },
  CompaniesSubMenu: {
    position: 'absolute !important',
    zIndex: '10 !important',
    maxHeight: '70vh',
    '& .MuiListItem-button': {
      transition: 'all 0.3s ease-in-out',
      '& a': {
        display: 'block'
      },
      '&.active': {
        opacity: 1
      },
      '&:hover, &.active': {
        backgroundColor: theme.palette.primary.light,
        '& a': {
          color: theme.palette.common.white
        }
      },
      '& *': {
        width: '100%'
      }
    }
  },
  homeText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  actionBtnWrapper:{
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto',
      textAlign: 'right'
    }
  }
}))
