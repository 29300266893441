import React from 'react'
import Item from '../../../../CompaniesInfo/Item'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { FormLoader } from '../../../../../../Loader/FormLoader'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { CompanyConsumer } from '../../../../../../../CompanyContext'
import { makeStyles } from '@material-ui/core/styles'
import { LanguageConsumer } from '../../../../../../../LanguageContext'

const useStyles = makeStyles((theme) => ({
  partialWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  partialAmount: {
    color: theme.palette.grey['600'],
    position: 'relative',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      top: '50%',
      left: 0,
      backgroundColor: theme.palette.grey['600']

    }
  }
}))
const PaymentRequestDetails = () => {
  const classes = useStyles()
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <CompanyConsumer>
          {({ PaymentForPayOthersColumns, paymentForPayOthers, paymentForPayOthersLoading }) => (
            <>
              {!!paymentForPayOthersLoading && <FormLoader loading={true}/>}
              {!!paymentForPayOthers && !!Object.keys(paymentForPayOthers).length &&
              <div>
                {(PaymentForPayOthersColumns).map((column) => (
                  <Item key={column.id} asTable label={column.label} value={
                    (['claim_amount'].includes(column.id)) ?
                      ((!!paymentForPayOthers['allow_partial_payment'] &&
                        !!paymentForPayOthers['due_amount'] && paymentForPayOthers['due_amount'] !== paymentForPayOthers[column.id]) ?
                        <div className={classes.partialWrapper}>
                          <div className={classes.partialAmount}><FormattedNumber
                            value={paymentForPayOthers[column.id]}/>
                          </div>
                          <div className={classes.partialDueAmount}>
                            <FormattedMessage id='partialPayment.DueAmount.Hint'
                                              values={{
                                                dueAmount: <FormattedNumber value={paymentForPayOthers['due_amount']}/>,
                                                currency: <FormattedMessage id={`partialPayment.DueAmount.Currency.${paymentForPayOthers?.currency?.key?.toLowerCase()||''}`} />
                                              }}/>
                          </div>
                        </div>
                        :
                        <FormattedNumber value={paymentForPayOthers[column.id]}/>)
                      :
                      ((['tax_period_from', 'tax_period_to', 'claim_expire_date'].includes(column.id)) ?
                        <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                format="Do MMMM YYYY h:mm a">{paymentForPayOthers[column.id]}</Moment>
                        : paymentForPayOthers[column.id] || 'N/A')
                  }/>
                ))}
              </div>
              }
            </>
          )}
        </CompanyConsumer>
      )}
    </LanguageConsumer>
  )
}

export default PaymentRequestDetails
