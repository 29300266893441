import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground, TableRowBackground } from '../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    position: 'relative'
  },
  paginationWrapper: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  pager: {
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      color: theme.palette.common.white,
      cursor: 'default',
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.hover
      }
    }
  },
  container: {
    '& .MuiTableCell-root': {
      borderRight: `1px solid rgba(224,224,244,1)`,
      '&:first-child': {
        borderLeft: '1px solid rgba(224,224,224,1)',
        [theme.breakpoints.down('sm')]: {
          border: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        border: 0,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
      }
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    '&.noFilter .MuiTableCell-head': {
      background: FilterBackground,
      borderTop: '1px solid rgba(224,224,224,1)'
    }
  },
  dataRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: TableRowBackground
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      border: '1px solid rgba(224,224,224,1)',
      margin: theme.spacing(2, 0.5),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      boxShadow: `0px 2px 4px -1px ${fade(theme.palette.common.black, 0.2)}, 0px 4px 5px 0px ${fade(theme.palette.common.black, 0.14)}, 0px 1px 10px 0px ${fade(theme.palette.common.black, 0.12)}`
    }
  },
  dataCell: {
    '& a': {
      width: '100%',
      display: 'block',
      fontWeight: theme.typography.fontWeightBold
    },
    '& ul': {
      textAlign: 'left'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      // flexWrap:'wrap'
      // alignItems: 'center',
      '&.MuiTableCell-sizeSmall': {
        paddingLeft: theme.spacing(1.75),
        paddingRight: theme.spacing(1.75)
      },
      '&.center': {
        alignItems: 'center'
      }
    }
  },
  canceled: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main
  },
  approved: {
    display: 'inline-block',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main
  },
  payButton: {
    border: 'none',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.spacing(0.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light
  },
  spanLTR: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    display: 'inline-block'
  },
  actionsCell: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal'
      },
      '&.wrap': {
        whiteSpace: 'normal'
      },
      '&:not(:first-child)': {
        marginTop: theme.spacing(1)
      }
    }
  },
  partialWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  partialAmountsWrapper: {
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  partialAmount: {
    color: theme.palette.grey['600'],
    position: 'relative',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      top: '50%',
      left: 0,
      backgroundColor: theme.palette.grey['600']

    }
  },
  topOffset: {
    marginTop: theme.spacing(0.5)
  },
  dataLabel: {
    display: 'block',
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(0.75),
    flex: '0 0 auto',
    // lineHeight: theme.typography.pxToRem(25),
    marginTop: theme.spacing(0.375),
    textAlign: 'left',
    color: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      width: 109
    },
    [theme.breakpoints.only('sm')]: {
      width: 170
    }

  },
  dataValue: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(17),
      textAlign: 'left',
      wordBreak: 'break-word'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  updateCompanyHint: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      cursor: 'pointer',
      display: 'flex',
      marginLeft: theme.spacing(1)
    }
  },
  ecommerceBtn: {
    margin: theme.spacing(1, 0)
  },
  uptcpBtn: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'block',
    fontWeight: theme.typography.fontWeightBold
  },
  dimmedCell: {
    filter: 'blur(5px)',
    opacity: 0.1,
    transition: 'opacity 0.8s ease, filter 0.8s ease',
    userSelect: 'none'
  },
  clientSecretWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  clientSecret: {
    maxWidth: '220px',
    transition: 'opacity 0.8s ease',
    wordWrap: 'break-word'
  }
}))
