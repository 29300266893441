/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  FormControl,
  InputLabel,
  Fade
} from '@material-ui/core'
import { FormLoader } from '../../../../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { useStyles } from './Styles'
import { PayNonCoreConsumer } from '../../../../../../../PayNoncoreContext'
import ClaimsListingTable from '../ClaimsListingTable'

const PayNonCoreStep2 = () => {
  const classes = useStyles()
  return (
    <PayNonCoreConsumer>
      {({
          payNonCoreStep2Failure, payNonCoreStep2FailureMessage, payNonCoreStep2Claims, PayNonCoreHeaderColumns,
          payNonCoreStep2Loading, handleChangePayment, tempPayNonCoreData
        }) => (
        <Fade in={true}>
          <div className={classes.formWrapper}>
            {!!payNonCoreStep2Loading && <FormLoader loading={true}/>}
            {!!payNonCoreStep2Failure && !!payNonCoreStep2FailureMessage &&
            <Alert severity="error">{payNonCoreStep2FailureMessage}</Alert>
            }
            <div className={classes.formRow + ' ' + classes.formTable}>
              <InputLabel id="PayNonCoreStep2Form.claimId"><FormattedMessage
                id='PayNonCoreStep2.Form.claimId'/>:</InputLabel>
              <FormControl variant='outlined'>
                <ClaimsListingTable rows={payNonCoreStep2Claims} noPagination
                                    columns={PayNonCoreHeaderColumns}
                                    value={tempPayNonCoreData.payment_number} setValue={handleChangePayment}/>
              </FormControl>
            </div>
          </div>
        </Fade>
      )}
    </PayNonCoreConsumer>
  )
}

export default PayNonCoreStep2
