import React from 'react'
import {
  FormControl, FormControlLabel, FormHelperText,
  InputLabel, MenuItem, Radio, RadioGroup, Select, Collapse
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { useStyles } from '../Styles'
import { FormLoader } from '../../../Loader/FormLoader'
import { useFormik } from 'formik'
import { simpleRegisterQuestionnaireFormValidation } from '../../../../utils/validationSchema'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

const SimpleRegisterQuestionnaireForm = ({ handleSimpleRegisterQuestionnaire, switchToNormalRegistration }) => {
  const classes = useStyles()
  const intl = useIntl()
  let registerCompanyForm = useFormik({
    initialValues: {
      reason_for_registration: '',
      need_tax_card: '',
      practice_activity: '',
      commercial_register: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: simpleRegisterQuestionnaireFormValidation,
    onSubmit: ((values, actions) => {
      handleSimpleRegisterQuestionnaire(values, actions)
    })
  })

  let registration_reasons = [
    {
      key: '',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons' })
    },
    {
      key: 'real_estate_transactions',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.real_estate_transactions' })
    },
    {
      key: 'tax_refund_vat',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.tax_refund_vat' })
    },
    {
      key: 'capital_gains',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.capital_gains' })
    },
    {
      key: 'payroll_tax',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.payroll_tax' })
    },
    {
      key: 'tax_refund_international_agreements',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.tax_refund_international_agreements' })
    },
    {
      key: 'e_commerce',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.e_commerce' })
    },
    {
      key: 'other',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.registrationReasons.Other' })
    }
  ]
  let options_list = [
    {
      key: 'true',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.options.true' })
    },
    {
      key: 'false',
      name: intl.formatMessage({ id: 'registerCompanyForm.simpleQuestionnaire.options.false' })
    }
  ]
  const [viewReasonForRegistration, setViewReasonForRegistration] = React.useState(false)
  const [viewNeedTaxCard, setViewNeedTaxCard] = React.useState(false)
  const [viewPracticeActivity, setViewPracticeActivity] = React.useState(false)
  const [viewCommercialRegister, setViewCommercialRegister] = React.useState(false)
  const [viewNormalRegistrationDisclaimer, setViewNormalRegistrationDisclaimer] = React.useState(false)
  const normalRegistrationInterval = React.useRef(null)
  const [seconds, setSeconds] = React.useState(5)


  React.useEffect(() => {
      setViewReasonForRegistration(true)
      return () => {
        clearInterval(normalRegistrationInterval.current)
        setSeconds(5)
        registerCompanyForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const handleSelectChange = (event, fieldName) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    let value = event.target?.value?.toString()
    registerCompanyForm.setFieldValue(fieldName, value)
    setTimeout(() => changeCollapse(fieldName, value), 100)
  }
  React.useEffect(() => {
      if (seconds <= 0) {
        clearInterval(normalRegistrationInterval.current)
        switchToNormalRegistration()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [seconds])
  const changeCollapse = (fieldName, value) => {
    if (!!value) {
      if (['other', 'true'].includes(value)) {
        setViewNormalRegistrationDisclaimer(true)
        normalRegistrationInterval.current = setInterval(() => setSeconds(prev => prev - 1), 1000)
      } else {


        switch (fieldName) {
          case 'reason_for_registration':
            setViewNeedTaxCard(true)
            setViewReasonForRegistration(false)
            setViewPracticeActivity(false)
            setViewCommercialRegister(false)
            break
          case 'need_tax_card':
            setViewPracticeActivity(true)
            setViewReasonForRegistration(false)
            setViewNeedTaxCard(false)
            setViewCommercialRegister(false)
            break
          case 'practice_activity':
            setViewCommercialRegister(true)
            setViewReasonForRegistration(false)
            setViewNeedTaxCard(false)
            setViewPracticeActivity(false)
            break
          case 'commercial_register':
            registerCompanyForm.handleSubmit()
            break
          default:
            break
        }
      }

    }
  }
  return (
    <form onSubmit={registerCompanyForm.handleSubmit} noValidate autoComplete="off"
          className={classes.questionnaireForm}>

      <Alert severity="error">
        <AlertTitle><FormattedMessage id='registerCompanyForm.simpleQuestionnaire.simpleQuestionnaireDisclaimer.title'/></AlertTitle>
        <FormattedMessage id='registerCompanyForm.simpleQuestionnaire.simpleRegistrationQuestionnaireDisclaimer'
                          values={{
                            strong: chunks => <strong>{chunks}</strong>,
                            a: aChunk => <a href={`tel:${aChunk}`}>{aChunk}</a>
                          }}/></Alert>

      <Collapse in={!!viewReasonForRegistration} timeout="auto" unmountOnExit>
        <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
          <InputLabel id="registerCompanyForm.reason_for_registration"><FormattedMessage
            id='registerCompanyForm.simpleQuestionnaire.registrationReasons'/></InputLabel>
          <FormControl variant='outlined'>
            <Select
              fullWidth
              labelId="registerCompanyForm.reason_for_registration"
              id="reason_for_registration"
              margin="dense"
              displayEmpty
              MenuProps={{ disableScrollLock: true }}
              name="reason_for_registration"
              disabled={!!viewNormalRegistrationDisclaimer}
              value={registerCompanyForm.values.reason_for_registration}
              onChange={(event) => handleSelectChange(event, 'reason_for_registration')}
              onBlur={registerCompanyForm.handleBlur}
              error={registerCompanyForm.errors.reason_for_registration && registerCompanyForm.touched.reason_for_registration !== undefined}
            >
              {!!registration_reasons.length && registration_reasons.map((reason) => (
                <MenuItem key={reason.key} value={reason.key}>{reason.name}</MenuItem>
              ))}
            </Select>
            {registerCompanyForm.errors.reason_for_registration && registerCompanyForm.touched.reason_for_registration && (
              <FormHelperText
                error={true}>{registerCompanyForm.errors.reason_for_registration}</FormHelperText>
            )}
          </FormControl>
        </div>
      </Collapse>

      <Collapse in={!!viewNeedTaxCard} timeout="auto" unmountOnExit>
        <div className={classes.LinkCompanyFormRow}>
          <InputLabel id="registerCompanyForm.need_tax_card"><FormattedMessage
            id='registerCompanyForm.simpleQuestionnaire.needTaxCard'/></InputLabel>
          <FormControl variant="outlined">
            <RadioGroup aria-label="registerCompanyForm.registration_plan"
                        name="need_tax_card"
                        value={registerCompanyForm.values.need_tax_card}
                        onChange={(event) => handleSelectChange(event, 'need_tax_card')}>
              {options_list.map((option) => (
                <FormControlLabel key={option.key} value={option.key}
                                  disabled={!!viewNormalRegistrationDisclaimer}
                                  control={<Radio color={'primary'}/>}
                                  label={option.name}/>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Collapse>
      <Collapse in={!!viewPracticeActivity} timeout="auto" unmountOnExit>
        <div className={classes.LinkCompanyFormRow}>
          <InputLabel id="registerCompanyForm.practice_activity"><FormattedMessage
            id='registerCompanyForm.simpleQuestionnaire.practiceActivity'/></InputLabel>
          <FormControl variant="outlined">
            <RadioGroup aria-label="registerCompanyForm.practice_activity"
                        name="practice_activity"
                        value={registerCompanyForm.values.practice_activity}
                        onChange={(event) => handleSelectChange(event, 'practice_activity')}>
              {options_list.map((option) => (
                <FormControlLabel key={option.key} value={option.key}
                                  disabled={!!viewNormalRegistrationDisclaimer}
                                  control={<Radio color={'primary'}/>}
                                  label={option.name}/>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Collapse>
      <Collapse in={!!viewCommercialRegister} timeout="auto" unmountOnExit>
        <div className={classes.LinkCompanyFormRow}>
          <InputLabel id="registerCompanyForm.commercial_register"><FormattedMessage
            id='registerCompanyForm.simpleQuestionnaire.commercialRegister'/></InputLabel>
          <FormControl variant="outlined">
            <RadioGroup aria-label="registerCompanyForm.commercial_register"
                        name="commercial_register"
                        value={registerCompanyForm.values.commercial_register}
                        onChange={(event) => handleSelectChange(event, 'commercial_register')}>
              {options_list.map((option) => (
                <FormControlLabel key={option.key} value={option.key}
                                  disabled={!!viewNormalRegistrationDisclaimer}
                                  control={<Radio color={'primary'}/>}
                                  label={option.name}/>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Collapse>
      <Collapse in={!!viewNormalRegistrationDisclaimer} timeout="auto" unmountOnExit>
        <div className={classes.LinkCompanyFormRow}>
          <Alert severity="error">
            <FormattedMessage id='registerCompanyForm.simpleQuestionnaire.normalRegistrationDisclaimer' values={{
              seconds: seconds
            }}/></Alert>
        </div>
      </Collapse>
      {registerCompanyForm.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default SimpleRegisterQuestionnaireForm
