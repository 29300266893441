import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { Avatar, FormHelperText, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useKeycloak } from '@react-keycloak/web'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import BlockIcon from '@material-ui/icons/Block'
import { useStyles } from './Styles'

const UserMenu = ({ isMobile, mobileMenu, toggleMobileMenuDrawer, isWorkspace }) => {
  const classes = useStyles()
  const intl = useIntl()
  const mobileClass = isMobile ? classes.userMenuMobile : ''

  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'

  const { keycloak } = useKeycloak()

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const DangerTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      marginTop: theme.spacing(0.5),
      textAlign: 'center',
      maxWidth: 240,
      '& .MuiTooltip-arrow': {
        color: theme.palette.error.main
      }
    }
  }))(Tooltip)
  return (
    <AuthenticatedUserConsumer>
      {({ userData, isUserActive, inactiveUserData }) => (
        <>
          {!!mobileMenu ?
            <div className={classes.MobileUserMenu}>
              {!!Object.keys(userData).length ?
                ((!!userData.first_name && !!userData.last_name) ?
                    <>
                      <Avatar alt={userData.first_name} src="/broken-image.jpg"
                              className={classes.userAvatar}/>
                      <span className={classes.userName}>{userData.first_name} {userData.last_name}</span>
                    </>
                    :
                    <>
                      <Avatar alt={userData.username.toUpperCase()} src="/broken-image.jpg"
                              className={classes.userAvatar}/>
                      <span className={classes.userName}>{userData.username}</span>
                    </>
                )
                :
                ((!isUserActive && !!Object.keys(inactiveUserData).length && !!inactiveUserData.name) ?
                    <>
                      <div className={classes.userAvatarWrapper}>
                        <BlockIcon className={classes.userInActive}/>
                        <Avatar alt={inactiveUserData.name} src="/broken-image.jpg"
                                className={classes.userAvatar}/>
                      </div>
                      <span className={classes.userName}>{inactiveUserData.name}</span>
                      <FormHelperText className={classes.userInActiveMessage} error={true}><FormattedMessage
                        id='User.inActive.Message'/></FormHelperText>
                    </>
                    :
                    <>
                      <Avatar alt={intl.formatMessage({ id: 'UserMenu.userName' })}
                              src="/broken-image.jpg" className={classes.userAvatar}/>
                      <span className={classes.userName}><FormattedMessage id='UserMenu.userName'/></span>
                    </>
                )
              }
              {!!Object.keys(userData).length && !!isUserActive &&
              <>
                {!isWorkspace && <NavLink exact activeClassName="active"
                                          to={NamedRoutes.workspace.dashboard}
                                          onClick={() => toggleMobileMenuDrawer(false)}>
                  <FormattedMessage id='UserMenu.dashboard'/>
                </NavLink>}
                <NavLink exact activeClassName="active"
                         to={NamedRoutes.workspace.editProfile}
                         onClick={() => toggleMobileMenuDrawer(false)}>
                  <FormattedMessage id='UserMenu.editProfile'/>
                </NavLink>
              </>
              }
              {/*<Button className={'signOut'} onClick={() => keycloak.logout()}>*/}
              {/*  <FormattedMessage id='UserMenu.logout'/></Button>*/}
            </div>
            :
            <>
              <IconButton
                edge="end"
                aria-label={<FormattedMessage id='UserMenu.label'/>}
                aria-controls={menuId}
                aria-haspopup="true"
                className={classes.userMenuButton + ' ' + mobileClass}
                onClick={handleProfileMenuOpen}
                color="inherit"
                disableRipple
              >
                {!!Object.keys(userData).length ?
                  ((!!userData.first_name && !!userData.last_name) ?
                      <>
                        <Avatar alt={userData.first_name} src="/broken-image.jpg"
                                className={classes.userAvatar}/>
                        <span>{userData.first_name} {userData.last_name}</span>
                      </>
                      :
                      <>
                        <Avatar alt={userData.username.toUpperCase()} src="/broken-image.jpg"
                                className={classes.userAvatar}/>
                        <span>{userData.username}</span>
                      </>
                  )
                  :
                  ((!isUserActive && !!Object.keys(inactiveUserData).length && !!inactiveUserData.name) ?
                      <DangerTooltip title={intl.formatMessage({ id: 'User.inActive.Message' })} arrow>
                        <div className={classes.userInActiveWrapper}>
                          <div className={classes.userAvatarWrapper}>
                            <BlockIcon className={classes.userInActive}/>
                            <Avatar alt={inactiveUserData.name} src="/broken-image.jpg"
                                    className={classes.userAvatar}/>
                          </div>
                          <span>{inactiveUserData.name}</span>
                        </div>
                      </DangerTooltip>
                      :
                      <>
                        <Avatar alt={intl.formatMessage({ id: 'UserMenu.userName' })}
                                src="/broken-image.jpg" className={classes.userAvatar}/>
                        <span><FormattedMessage id='UserMenu.userName'/></span>
                      </>
                  )
                }
                <ArrowDropDownIcon color="inherit"/>
              </IconButton>

              {(!!Object.keys(userData).length && !!isUserActive) ?
                <Menu
                  anchorEl={anchorEl}
                  id={menuId}
                  classes={{ paper: classes.userMenuPaper }}
                  keepMounted
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  disableScrollLock
                >
                  <MenuItem onClick={handleMenuClose}>
                    <NavLink exact activeClassName="active" to={NamedRoutes.workspace.dashboard}>
                      <FormattedMessage id='UserMenu.dashboard'/>
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <NavLink exact activeClassName="active" to={NamedRoutes.workspace.editProfile}>
                      <FormattedMessage id='UserMenu.editProfile'/>
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={'signOut'} onClick={() => keycloak.logout()}><FormattedMessage
                    id='UserMenu.logout'/></MenuItem>
                </Menu>
                :
                <Menu
                  anchorEl={anchorEl}
                  id={menuId}
                  classes={{ paper: classes.userMenuPaper }}
                  keepMounted
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  disableScrollLock
                >
                  <MenuItem className={'signOut'} onClick={() => keycloak.logout()}><FormattedMessage
                    id='UserMenu.logout'/></MenuItem>
                </Menu>
              }
            </>
          }
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default UserMenu
