import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useStyles } from '../Styles'
import logo from '../../../../../../images/etax-logo.png'
import eFinance from '../../../../../../images/e-finance.png'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'

const PaymentReceiptPDFContent = ({ receiptDetails, userName, locale }) => {
  const classes = useStyles()

  return (
    <div id={'pdfContent'} className={classes.pdfContent}>
      <h3 align={'center'}><FormattedMessage id='PaymentReceipt.Dialog.Title'
                                             values={{ rec_num: receiptDetails.receipt_number }}/></h3>
      <div className='first-row'>
        <div className='dateWrapper'>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.receiptNumber'/>}</h4>
            <span className='itemValue'>{receiptDetails.receipt_number}</span>
          </div>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.requestNumber'/>}</h4>
            <span className='itemValue'>{receiptDetails.request_number}</span>
          </div>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.createDate'/>}</h4>
            <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                format="Do MMMM YYYY h:mm a">{receiptDetails.create_at}</Moment></span>
          </div>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.printDate'/>}</h4>
            <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                format="Do MMMM YYYY h:mm a"/></span>
          </div>
        </div>
        <img src={logo} alt='e-finance logo' width={150}/>
      </div>
      <div className='data-row'>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.userName'/>}</h4>
          <span className='itemValue'>{userName}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.TaxpayerName'/>}</h4>
          <span className='itemValue'>{receiptDetails.taxpayer_name}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.CompanyName'/>}</h4>
          <span className='itemValue'>{receiptDetails.companyNameString}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.UniqueTaxId'/>}</h4>
          <span className='itemValue'>{receiptDetails.tax_id}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeType'/>}</h4>
          <span className='itemValue'>{receiptDetails.payment_tax_type}</span>
        </div>
        {!!receiptDetails.payment_claim_number &&
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeNumber'/>}</h4>
          <span className='itemValue'>{receiptDetails.payment_claim_number}</span>
        </div>
        }
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.receiptAmountPDF'/>}</h4>
          <span className='itemValue'><FormattedNumber value={receiptDetails.payment_claim_amount}/></span>
        </div>
        {!!receiptDetails.pg_payment_fees_amount &&
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.pgPaymentFeesAmountPDF'/>}</h4>
          <span className='itemValue'><FormattedNumber value={receiptDetails.pg_payment_fees_amount}/></span>
        </div>
        }
        {!!receiptDetails.pg_payment_total_amount &&
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.pgPaymentTotalAmount'/>}</h4>
          <span className='itemValue'><FormattedNumber value={receiptDetails.pg_payment_total_amount}/></span>
        </div>
        }
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.receiptCurrency'/>}</h4>
          <span className='itemValue'>{receiptDetails.payment_claim_currency}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.paymentType'/>}</h4>
          <span className='itemValue'>{receiptDetails.payment_type}</span>
        </div>
        {!!receiptDetails.tax_payment_request_number &&
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.taxPaymentRequestNumber'/>}</h4>
          <span className='itemValue'>{receiptDetails.tax_payment_request_number}</span>
        </div>
        }
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.pgPaymentRequestNumber'/>}</h4>
          <span className='itemValue'>{receiptDetails.pg_payment_request_number}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.taxPeriodFrom'/>}</h4>
          <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                              format="Do MMMM YYYY h:mm a">{receiptDetails.payment_tax_period_from}</Moment></span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.taxPeriodTo'/>}</h4>
          <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                              format="Do MMMM YYYY h:mm a">{receiptDetails.payment_tax_period_to}</Moment></span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.paymentItem'/>}</h4>
          <span className='itemValue'>{receiptDetails.payment_item}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.createDate'/>}</h4>
          <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                              format="Do MMMM YYYY h:mm a">{receiptDetails.create_at}</Moment></span>
        </div>
      </div>
      <div className='hint'>
        <div><span className='icon'>★</span> <span><FormattedMessage id='PaymentReceipt.hint1PDF'/></span></div>
      </div>
      <div className='last-row'>
        <h5><FormattedMessage id='footer.copyrights.poweredBy'/></h5>
        <img src={eFinance} alt='e-finance logo' width={230}/>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default PaymentReceiptPDFContent
