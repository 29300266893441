import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography,
  Grid, Stepper, Step, StepButton, StepContent, StepLabel
} from '@material-ui/core'
import { sm } from '../../../../../../utils/ResponsiveUtility'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles, RequestStepsConnector, requestStepIconStyles } from './Styles'
import { AuthenticatedUserConsumer } from '../../../../../../AuthenticatedUserContext'
import { PayNonCoreProvider, PayNonCoreConsumer } from '../../../../../../PayNoncoreContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PayNonCoreDialog = ({ currentPaymentData, handlePayNonCoreDialog, openPayNonCoreDialog, claimId, refreshPaymentsAfterLinking }) => {
  const classes = useStyles()

  let [width, setWidth] = React.useState(document.body.clientWidth)
  let Mobile = (width < sm)

  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  const requestStepIcon = (props, icon) => {
    const classes = requestStepIconStyles()
    const { active, completed } = props
    let iconClasses = [classes.root]
    if (!!active) iconClasses.push(classes.active)
    if (!!completed) iconClasses.push(classes.completed)
    return (
      <div className={iconClasses.join(' ')}>
        {icon}
      </div>
    )
  }

  return (
    <AuthenticatedUserConsumer>
      {({ selectedCompany }) => (
        <PayNonCoreProvider currentPaymentData={currentPaymentData} cid={selectedCompany.id}
                            closeDialog={() => handlePayNonCoreDialog(null, refreshPaymentsAfterLinking)}>
          <PayNonCoreConsumer>
            {({
                payNonCoreActiveStep, payNonCoreSteps, getPayNonCoreStepContent, handleGoToStep,
                isPayNonCoreStepComplete, inPayNonCoreStepsTrail, submitPayNonCoreStepForm, submitPayNonCoreBtnStatus
              }) => (
              <Dialog
                keepMounted
                fullWidth
                maxWidth={'md'}
                open={openPayNonCoreDialog}
                classes={{ paper: classes.NewOrderDialogPaper }}
                TransitionComponent={Transition}
                onClose={(event) => handlePayNonCoreDialog(event, payNonCoreActiveStep === 2 ? refreshPaymentsAfterLinking : null)}
                aria-labelledby="BasicInfoDialog"
                aria-describedby="BasicInfoDialog"
              >
                <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
                  <Typography variant="h6"><FormattedMessage id='PayNonCoreDialog.title'
                                                             values={{ 'claimId': claimId }}/></Typography>
                  <IconButton
                    edge={false}
                    className={classes.NewOrderDialogClose}
                    color="inherit"
                    aria-label="close dialog"
                    onClick={(event) => handlePayNonCoreDialog(event, payNonCoreActiveStep === 2 ? refreshPaymentsAfterLinking : null)}
                  >
                    <CloseIcon/>
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.NewOrderDialogContent}>
                  <Box p={4} className={classes.NewOrderStepsWrapper}>
                    <Stepper className={classes.StepperWrapper}
                             activeStep={payNonCoreActiveStep} {...!!Mobile ? { orientation: 'vertical' } : {
                      connector: <RequestStepsConnector/>, alternativeLabel: true
                    }}>
                      {payNonCoreSteps.map((step, index) => (
                        <Step key={step.key}>
                          <StepButton
                            disableRipple
                            onClick={() => handleGoToStep(index)}
                            completed={isPayNonCoreStepComplete(index)}
                            disabled={!(isPayNonCoreStepComplete(index) || inPayNonCoreStepsTrail(index))}
                          >
                            <StepLabel
                              StepIconComponent={(props) => requestStepIcon(props, step.icon)}>{step.title}</StepLabel>
                          </StepButton>
                          {!!Mobile &&
                          <StepContent>
                            {getPayNonCoreStepContent(index)}
                          </StepContent>
                          }
                        </Step>
                      ))}
                    </Stepper>
                    {!Mobile &&
                    <Grid container>
                      {getPayNonCoreStepContent(payNonCoreActiveStep)}
                    </Grid>
                    }
                  </Box>
                </DialogContent>
                <DialogActions className={classes.NewOrderDialogFooter}>
                  <Button className={classes.NewOrderPayButton} size="large" variant="contained" color="primary"
                          disabled={submitPayNonCoreBtnStatus}
                          onClick={submitPayNonCoreStepForm}>
                    <FormattedMessage id={`PayNonCoreDialog.ActionLinks.confirm.${payNonCoreActiveStep}`}/>
                  </Button>
                  {payNonCoreActiveStep < 2 &&
                  <Button color="primary"
                          onClick={(event) => handlePayNonCoreDialog(event, payNonCoreActiveStep === 2 ? refreshPaymentsAfterLinking : null)}>
                    <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                  </Button>
                  }
                </DialogActions>
              </Dialog>
            )}
          </PayNonCoreConsumer>
        </PayNonCoreProvider>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default PayNonCoreDialog
