import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NamedRoutes } from '../../../routes'
import Configuration from '../../../Services/Api/Configuration'

export const workspaceMenu = [
  {
    key: 1,
    label: <FormattedMessage id='workspaceMenu.link.item1'/>,
    link: NamedRoutes.workspace.dashboard
  }]
if (!Configuration.HIDE_INSURANCE_SERVICES) {
  workspaceMenu.push(
    {
      key: 2,
      label: <FormattedMessage id='workspaceMenu.link.item2'/>,
      link: NamedRoutes.workspace.insuranceServices
    }
  )
}

export const workspaceCompanyMenu = (locale) => {
  let subscriptionLink = new URL(Configuration.Subscription_Config.url)
  subscriptionLink.searchParams.append('locale', locale)
  return [
    {
      key: 3,
      label: <FormattedMessage id='workspaceMenu.link.item3'/>,
      link: subscriptionLink,
      target: true
    },
    {
      key: 4,
      label: <FormattedMessage id='workspaceMenu.link.item4'/>,
      link: NamedRoutes.workspace.company.view
    },
    {
      key: 5,
      label: <FormattedMessage id='workspaceMenu.link.item5'/>,
      link: NamedRoutes.workspace.company.delegates,
      notForECommerce: true
    },
    {
      key: 6,
      label: <FormattedMessage id='workspaceMenu.link.item6'/>,
      link: '/workspace/item5/404',
      subMenu: [
        {
          key: 61,
          label: <FormattedMessage id='workspaceMenu.link.item61'/>,
          link: Configuration.DashBoardActions_CreatTaxLink,
          isSAP: true,
          target: true,
          isTaxService: true
        },
        {
          key: 62,
          label: <FormattedMessage id='workspaceMenu.link.item62'/>,
          link: '/workspace/item52/404',
          noLink: true
        },
        {
          key: 63,
          label: <FormattedMessage id='PageTitle.PaymentsBalance'/>,
          link: NamedRoutes.workspace.company.paymentsBalance
        },
        {
          key: 64,
          label: <FormattedMessage id='workspaceMenu.link.item64'/>,
          link: NamedRoutes.workspace.company.payments
        },
        {
          key: 65,
          label: <FormattedMessage id='workspaceMenu.link.item65'/>,
          link: NamedRoutes.workspace.company.paymentClaims,
          hide: !!Configuration.HIDE_PAYMENT_CLAIMS_MENUITEM
        },
        {
          key: 66,
          label: <FormattedMessage id='workspaceMenu.link.item66'/>,
          link: NamedRoutes.workspace.company.downPayment
        }
      ]
    },
    {
      key: 7,
      label: <FormattedMessage id='workspaceMenu.link.item7'/>,
      link: NamedRoutes.workspace.company.notifications,
      noLink: true
    },
    // {
    //   key: 8,
    //   label: <FormattedMessage id='workspaceMenu.link.item8'/>,
    //   link: '/workspace/item7/404',
    //   noLink: true
    // },
    {
      key: 9,
      label: <FormattedMessage id='workspaceMenu.link.item9'/>,
      isIncentive: true,
      hide: !!Configuration.HIDE_INCENTIVES
    },
    {
      key: 10,
      label: <FormattedMessage id='workspaceMenu.link.item10'/>,
      link: '/workspace/item9/404',
      subMenu: [
        {
          key: 101,
          label: <FormattedMessage id='workspaceMenu.link.item101'/>,
          link: Configuration.UPTCP.URL[locale],
          isUPTCP: true
        }
      ]
    },
    {
      key: 11,
      label: <FormattedMessage id='workspaceMenu.link.item11'/>,
      link: '/workspace/item10/404',
      noLink: true
    },
    {
      key: 12,
      label: <FormattedMessage id='workspaceMenu.link.item12'/>,
      link: NamedRoutes.workspace.company.ecommerceClients,
      isEcommerce: true,
      hide: !!Configuration.HIDE_ECOMMERCE

    }
  ]
}
export const portalMenu = [
  {
    key: 1,
    label: <FormattedMessage id='MainNavigation.link.item1'/>,
    link: '/'
  },
  {
    key: 2,
    label: <FormattedMessage id='MainNavigation.link.item2'/>,
    link: '/about2',
    noLink: true
  },
  {
    key: 3,
    label: <FormattedMessage id='MainNavigation.link.item3'/>,
    link: '/about3',
    noLink: true
  },
  {
    key: 4,
    label: <FormattedMessage id='MainNavigation.link.item4'/>,
    link: '/about4',
    noLink: true
  },
  {
    key: 5,
    label: <FormattedMessage id='MainNavigation.link.item5'/>,
    link: '/about5',
    noLink: true
  },
  {
    key: 6,
    label: <FormattedMessage id='MainNavigation.link.item6'/>,
    link: '/about6',
    noLink: true
  },
  {
    key: 7,
    label: <FormattedMessage id='MainNavigation.link.item7'/>,
    link: '/about7',
    noLink: true
  },
  {
    key: 8,
    label: <FormattedMessage id='MainNavigation.link.item8'/>,
    link: '/about8',
    noLink: true
  }
]
