import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  termsConditionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(20),
      '&.not-label': {
        padding: theme.spacing(0, 1),
        marginBottom: theme.spacing(1)
      }
    },
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      textDecoration: 'underline',
      marginLeft: theme.spacing(0.75),
      padding: 0
    }
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative'
  },
  PaymentOrderStepMessage: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.7,
    '&.center': {
      textAlign: 'center'
    },
    '& p': {
      marginBottom: theme.spacing(1)
    }
  },
  PaymentsBalanceResultItem: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > button': {
      margin: theme.spacing(1, 2)
    },
    '& .success': {
      color: theme.palette.success.main
    },
    '& .error': {
      color: theme.palette.error.main

    }
  },
  innerPayButton: {
    minWidth: '20%'
  },
  NewOrderPayButton: {
    padding: theme.spacing(1.25, 3),
    fontSize: theme.typography.pxToRem(18)
  }
}))
