import React from 'react'
import logoImage from './../../images/logo.png'
import logoEn from './../../images/etax-logo-en.png'
import { useStyles } from './Styles'
import Configuration from '../../Services/Api/Configuration'
import {LanguageConsumer} from './../../LanguageContext'

const Logo = () => {
  const classes = useStyles()
  return (
      <LanguageConsumer>
        {({locale}) => (
            <div className={classes.logo}><a
                href={Configuration.CMS_portal_url}> <img alt='logo'
                src={locale === 'ar' ? logoImage : logoEn}/> </a></div>
        )}
      </LanguageConsumer>
  )
}

export default Logo
