import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { FormattedNumber } from 'react-intl'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import {
  Radio
} from '@material-ui/core'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../../../../LanguageContext'

const BalancePaymentRow = ({ row, columns, value, setValue }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <TableRow hover className={classes.dataRow} role="checkbox" tabIndex={-1}>
        <TableCell padding="checkbox">
          <Radio
            color="primary"
            checked={row.id.toString() === value.toString()}
            onChange={(event) => setValue(event.target.value.toString())}
            value={row.id.toString()}
            name={`claim_id`}
            inputProps={{ 'aria-label': 'claim id' }}
          />
        </TableCell>
        {columns.map((column, columnIndex) => (
          <TableCell className={classes.dataCell}
                     key={(row.id || columnIndex) + '-' + (column.id || columnIndex)}
                     align='center'>
            <LanguageConsumer>
              {({ locale }) => (
                <span className={classes.dataValue}>
                 {((['payment_item'].includes(column.id)) ?
                   <span className={classes.spanLTR}>{row[column.id]}</span>
                   :
                   ((['claim_amount', 'payment_claim_amount', 'original_amount', 'remaining_amount'].includes(column.id)) ?
                     <FormattedNumber value={row[column.id]}/>
                     :
                     ((['tax_period_from', 'tax_period_to', 'claim_expire_date', 'payment_date',
                       'payment_tax_period_from', 'payment_tax_period_to', 'payment_claim_expire_date', 'expire_date',
                       'create_at', 'created_at'].includes(column.id) && row[column.id]) ?
                       <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                               format="Do MMMM YYYY h:mm a">{row[column.id]}</Moment>
                       : row[column.id] || '-')))}</span>
              )}
            </LanguageConsumer>
          </TableCell>
        ))}
      </TableRow>
    </React.Fragment>

  )
}

export default BalancePaymentRow