import React,{ useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FormattedMessage } from "react-intl";

function CopyButton({ text }) {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const handleTooltipClose = () => {
    setCopied(false);
  };

  return (
    <Tooltip title={copied ? <FormattedMessage id="EcommerceHeader.Clipboard.Copied" /> :  <FormattedMessage id="EcommerceHeader.Clipboard.Copy" />}>
      <IconButton onClick={handleCopyClick} onBlur={handleTooltipClose}>
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
}

export default CopyButton