/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import Configuration from '../Api/Configuration'

const { User_EndPoints } = Configuration

class CompanyServices {

  static getNotifications (cid, header) {
    return RestClient.getRequest(`/company/${cid}/notification`, header, true)
  }

  static getPaymentsFilterData (header) {
    return RestClient.getRequest(User_EndPoints.paymentFilterData, header, true)
  }

  static getPayments (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment`, header, true)
  }

  static getBalancePayments (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment`, header, true)
  }

  static getTaxpayerBalance (cid, header) {
    return RestClient.getRequest(`/company/${cid}/taxpayer-balance`, header, true)
  }

  static PayPaymentsBalance (cid, data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(`/company/${cid}/taxpayer-balance`,
      data, header, setFormLoading, setFailure, null, true)
  }

  static getPaymentForPayOthers (cid, claimId, header) {
    return RestClient.getRequest(`/company/${cid}/payment/${claimId}/inquire`, header, true)
  }

  static getDownPaymentRequests (cid, header) {
    return RestClient.getRequest(`/company/${cid}/down-payment`, header, true)
  }
  static getPaymentClaims (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment-claim`, header, true)
  }

  static getPaymentClaimItems (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment-claim/inquire`, header, true)
  }

  static createPaymentClaim (cid, data, header, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(`/company/${cid}/payment-claim`,
      data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static createDownPayment (cid, data, header, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(`/company/${cid}/down-payment`,
      data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static getDownPaymentAmountRange (cid, header) {
    return RestClient.getRequest(`/company/${cid}/down-payment/inquire`, header, true)
  }

  static getPaymentRequests (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment-request`, header, true)
  }

  static getPaymentClaimRequests (cid, claimId, header) {
    return RestClient.getRequest(`/company/${cid}/payment-claim/${claimId}/payment-request`, header, true)
  }

  static deletePaymentOrder (cid, paymentOrderId, header, setFormLoading, setFailure) {
    return RestClient.deleteRequest(`/company/${cid}/payment-request/${paymentOrderId}`, header, setFormLoading, setFailure, null, true)
  }

  static checkPaymentOrderValidity (cid, requestNumber, header) {
    return RestClient.getRequest(`/company/${cid}/payment-request/${requestNumber}/resume`, header, true)
  }

  static createPaymentOrder (cid, claimId, isPaymentClaim, header, setFormLoading, setFailure, partialData) {
    if (!!isPaymentClaim) {
      return RestClient.postRequest(`/company/${cid}/payment-claim/${claimId}/payment-request`, {}, header, setFormLoading, setFailure, null, true)
    } else {
      return RestClient.postRequest(`/company/${cid}/payment-request`, { 'claim_number': claimId.toString(), ...partialData }, header, setFormLoading, setFailure, null, true)
    }
  }

  static createTransaction (cid, paymentOrderId, header, setFormLoading, setFailure) {
    return RestClient.postRequest(`/company/${cid}/payment-transaction/create`, { 'request_number': paymentOrderId.toString() }, header, setFormLoading, setFailure, null, true)
  }

  static getPaymentReceipts (cid, header) {
    return RestClient.getRequest(`/company/${cid}/payment-receipt`, header, true)
  }

  static getPaymentReceiptByRID (cid, requestNumber, header) {
    return RestClient.getRequest(`/company/${cid}/payment-request/${requestNumber}/receipt`, header, true)
  }

  static getPageContent (slug, header) {
    return RestClient.getRequest(`/page/${slug}`, header, true)
  }

  static getSettingContent (key, header) {
    return RestClient.getRequest(`/setting/${key}`, header, true)
  }

  static getDelegates (cid, header) {
    return RestClient.getRequest(`/company/${cid}/delegate`, header, true)
  }

  static submitDelegate (delegateId, cid, isEdit, data, header, setFormLoading, setFailure, FormikActions) {
    return !!isEdit ? RestClient.putRequest(`/company/${cid}/delegate/${delegateId}`, data, header, setFormLoading, setFailure, FormikActions, true)
      : RestClient.postRequest(`/company/${cid}/delegate`, data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static deleteDelegate (delegateId, cid, header, setFormLoading) {
    return RestClient.deleteRequest(`/company/${cid}/delegate/${delegateId}`, header, setFormLoading, null, null, true)
  }

  static getEcommerce (cid, header) {
    return RestClient.getRequest(`/company/${cid}/ecommerce-platform`, header, true)
  }

  static submitEcommerce (platformId, cid, isEdit, data, header, setFormLoading, setFailure, FormikActions) {
    return !!isEdit ? RestClient.putRequest(`/company/${cid}/ecommerce-platform/${platformId}`, data, header, setFormLoading, setFailure, FormikActions, true)
      : RestClient.postRequest(`/company/${cid}/ecommerce-platform`, data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static deleteEcommerce (delegateId, cid, header, setFormLoading) {
    return RestClient.deleteRequest(`/company/${cid}/ecommerce-platform/${delegateId}`, header, setFormLoading, null, null, true)
  }

  static viewEcommerceSecret (delegateId ,cid, header) {
    return RestClient.getRequest(`/company/${cid}/ecommerce-platform/${delegateId}/reset-client-secret`, header, true)
  }

  static getNonCorePayment (cid, header) {
    return RestClient.getRequest(`/company/${cid}/none-core-payment/inquire`, header, true)
  }
  static submitNonCorePayment (cid,data, header, setFailure) {
    return RestClient.postRequest(`/company/${cid}/none-core-payment/link`, data, header, null, setFailure, null, true)
  }
}

export default CompanyServices
