import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useIntl } from 'react-intl'
import CompanyServices from './Services/Consumers/CompanyServices'
import { filterData } from './utils/helpers'
import { DelegatePayload } from './Services/Payloads/AuthenticatedUserPayload'

const DelegateContext = createContext({})

const DelegateProvider = ({ children, pageAction, cid, delegateData, handleSubmitDelegatesFilter, checkCompanyData }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const [deleteDelegateLoading, setDeleteDelegateLoading] = React.useState(false)
  const [delegateDataFailure, setDelegateDataFailure] = React.useState(false)
  const [delegateDataSuccess, setDelegateDataSuccess] = React.useState(false)
  const [delegateDataFailureMessage, setDelegateDataFailureMessage] = React.useState('')
  // const [needDelegatesToRefresh, setNeedDelegatesToRefresh] = React.useState(false)

  const resetDelegateStatus = () => {
    setDelegateDataSuccess(false)
    setDelegateDataFailure(false)
    setDelegateDataFailureMessage('')
    setDeleteDelegateLoading(false)
  }
  const handleDelegateSubmit = (values, FormikActions, handleEditDelegateDialog) => {
    if (!!keycloak?.authenticated) {
      FormikActions.setSubmitting(true)

      CompanyServices.submitDelegate(delegateData.id, cid, pageAction === 'Edit',
        filterData(DelegatePayload(values, pageAction === 'Edit')), {
          headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
        }, FormikActions.setSubmitting, null, FormikActions).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setDelegateDataFailure(true)

          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setDelegateDataFailureMessage(message)
          FormikActions.setSubmitting(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setDelegateDataSuccess(true)
            setTimeout(() => {
              handleEditDelegateDialog()
              setTimeout(() => {
                checkCompanyData(cid, true)
                handleSubmitDelegatesFilter()
              }, 200)
            }, 1200)
          } else {
            FormikActions.setSubmitting(false)
          }
        }
      })
    }
  }

  const handleDeleteDelegate = (handleDeleteDelegateDialog) => {
    if (!!keycloak?.authenticated) {
      setDeleteDelegateLoading(true)
      CompanyServices.deleteDelegate(delegateData.id, cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, setDeleteDelegateLoading).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setDelegateDataFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setDelegateDataFailureMessage(message)
          setDeleteDelegateLoading(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setDelegateDataSuccess(true)
            setTimeout(() => {
              handleDeleteDelegateDialog()
              setTimeout(() => {
                checkCompanyData(cid, true)
                handleSubmitDelegatesFilter()
              }, 500)
            }, 2000)
          } else {
            setDeleteDelegateLoading(false)
          }
        }
      })
    }
  }

  return (
    <DelegateContext.Provider value={{

      deleteDelegateLoading: deleteDelegateLoading,
      delegateDataFailure: delegateDataFailure,
      delegateDataSuccess: delegateDataSuccess,
      delegateDataFailureMessage: delegateDataFailureMessage,
      delegateData: delegateData,
      pageAction: pageAction,

      handleDelegateSubmit,
      handleDeleteDelegate,
      resetDelegateStatus
    }}>
      {children}
    </DelegateContext.Provider>
  )
}
const DelegateConsumer = DelegateContext.Consumer

export { DelegateProvider, DelegateConsumer }
