import React from 'react'
import {useStyles} from './Styles'
import Menu from './Menu'
import Copyrights from './Copyrights'

const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer} id='footer'>
      <Menu/>
      <Copyrights/>
    </footer>
  )
}

export default Footer
