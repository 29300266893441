import React from 'react'
import WorkspaceTable from '../../Table'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import { CompanyConsumer } from '../../../../CompanyContext'
import { isEmpty } from '../../../../utils/helpers'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../../../../routes'
import RequestsFilter from './RequestsFilter'
import { Box } from '@material-ui/core'
import UnAuthorize from '../../../UnAuthorize'
import { NavLink } from 'react-router-dom'

const CompanyPaymentRequests = ({ scrollableTarget, claimId, handleOldPaymentOrdersDialog, selectedCompany, getPaymentRequests, isPaymentClaim, getPaymentClaimRequests, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  React.useEffect(() => {
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          },
          {
            key: 'BreadcrumbLink-3',
            name: <FormattedMessage id='workspaceMenu.link.item63'/>,
            link: formatRoute(NamedRoutes.workspace.company.payments, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='PaymentsContent.ActionLinks.PaymentRequests'/>)
      }
      if (!!isPaymentClaim && !!getPaymentClaimRequests) {
        getPaymentClaimRequests(claimId, true)
      } else if (!!getPaymentRequests) {
        getPaymentRequests(claimId, true)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <>
      <CompanyConsumer>
        {({
            paymentRequestsLoading, paymentRequestsFailure, paymentRequestsFailure403, paymentRequests, PaymentClaimOrderHeaderColumns,
            PaymentOrderHeaderColumns, handleSubmitRequestsFilter, hasMorePaymentRequests, PaymentOrderWithoutSourceHeaderColumns,
            PaymentClaimOrderWithoutSourceHeaderColumns, isCompanyUnderage, companyFailureUnderage, companyFailureUnderageMessage
          }) => (
          <Box p={2} className={'back-white p-xs-1'}>

            {!!paymentRequestsFailure403 ? <UnAuthorize/> :
              (!!isCompanyUnderage && !!companyFailureUnderage ?
                <Alert severity="error">
                  {companyFailureUnderageMessage}
                  <FormattedMessage id='Underage.ErrorAlert' values={{
                    a: chunks => <NavLink to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
                  }}/></Alert>
                : (!!paymentRequestsFailure &&
                  <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                ))
            }
            {!paymentRequestsFailure403 && !companyFailureUnderage && !(!!isCompanyUnderage && !!paymentRequestsLoading) &&
            <RequestsFilter submitForm={handleSubmitRequestsFilter} claimId={claimId}
                            isPaymentClaim={isPaymentClaim}/>
            }
            {!!paymentRequestsLoading && <SearchLoader loading={true}/>}
            {(!!paymentRequests && !!paymentRequests.length) ?
              <WorkspaceTable
                scrollableTarget={scrollableTarget}
                loadRows={getPaymentRequests}
                hasMoreRows={hasMorePaymentRequests}
                rows={paymentRequests}
                columns={
                  !!claimId ? (!!isPaymentClaim ? PaymentClaimOrderWithoutSourceHeaderColumns : PaymentOrderWithoutSourceHeaderColumns)
                    : (!!isPaymentClaim ? PaymentClaimOrderHeaderColumns : PaymentOrderHeaderColumns)
                }
                isPaymentClaim={isPaymentClaim}
                claimId={claimId}
                paymentActions={true}
                isOldPayments={true}
                size={'small'}
                handleOldPaymentOrdersDialog={handleOldPaymentOrdersDialog}/>
              :
              !paymentRequestsLoading && !paymentRequestsFailure403 && !companyFailureUnderage &&
              <EmptyResult isPaymentRequests={true}/>
            }
          </Box>
        )}
      </CompanyConsumer>
    </>
  )
}

export default CompanyPaymentRequests
