import { darken, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  header: {
    left: 0,
    right: 0,
    top: 0,
    width: '100% !important',
    zIndex: 1000,
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    transition: 'all 0.5s ease-in-out',
    '& .MuiContainer-root': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  topHeader: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.primary.light}`,
    background: theme.palette.common.white
  },
  topHeaderContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  topHeaderLink: {
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    padding: theme.spacing(0.25, 4),
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(1),
    display: 'inline-block'
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    // flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  headerIdentity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h1': {
      fontSize: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(0),
      marginRight: '5%'
    }
  },
  headerQuickActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    position: 'relative',
    '& a': {
      display: 'inline-block'
    },
    '&:before': {
      content: '""',
      display: 'inline-block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: `30px 0 0 40px`,
      borderColor: `${theme.palette.grey[200]} ${theme.palette.common.white} ${theme.palette.common.white} ${theme.palette.common.white}`,
      left: 0,
      position: 'absolute'

    },
    '&:after': {
      content: '""',
      display: 'inline-block',
      width: '40vw',
      height: '100%',
      backgroundColor: theme.palette.grey[200],
      left: '95%',
      position: 'absolute',
      zIndex: 0

    }
  },
  headerQuickActionsLink: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  headerQuickActionsButton: {
    background: theme.palette.warning.dark,
    borderRadius: '3px',
    color: theme.palette.common.white,
    padding: theme.spacing(0.25, 0.6),
    margin: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    zIndex: 2,
    '&.hidden': {
      visibility: 'hidden'
    },
    '&:hover': {
      background: darken(theme.palette.warning.dark, 0.2),
      color: theme.palette.common.white
    },
    '&:focus': {
      color: theme.palette.common.white
    }
  }
}))
