import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  BasicInfoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root': {
      width: 340,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: 510
    }
  },
  accountManagementLinkWrapper:{
    justifyContent: 'flex-end',
  },
  accountManagementLink: {
    padding: theme.spacing(0.25),
    display: 'inline-block',
    color: theme.palette.primary.main,
    position: 'relative',
    backgroundColor: 'transparent !important',
  }
}))
