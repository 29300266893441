import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    //   margin: 0,
    //   maxHeight: '100%'
    // }
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    position: 'relative',
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative'
  },
  PaymentOrderStepMessage: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.7,
    marginBottom: theme.spacing(2),
    '&.center': {
      textAlign: 'center'
    },
    '& p': {
      marginBottom: theme.spacing(1.5)
    }
  },
  PaymentFirstStepActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      minWidth: '20%'
    }
  },
  NewOrderPayButton: {
    minWidth: '20%'
  },

  ErrorDialogPaper: {
    borderRadius: 0
  },
  ErrorDialogContent: {
    padding: '0 !important',
    position: 'relative',
    '& .MuiAlert-root':{
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(6),
      }
    }
  },
  ErrorDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.primary.dark
  }
}))
