import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableCell,
  TableBody
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import BalancePaymentRow from './Row'
import { useStyles } from './Styles'
import { PayNonCoreConsumer } from '../../../../../../../PayNoncoreContext'
import { sm } from '../../../../../../../utils/ResponsiveUtility'

const ClaimsListingTable = ({ rows, columns, value, setValue, noPagination }) => {
  const classes = useStyles()
  const intl = useIntl()

  let [width, setWidth] = React.useState(document.body.clientWidth)
  let Mobile = (width < sm)

  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])
  const handleLabelDisplayedRows = ({ from, to, count }) => (
    <FormattedMessage
      id={`TablePagination.LabelDisplayedRows.with${(count !== -1) ? 'Count' : 'NoCount'}`}
      values={{ from: from, to: to, count: count }}/>
  )
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table aria-label="Payment table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='center'>
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align='center'
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!rows?.length ? rows.map((row, index) => (
                  <BalancePaymentRow key={index} row={row} columns={columns}
                                     value={value} setValue={setValue}/>
                )) :
                <TableRow hover role="checkbox" tabIndex={-1}>
                  <TableCell colSpan={columns?.length || 1} className={classes.noResults}>
                    <FormattedMessage id='Table.NoResults'/>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {!noPagination &&
        <PayNonCoreConsumer>
          {({
              paymentClaimsCount,
              paymentClaimsRowsPerPage,
              paymentClaimsPage,
              handleChangePaymentClaimsPage,
              handleChangePaymentClaimsRowsPerPage
            }) => (
            !!Mobile ?
              <Pagination className={classes.mobilePagination}
                          count={Math.ceil(paymentClaimsCount / paymentClaimsRowsPerPage)}
                          page={paymentClaimsPage + 1}
                          onChange={(event, newPage) => handleChangePaymentClaimsPage(event, newPage - 1)}/>
              :
              <TablePagination
                rowsPerPageOptions={[10, 25, 100, 200]}
                component="div"
                count={paymentClaimsCount || 0}
                rowsPerPage={paymentClaimsRowsPerPage}
                page={paymentClaimsPage}
                onChangePage={handleChangePaymentClaimsPage}
                onChangeRowsPerPage={handleChangePaymentClaimsRowsPerPage}
                labelDisplayedRows={handleLabelDisplayedRows}
                labelRowsPerPage={intl.formatMessage({ id: 'TablePagination.LabelRowsPerPage' })}
                backIconButtonText={intl.formatMessage({ id: 'TablePagination.BackIconButtonText' })}
                nextIconButtonText={intl.formatMessage({ id: 'TablePagination.NextIconButtonText' })}
              />
          )}
        </PayNonCoreConsumer>
        }
      </Paper>
    </React.Fragment>
  )
}
export default ClaimsListingTable