import React from 'react'
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar'
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton'
import { useStyles } from './Styles'
import moment from 'moment'
import 'moment/locale/ar-ly'
import { LanguageConsumer } from '../../../../../../LanguageContext'

const CustomToolbarMonth = function(props) {
  const {
    date,
    isLandscape,
    openView,
    setOpenView,
    title
  } = props

  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view)
  }
  const classes = useStyles()
  return (
    <PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h6"
        label={moment(date).format('YYYY')}
        selected={openView === 'year'}
      />
      <LanguageConsumer>
        {({ locale }) => (
          <ToolbarButton
            onClick={handleChangeViewClick('month')}
            variant="h4"
            label={moment(date).locale(locale === 'ar' ? 'ar-ly' : 'en-us').format('MMMM')}
            selected={openView === 'month'}
          />
        )}
      </LanguageConsumer>
    </PickerToolbar>
  )
}

export default CustomToolbarMonth