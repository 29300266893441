import React from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { DelegateConsumer } from '../../../../DelegateContext'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import EditDelegate from './EditDelegate'
import { useStyles } from './Styles'

const AddDelegateButton = ({ resetDelegateStatus, companyIsAccountant }) => {
  let classes = useStyles()

  const [viewAddDelegateDialog, setViewAddDelegateDialog] = React.useState(false)
  const [openAddDelegateDialog, setOpenAddDelegateDialog] = React.useState(false)
  const handleAddDelegateDialog = () => {
    if (!openAddDelegateDialog) {
      resetDelegateStatus()
      setViewAddDelegateDialog(true)
    } else {
      setTimeout(() => setViewAddDelegateDialog(false), 500)
    }
    setOpenAddDelegateDialog(!openAddDelegateDialog)
  }

  return (
    <>
      <Button className={classes.addDelegateBtn} size="medium" variant="contained" color="primary" onClick={handleAddDelegateDialog}>
        <FormattedMessage id='Delegate.AddNew'/>
      </Button>
      {!!viewAddDelegateDialog &&
      <AuthenticatedUserConsumer>
        {({ paymentsFiltersData }) => (
          !!paymentsFiltersData && !!Object.keys(paymentsFiltersData).length &&
          <DelegateConsumer>
            {({ delegateData, handleDelegateSubmit }) => (
              <EditDelegate
                openEditDelegateDialog={openAddDelegateDialog}
                handleEditDelegateDialog={handleAddDelegateDialog}
                delegateData={delegateData}
                companyIsAccountant={companyIsAccountant}
                handleDelegateSubmit={handleDelegateSubmit}
                paymentsFiltersData={paymentsFiltersData}
              />
            )}
          </DelegateConsumer>
        )}
      </AuthenticatedUserConsumer>
      }
    </>
  )
}

export default AddDelegateButton
