/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import InfiniteScroll from 'react-infinite-scroll-component'
import UnAuthorize from '../../../UnAuthorize'
import { CompanyContactProvider } from '../../../../CompanyContactContext'
import CompanyContact from './CompanyContact'
import { useStyles } from '../Styles'

const OldCompaniesContacts = () => {
  const classes = useStyles()
  return (
    <AuthenticatedUserConsumer>
      {({
          companiesContactsLoading, companiesContactsFailure, companiesContactsFailure403, companiesContacts,
          getCompaniesContacts, hasMoreCompaniesContacts, resetAddCompanyContact
        }) => (
        <>
          {!!companiesContactsLoading ? <SearchLoader loading={true}/> :
            !!companiesContactsFailure ?
              (!!companiesContactsFailure403 ? <UnAuthorize/> :
                <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>)
              :
              <>
                {!(companiesContacts === undefined || companiesContacts.length === 0) &&
                <div className={classes.CompaniesContactsWrapper}>
                  <Accordion defaultExpanded={true} variant={'outlined'}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                    >
                      <div className={classes.SettingHeader}>
                        <Typography component={'h2'} variant={'h6'} color={'primary'}>
                          <FormattedMessage id='profile.OldCompaniesProfileTitle'/>
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                      <div className={classes.CompaniesContactsWrapper}>
                        <InfiniteScroll
                          className={classes.infiniteScroll}
                          dataLength={companiesContacts.length} //This is important field to render the next data
                          next={getCompaniesContacts}
                          hasMore={hasMoreCompaniesContacts}
                          loader={<SearchLoader/>}
                        >
                          {companiesContacts.map((companyContact) =>
                            <CompanyContactProvider key={companyContact.id} companyContactData={companyContact}
                                                    ccid={companyContact.id}
                                                    resetAddCompanyContact={resetAddCompanyContact}>
                              <CompanyContact/>
                            </CompanyContactProvider>
                          )}
                        </InfiniteScroll>
                      </div>

                    </AccordionDetails>
                    <Divider/>
                  </Accordion>
                </div>
                }
              </>
          }
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default OldCompaniesContacts
