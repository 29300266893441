import React from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import EditProfile from './pages/Workspace/EditProfile'
import Dashboard from './pages/Workspace/Dashboard'
import Company from './pages/Workspace/Company'
import Notifications from './pages/Workspace/Company/Notifications'
import Payments from './pages/Workspace/Company/Payments'
import PaymentRequests from './pages/Workspace/Company/PaymentRequests'
import PaymentReceipts from './pages/Workspace/Company/PaymentReceipts'
import NoMatch from './pages/NoMatch'
import UnAuthorized from './pages/UnAuthorized'
import PaymentClaims from './pages/Workspace/Company/PaymentClaims'
import RedirectRoute from './utils/RedirectRoute'
import Delegates from './pages/Workspace/Company/Delegates'
import EcommerceClient from './pages/Workspace/Company/EcommerceClient'
import EditCompanyContact from './pages/Workspace/EditCompanyContact'
import PaymentsBalance from './pages/Workspace/Company/PaymentsBalance'
import InsuranceServices from './pages/Workspace/InsuranceServices'
import DownPayment from './pages/Workspace/Company/DownPayment'
import UnderMaintenance from './pages/UnderMaintenance'
import Configuration from './Services/Api/Configuration'

export const NamedRoutes = {
  home: '/',
  workspace: {
    dashboard: '/workspace/dashboard',
    insuranceServices: '/workspace/insurance-services',
    company: {
      view: '/workspace/company/:id',
      notifications: '/workspace/company/:id/notifications',
      delegates: '/workspace/company/:id/delegates',
      payments: '/workspace/company/:id/payments',
      paymentsBalance: '/workspace/company/:id/payments/balance',
      paymentClaims: '/workspace/company/:id/payment-claims',
      downPayment: '/workspace/company/:id/down-payment',
      paymentRequests: '/workspace/company/:id/payment-requests',
      paymentReceipts: '/workspace/company/:id/payment-receipts',
      ecommerceClients: '/workspace/company/:id/ecommerce-clients'
    },
    editProfile: '/workspace/profile/edit',
    editCompanyContact: '/workspace/profile/company-contact'
    // notifications: '/workspace/notifications'
  },
  unAuthorized: '/unauthorized',
  underMaintenance: '/undermaintenance',
  test: {
    main: '/test/main'
  }
}

export const Routes = () => {
  return (
    <Switch>
      <RedirectRoute exact path={NamedRoutes.home} redirectURL={NamedRoutes.workspace.dashboard}/>
      <Route exact path={NamedRoutes.unAuthorized} component={UnAuthorized}/>
      <PrivateRoute exact path={NamedRoutes.workspace.dashboard} component={Dashboard}/>
      <PrivateRoute exact path={NamedRoutes.workspace.insuranceServices} component={InsuranceServices}
                    hasRedirection={!!Configuration.HIDE_INSURANCE_SERVICES}
                    redirectURL={NamedRoutes.workspace.dashboard}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.view} component={Company}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.notifications} component={Notifications}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.delegates} component={Delegates}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.ecommerceClients} component={EcommerceClient}/>

      <PrivateRoute exact path={NamedRoutes.workspace.company.payments} component={Payments}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.paymentClaims} component={PaymentClaims}
                    hasRedirection={!!Configuration.HIDE_PAYMENT_CLAIMS_MENUITEM}
                    redirectURL={NamedRoutes.workspace.dashboard}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.downPayment} component={DownPayment}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.paymentRequests} component={PaymentRequests}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.paymentReceipts} component={PaymentReceipts}/>
      <PrivateRoute exact path={NamedRoutes.workspace.company.paymentsBalance} component={PaymentsBalance}/>
      <PrivateRoute exact path={NamedRoutes.workspace.editProfile} component={EditProfile}/>
      <PrivateRoute exact path={NamedRoutes.workspace.editCompanyContact} component={EditCompanyContact}/>
      <Route exact path={NamedRoutes.underMaintenance} component={UnderMaintenance}/>
      <Route component={NoMatch}/>
    </Switch>
  )
}
