import React from 'react'
import { SEO } from '../../../../components/Seo/seo'
import { useParams } from 'react-router-dom'
import { ecommerce } from '../../../../utils/pages-meta'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import NoMatch from '../../../NoMatch'
import EcommerceClientContent from '../../../../components/Workspace/CompanyPage/EcommerceClientContent'
import { FormattedMessage, useIntl } from 'react-intl'
import { SearchLoader } from '../../../../components/Loader/SearchLoader'
import InActiveUserContent from '../../../../components/InActiveUserContent'
import { Container } from '@material-ui/core'
import { useStyles } from '../Styles'

const EcommerceClient = () => {
  let { id } = useParams()
  const intl = useIntl()
  const classes = useStyles()
  return (
    <>
      {!!id && !!parseInt(id) ? <>
          <SEO title={intl.formatMessage({ id: 'PageTitle.EcommerceClients' })}
               pathname={window.location.origin}
               titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
               image={ecommerce.image}
               description={intl.formatMessage({ id: 'PageTitle.EcommerceClients' })}
          />
          <AuthenticatedUserConsumer>
            {({ userDataLoading, isUserActive, userData, checkCompanyData }) => (
              <>
                <Container maxWidth="lg" className={classes.TitleContainer}>
                  <h1><FormattedMessage id='workspaceMenu.link.item12'/></h1>
                </Container>
                {!!userDataLoading && <SearchLoader loading={true}/>}
                {(!!Object.keys(userData).length && !!isUserActive) ?
                  <EcommerceClientContent checkCompanyData={checkCompanyData} cid={id}/>
                  : (!userDataLoading && <InActiveUserContent/>)
                }
              </>
            )}
          </AuthenticatedUserConsumer>
        </> :
        <NoMatch/>
      }
    </>
  )
}

export default EcommerceClient
