import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { FormattedMessage, useIntl } from 'react-intl'
import CompanyServices from './Services/Consumers/CompanyServices'
import { NavLink } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from './routes'
import Configuration from './Services/Api/Configuration'
import { filterData, log } from './utils/helpers'
import {
  PaymentClaimPayload, PaymentClaimRequestsFiltersPayload, EcommerceFiltersPayload,
  PaymentReceiptsFiltersPayload,
  PaymentRequestsFiltersPayload, PaymentsFiltersPayload
} from './Services/Payloads/AuthenticatedUserPayload'

let { User_Config } = Configuration

const CompanyContext = createContext({})

const CompanyProvider = ({ cid, selectedCompany, children }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const PaymentHeaderColumns = [
    { id: 'tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 122 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    {
      id: 'registration_payment_type',
      label: <FormattedMessage id='PaymentHeader.registrationPaymentType'/>,
      minWidth: 122
    },
    { id: 'claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 130 },
    { id: 'claim_amount', label: <FormattedMessage id='PaymentHeader.claimAmountNoCur'/>, minWidth: 150 },
    { id: 'claim_currency', label: <FormattedMessage id='PaymentHeader.claimCurrency'/>, minWidth: 150 },
    { id: 'payment_status', label: <FormattedMessage id='PaymentHeader.paymentStatus'/>, minWidth: 133 },
    { id: 'tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'claim_expire_date', label: <FormattedMessage id='PaymentHeader.climeExpireDate'/>, minWidth: 145 }
  ]

  const BalancePaymentHeaderColumns = [
    { id: 'tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 122 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    {
      id: 'registration_payment_type',
      label: <FormattedMessage id='PaymentHeader.registrationPaymentType'/>,
      minWidth: 122
    },
    { id: 'claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 130 },
    { id: 'claim_amount', label: <FormattedMessage id='PaymentHeader.claimAmountNoCur'/>, minWidth: 150 },
    { id: 'claim_currency', label: <FormattedMessage id='PaymentHeader.claimCurrency'/>, minWidth: 150 },
    { id: 'payment_status', label: <FormattedMessage id='PaymentHeader.paymentStatus'/>, minWidth: 133 },
    { id: 'tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'claim_expire_date', label: <FormattedMessage id='PaymentHeader.climeExpireDate'/>, minWidth: 145 }
  ]

  const PaymentForPayOthersColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 122 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    {
      id: 'registration_payment_type',
      label: <FormattedMessage id='PaymentHeader.registrationPaymentType'/>,
      minWidth: 122
    },
    { id: 'claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 130 },
    { id: 'claim_amount', label: <FormattedMessage id='PaymentHeader.claimAmountNoCur'/>, minWidth: 150 },
    { id: 'claim_currency', label: <FormattedMessage id='PaymentHeader.claimCurrency'/>, minWidth: 150 },
    { id: 'payment_status', label: <FormattedMessage id='PaymentHeader.paymentStatus'/>, minWidth: 133 },
    { id: 'tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'claim_expire_date', label: <FormattedMessage id='PaymentHeader.climeExpireDate'/>, minWidth: 145 }
  ]

  const PaymentClaimHeaderColumns = [
    { id: 'tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 122 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmount'/>, minWidth: 150 },
    { id: 'claim_state', label: <FormattedMessage id='PaymentHeader.paymentStatus'/>, minWidth: 133 },
    { id: 'tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'created_at', label: <FormattedMessage id='PaymentHeader.createdDate'/>, minWidth: 150 }
  ]

  const PaymentOrderWithoutSourceHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    {
      id: 'registration_payment_type',
      label: <FormattedMessage id='PaymentHeader.registrationPaymentType'/>,
      minWidth: 140
    },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 140 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmountPayed'/>, minWidth: 250 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    {
      id: 'payment_claim_expire_date',
      label: <FormattedMessage id='PaymentHeader.climeExpireDate'/>,
      minWidth: 150
    },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]

  const PaymentOrderHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    {
      id: 'registration_payment_type',
      label: <FormattedMessage id='PaymentHeader.registrationPaymentType'/>,
      minWidth: 140
    },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 140 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmountPayed'/>, minWidth: 250 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    {
      id: 'payment_claim_expire_date',
      label: <FormattedMessage id='PaymentHeader.climeExpireDate'/>,
      minWidth: 150
    },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]


  const PaymentClaimOrderWithoutSourceHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmount'/>, minWidth: 250 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]

  const PaymentClaimOrderHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmount'/>, minWidth: 250 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]
  const PaymentReceiptHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'receipt_number', label: <FormattedMessage id='PaymentHeader.receiptNumber'/>, minWidth: 160 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 140 },
    {
      id: 'tax_payment_request_number',
      label: <FormattedMessage id='PaymentHeader.taxPaymentRequestNumber'/>,
      minWidth: 140,
      singleOnly: true
    },
    {
      id: 'pg_payment_request_number',
      label: <FormattedMessage id='PaymentHeader.pgPaymentRequestNumber'/>,
      minWidth: 140,
      singleOnly: true
    },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.receiptAmountPayed'/>, minWidth: 140 },
    {
      id: 'pg_payment_fees_amount',
      label: <FormattedMessage id='PaymentHeader.pgPaymentFeesAmount'/>,
      minWidth: 200,
      singleOnly: true
    },
    {
      id: 'pg_payment_total_amount',
      label: <FormattedMessage id='PaymentHeader.pgPaymentTotalAmount'/>,
      minWidth: 200,
      singleOnly: true
    },
    { id: 'payment_claim_currency', label: <FormattedMessage id='PaymentHeader.receiptCurrency'/>, minWidth: 140 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'create_at', label: <FormattedMessage id='PaymentHeader.createDate'/>, minWidth: 150 }
  ]
  const DelegatesHeaderColumns = [
    { id: 'username', label: <FormattedMessage id='DelegateHeader.UserName'/>, minWidth: 200 },
    { id: 'name', label: <FormattedMessage id='DelegateHeader.Name'/>, minWidth: 200 },
    { id: 'email', label: <FormattedMessage id='DelegateHeader.Email'/>, minWidth: 200 },
    { id: 'role', label: <FormattedMessage id='DelegateHeader.Role'/>, minWidth: 180 },
    { id: 'coretaxtion_delegate_roles', label: <FormattedMessage id='DelegateHeader.DelegateRole'/>, minWidth: 160 }
  ]
  const EcommerceHeaderColumns = [
    { id: 'name_en', label: <FormattedMessage id='EcommerceHeader.platformEnglish'/>, minWidth: 200 },
    { id: 'name_ar', label: <FormattedMessage id='EcommerceHeader.platformArabic'/>, minWidth: 200 },
    { id: 'url', label: <FormattedMessage id='EcommerceHeader.platformUrl'/>, minWidth: 200 },
    { id: 'services', label: <FormattedMessage id='EcommerceHeader.services'/>, minWidth: 180 },
    { id: 'client_id', label: <FormattedMessage id='EcommerceHeader.clientId'/>, minWidth: 160 }
  ]

  const [companyFailureUnderageMessage, setCompanyFailureUnderageMessage] = React.useState('')
  const [companyFailureUnderage, setCompanyFailureUnderage] = React.useState(false)
  const [isCompanyUnderage, setIsCompanyUnderage] = React.useState(false)

  const [paymentsLoading, setPaymentsLoading] = React.useState(false)
  const [paymentsRefreshLoading, setPaymentsRefreshLoading] = React.useState(false)
  const [paymentsFailure, setPaymentsFailure] = React.useState(false)
  const [paymentsFailure403, setPaymentsFailure403] = React.useState(false)
  const [payments, setPayments] = React.useState([])
  const [paymentsPage, setPaymentsPage] = React.useState(1)
  const [paymentsPagesCount, setPaymentsPagesCount] = React.useState(1)
  const [paymentsTotalCount, setPaymentsTotalCount] = React.useState(0)
  const [paymentsFiltersBackup, setPaymentsFiltersBackup] = React.useState(null)

  const [paymentRequestsLoading, setPaymentRequestsLoading] = React.useState(false)
  const [paymentRequestsFailure, setPaymentRequestsFailure] = React.useState(false)
  const [paymentRequestsFailure403, setPaymentRequestsFailure403] = React.useState(false)
  const [paymentRequests, setPaymentRequests] = React.useState([])
  const [hasMorePaymentRequests, setHasMorePaymentRequests] = React.useState(false)
  const [paymentRequestsPage, setPaymentRequestsPage] = React.useState(1)
  const [paymentRequestsClaimId, setPaymentRequestsClaimId] = React.useState(null)

  const [shouldRefreshPaymentsForDeletedRecords, setShouldRefreshPaymentsForDeletedRecords] = React.useState(false)

  const [breadCrumbLinks, setBreadCrumbLinks] = React.useState([{
    key: 'BreadcrumbLink-1',
    name: <FormattedMessage id='DashboardContent.BreadCrumb.companies'/>,
    link: NamedRoutes.workspace.dashboard
  }])
  const [breadCrumbCurrent, setBreadCrumbCurrent] = React.useState('')
  const [breadCrumbButton, setBreadCrumbButton] = React.useState('')

  const resetCompanyUnderageError = () => {
    setCompanyFailureUnderageMessage('')
    setCompanyFailureUnderage(false)
  }

  React.useEffect(() => {
      if (!!Object.keys(selectedCompany || {}).length) {
        setIsCompanyUnderage(!!selectedCompany?.is_underage)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCompany])

  const refreshPaymentsForDeletedRecords = (shouldRefreshPayments, isPaymentClaim) => {
    if (!!shouldRefreshPaymentsForDeletedRecords || shouldRefreshPayments) {
      log(shouldRefreshPaymentsForDeletedRecords)
      if (!!isPaymentClaim) {
        setPaymentClaims([])
        getPaymentClaims(true)
      } else {
        setPayments([])
        getPayments(true)
      }
      setShouldRefreshPaymentsForDeletedRecords(false)
    }
  }

  const removePaymentRequest = (paymentOrderId, shouldRefreshPayments, isPaymentClaim) => {
    setPaymentRequestsLoading(true)
    setPaymentRequests((prevResent) => [
      ...prevResent.filter((recent) => ![paymentOrderId].some((current) => recent.request_number === current))])
    setPaymentRequestsLoading(false)
    setShouldRefreshPaymentsForDeletedRecords(true)
    if (!!shouldRefreshPayments) {
      refreshPaymentsForDeletedRecords(shouldRefreshPayments, isPaymentClaim)
    }
  }

  const removeCurrentRequestsClaimId = () => {
    setPaymentRequestsClaimId(null)
  }

  const [paymentReceiptsLoading, setPaymentReceiptsLoading] = React.useState(false)
  const [paymentReceiptsFailure, setPaymentReceiptsFailure] = React.useState(false)
  const [paymentReceiptsFailure403, setPaymentReceiptsFailure403] = React.useState(false)
  const [paymentReceipts, setPaymentReceipts] = React.useState([])
  const [receiptsFilterData, setReceiptsFilterData] = React.useState(null)
  const [ridToOpen, setRidToOpen] = React.useState(0)
  const [hasMorePaymentReceipts, setHasMorePaymentReceipts] = React.useState(false)
  const [paymentReceiptsPage, setPaymentReceiptsPage] = React.useState(1)


  const handleSubmitReceiptsFilter = (values, FormikActions) => {
    setPaymentReceiptsPage(1)
    setRidToOpen(0)
    setRequestIdToOpen(null)
    setPaymentReceipts([])
    setHasMorePaymentReceipts(false)

    setReceiptsFilterData({
      values: values,
      FormikActions: FormikActions
    })
  }


  const [requestIdToOpen, setRequestIdToOpen] = React.useState(null)
  const checkReceiptToOpen = () => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let rid = params.get('req_num')
    // if (rid) {
    //   setRequestIdToOpen(rid)
    // } else {
    //   setRequestIdToOpen(null)
    // }
    return rid || 0
  }

  const getPaymentReceiptsList = (filters, FormikActions, openValidity) => {
    CompanyServices.getPaymentReceipts(cid, {
      headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
      params: filterData(PaymentReceiptsFiltersPayload(paymentReceiptsPage, User_Config.userPaymentReceiptsLimit, filters))
    }).then((result) => {
      if ((!!result.response && result.response.status !== 200) || !result) {
        if (!!result.response && result.response.status === 403) {
          setPaymentReceiptsFailure403(true)
        }
        if (!!result.response && result.response.status === 405) {
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          setCompanyFailureUnderage(true)
          if (result.response?.data?.status?.message) {
            message = result.response.data.status.message
          }
          setCompanyFailureUnderageMessage(message)
        }
        setPaymentReceiptsFailure(true)
      } else {
        if (!!result && !!result.data && !!result.data.data) {
          let newData = []
          // let openError = true
          result.data.data.forEach((receipt) => {
            let newReceipt = {
              id: receipt.id,
              create_at: receipt.create_at,
              receipt_number: receipt.receipt_number,
              companyNameString: receipt.payment_request.company.name || 'N/A',
              companyName: (!!receipt.payment_request.company.active && !!receipt.payment_request.company.company_user_active && !!receipt.payment_request.company.name) ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: receipt.payment_request.company.id })}>
                  {receipt.payment_request.company.name}</NavLink> : receipt.payment_request.company.name || 'N/A',
              taxpayer_name: receipt.payment_request.company.taxpayer_name,
              taxpayerName: !!receipt.payment_request.company.active && !!receipt.payment_request.company.company_user_active ?
                <NavLink
                  to={formatRoute(NamedRoutes.workspace.company.view, { id: receipt.payment_request.company.id })}>
                  {receipt.payment_request.company.taxpayer_name}</NavLink> : receipt.payment_request.company.taxpayer_name,
              tax_id: receipt.payment_request.company.tax_id,
              request_number: receipt.payment_request.request_number,
              payment_tax_type: receipt.payment_request.payment_tax_type,
              payment_claim_number: receipt.payment_request.payment_claim_number,
              tax_payment_request_number: receipt.payment_request?.none_core_payment_claim?.tax_payment_request_number || '',
              pg_payment_request_number: receipt.pg_payment_request_number || '',
              pg_payment_fees_amount: receipt.pg_payment_fees_amount || '',
              pg_payment_total_amount: receipt.pg_payment_total_amount || '',
              payment_type: receipt.payment_request.payment_type,
              payment_tax_period_from: receipt.payment_request.payment_tax_period_from,
              payment_tax_period_to: receipt.payment_request.payment_tax_period_to,
              payment_item: receipt.payment_request.payment_item,
              payment_claim_amount: receipt.payment_request.payment_claim_amount,
              payment_claim_currency: <FormattedMessage id='PaymentHeader.receiptCurrency.egp'/>
            }
            newData.push(newReceipt)
          })
          if (!!openValidity) {
            handlePaymentReceiptValidityDialog()
          }
          setPaymentReceipts((prevState) =>
            result.data.meta.current_page === 1 ? newData :
              [...prevState, ...newData])
          // (result.data.meta.current_page === 1 && !isAddedToReceipts) ? newData :
          //   [...new Map([...prevState, ...newData].map(item => [item.id, item])).values()])

          if (paymentReceiptsPage < result.data.meta.last_page) {
            setPaymentReceiptsPage(paymentReceiptsPage + 1)
            setHasMorePaymentReceipts(true)
          } else {
            setPaymentReceiptsPage(1)
            setHasMorePaymentReceipts(false)
          }
        }
      }
    }).then(() => {
      setPaymentReceiptsLoading(false)
      if (!!FormikActions) {
        FormikActions.setSubmitting(false)
      }
    })
  }

  const getPaymentReceipts = (reset) => {
    if (!!reset) {
      setRidToOpen(checkReceiptToOpen())
      setReceiptValidityCode(0)
      setPaymentReceipts([])
      setHasMorePaymentReceipts(false)
      setPaymentReceiptsPage(1)
      setReceiptsFilterData({})
    } else {
      setRidToOpen(0)
      setRequestIdToOpen(null)
      getPaymentReceiptsListBefore()
    }
  }
  React.useEffect(() => {
      if (!!receiptsFilterData && paymentReceiptsPage === 1) {
        getPaymentReceiptsListBefore()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiptsFilterData])

  const [receiptValidityCode, setReceiptValidityCode] = React.useState(0)
  const getPaymentReceiptsListBefore = () => {
    if (!!keycloak?.authenticated) {
      setPaymentReceiptsFailure(false)
      setPaymentReceiptsFailure403(false)
      resetCompanyUnderageError()
      if (paymentReceiptsPage === 1) {
        setPaymentReceiptsLoading(true)
      }
      if (!!ridToOpen) {
        CompanyServices.checkPaymentOrderValidity(cid, ridToOpen, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
          if ((!!result.response && result.response.status === 200) || result.status === 200) {
            setReceiptValidityCode(1)//Continue
          } else if ((!!result.response && result.response.status === 201) || result.status === 201) {
            setReceiptValidityCode(2)//Paid
          } else if ((!!result.response && result.response.status === 409) || result.status === 409) {
            setReceiptValidityCode(3)//Failed
          } else {
            setReceiptValidityCode(4)//Error
          }
        }).then(() => {
          setRidToOpen(0)
          getPaymentReceiptsList(receiptsFilterData.values || {}, receiptsFilterData.FormikActions || null, true)
        })
      } else {
        getPaymentReceiptsList(receiptsFilterData.values || {}, receiptsFilterData.FormikActions || null, false)
      }
    }
  }


  const [requestsFilterData, setRequestsFilterData] = React.useState(null)
  const handleSubmitRequestsFilter = (values, FormikActions, isPaymentClaim) => {
    setPaymentRequestsPage(1)
    setPaymentRequests([])
    setHasMorePaymentRequests(false)
    setRequestsFilterData({
      values: values,
      FormikActions: FormikActions,
      isPaymentClaim: isPaymentClaim
    })
  }

  const getPaymentRequests = (claimId, reset) => {
    if (!!claimId) {
      setPaymentRequestsClaimId(claimId)
    }
    if (!!reset) {
      setPaymentRequestsPage(1)
      setPaymentRequests([])
      setHasMorePaymentRequests(false)
      setRequestsFilterData({
        isPaymentClaim: false
      })
    } else {
      getPaymentRequestsList(claimId)
    }
  }
  React.useEffect(() => {
      if (!!requestsFilterData && paymentRequestsPage === 1) {
        !!requestsFilterData.isPaymentClaim ? getPaymentClaimRequestsList() : getPaymentRequestsList()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestsFilterData])


  const getPaymentClaimRequests = (claimId, reset) => {
    if (!!claimId) {
      setPaymentRequestsClaimId(claimId)
    }
    if (!!reset) {
      setPaymentRequestsPage(1)
      setPaymentRequests([])
      setHasMorePaymentRequests(false)
      setRequestsFilterData({
        isPaymentClaim: true
      })
    } else {
      getPaymentClaimRequestsList(claimId)
    }
  }

  const getPaymentRequestsList = (claimId) => {
    if (!!keycloak?.authenticated) {
      setPaymentRequestsFailure(false)
      setPaymentRequestsFailure403(false)
      resetCompanyUnderageError()
      if (paymentRequestsPage === 1) {
        setPaymentRequestsLoading(true)
      }
      CompanyServices.getPaymentRequests(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentRequestsFiltersPayload(paymentRequestsClaimId || claimId, paymentRequestsPage, User_Config.userPaymentRequestsLimit, (!!requestsFilterData && !!requestsFilterData.values) ? requestsFilterData.values : {}))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setPaymentRequestsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setPaymentRequestsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((request) => {
              request['taxpayerName'] = <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.taxpayer_name}</NavLink>
              request['companyName'] = request.company.name ? <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.name}</NavLink> : 'N/A'
              request['tax_id'] = request.company.tax_id
              newData.push(request)
            })
            setPaymentRequests((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (paymentRequestsPage < result.data.meta.last_page) {
              setPaymentRequestsPage(paymentRequestsPage + 1)
              setHasMorePaymentRequests(true)
            } else {
              setPaymentRequestsPage(1)
              setHasMorePaymentRequests(false)
              // setPaymentRequestsClaimId(null)
            }
          }
        }
      }).then(() => {
        setPaymentRequestsLoading(false)
        if (!!requestsFilterData && !!requestsFilterData.FormikActions) {
          requestsFilterData.FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const getPaymentClaimRequestsList = (claimId) => {
    if (!!keycloak?.authenticated) {
      setPaymentRequestsFailure(false)
      setPaymentRequestsFailure403(false)
      resetCompanyUnderageError()
      if (paymentRequestsPage === 1) {
        setPaymentRequestsLoading(true)
      }
      CompanyServices.getPaymentClaimRequests(cid, paymentRequestsClaimId || claimId, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentClaimRequestsFiltersPayload(paymentRequestsPage, User_Config.userPaymentRequestsLimit, (!!requestsFilterData && !!requestsFilterData.values) ? requestsFilterData.values : {}))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setPaymentRequestsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setPaymentRequestsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((request) => {
              request['taxpayerName'] = <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.taxpayer_name}</NavLink>
              request['companyName'] = request.company.name ? <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.name}</NavLink> : 'N/A'
              request['tax_id'] = request.company.tax_id
              newData.push(request)
            })
            setPaymentRequests((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (paymentRequestsPage < result.data.meta.last_page) {
              setPaymentRequestsPage(paymentRequestsPage + 1)
              setHasMorePaymentRequests(true)
            } else {
              setPaymentRequestsPage(1)
              setHasMorePaymentRequests(false)
              // setPaymentRequestsClaimId(null)
            }
          }
        }
      }).then(() => {
        setPaymentRequestsLoading(false)
        if (!!requestsFilterData && !!requestsFilterData.FormikActions) {
          requestsFilterData.FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const [paymentClaimsLoading, setPaymentClaimsLoading] = React.useState(false)
  const [paymentClaimsRefreshLoading, setPaymentClaimsRefreshLoading] = React.useState(false)
  const [paymentClaimsFailure, setPaymentClaimsFailure] = React.useState(false)
  const [paymentClaimsFailure403, setPaymentClaimsFailure403] = React.useState(false)
  const [paymentClaims, setPaymentClaims] = React.useState([])
  const [hasMorePaymentClaims, setHasMorePaymentClaims] = React.useState(false)
  const [paymentClaimsPage, setPaymentClaimsPage] = React.useState(1)
  const [paymentClaimsFiltersBackup, setPaymentClaimsFiltersBackup] = React.useState(null)


  const handleSubmitClaimsFilter = (values, FormikActions) => {
    setPaymentClaims([])
    setHasMorePaymentClaims(false)
    getPaymentClaims(true, values, FormikActions)
  }

  const getPaymentClaims = (forceReload, filters, FormikActions) => {
    if (!!keycloak?.authenticated) {
      setPaymentClaimsFailure(false)
      setPaymentClaimsFailure403(false)
      resetCompanyUnderageError()
      if (paymentClaimsPage === 1 && !forceReload) {
        setPaymentClaimsLoading(true)
      }
      if (!!forceReload) {
        setPaymentClaimsPage(1)
        setPaymentClaimsRefreshLoading(true)
      }
      CompanyServices.getPaymentClaims(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentClaimRequestsFiltersPayload(!!forceReload ? 1 : paymentClaimsPage, User_Config.userPaymentClaimsLimit, filters || paymentClaimsFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setPaymentClaimsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setPaymentClaimsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((payment) => {
              payment['tax_id'] = selectedCompany.tax_id
              newData.push(payment)
            })
            setPaymentClaims((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (paymentClaimsPage < result.data.meta.last_page) {
              setPaymentClaimsPage(paymentClaimsPage + 1)
              if (!!filters && !!Object.keys(filters).length) {
                setPaymentClaimsFiltersBackup(filters)
              }
              setHasMorePaymentClaims(true)
            } else {
              setPaymentClaimsPage(1)
              setPaymentClaimsFiltersBackup(null)
              setHasMorePaymentClaims(false)
              // setPaymentRequestsClaimId(null)
            }
          }
        }
      }).then(() => {
        if (!!forceReload) {
          setPaymentClaimsRefreshLoading(false)
        } else {
          setPaymentClaimsLoading(false)
        }
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const handleSubmitPaymentsFilter = (values, FormikActions) => {
    setPayments([])
    if (!!Object.keys(values).length) {
      setPaymentsFiltersBackup(values)
    } else {
      setPaymentsFiltersBackup(null)
    }
    getPayments(true, values, FormikActions)
  }
  const getPayments = (forceReload, filters, FormikActions, page) => {
    if (!!keycloak?.authenticated) {
      setPaymentsFailure(false)
      setPaymentsFailure403(false)
      resetCompanyUnderageError()
      if (paymentsPage === 1 && !forceReload && !page) {
        setPaymentsLoading(true)
      }
      if (!!forceReload) {
        setPaymentsPage(1)
        setPaymentsRefreshLoading(true)
      }
      CompanyServices.getPayments(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentsFiltersPayload(!!forceReload ? 1 : (page || paymentsPage), User_Config.userPaymentClaimsLimit, filters || paymentsFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setPaymentsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setPaymentsFailure(true)
        } else {
          if (!!result && !!result.data && !!result?.data.data && !!result?.data.data.items) {
            let newData = []
            result.data.data.items.forEach((payment) => {
              payment['tax_id'] = selectedCompany.tax_id
              payment['claim_currency'] = `${payment?.currency?.name} (${payment?.currency?.key?.toUpperCase()})`
              newData.push(payment)
            })
            setPayments(newData)
            setPaymentsPagesCount(parseInt(result.data.data?.pagination?.total_pages_count))
            setPaymentsTotalCount(parseInt(result.data.data?.pagination?.total_bills_count))

          }
        }
      }).then(() => {
        setPaymentsRefreshLoading(false)
        if (!!forceReload) {
          setPaymentsRefreshLoading(false)
        } else {
          setPaymentsLoading(false)
        }
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const handleChangePaymentsPage = (event, newPage) => {
    if (newPage !== paymentsPage) {
      setPaymentsPage(newPage)
      setPaymentsRefreshLoading(true)
      getPayments(false, null, null, newPage)
    }
  }

  const [balancePaymentsLoading, setBalancePaymentsLoading] = React.useState(false)
  const [balancePaymentsRefreshLoading, setBalancePaymentsRefreshLoading] = React.useState(false)
  const [balancePaymentsFailure, setBalancePaymentsFailure] = React.useState(false)
  const [balancePaymentsFailure403, setBalancePaymentsFailure403] = React.useState(false)
  const [balancePayments, setBalancePayments] = React.useState([])
  const [balancePaymentsPage, setBalancePaymentsPage] = React.useState(1)
  const [balancePaymentsPagesCount, setBalancePaymentsPagesCount] = React.useState(1)
  const [balancePaymentsTotalCount, setBalancePaymentsTotalCount] = React.useState(0)
  const [balancePaymentsFiltersBackup, setBalancePaymentsFiltersBackup] = React.useState(null)
  const [availableBalance, setAvailableBalance] = React.useState(0)
  const [currentBalanceFilters, setCurrentBalanceFilters] = React.useState({})

  const handleSubmitBalancePaymentsFilter = (values, FormikActions) => {
    setBalancePayments([])
    if (!!Object.keys(values).length) {
      setBalancePaymentsFiltersBackup(values)
    } else {
      setBalancePaymentsFiltersBackup(null)
    }
    getBalancePayments(true, values, FormikActions)
    setCurrentBalanceFilters(values || {})
    if (!!values.TaxPaymentType) {
      getTaxpayerBalance(values.TaxPaymentType)
    }
    // if (!!values.TaxType) {
    //   getTaxpayerBalance(values.TaxType)
    // }
  }
  const handleChangeBalancePaymentsPage = (event, newPage) => {
    if (newPage !== balancePaymentsPage) {
      setBalancePaymentsPage(newPage)
      setBalancePaymentsRefreshLoading(true)
      getBalancePayments(false, null, null, newPage)
    }
  }

  const getBalancePayments = (forceReload, filters, FormikActions, page) => {
    if (!!keycloak?.authenticated) {
      setBalancePaymentsFailure(false)
      setBalancePaymentsFailure403(false)
      resetCompanyUnderageError()

      if (balancePaymentsPage === 1 && !forceReload && !page) {
        setBalancePaymentsLoading(true)
      }
      if (!!forceReload) {
        setBalancePaymentsPage(1)
        setBalancePaymentsRefreshLoading(true)
      }
      CompanyServices.getBalancePayments(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentsFiltersPayload(!!forceReload ? 1 : (page || balancePaymentsPage), User_Config.userPaymentClaimsLimit, filters || balancePaymentsFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setBalancePaymentsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setBalancePaymentsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data && !!result?.data.data.items) {
            let newData = []
            result.data.data.items.forEach((payment) => {
              payment['tax_id'] = selectedCompany.tax_id
              payment['claim_currency'] = `${payment?.currency?.name} (${payment?.currency?.key?.toUpperCase()})`
              newData.push(payment)
            })
            setBalancePayments(newData)
            setBalancePaymentsPagesCount(parseInt(result.data.data?.pagination?.total_pages_count))
            setBalancePaymentsTotalCount(parseInt(result.data.data?.pagination?.total_bills_count))
          }
        }
      }).then(() => {
        setBalancePaymentsRefreshLoading(false)
        setBalancePaymentsLoading(false)
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const [handleBalanceLoading, setHandleBalanceLoading] = React.useState(false)
  const [handleBalanceForceRefresh, setHandleBalanceForceRefresh] = React.useState(false)
  const [handleBalanceFailure, setHandleBalanceFailure] = React.useState(false)
  const [handleBalanceFailureMessage, setHandleBalanceFailureMessage] = React.useState('')
  const [handleBalanceSuccess, setHandleBalanceSuccess] = React.useState(false)
  const [handleBalanceSuccessData, setHandleBalanceSuccessData] = React.useState([])

  const resetHandlePaymentsBalanceStatues = () => {
    setHandleBalanceForceRefresh(false)
    setHandleBalanceFailure(false)
    setHandleBalanceFailureMessage('')
    setHandleBalanceSuccess(false)
    setHandleBalanceSuccessData([])
  }
  const handlePaymentsBalance = (paymentsToPay) => {
    if (!!keycloak?.authenticated && !!paymentsToPay.length) {
      resetHandlePaymentsBalanceStatues()
      setHandleBalanceLoading(true)
      let payments = []
      paymentsToPay.forEach((payment) => {
        let claim = {
          'number': payment.id,
          'partial_payment': payment.isPartial
        }
        if (!!payment.isPartial) {
          claim['amount'] = payment.partialAmount
        }
        payments.push(claim)
      })
      let date = {
        'tax_type': currentBalanceFilters?.TaxPaymentType || '',
        // 'tax_type': currentBalanceFilters?.TaxType || '',
        'claims': payments
      }
      CompanyServices.PayPaymentsBalance(cid, date, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, setHandleBalanceLoading, setHandleBalanceFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setHandleBalanceFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setHandleBalanceFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setHandleBalanceSuccessData(result.data.data)
            setHandleBalanceForceRefresh(true)
            setHandleBalanceSuccess(true)
          }
        }
      }).then(() => {
        setHandleBalanceLoading(false)
      })
    }
  }

  const getTaxpayerBalance = (taxType) => {
    if (!!keycloak?.authenticated) {
      CompanyServices.getTaxpayerBalance(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'tax_type': taxType }
      }).then((result) => {
        if (!!result && !!result.data && !!result.data.data) {
          setAvailableBalance(result.data.data?.available_balance || 0)
        } else {
          setAvailableBalance(0)
        }
      })
    }
  }

  const [notificationsLoading, setNotificationsLoading] = React.useState(false)
  const [notificationsFailure, setNotificationsFailure] = React.useState(false)
  const [notifications, setNotifications] = React.useState([])
  const getNotifications = () => {
    if (!!keycloak?.authenticated) {
      if (!notifications.length) {
        resetCompanyUnderageError()
        setNotificationsFailure(false)
        setNotificationsLoading(true)
        CompanyServices.getNotifications(cid, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            setNotificationsFailure(true)
            if (!!result.response && result.response.status === 405) {
              let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
              setCompanyFailureUnderage(true)
              if (result.response?.data?.status?.message) {
                message = result.response.data.status.message
              }
              setCompanyFailureUnderageMessage(message)
            }
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              setNotifications(result.data.data)
            }
          }
        }).then(() => {
          setNotificationsLoading(false)
        })
      }
    }
  }
  const updatePaymentRequestsAfterValidity = (requestValidityCode, rid, requestValidityNewData) => {
    let allRequests = paymentRequests
    let TargetIndex = allRequests.findIndex(request => request['request_number'] === rid)
    if (requestValidityCode === 2) {
      setPaymentRequests([])
      allRequests[TargetIndex] = requestValidityNewData
      setPaymentRequests(allRequests)
    } else if (requestValidityCode === 3) {
      setPaymentRequests([])
      allRequests[TargetIndex] = requestValidityNewData
      setPaymentRequests(allRequests)
      setShouldRefreshPaymentsForDeletedRecords(true)
    }

  }


  const [viewPaymentReceiptValidity, setViewPaymentReceiptValidity] = React.useState(false)
  const [openPaymentReceiptValidity, setOpenPaymentReceiptValidity] = React.useState(false)

  const handlePaymentReceiptValidityDialog = () => {
    if (!openPaymentReceiptValidity) {
      setViewPaymentReceiptValidity(true)
    } else {
      setTimeout(() => setViewPaymentReceiptValidity(false), 500)
    }
    setOpenPaymentReceiptValidity(!openPaymentReceiptValidity)
  }

  const [PaymentClaimItems, setPaymentClaimItems] = React.useState([])
  const [PaymentClaimOwners, setPaymentClaimOwners] = React.useState([])
  const [PaymentClaimRegTypes, setPaymentClaimRegTypes] = React.useState([])
  const [PaymentClaimItemsLoading, setPaymentClaimItemsLoading] = React.useState(false)
  const [PaymentClaimItemsFailure, setPaymentClaimItemsFailure] = React.useState(false)
  const getPaymentClaimItems = (claimTypeID, FormikActions) => {
    if (!!keycloak?.authenticated && !!claimTypeID) {
      setPaymentClaimItems([])
      setPaymentClaimOwners([])
      setPaymentClaimRegTypes([])
      setPaymentClaimItemsFailure(false)
      setPaymentClaimItemsLoading(true)
      CompanyServices.getPaymentClaimItems(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: { 'tax_type': claimTypeID }
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setPaymentClaimItemsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setPaymentClaimItems(result.data.data.items)
            FormikActions.setFieldTouched('inquiry_request_number', true, true)
            FormikActions.setFieldValue('inquiry_request_number', result.data.data.inquiry_request_number)
            if (1 === parseInt(claimTypeID) && result.data.data.owners) {
              setPaymentClaimOwners(result.data.data.owners)
            } else if (3 === parseInt(claimTypeID) && result.data.data.registration_payment_types) {
              setPaymentClaimRegTypes(result.data.data.registration_payment_types)
            }
          }
        }
      }).then(() => {
        setPaymentClaimItemsLoading(false)
      })
    } else {
      setPaymentClaimItems([])
    }
  }

  const [paymentClaimReturnedRequest, setPaymentClaimReturnedRequest] = React.useState({})
  const [newPaymentClaimFailure, setNewPaymentClaimFailure] = React.useState(false)
  const [newPaymentClaimFailureMessage, setNewPaymentClaimFailureMessage] = React.useState('')
  const [newPaymentClaimSuccess, setNewPaymentClaimSuccess] = React.useState(false)

  const handleNewPaymentClaimSubmit = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      setNewPaymentClaimFailure(false)
      setNewPaymentClaimSuccess(false)
      FormikActions.setSubmitting(true)
      CompanyServices.createPaymentClaim(cid, filterData(PaymentClaimPayload(values)), { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setNewPaymentClaimFailure, FormikActions)
        .then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            setNewPaymentClaimFailure(true)
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
              if (result.response.data.status.errors) {
                message = result.response.data.status.errors
              } else if (result.response.data.status.message) {
                message = result.response.data.status.message
              }
            }
            setNewPaymentClaimFailureMessage(message)
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              let newData = result.data.data.payment_request
              newData['id'] = result.data.data.id
              newData['taxpayerName'] = <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: result.data.data.payment_request.company.id })}>{result.data.data.payment_request.company.taxpayer_name}</NavLink>
              newData['companyName'] = result.data.data.payment_request.company.name ? <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: result.data.data.payment_request.company.id })}>{result.data.data.payment_request.company.name}</NavLink> : 'N/A'
              newData['tax_id'] = result.data.data.payment_request.company.tax_id

              setPaymentClaimReturnedRequest(newData)
              setNewPaymentClaimSuccess(true)
            }
          }
        }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const resetAllNewPaymentClaimStates = () => {
    setPaymentClaimItems([])
    setPaymentClaimOwners([])
    setPaymentClaimRegTypes([])
    setPaymentClaimItemsLoading(false)
    setPaymentClaimItemsFailure(false)
    setNewPaymentClaimFailure(false)
    setNewPaymentClaimFailureMessage('')
    setNewPaymentClaimSuccess(false)
    setPaymentClaimReturnedRequest({})
  }


  const [paymentForPayOthers, setPaymentForPayOthers] = React.useState({})
  const [paymentForPayOthersLoading, setPaymentForPayOthersLoading] = React.useState(false)
  const [paymentForPayOthersFailure, setPaymentForPayOthersFailure] = React.useState(false)
  const [paymentForPayOthersFailureMessage, setPaymentForPayOthersFailureMessage] = React.useState('')

  const checkPaymentForPayOthers = (claimID) => {
    if (!!keycloak?.authenticated) {
      setPaymentForPayOthers({})
      setPaymentForPayOthersLoading(true)
      setPaymentForPayOthersFailure(false)
      setPaymentForPayOthersFailureMessage('')
      CompanyServices.getPaymentForPayOthers(cid, claimID, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        console.log('result.response', result.response)
        if ((!!result.response && result.response.status !== 200) || !result) {
          setPaymentForPayOthersFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setPaymentForPayOthersFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = result.data.data
            newData['taxpayerName'] = <NavLink
              to={formatRoute(NamedRoutes.workspace.company.view, { id: newData.company.id })}>{newData.company.taxpayer_name}</NavLink>
            newData['companyNameString'] = newData.company.name || 'N/A'
            newData['companyName'] = newData.company.name ? <NavLink
              to={formatRoute(NamedRoutes.workspace.company.view, { id: newData.company.id })}>{newData.company.name}</NavLink> : 'N/A'
            newData['tax_id'] = newData.company.tax_id
            newData['claim_currency'] = `${newData?.currency?.name} (${newData?.currency?.key?.toUpperCase()})`
            setPaymentForPayOthers(newData)
          } else {
            setPaymentForPayOthersFailure(true)
          }
        }
      }).then(() => {
        setPaymentForPayOthersLoading(false)
      })
    }
  }


  const [delegatesLoading, setDelegatesLoading] = React.useState(false)
  const [delegatesRefreshLoading, setDelegatesRefreshLoading] = React.useState(false)
  const [delegatesFailure, setDelegatesFailure] = React.useState(false)
  const [delegatesFailure403, setDelegatesFailure403] = React.useState(false)
  const [delegates, setDelegates] = React.useState([])
  const [hasMoreDelegates, setHasMoreDelegates] = React.useState(false)
  const [delegatesPage, setDelegatesPage] = React.useState(1)
  const [delegatesFiltersBackup, setDelegatesFiltersBackup] = React.useState(null)


  const handleSubmitDelegatesFilter = (values, FormikActions) => {
    setDelegates([])
    setHasMoreDelegates(false)
    getDelegates(true, values, FormikActions)
  }

  const getDelegates = (forceReload, filters, FormikActions) => {
    console.log(forceReload, filters, FormikActions)
    if (!!keycloak?.authenticated) {
      setDelegatesFailure(false)
      setDelegatesFailure403(false)
      if (delegatesPage === 1 && !forceReload) {
        setDelegatesLoading(true)
      }
      if (!!forceReload) {
        setDelegatesPage(1)
        setDelegatesRefreshLoading(true)
      }
      CompanyServices.getDelegates(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentClaimRequestsFiltersPayload(!!forceReload ? 1 : delegatesPage, User_Config.userDelegatesLimit, filters || delegatesFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setDelegatesFailure403(true)
          }
          setDelegatesFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((delegate) => {
              // let rolesIds = (!!delegate.coretaxtion_delegate_roles && !!delegate.coretaxtion_delegate_roles.length) ?
              //   delegate.coretaxtion_delegate_roles.map(role => role.id) : []
              newData.push({
                id: delegate.id,
                username: delegate.user.username,
                name: [delegate.user.first_name, delegate.user.last_name].join(' '),
                email: delegate.user.email,
                phone: delegate.user.phone,
                role: delegate.role,
                coretaxtion_delegate_roles: delegate.coretaxtion_delegate_roles,
                // delegate_roles_Ids: rolesIds,
                additional_info: delegate.additional_info,
                is_accountant: !!delegate.additional_info && !!Object.keys(delegate.additional_info).length ? true : false,
                nid: !!delegate.additional_info && !!delegate.additional_info.nid ? delegate.additional_info.nid : '',
                accountant_ref_number: !!delegate.additional_info && !!delegate.additional_info.accountant_ref_number ? delegate.additional_info.accountant_ref_number : ''
              })
            })
            setDelegates((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (delegatesPage < result.data.meta.last_page) {
              setDelegatesPage(paymentClaimsPage + 1)
              if (!!filters && !!Object.keys(filters).length) {
                setDelegatesFiltersBackup(filters)
              }
              setHasMoreDelegates(true)
            } else {
              setDelegatesPage(1)
              setDelegatesFiltersBackup(null)
              setHasMoreDelegates(false)
            }
          }
        }
      }).then(() => {
        if (!!forceReload) {
          setDelegatesRefreshLoading(false)
        } else {
          setDelegatesLoading(false)
        }
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const [ecommerceLoading, setEcommerceLoading] = React.useState(false)
  const [ecommerceRefreshLoading, setEcommerceRefreshLoading] = React.useState(false)
  const [ecommerceFailure, setEcommerceFailure] = React.useState(false)
  const [ecommerceFailure403, setEcommerceFailure403] = React.useState(false)
  const [ecommerceClients, setEcommerceClients] = React.useState([])
  const [hasMoreEcommerce, setHasMoreEcommerce] = React.useState(false)
  const [ecommercePage, setEcommercePage] = React.useState(1)
  const [ecommerceFiltersBackup, setEcommerceFiltersBackup] = React.useState(null)

  const handleSubmitEcommerceFilter = (values, FormikActions) => {
    setEcommerceClients([])
    setHasMoreEcommerce(false)
    getEcommerceClient(true, values, FormikActions)
  }


  const getEcommerceClient = (forceReload, filters, FormikActions) => {
    // console.log(forceReload, filters, FormikActions)
    if (!!keycloak?.authenticated) {
      setEcommerceFailure(false)
      setEcommerceFailure403(false)
      resetCompanyUnderageError()
      if (ecommercePage === 1 && !forceReload) {
        setEcommerceLoading(true)
      }
      if (!!forceReload) {
        setEcommercePage(1)
        setEcommerceRefreshLoading(true)
      }
      CompanyServices.getEcommerce(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(EcommerceFiltersPayload(!!forceReload ? 1 : ecommercePage, User_Config.userEcommerceLimit, filters || ecommerceFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setEcommerceFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setEcommerceFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((platform) => {
              // let rolesIds = (!!delegate.coretaxtion_delegate_roles && !!delegate.coretaxtion_delegate_roles.length) ?
              //   delegate.coretaxtion_delegate_roles.map(role => role.id) : []
              newData.push({
                id: platform.id,
                name_en: platform.name_en,
                name_ar: platform.name_ar,
                services: platform.services.length === 0 ? null : platform.services.map((service) => service.name).join(' - '),
                client_id: platform.client_id,
                url: platform.url,
                servicesRaw: platform.services.length === 0 ? [] : platform.services
              })
            })
            setEcommerceClients((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (ecommercePage < result.data.meta.last_page) {
              setEcommercePage(ecommercePage + 1)
              if (!!filters && !!Object.keys(filters).length) {
                setEcommerceFiltersBackup(filters)
              }
              setHasMoreEcommerce(true)
            } else {
              setEcommercePage(1)
              setEcommerceFiltersBackup(null)
              setHasMoreEcommerce(false)
            }
          }
        }
      }).then(() => {
        if (!!forceReload) {
          setEcommerceRefreshLoading(false)
        } else {
          setEcommerceLoading(false)
        }
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  return (
    <CompanyContext.Provider value={{
      paymentsLoading: paymentsLoading,
      paymentsRefreshLoading: paymentsRefreshLoading,
      paymentsFailure: paymentsFailure,
      paymentsFailure403: paymentsFailure403,
      payments: payments,
      PaymentHeaderColumns: PaymentHeaderColumns,
      paymentsPagesCount: paymentsPagesCount,
      paymentsTotalCount: paymentsTotalCount,
      paymentsPage: paymentsPage,
      handleChangePaymentsPage,
      getPayments,

      handleSubmitPaymentsFilter,

      paymentClaimsLoading: paymentClaimsLoading,
      paymentClaimsRefreshLoading: paymentClaimsRefreshLoading,
      paymentClaimsFailure: paymentClaimsFailure,
      paymentClaimsFailure403: paymentClaimsFailure403,
      paymentClaims: paymentClaims,
      hasMorePaymentClaims: hasMorePaymentClaims,
      PaymentClaimHeaderColumns: PaymentClaimHeaderColumns,
      getPaymentClaims,
      handleSubmitClaimsFilter,

      paymentRequestsLoading: paymentRequestsLoading,
      paymentRequestsFailure: paymentRequestsFailure,
      paymentRequestsFailure403: paymentRequestsFailure403,
      paymentRequests: paymentRequests,
      hasMorePaymentRequests: hasMorePaymentRequests,
      PaymentOrderHeaderColumns: PaymentOrderHeaderColumns,
      PaymentOrderWithoutSourceHeaderColumns: PaymentOrderWithoutSourceHeaderColumns,
      getPaymentRequests,
      handleSubmitRequestsFilter,

      PaymentClaimOrderHeaderColumns: PaymentClaimOrderHeaderColumns,
      PaymentClaimOrderWithoutSourceHeaderColumns: PaymentClaimOrderWithoutSourceHeaderColumns,
      getPaymentClaimRequests,


      notificationsLoading: notificationsLoading,
      notificationsFailure: notificationsFailure,
      notifications: notifications,
      getNotifications,

      removePaymentRequest,
      removeCurrentRequestsClaimId,
      refreshPaymentsForDeletedRecords,

      updatePaymentRequestsAfterValidity,

      breadCrumbLinks: breadCrumbLinks,
      setBreadCrumbLinks: setBreadCrumbLinks,
      breadCrumbCurrent: breadCrumbCurrent,
      setBreadCrumbCurrent: setBreadCrumbCurrent,
      breadCrumbButton: breadCrumbButton,
      setBreadCrumbButton: setBreadCrumbButton,


      paymentReceiptsLoading: paymentReceiptsLoading,
      paymentReceiptsFailure: paymentReceiptsFailure,
      paymentReceiptsFailure403: paymentReceiptsFailure403,
      paymentReceipts: paymentReceipts,
      hasMorePaymentReceipts: hasMorePaymentReceipts,
      PaymentReceiptHeaderColumns: PaymentReceiptHeaderColumns,
      getPaymentReceipts,
      handleSubmitReceiptsFilter,

      requestIdToOpen: requestIdToOpen,
      checkReceiptToOpen,

      openPaymentReceiptValidity: openPaymentReceiptValidity,
      viewPaymentReceiptValidity: viewPaymentReceiptValidity,
      handlePaymentReceiptValidityDialog,
      receiptValidityCode: receiptValidityCode,

      PaymentClaimItems: PaymentClaimItems,
      PaymentClaimOwners: PaymentClaimOwners,
      PaymentClaimRegTypes: PaymentClaimRegTypes,
      PaymentClaimItemsLoading: PaymentClaimItemsLoading,
      PaymentClaimItemsFailure: PaymentClaimItemsFailure,
      getPaymentClaimItems,

      paymentClaimReturnedRequest: paymentClaimReturnedRequest,
      newPaymentClaimFailure: newPaymentClaimFailure,
      newPaymentClaimFailureMessage: newPaymentClaimFailureMessage,
      newPaymentClaimSuccess: newPaymentClaimSuccess,
      handleNewPaymentClaimSubmit,
      resetAllNewPaymentClaimStates,

      paymentForPayOthers: paymentForPayOthers,
      PaymentForPayOthersColumns: PaymentForPayOthersColumns,
      paymentForPayOthersLoading: paymentForPayOthersLoading,
      paymentForPayOthersFailure: paymentForPayOthersFailure,
      paymentForPayOthersFailureMessage: paymentForPayOthersFailureMessage,
      checkPaymentForPayOthers,

      delegatesLoading: delegatesLoading,
      delegatesRefreshLoading: delegatesRefreshLoading,
      delegatesFailure: delegatesFailure,
      delegatesFailure403: delegatesFailure403,
      delegates: delegates,
      hasMoreDelegates: hasMoreDelegates,
      DelegatesHeaderColumns: DelegatesHeaderColumns,
      getDelegates,
      handleSubmitDelegatesFilter,

      EcommerceHeaderColumns: EcommerceHeaderColumns,
      ecommerceLoading: ecommerceLoading,
      ecommerceRefreshLoading: ecommerceRefreshLoading,
      ecommerceFailure: ecommerceFailure,
      ecommerceFailure403: ecommerceFailure403,
      ecommerceClients: ecommerceClients,
      hasMoreEcommerce: hasMoreEcommerce,
      getEcommerceClient,
      handleSubmitEcommerceFilter,

      balancePaymentsLoading,
      balancePaymentsFailure,
      handleBalanceFailureMessage,
      balancePaymentsFailure403,
      balancePayments,
      BalancePaymentHeaderColumns,
      balancePaymentsRefreshLoading,
      availableBalance,
      balancePaymentsPage,
      balancePaymentsPagesCount,
      balancePaymentsTotalCount,
      handleChangeBalancePaymentsPage,
      handleSubmitBalancePaymentsFilter,
      getBalancePayments,
      handlePaymentsBalance,

      currentBalanceFilters: currentBalanceFilters,
      resetHandlePaymentsBalanceStatues,
      handleBalanceLoading: handleBalanceLoading,
      handleBalanceForceRefresh: handleBalanceForceRefresh,
      handleBalanceFailure: handleBalanceFailure,
      handleBalanceSuccess: handleBalanceSuccess,
      handleBalanceSuccessData: handleBalanceSuccessData,

      isCompanyUnderage,
      companyFailureUnderage,
      companyFailureUnderageMessage
    }}>
      {children}
    </CompanyContext.Provider>
  )
}
const CompanyConsumer = CompanyContext.Consumer

export { CompanyProvider, CompanyConsumer }
