import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { insuranceService } from '../../../utils/pages-meta.json'
import InsuranceServicesContent from '../../../components/Workspace/InsuranceSevices'
import { useIntl } from 'react-intl'

const InsuranceServices = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'workspaceMenu.link.item2' })}
           pathname={window.location.origin}
           titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
           image={insuranceService.image}
           description={intl.formatMessage({ id: 'workspaceMenu.link.item2' })}
      />
      <InsuranceServicesContent/>
    </>
  )
}

export default InsuranceServices
