import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import {
  Dialog,
  DialogContent, IconButton,
  Slide
} from '@material-ui/core'
import { useStyles } from '../Styles'
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentOrderCreateError = ({ open, view, handleClose, requestValidityCode }) => {
  const classes = useStyles()

  return (
    !!view &&
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={open}
      classes={{ paper: classes.ErrorDialogPaper }}
      TransitionComponent={Transition}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogContent className={classes.ErrorDialogContent}>
        <IconButton
          edge={false}
          className={classes.ErrorDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleClose}
        >
          <CloseIcon/>
        </IconButton>
        {!!requestValidityCode ?
          {
            2: <Alert severity="success"><FormattedMessage id='PaymentOrderValidity.PaidAlert'/></Alert>,
            3: <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.FailedAlert'/></Alert>,
            4: <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.ErrorAlert'/></Alert>,
            5: <Alert severity="error"><FormattedMessage id='PaymentOrderValidity.DuplicateRequestAlert'/></Alert>
          }[requestValidityCode]
          :
          <Alert severity="error"><FormattedMessage id='PaymentOrderCreateError.FormErrorAlert'/></Alert>
        }
      </DialogContent>
    </Dialog>
  )
}

export default PaymentOrderCreateError
