import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Accordion, AccordionDetails, AccordionSummary, FormControl, TextField } from '@material-ui/core'
import { useStyles } from '../../Styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { lg } from '../../../../utils/ResponsiveUtility'

const InsuranceOrganizationBranchesFilter = ({ submitForm }) => {
  const classes = useStyles()
  const filterForm = useFormik({
    initialValues: {
      organization_name: '',
      organization_number: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])
  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.organization_name"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='InsuranceOrganizationBranchesHeader.OrganizationName'/>}
              variant="outlined"
              name='organization_name'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.organization_name}
              helperText={filterForm.errors.organization_name && filterForm.touched.organization_name && filterForm.errors.organization_name}
              error={filterForm.errors.organization_name && filterForm.touched.organization_name !== undefined}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.organization_number"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='InsuranceOrganizationBranchesHeader.OrganizationNumber'/>}
              variant="outlined"
              name='organization_number'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.organization_number}
              helperText={filterForm.errors.organization_number && filterForm.touched.organization_number && filterForm.errors.organization_number}
              error={filterForm.errors.organization_number && filterForm.touched.organization_number !== undefined}
            />
          </FormControl>
          <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                  className={classes.submitButton}>
            <FormattedMessage id='Filter.button.submit'/>
          </button>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export default InsuranceOrganizationBranchesFilter
