import React from 'react'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormattedMessage } from 'react-intl'
import { FormLoader } from '../../../../../Loader/FormLoader'
import { useStyles } from '../Styles'
import TermsConditions from '../TermsConditions'
import Alert from '@material-ui/lab/Alert'

const PaymentClaimOrderStep2 = ({ getRequestExpirationTime }) => {
  const classes = useStyles()
  React.useEffect(() => {
      if (!!getRequestExpirationTime) {
        getRequestExpirationTime()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <PaymentOrderConsumer>
      {({ getTermsConditionsContent, requestExpirationTimeLoading, requestExpirationTimeFailure, requestExpirationTime }) => (
        <>
          <div className={classes.PaymentOrderStepMessage}>
            <div className={classes.termsConditionsWrapper}>
              {!!requestExpirationTimeLoading && <FormLoader loading={true}/>}
              {!!requestExpirationTimeFailure &&
              <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>}
              <div className='MuiFormControlLabel-label not-label'>
                <FormattedMessage id='PaymentOrderStep3.Message'
                                  values={{ paymentOrderValidTime: (!!Object.keys(requestExpirationTime).length && !!requestExpirationTime.value ? requestExpirationTime.value : '') }}/>
              </div>

              <div className='MuiFormControlLabel-label not-label'>
                <FormattedMessage id='PaymentClaimOrder.steps.termsConditions.title.firstPart'/>
                <TermsConditions getTermsConditionsContent={getTermsConditionsContent}/>
              </div>
            </div>
          </div>
        </>
      )}
    </PaymentOrderConsumer>
  )
}

export default PaymentClaimOrderStep2
