import React from 'react'
import { useIntl } from 'react-intl'
import {AuthenticatedUserConsumer} from '../../../../AuthenticatedUserContext'
import InActiveCompanyContent from '../../../InActiveCompanyContent'
import CompanyPaymentsBalance from './CompanyPaymentsBalance'
import {CompanyConsumer} from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import {payments} from '../../../../utils/pages-meta'
import {SEO} from '../../../Seo/seo'

const CompaniesPaymentsBalanceContent = ({checkCompanyData, cid, getFiltersData}) => {
  const intl = useIntl()
  React.useEffect(() => {
      if (!!getFiltersData) {
        getFiltersData()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({selectedCompany, paymentsFiltersData, paymentsFiltersFailure}) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={intl.formatMessage({id: 'PageTitle.PaymentsBalance'}) + ' - ' + selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({id: 'E-Tax-Workspace'})}
                   image={payments.image}
                   description={intl.formatMessage({id: 'PageTitle.PaymentsBalance'}) + ' - ' + selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                <CompanyConsumer>
                  {({handleSubmitBalancePaymentsFilter, setBreadCrumbLinks, setBreadCrumbCurrent, availableBalance}) => (
                    <CompanyPaymentsBalance selectedCompany={selectedCompany}
                                            handleSubmitBalancePaymentsFilter={handleSubmitBalancePaymentsFilter}
                                            paymentsFiltersData={paymentsFiltersData}
                                            paymentsFiltersFailure={paymentsFiltersFailure}
                                            setBreadCrumbLinks={setBreadCrumbLinks}
                                            setBreadCrumbCurrent={setBreadCrumbCurrent}
                                            availableBalance={availableBalance}
                    />
                  )}
                </CompanyConsumer>
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default CompaniesPaymentsBalanceContent
