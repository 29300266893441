import React from 'react'
import {SEO} from '../../../components/Seo/seo'
import {dashboard} from '../../../utils/pages-meta.json'
import DashboardContent from '../../../components/Workspace/Dashboard'
import { useIntl } from 'react-intl'

const Dashboard = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'workspaceMenu.link.item1' })}
           pathname={window.location.origin}
           titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
           image={dashboard.image}
           description={intl.formatMessage({ id: 'workspaceMenu.link.item1' })}
      />
      <DashboardContent/>
    </>
  )
}

export default Dashboard
