/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Container, Grid } from '@material-ui/core'
import { useStyles } from './Styles'
import Logo from '../Logo'
import LanguageSwitcher from '../LanguageSwitcher'
// import SearchBox from './SearchBox'
import HeaderAuth from './Auth'
import { lg, md } from '../../utils/ResponsiveUtility'
import MobileMenu from './MobileMenu'
import MainNavigation from './MainNavigation'
import SocialIcons from './SocialIcons'
import { NamedRoutes } from '../../routes'
import { Sticky } from 'react-sticky'
import { useKeycloak } from '@react-keycloak/web'
import Configuration from '../../Services/Api/Configuration'
import { AuthenticatedUserConsumer } from '../../AuthenticatedUserContext'
import UPTCPUserMessage from './UPTCPUserMessage'
import { FullBodyLoader } from '../Loader/FullBodyLoader'
import { LanguageConsumer } from '../../LanguageContext'
import TaxServicesErrorDialog from './TaxServicesErrorDialog'

const Header = () => {
  const location = useLocation()
  const classes = useStyles()
  const { keycloak, initialized } = useKeycloak()
  let [width, setWidth] = React.useState(document.body.clientWidth)
  const [isWorkspace, setIsWorkspace] = React.useState(false)
  let Mobile = (width < md)
  let DesktopAndUp = (width >= md)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  React.useEffect(() => {
    if (!!location) {
      (location.pathname.split('/')[1] === 'workspace') ? setIsWorkspace(true) : setIsWorkspace(false)
    }
  }, [location])
  return (
    <>
      <Sticky topOffset={!keycloak?.authenticated ? 39 : 0}>
        {({ style }) => (
          <header className={classes.header} id='header' style={style}>
            <Container maxWidth="lg">
              <Grid container justify='space-between'>
                <Grid item xs={12} md={5} lg={7} className={classes.headerIdentity}>
                  <Logo/>
                  {(width >= lg) && !!isWorkspace && <h1><FormattedMessage id='Header.headerQuickActions.Button'/></h1>}
                  {Mobile && <MobileMenu isWorkspace={isWorkspace}/>}
                </Grid>
                {DesktopAndUp && <Grid item xs={12} md={7} lg={5}>
                  <div className={classes.headerQuickActions}>
                    {!Configuration.HIDE_LANGUAGE_SWITCHER && <LanguageSwitcher/>}
                    <a href='https://www.shakwa.eg/GCP/Default.aspx' target={'_blank'} rel="noopener noreferrer"
                       className={classes.headerQuickActionsLink}>
                      <FormattedMessage id='Header.headerQuickActions.Link.inquires'/>
                    </a>
                    <a href='#' className={classes.headerQuickActionsLink}>
                      <FormattedMessage id='Header.headerQuickActions.Link.call'/>
                    </a>
                    {!isWorkspace && <SocialIcons/>}

                    {!!isWorkspace ?
                      <a href={Configuration.CMS_Portal_URL}
                         className={classes.headerQuickActionsButton + ((!initialized || !keycloak?.authenticated) ? ' hidden' : '')}>
                        <FormattedMessage id='Header.headerQuickActions.Button.home'/>
                      </a>
                      :
                      <NavLink to={NamedRoutes.workspace.dashboard}
                               className={classes.headerQuickActionsButton + ((!initialized || !keycloak?.authenticated) ? ' hidden' : '')}>
                        <FormattedMessage id='Header.headerQuickActions.Button'/>
                      </NavLink>
                    }
                  </div>
                  <div className={classes.headerActions}>
                    {/*<SearchBox/>*/}
                    <HeaderAuth/>
                  </div>
                </Grid>}
              </Grid>
            </Container>
            {DesktopAndUp && <MainNavigation isWorkspace={isWorkspace}/>}
          </header>
        )}
      </Sticky>
      <AuthenticatedUserConsumer>
        {({
            selectedCompany, checkCompanyUPTCPRegistration, companyUPTCPRegistrationLoading, companyUPTCPRegistrationFailureMessage,
            companyUPTCPRegistrationFailure, companyUPTCPRegistration, resetCompanyUPTCPRegistrationStatus, incentivesTaxIdValidityLoading,
            incentivesTaxIdValidityFailure, incentivesTaxIdValidityFailureMessage, resetIncentivesFormStatus, companyUPTCPRegistrationDialog,
            closeCompanyUPTCPRegistrationDialog
          }) => (
          <>
            {(!!companyUPTCPRegistrationLoading || !!incentivesTaxIdValidityLoading) &&
            <FullBodyLoader loading={true}/>}
            {!selectedCompany?.tax_services_eligibility && <TaxServicesErrorDialog/>}
            <LanguageConsumer>
              {({ locale }) => (
                <UPTCPUserMessage selectedCompany={selectedCompany}
                                  checkCompanyUPTCPRegistration={checkCompanyUPTCPRegistration}
                                  companyUPTCPRegistrationFailure={companyUPTCPRegistrationFailure}
                                  companyUPTCPRegistrationFailureMessage={companyUPTCPRegistrationFailureMessage}
                                  companyUPTCPRegistration={companyUPTCPRegistration}
                                  resetCompanyUPTCPRegistrationStatus={resetCompanyUPTCPRegistrationStatus}

                                  incentivesTaxIdValidityFailure={incentivesTaxIdValidityFailure}
                                  incentivesTaxIdValidityFailureMessage={incentivesTaxIdValidityFailureMessage}
                                  resetIncentivesFormStatus={resetIncentivesFormStatus}
                                  locale={locale}

                                  companyUPTCPRegistrationDialog={companyUPTCPRegistrationDialog}
                                  closeCompanyUPTCPRegistrationDialog={closeCompanyUPTCPRegistrationDialog}/>
              )}
            </LanguageConsumer>
          </>
        )}
      </AuthenticatedUserConsumer>
    </>
  )
}

export default Header
