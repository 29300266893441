/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Grid } from '@material-ui/core'
import { useStyles } from './Styles'
import { useLocation } from 'react-router-dom'
import { portalMenu, workspaceCompanyMenu, workspaceMenu } from '../Header/MainNavigation/Menus'
import { AuthenticatedUserConsumer } from '../../AuthenticatedUserContext'
import Item from './Item'
import { LanguageConsumer } from '../../LanguageContext'

const Menu = () => {
  const classes = useStyles()
  const location = useLocation()
  const [isWorkspace, setIsWorkspace] = React.useState(false)

  React.useEffect(() => {
    if (!!location) {
      (location.pathname.split('/')[1] === 'workspace') ? setIsWorkspace(true) : setIsWorkspace(false)
    }
  }, [location])

  return (
    <nav className={classes.footerMenu}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} lg={2} className={classes.footerIdentity}>
            <div className={classes.footerIdentityTitle}>
              {!!isWorkspace ? <FormattedMessage id='footer.WorkspaceMenu.identity.title'/> :
                <FormattedMessage id='footer.portalMenu.identity.title'/>}
            </div>
            <div><FormattedMessage id='footer.Menu.identity.Slogan'/></div>
          </Grid>
          <Grid item xs={12} lg={10}>
            <AuthenticatedUserConsumer>
              {({ selectedCompany, checkIncentivesTaxIdValidity, checkTaxServiceLink }) => (
                <LanguageConsumer>
                  {({ locale }) => (
                    <ul>
                      {(!!isWorkspace ? workspaceMenu : portalMenu).map((item, index) => (
                        <Item key={item.key} index={index} item={item} selectedCompany={selectedCompany}/>
                      ))}

                      {(!!isWorkspace && !!selectedCompany && !!Object.keys(selectedCompany).length) &&
                      <>
                        {workspaceCompanyMenu(locale).map((item, index) => (
                          <Item key={item.key} index={index} item={item} selectedCompany={selectedCompany}
                                locale={locale} checkTaxServiceLink={checkTaxServiceLink}
                                checkIncentivesTaxIdValidity={checkIncentivesTaxIdValidity}/>
                        ))}
                      </>
                      }
                    </ul>
                  )}
                </LanguageConsumer>
              )}
            </AuthenticatedUserConsumer>
          </Grid>
        </Grid>
      </Container>
    </nav>
  )
}

export default Menu
