import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { NamedRoutes } from '../../../../routes'
import { formatRoute } from 'react-router-named-routes'
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { CompanyConsumer } from '../../../../CompanyContext'
import { SearchLoader } from '../../../Loader/SearchLoader'
import { FormLoader } from '../../../Loader/FormLoader'
import { isEmpty } from '../../../../utils/helpers'
import WorkspaceTableWithPager from '../../TableWithPager'
import EmptyResult from '../../../EmptyResult'
import { useStyles } from './Styles'
import PaymentFilter from './PaymentFilter'
import UnAuthorize from '../../../UnAuthorize'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { sm } from '../../../../utils/ResponsiveUtility'
import Configuration from '../../../../Services/Api/Configuration'

const CompanyPayments = ({ selectedCompany, getPayments, getFiltersData, paymentsFiltersData, paymentsFiltersFailure, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  const classes = useStyles()
  let history = useHistory()
  const btnOptions = [
    {
      text: <FormattedMessage id='PaymentsContent.ActionLinks.PaymentRequests'/>,
      action: () => history.push({
        pathname: formatRoute(NamedRoutes.workspace.company.paymentRequests, { id: selectedCompany.id })
      })
    },
    {
      text: <FormattedMessage id='PaymentsContent.ActionLinks.PaymentReceipts'/>,
      action: () => history.push({
        pathname: formatRoute(NamedRoutes.workspace.company.paymentReceipts, { id: selectedCompany.id })
      })
    }
  ]
  if (!Configuration?.HIDE_PAYMENT_CLAIMS_MENUITEM) {
    btnOptions.push(
      {
        text: <FormattedMessage id='workspaceMenu.link.item65'/>,
        action: () => history.push({
          pathname: formatRoute(NamedRoutes.workspace.company.paymentClaims, { id: selectedCompany.id })
        })
      }
    )
  }

  React.useEffect(() => {
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='workspaceMenu.link.item64'/>)
      }
      if (!!getPayments) {
        getPayments()
      }
      if (!!getFiltersData) {
        getFiltersData()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <>
      {!!selectedCompany && !isEmpty(selectedCompany) &&
      <Container disableGutters maxWidth="lg" className={classes.paymentsActionLinksContainer}>

        {!!Mobile ?
          <>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
              <Button onClick={btnOptions[0].action}>{btnOptions[0].text}</Button>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon/>
              </Button>
            </ButtonGroup>
            <Popper open={open} className={classes.btnPopper} anchorEl={anchorRef.current} role={undefined} transition
                    disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {btnOptions.map((btnObj, index) => (
                          <MenuItem
                            key={index}
                            selected={index === 0}
                            onClick={btnOptions[index].action}
                          >
                            {btnObj.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </> :
          <>
            <ButtonGroup size="large" variant={'contained'} color="primary"
                         aria-label="large outlined primary button group">
              <Button onClick={btnOptions[0].action}>
                {btnOptions[0].text}
              </Button>
              <Button onClick={btnOptions[1].action}>
                {btnOptions[1].text}
              </Button>
            </ButtonGroup>
            {!!btnOptions[2] &&
            <ButtonGroup size="large" variant={'contained'} color="primary"
                         aria-label="large outlined primary button group">
              <Button onClick={btnOptions[2].action}>
                {btnOptions[2].text}
              </Button>
            </ButtonGroup>}
          </>
        }

      </Container>
      }

      <CompanyConsumer>
        {({
            paymentsLoading, paymentsFailure, paymentsFailure403, payments, PaymentHeaderColumns, paymentsRefreshLoading, handleSubmitPaymentsFilter,
            isCompanyUnderage, companyFailureUnderage, companyFailureUnderageMessage, paymentsPagesCount, paymentsPage, handleChangePaymentsPage, paymentsTotalCount
          }) => (
          <Box p={2} className={'back-white'}>

            {!!paymentsFailure403 ? <UnAuthorize/> :
              (!!isCompanyUnderage && !!companyFailureUnderage ?
                <Alert severity="error">
                  {companyFailureUnderageMessage}
                  <FormattedMessage id='Underage.ErrorAlert' values={{
                    a: chunks => <NavLink
                      to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
                  }}/></Alert>
                : ((!!paymentsFailure || !!paymentsFiltersFailure) &&
                  <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                ))
            }

            {!paymentsFailure403 && !companyFailureUnderage && !(!!isCompanyUnderage && !!paymentsLoading) &&
            <PaymentFilter paymentsFiltersData={paymentsFiltersData}
                           handleSubmitPaymentsFilter={handleSubmitPaymentsFilter}/>}

            {!!paymentsLoading && <SearchLoader loading={true}/>}
            {(!!payments && !!payments.length) ?
              <WorkspaceTableWithPager rows={payments} columns={PaymentHeaderColumns} paymentActions={true}
                                       size={'small'} pagesCount={paymentsPagesCount}
                                       handleChangePage={handleChangePaymentsPage}
                                       page={paymentsPage} loading={paymentsRefreshLoading}
                                       totalCount={paymentsTotalCount || 0}/>
              :
              <>
                {!!paymentsRefreshLoading && <div className={classes.refreshWrapper}><FormLoader loading={true}/></div>}
                {!paymentsLoading && !paymentsRefreshLoading && !paymentsFailure403 && !companyFailureUnderage &&
                <EmptyResult isPayments={true}/>}
              </>
            }
          </Box>
        )}
      </CompanyConsumer>

    </>
  )
}

export default CompanyPayments
