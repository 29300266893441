import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative',
    '&.disabled-form': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        backgroundColor: fade(theme.palette.common.white, 0.3)
      }
    }
  },
  EmailWrapper: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto'
  },
  ResendEmail: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    '& svg': {
      fontSize: theme.typography.pxToRem(28)
    },
    '& + .MuiTooltip-popper': {
      marginTop: theme.spacing(-1)
    }
  },
  VerifiedEmail: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.success.main,
    '& + .MuiTooltip-popper': {
      marginTop: theme.spacing(-1)
    },
    '&.notVerified': {
      color: theme.palette.error.main
    }
  },
  usePersonalInfoWrapper: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    }
  },
  AdditionalInfoRow: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      width: 340,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 340,
      }
    },
    // '& .MuiTextField-root': {
    //   [theme.breakpoints.up('sm')]: {
    //     maxWidth: 228
    //   }
    // },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& .react-tel-input': {
      fontFamily: 'inherit'
    },
    '& .react-tel-input .form-control': {
      width: 340,
      paddingTop: theme.spacing(1.375),
      paddingBottom: theme.spacing(1.125),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '&[disabled]': {
        backgroundColor: theme.palette.secondary.main
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',
      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  AdditionalInfoPhoneRow: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      width: 340,
      maxWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  changePhoneButton: {
    color: theme.palette.primary.light,
    background: 'transparent',
    border: 'none',
    marginLeft: theme.spacing(2)
  },
  ChangePhoneVerifyWrapper: {
    whiteSpace: 'pre-line',
    textAlign: 'center'
  },
  ChangePhoneVerify: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  },
  SubmitRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4, 0, 0),
    '& $DeleteOrderBtn': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1.25),
        marginRight: theme.spacing(1.25)
      }
    }
  },
  AdditionalInfoSubmit: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 120
    }
  },
  ChangePhoneDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  ChangePhoneDialogPaper: {
    borderRadius: 0,
    overflow: 'visible'
  },
  ChangePhoneDialogContent: {
    padding: '0 !important',
    position: 'relative',
    overflow: 'visible'
  },
  ChangePhoneDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  ChangePhoneDialogFooter: {
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  },
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  }
}))
