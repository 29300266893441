import React from 'react'
// import Notification from '../Notification'
import UserMenu from '../UserMenu'

const LoggedIn = ({ mobileMenu, toggleMobileMenuDrawer, isWorkspace }) => {
  return (
    <>
      {/*{!mobileMenu && <Notification/>}*/}
      <UserMenu mobileMenu={mobileMenu} toggleMobileMenuDrawer={toggleMobileMenuDrawer} isWorkspace={isWorkspace}/>

    </>
  )
}

export default LoggedIn