/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import Alert from '@material-ui/lab/Alert'
import CompanyContactForm from '../CompanyContactForm'
import { useStyles } from '../Styles'

const NewCompaniesContacts = () => {
  const classes = useStyles()
  const [accordionOpen, setAccordionOpen] = React.useState(true)
  const handleAccordionClose = () => {
    setAccordionOpen(false)
  }

  const ToggleAccordionOpen = () => {
    setAccordionOpen(!accordionOpen)
  }
  return (
    <div className={classes.CompaniesContactsWrapper}>
      <Accordion defaultExpanded={true} expanded={accordionOpen} variant={'outlined'}
                 className={classes.CompaniesContactsAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1c-content"
          id="panel1c-header"
          onClick={ToggleAccordionOpen}
        >
          <div className={classes.SettingHeader}>
            <Typography component={'h2'} variant={'h6'} color={'primary'}>
              <FormattedMessage id='profile.NewCompaniesProfileTitle'/>
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <AuthenticatedUserConsumer>
            {({ addCompanyContactFailure, addCompanyContactFailureMessage, addCompanyContactSuccess,
                addCompanyContactSuccessTaxId, handleAddCompanyContact, resetAddCompanyContact,
                showCompaniesContactForm, configuredUnlinkedCompaniesContactsCount }) => (
              <div className={classes.CompaniesContactsWrapper}>
                {!!addCompanyContactFailure && !!addCompanyContactFailureMessage &&
                <Alert severity="error">{addCompanyContactFailureMessage}</Alert>
                }
                {!!addCompanyContactSuccess && !!addCompanyContactSuccessTaxId &&
                <Alert severity="success"><FormattedMessage id='CompanyContact.FormSuccessAlert'
                                                            values={{ taxId: addCompanyContactSuccessTaxId }}/></Alert>
                }
                {!showCompaniesContactForm &&
                <Alert severity="warning">
                  <FormattedMessage id='CompanyContact.FormDisabledAlert'
                                    values={{ configuredCount: configuredUnlinkedCompaniesContactsCount }}/></Alert>
                }
                <CompanyContactForm companyContact={{}} onSubmit={handleAddCompanyContact}
                                    resetStatus={resetAddCompanyContact} handleAccordionClose={handleAccordionClose}/>
              </div>
            )}
          </AuthenticatedUserConsumer>
        </AccordionDetails>
        <Divider/>
      </Accordion>
    </div>
  )
}

export default NewCompaniesContacts
