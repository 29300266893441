import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import {
  FormControl, Grid, Select, InputLabel, MenuItem,
  FormHelperText, AccordionSummary, AccordionDetails, Accordion
} from '@material-ui/core'
import { CompaniesBalancePaymentsFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { lg } from '../../../../utils/ResponsiveUtility'

const PaymentsBalanceFilter = ({ paymentsFiltersData, availableBalance, handleSubmitBalancePaymentsFilter }) => {
  const classes = useStyles()
  const intl = useIntl()
  const filterForm = useFormik({
    initialValues: {
      ClaimtStatus: '5',
      TaxPaymentType: ''
      // TaxType: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CompaniesBalancePaymentsFilterFormValidation,
    onSubmit: ((values, actions) => {
      handleSubmitBalancePaymentsFilter(values, actions)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(paymentsFiltersData).length && paymentsFiltersData?.payment_tax_types?.length &&
        // !filterForm.values.TaxType) {
        !filterForm.values.TaxPaymentType) {
        filterForm.setFieldValue('TaxPaymentType', paymentsFiltersData?.payment_tax_types[0]?.code?.toString() || '')
        // filterForm.setFieldValue('TaxType', paymentsFiltersData?.payment_tax_types[0]?.code?.toString() || '')
      }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentsFiltersData])

  const handleSelectFilterChange = (event, fieldName) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue(fieldName, event.target.value.toString())
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <>
      <Accordion className={classes.filterWrapper}
                 expanded={desktop || expanded}
                 onChange={handleCollapseChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-label="Expand"
          aria-controls="companies-filter"
          id="companies-filter"
        >
          <h3 className={classes.filterTitle + ' noMargin'}><FormattedMessage id='Filter.Title'/></h3>
        </AccordionSummary>
        <AccordionDetails>
          <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper + ' center '}>
            <Grid container justify={'center'} wrap={'wrap'}
                  className={classes.filtersFieldsWrapper + ' ' + classes.BalanceFilterWrapper}>
              <FormControl fullWidth margin={'dense'} variant='outlined'
                           className={classes.formControl + ' ' + classes.formSelectControl}>
                <InputLabel id="ClaimStatus-label"><FormattedMessage id='PaymentHeader.paymentStatus'/></InputLabel>
                <Select
                  fullWidth
                  labelId="ClaimStatus-label"
                  id="ClaimtStatus"
                  name="ClaimtStatus"
                  MenuProps={{ disableScrollLock: true }}
                  value={filterForm.values.ClaimtStatus}
                  label={intl.formatMessage({ id: 'PaymentHeader.paymentStatus' })}
                  onChange={(event) => handleSelectFilterChange(event, 'ClaimtStatus')}
                  onBlur={filterForm.handleBlur}
                  error={filterForm.errors.ClaimtStatus && filterForm.touched.ClaimtStatus !== undefined}
                >
                  <MenuItem value=''><FormattedMessage id='PaymentHeader.paymentStatus'/></MenuItem>
                  {!!Object.keys(paymentsFiltersData).length &&
                  paymentsFiltersData.coretaxation_payment_status.length &&
                  paymentsFiltersData.coretaxation_payment_status.map((item) => (
                    item.name !== 'مسدد' &&
                    <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                </Select>
                {filterForm.errors.ClaimtStatus && filterForm.touched.ClaimtStatus && (
                  <FormHelperText error={true}>{filterForm.errors.ClaimtStatus}</FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth margin={'dense'} variant='outlined'
                           className={classes.formControl + ' ' + classes.formSelectControl}>
                <InputLabel id="TaxPaymentType-label"><FormattedMessage id='PaymentHeader.climeType'/></InputLabel>
                <Select
                  fullWidth
                  required
                  labelId="TaxPaymentType-label"
                  id="TaxPaymentType"
                  name="TaxPaymentType"
                  MenuProps={{ disableScrollLock: true }}
                  value={filterForm.values.TaxPaymentType}
                  label={intl.formatMessage({ id: 'PaymentHeader.climeType' })}
                  onChange={(event) => handleSelectFilterChange(event, 'TaxPaymentType')}
                  onBlur={filterForm.handleBlur}
                  error={filterForm.errors.TaxPaymentType && filterForm.touched.TaxPaymentType !== undefined}
                >
                  {!!Object.keys(paymentsFiltersData).length &&
                  paymentsFiltersData.payment_tax_types.length &&
                  paymentsFiltersData.payment_tax_types.map((item) => (
                    <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                </Select>
                {filterForm.errors.TaxPaymentType && filterForm.touched.TaxPaymentType && (
                  <FormHelperText error={true}>{filterForm.errors.TaxPaymentType}</FormHelperText>
                )}
                {/*<Select*/}
                {/*  fullWidth*/}
                {/*  required*/}
                {/*  labelId="TaxPaymentType-label"*/}
                {/*  id="TaxType"*/}
                {/*  name="TaxType"*/}
                {/*  MenuProps={{ disableScrollLock: true }}*/}
                {/*  value={filterForm.values.TaxType}*/}
                {/*  label={intl.formatMessage({ id: 'PaymentHeader.climeType' })}*/}
                {/*  onChange={(event) => handleSelectFilterChange(event, 'TaxType')}*/}
                {/*  onBlur={filterForm.handleBlur}*/}
                {/*  error={filterForm.errors.TaxType && filterForm.touched.TaxType !== undefined}*/}
                {/*>*/}
                {/*  {!!Object.keys(paymentsFiltersData).length &&*/}
                {/*  paymentsFiltersData.payment_tax_types.length &&*/}
                {/*  paymentsFiltersData.payment_tax_types.map((item) => (*/}
                {/*    <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}*/}
                {/*</Select>*/}
                {/*{filterForm.errors.TaxType && filterForm.touched.TaxType && (*/}
                {/*  <FormHelperText error={true}>{filterForm.errors.TaxType}</FormHelperText>*/}
                {/*)}*/}
              </FormControl>
            </Grid>
            <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                    className={classes.submitButton + ' noMargin'}>
              <FormattedMessage id='Filter.button.submit'/>
            </button>
          </form>
          {!!desktop &&
          <div className={classes.availableBalanceWrapper}>
            <div className={classes.availableBalance}>
              <FormattedMessage id='workspaceMenu.availableBalance'
                                values={{
                                  strong: chunks => <strong>{chunks}</strong>,
                                  availableBalance: <FormattedNumber
                                    value={availableBalance}/>
                                }}/>
            </div>
          </div>
          }
        </AccordionDetails>
      </Accordion>
      {!desktop &&
      <div className={classes.availableBalanceWrapper}>
        <div className={classes.availableBalance}>
          <FormattedMessage id='workspaceMenu.availableBalance'
                            values={{
                              strong: chunks => <strong>{chunks}</strong>,
                              availableBalance: <FormattedNumber
                                value={availableBalance}/>
                            }}/>
        </div>
      </div>
      }
    </>
  )
}

export default PaymentsBalanceFilter
