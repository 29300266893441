import React from 'react'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { FormLoader } from '../../../../../Loader/FormLoader'
import { useStyles } from '../Styles'
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch, TextField
} from '@material-ui/core'
import TermsConditions from '../TermsConditions'
import Alert from '@material-ui/lab/Alert'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { useFormik } from 'formik'
import { partialAmountFormValidation } from '../../../../../../utils/validationSchema'
import { LanguageConsumer } from '../../../../../../LanguageContext'

const PaymentOrderStep2 = ({ setCanCreateRequest, getRequestExpirationTime, paymentDetails }) => {
  const classes = useStyles()
  const intl = useIntl()
  React.useEffect(() => {
      if (!!getRequestExpirationTime) {
        getRequestExpirationTime()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  const partialAmountForm = useFormik({
    initialValues: {
      amount: '',
      minimum: paymentDetails.min_amount,
      maximum: paymentDetails.max_amount,
      payPartial: false
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: partialAmountFormValidation,
    onSubmit: ((values, actions) => {
      // submitForm(values, actions)
    })
  })
  return (
    <PaymentOrderConsumer>
      {({
          currentPaymentOrderStep, paymentOrderData, paymentOrderLoading, handleAcceptValidTimeChange,
          isAcceptTerms, handleAcceptTermsChange, handleNewOrderNext, isAcceptValidTime, getTermsConditionsContent,
          requestExpirationTimeLoading, requestExpirationTimeFailure, requestExpirationTime, paymentOrderFailure,
          paymentOrderFailureMessage
        }) => (
        <>
          <Collapse in={!(!!paymentOrderData && !!Object.keys(paymentOrderData).length)} timeout="auto" unmountOnExit>
            <div className={classes.PaymentOrderStepMessage}>
              {!!paymentDetails.allow_partial_payment && !!paymentDetails.due_amount &&
              <form onSubmit={partialAmountForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper}>
                <div className={classes.AdditionalInfoRow}>
                  <InputLabel id="paymentRequest.partial.amount"><FormattedMessage
                    id='Fields.label.payPartialSwitch'/></InputLabel>
                  <div className={classes.usePersonalInfoWrapper}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={partialAmountForm.values.payPartial}
                          onChange={(event) => {
                            partialAmountForm.setFieldValue('payPartial', !!event.target.checked)
                          }}
                          name="payPartial"
                          color="primary"
                          onBlur={partialAmountForm.handleBlur}
                          error={partialAmountForm.errors.payPartial && partialAmountForm.touched.payPartial !== undefined}
                        />}
                      label={intl.formatMessage({ id: `partialAmountForm.payPartialSwitch.${!!partialAmountForm.values.payPartial}` })}
                      labelPlacement="end"
                    />
                  </div>
                </div>
                <Collapse in={!!partialAmountForm.values.payPartial} timeout="auto" unmountOnExit>
                  <div className={classes.AdditionalInfoRow + ' column'}>
                    <InputLabel id="partialAmountForm.partialAmount"><FormattedMessage id='Fields.label.partialAmount'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'partialAmountForm.placeholder.partialAmount' })}
                        id="partialAmountForm.partialAmount"
                        type={'number'}
                        margin="dense"
                        variant="outlined"
                        name='amount'
                        inputProps={{
                          min: paymentDetails.min_amount,
                          max: paymentDetails.max_amount
                        }}
                        onChange={partialAmountForm.handleChange}
                        onBlur={partialAmountForm.handleBlur}
                        value={partialAmountForm.values.amount}
                        helperText={partialAmountForm.errors.amount && partialAmountForm.touched.amount && partialAmountForm.errors.amount}
                        error={partialAmountForm.errors.amount && partialAmountForm.touched.amount !== undefined}
                      />
                    </FormControl>
                    <FormHelperText><FormattedMessage id='partialAmount.Hint' values={{
                      min: <FormattedNumber value={paymentDetails.min_amount}/>,
                      max: <FormattedNumber value={paymentDetails.max_amount}/>
                    }}/></FormHelperText>
                  </div>
                </Collapse>
              </form>
              }

              <div className={classes.termsConditionsWrapper}>
                {!!requestExpirationTimeLoading && <FormLoader loading={true}/>}
                {!!paymentOrderFailure && !!paymentOrderFailureMessage &&
                <Alert severity="error">{paymentOrderFailureMessage}</Alert>}

                {!!requestExpirationTimeFailure &&
                <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAcceptValidTime}
                      onChange={handleAcceptValidTimeChange}
                      name="termsConditions"
                      color="primary"
                    />
                  }
                  label={<>
                    <FormattedMessage id='PaymentOrderStep3.Message'
                                      values={{ paymentOrderValidTime: (!!Object.keys(requestExpirationTime).length && !!requestExpirationTime.value ? requestExpirationTime.value : '') }}/>
                  </>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAcceptTerms}
                      onChange={handleAcceptTermsChange}
                      name="termsConditions"
                      color="primary"
                    />
                  }
                  label={<>
                    <FormattedMessage id='PaymentOrder.steps.termsConditions.title.firstPart'/>
                    <TermsConditions getTermsConditionsContent={getTermsConditionsContent}/>
                  </>}
                />
              </div>
            </div>
            <div className={classes.PaymentFirstStepActionWrapper}>
              <Button size="large" variant="contained" color="primary"
                      disabled={!(!!isAcceptTerms && !!isAcceptValidTime && currentPaymentOrderStep === 0 && !!partialAmountForm.isValid)}
                      onClick={() => {handleNewOrderNext(setCanCreateRequest, partialAmountForm.values)}}>
                <FormattedMessage id='PaymentOrder.ActionLinks.Confirm'/>
              </Button>
            </div>
          </Collapse>
          {!!paymentOrderLoading && <FormLoader loading={true}/>}
          <Collapse in={!!paymentOrderData && !!Object.keys(paymentOrderData).length}
                    className={classes.PaymentFirstStepAlert}
                    timeout="auto" unmountOnExit>
            <Alert severity="success">
              <FormattedMessage id='paymentOrderData.generateRequest'
                                values={
                                  {
                                    paymentOrderId: (!!paymentOrderData && !!Object.keys(paymentOrderData).length) ? paymentOrderData.request_number : '',
                                    validDate: (!!paymentOrderData && !!Object.keys(paymentOrderData).length) ?
                                      <LanguageConsumer>
                                        {({ locale }) => (
                                          <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                  format="Do MMMM YYYY h:mm a">{paymentOrderData.expire_date}</Moment>
                                        )}
                                      </LanguageConsumer> : ''
                                  }}/></Alert>
          </Collapse>

        </>
      )}
    </PaymentOrderConsumer>
  )
}

export default PaymentOrderStep2
