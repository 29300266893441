import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LanguageConsumer } from '../../LanguageContext'
import { LocalStorageService } from '../../Services/LocalStorageService'
import { useStyles } from './Styles'
import Configuration from '../../Services/Api/Configuration'
import LanguageIcon from '@material-ui/icons/Language'

const LanguageSwitcher = ({ otherClasses, isMobile }) => {
  const classes = useStyles()
  const intl = useIntl()
  let { DefaultLocale } = Configuration
  let [active, setActive] = React.useState(DefaultLocale)
  React.useEffect(() => {
    LocalStorageService.get('ETaxAppLanguage').then((result) => {
      setActive(result)
    })
  }, [])

  return (
    <LanguageConsumer>
      {({ switchToEnglish, switchToArabic }) => (
        <div className={`${classes.languageSwitcherWrapper} ${!!otherClasses ? otherClasses : ''}`}
             id='languageSwitcher'>
          <button onClick={active === 'ar' ? switchToEnglish : switchToArabic}
                  className={`${classes.languageSwitcherButton} ${!!isMobile ? 'mobile' : ''}`}
                  title={active === 'ar' ? intl.formatMessage({ id: 'LanguageSwitcher.enLanguage' }) : intl.formatMessage({ id: 'LanguageSwitcher.arLanguage' })}>
            <LanguageIcon/>
            {active === 'ar' ? <FormattedMessage id='En'/> : <FormattedMessage id='Ar'/>}
          </button>
        </div>
      )}
    </LanguageConsumer>
  )
}
export default LanguageSwitcher
