import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './Styles'
import { CompanyConsumer } from '../../../../../CompanyContext'
import { AuthenticatedUserConsumer } from '../../../../../AuthenticatedUserContext'
import CompanyPaymentRequests from '../../PaymentRequestsContent/CompanyPaymentRequests'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentOldPaymentOrders = ({ claimId, isPaymentClaim, removeCurrentRequestsClaimId, refreshPaymentsForDeletedRecords }) => {
  const classes = useStyles()
  const [viewOldPaymentOrdersDialog, setViewOldPaymentOrdersDialog] = React.useState(false)
  const [openOldPaymentOrdersDialog, setOpenOldPaymentOrdersDialog] = React.useState(false)
  const handleOldPaymentOrdersDialog = () => {
    if (!openOldPaymentOrdersDialog) {
      setViewOldPaymentOrdersDialog(true)
    } else {
      if (!!removeCurrentRequestsClaimId) {
        removeCurrentRequestsClaimId()
      }

      setTimeout(() => {
        setViewOldPaymentOrdersDialog(false)
        if (!!refreshPaymentsForDeletedRecords) {
          refreshPaymentsForDeletedRecords(false, isPaymentClaim)
        }
      }, 500)
    }
    setOpenOldPaymentOrdersDialog(!openOldPaymentOrdersDialog)
  }

  return (
    <>
      <Button size="small" variant="contained" color="primary"
              onClick={handleOldPaymentOrdersDialog}>
        <FormattedMessage id='PaymentOrder.ActionLinks.oldOrders'/>
      </Button>

      {!!viewOldPaymentOrdersDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'lg'}
        open={openOldPaymentOrdersDialog}
        classes={{ paper: classes.NewOrderDialogPaper }}
        TransitionComponent={Transition}
        onClose={handleOldPaymentOrdersDialog}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
          <Typography variant="h5">
            <FormattedMessage id='PaymentOrder.ActionLinks.oldOrders.title'
                              values={{ claimId: claimId }}/></Typography>
          <IconButton
            edge={false}
            className={classes.NewOrderDialogClose}
            color="inherit"
            aria-label="close dialog"
            onClick={handleOldPaymentOrdersDialog}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent id={'scrollableDiv'} className={classes.NewOrderDialogContent}>
          <Box p={4} className={classes.NewOrderStepsWrapper}>
            <AuthenticatedUserConsumer>
              {({ selectedCompany }) => (
                <CompanyConsumer>
                  {({ getPaymentRequests, getPaymentClaimRequests }) => (
                    <CompanyPaymentRequests
                      scrollableTarget={'scrollableDiv'}
                      isPaymentClaim={isPaymentClaim}
                      claimId={claimId}
                      selectedCompany={selectedCompany}
                      getPaymentRequests={getPaymentRequests}
                      getPaymentClaimRequests={getPaymentClaimRequests}/>
                  )}
                </CompanyConsumer>
              )}
            </AuthenticatedUserConsumer>

          </Box>
        </DialogContent>
        <DialogActions className={classes.NewOrderDialogFooter}>
          <Button onClick={handleOldPaymentOrdersDialog} color="primary">
            <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
          </Button>
        </DialogActions>
      </Dialog>
      }
    </>
  )
}

export default PaymentOldPaymentOrders
