import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  paymentsActionLinksContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    }
  },
  ErrorDialogPaper: {
    borderRadius: 0
  },
  ErrorDialogContent: {
    padding: '0 !important',
    position: 'relative',
    '& .MuiAlert-root':{
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(6),
      }
    },
    '&.with-lines .MuiAlert-root':{
      paddingRight: theme.spacing(6)
    }
  },
  ErrorDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.primary.dark
  },
  refreshWrapper:{
    width: '100%',
    height: 200,
    position: 'relative'
  },
  btnPopper:{
    zIndex: 5,
    '& .MuiMenuItem-root':{
      fontSize: theme.typography.pxToRem(14),
      whiteSpace: 'normal'
    }
  }
}))
