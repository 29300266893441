import React from 'react'
import { NavLink } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../routes'

export const DisplayFormikState = (props) => {
  return (
    <div style={{
      position: 'sticky',
      top: 75.67,
      zIndex: 10
    }}>
      <pre
        style={{
          background: '#f6f8fa',
          padding: '.5rem',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          margin: 0
        }}
      >
      <strong>props</strong> ={' '}
        {JSON.stringify(props, null, 2)}
    </pre>
    </div>
  )
}

/**
 * Log function works only in development and removed in production
 * @param args
 */
export const log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

/** Method to Filter the Empty params before send the API Request.
 *
 * @param data
 * @returns {{}}
 */
export const filterData = (data) => {
  let newData = {}
  Object.keys(data).forEach((key => {
    const isString = typeof data[key] === 'string' && data[key].length
    const isArray = Array.isArray(data[key]) && data[key].length
    const isNumber = Number.isInteger(data[key])
    const isDate = data[key] instanceof Date
    const isBoolean = typeof data[key] === 'boolean' && data[key] === !!data[key]
    if (isString || isArray || isNumber || isBoolean) {
      newData[key] = data[key]
    }
    if (isDate) {
      let date = new Date(data[key])
      let dd = String(date.getDate()).padStart(2, '0')
      let mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
      let yyyy = date.getFullYear()
      newData[key] = dd + '-' + mm + '-' + yyyy
    }
  }))
  return newData
}

export const filterTags = (tags) => {
  let newTags = []
  tags.forEach((value, index) => {
    if (!!value.tag) {
      newTags.push(value)
    }
  })
  return newTags
}
export const filterCategories = (categories) => {
  let newCategories = []
  categories.forEach((value, index) => {
    if (!!value.id) {
      newCategories.push(value)
    }
  })
  return newCategories
}

export const randomKey = Math.random().toString(36).substr(2, 9)

export const isEmpty = (object) => {
  return !Object.getOwnPropertySymbols(object).length && !Object.getOwnPropertyNames(object).length
}

export const formatPhoneNumber = (str) => {
  let match = str.match(/.{1,3}/g)
  match[0] = '(' + match[0] + ')'
  return match.join(' ')
}

export const formatMobilePhoneNumber = (str, formated) => {
  let match = str.replace(/ /g, '').match(/.{1,3}/g)
  if (!!formated) {
    match[0] = '(' + match[0] + ')'
  }
  return match.reduce((previous, current, index, array) => {
    if (index > 3) {
      return previous + '' + current
    } else {
      return previous + ' ' + current
    }
  })
}

export const formatReceipt = (receipt) => {
  let newReceipt = {}
  if (!!receipt && !!Object.keys(receipt).length) {
    newReceipt = {
      id: receipt?.id || '',
      create_at: receipt?.create_at || '',
      receipt_number: receipt?.receipt_number || '',
      companyNameString: receipt?.payment_request?.company.name || 'N/A',
      companyName: (!!receipt?.payment_request?.company?.active && !!receipt?.payment_request?.company?.company_user_active && !!receipt?.payment_request?.company?.name) ?
        <NavLink
          to={formatRoute(NamedRoutes.workspace.company.view, { id: receipt?.payment_request?.company?.id || '' })}>
          {receipt?.payment_request?.company?.name || ''}</NavLink> : receipt?.payment_request?.company?.name || 'N/A',
      taxpayer_name: receipt?.payment_request?.company?.taxpayer_name || '',
      taxpayerName: !!receipt?.payment_request?.company?.active && !!receipt?.payment_request?.company?.company_user_active ?
        <NavLink
          to={formatRoute(NamedRoutes.workspace.company.view, { id: receipt?.payment_request?.company?.id || '' })}>
          {receipt?.payment_request?.company?.taxpayer_name || ''}</NavLink> : receipt?.payment_request?.company?.taxpayer_name || 'N/A',
      tax_id: receipt?.payment_request?.company.tax_id || '',
      request_number: receipt?.payment_request?.request_number || '',
      payment_tax_type: receipt?.payment_request?.payment_tax_type || '',
      payment_claim_number: receipt?.payment_request?.payment_claim_number || '',
      payment_type: receipt?.payment_request?.payment_type || '',
      payment_tax_period_from: receipt?.payment_request?.payment_tax_period_from || '',
      payment_tax_period_to: receipt?.payment_request?.payment_tax_period_to || '',
      payment_item: receipt?.payment_request?.payment_item || '',
      payment_claim_amount: receipt?.payment_request?.payment_claim_amount || ''
    }
  }
  return newReceipt
}