import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { Dialog, DialogContent, IconButton } from '@material-ui/core'
import { useStyles } from './Styles'
import CloseIcon from '@material-ui/icons/Close'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'

const LinkCompanyErrorDialog = ({ openLinkCompanyDialog, handleLinkCompanyDialog, Transition, isRegister, isRegisterUpdate }) => {
  const classes = useStyles()

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={openLinkCompanyDialog}
      classes={{ paper: classes.ErrorDialogPaper }}
      onClose={handleLinkCompanyDialog}
      TransitionComponent={Transition}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogContent className={classes.ErrorDialogContent}>
        <IconButton
          edge={false}
          className={classes.ErrorDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleLinkCompanyDialog}
        >
          <CloseIcon/>
        </IconButton>
        <Alert severity="error" className={classes.linkCompanyErrorMessage}>
          <FormattedMessage
            id={!!isRegister ? `RegisterCompany.Contacts.NotExistError${!!isRegisterUpdate ? '.update' : ''}` : 'LinkCompany.Contacts.NotExistError'}
            values={{
              a: chunks => <NavLink to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
            }}/>
        </Alert>
      </DialogContent>
    </Dialog>
  )
}

export default LinkCompanyErrorDialog
