import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './Styles'
import PaymentRequestDetails from './PaymentRequestDetails'
import PayOthersMessages from './PayOthersMessages'
import { AuthenticatedUserConsumer } from '../../../../../../AuthenticatedUserContext'
import PaymentRequestPDFContent from './PaymentRequestPDFContent'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { CompanyConsumer } from '../../../../../../CompanyContext'
import Alert from '@material-ui/lab/Alert'
import { FullBodyLoader } from '../../../../../Loader/FullBodyLoader'
import { LanguageConsumer } from '../../../../../../LanguageContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PayOthersDialog = ({ handlePayOthersDialog, checkPaymentForPayOthers, openPayOthersDialog, claimId }) => {
  const classes = useStyles()
  const [currentDate, setCurrentDate] = React.useState(new Date())
  const intl = useIntl()
  const generatePDF = () => {
    html2canvas(document.getElementById(`pdfContent-${claimId}`), {
      onclone: function(clonedDoc) {
        clonedDoc.getElementById(`pdfContent-${claimId}`).style.visibility = 'visible'
      },
      x: 0,
      y: 0,
      scrollX: 0,
      scrollY: 0
    }).then((canvas) => {
      let imgData = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'px', 'a4')
      pdf.setProperties({
        title: intl.formatMessage({ id: 'PayOthersDialog.title' }, { claimId: claimId }),
        subject: 'Info about PDF',
        author: 'PDFAuthor',
        creator: 'E-Finance'
      })
      let imgProps = pdf.getImageProperties(imgData)
      let pdfWidth = pdf.internal.pageSize.getWidth()
      let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.autoPrint({ variant: 'javascript' })
      window.open(URL.createObjectURL(pdf.output('blob')), '_blank')
    })
  }

  React.useEffect(() => {
      if (!!checkPaymentForPayOthers) {
        checkPaymentForPayOthers(claimId)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <CompanyConsumer>
      {({
          paymentForPayOthers, paymentForPayOthersLoading, paymentForPayOthersFailure,
          paymentForPayOthersFailureMessage
        }) => (
        !!paymentForPayOthersLoading ?
          <FullBodyLoader loading={true}/>
          :
          <Dialog
            keepMounted
            fullWidth
            maxWidth={!!paymentForPayOthersFailure ? 'sm' : 'md'}
            open={openPayOthersDialog}
            classes={{ paper: !!paymentForPayOthersFailure ? classes.ErrorDialogPaper : classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handlePayOthersDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            {!paymentForPayOthersFailure &&
            <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='PayOthersDialog.title'
                                                         values={{ 'claimId': claimId }}/></Typography>
              <IconButton
                edge={false}
                className={classes.NewOrderDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handlePayOthersDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            }
            <DialogContent
              className={!!paymentForPayOthersFailure ? classes.ErrorDialogContent : classes.NewOrderDialogContent}>
              {!!paymentForPayOthersFailure ?
                <>
                  <IconButton
                    edge={false}
                    className={classes.ErrorDialogClose}
                    color="inherit"
                    aria-label="close dialog"
                    onClick={handlePayOthersDialog}
                  >
                    <CloseIcon/>
                  </IconButton>
                  <Alert severity="error">{paymentForPayOthersFailureMessage}</Alert>
                </>
                :
                <>
                  <Box p={4} className={classes.NewOrderStepsWrapper}>
                    <PayOthersMessages isForeign={paymentForPayOthers?.currency?.key !== 'egp'}/>
                    <PaymentRequestDetails/>
                  </Box>
                  <AuthenticatedUserConsumer>
                    {({ userData }) => (
                      !!paymentForPayOthers && !!Object.keys(paymentForPayOthers).length &&
                      <LanguageConsumer>
                        {({ locale }) => (
                          <PaymentRequestPDFContent
                            requestDetails={paymentForPayOthers}
                            claimId={claimId}
                            isForeign={paymentForPayOthers?.currency?.key !== 'egp'}
                            locale={locale}
                            userName={userData.first_name + ' ' + userData.last_name}
                            currentDate={currentDate}
                          />
                        )}
                      </LanguageConsumer>
                    )}
                  </AuthenticatedUserConsumer>
                </>
              }
            </DialogContent>
            {!paymentForPayOthersFailure &&
            <DialogActions className={classes.NewOrderDialogFooter}>
              <Button className={classes.NewOrderPayButton} size="large" variant="contained" color="primary"
                      onClick={() => {
                        setCurrentDate(new Date())
                        generatePDF()
                      }}>
                <FormattedMessage id='PaymentOrder.ActionLinks.print'/>
              </Button>
              <Button onClick={handlePayOthersDialog} color="primary">
                <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
              </Button>
            </DialogActions>
            }
          </Dialog>
      )}
    </CompanyConsumer>
  )
}

export default PayOthersDialog
