import React from 'react'
import Item from '../../../CompaniesInfo/Item'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { FormLoader } from '../../../../../Loader/FormLoader'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { makeStyles } from '@material-ui/core/styles'
import { LanguageConsumer } from '../../../../../../LanguageContext'


const useStyles = makeStyles((theme) => ({
  partialWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  partialAmount: {
    color: theme.palette.grey['600'],
    position: 'relative',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      top: '50%',
      left: 0,
      backgroundColor: theme.palette.grey['600']

    }
  }
}))
const PaymentOrderStep1 = ({ isOld }) => {
  const classes = useStyles()
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <PaymentOrderConsumer>
          {({ paymentDetails, PaymentOrderColumns, paymentOrderLoading }) => (
            <>
              {!!paymentOrderLoading && <FormLoader loading={true}/>}
              {!!paymentDetails && !!Object.keys(paymentDetails).length &&
              <div>
                {!isOld &&
                <Item asTable label={<FormattedMessage id='CompanyHeader.UniqueTaxId'/>}
                      value={paymentDetails['tax_id']}/>}
                {PaymentOrderColumns.map((column) => (
                  <Item key={column.id} asTable label={column.label} value={
                    (['claim_amount', 'payment_claim_amount'].includes(column.id)) ?

                      (('claim_amount' === column.id && !!paymentDetails['allow_partial_payment'] &&
                        !!paymentDetails['due_amount'] && paymentDetails['due_amount'] !== paymentDetails[column.id]) ?
                        <div className={classes.partialWrapper}>
                          <div className={classes.partialAmount}><FormattedNumber value={paymentDetails[column.id]}/>
                          </div>
                          <div className={classes.partialDueAmount}>
                            <FormattedMessage id='partialPayment.DueAmount.Hint'
                                              values={{
                                                dueAmount: <FormattedNumber value={paymentDetails['due_amount']}/>,
                                                currency: <FormattedMessage id={`partialPayment.DueAmount.Currency.${paymentDetails?.currency?.key?.toLowerCase()||''}`} />
                                              }}/>
                          </div>
                        </div>
                        :
                        <FormattedNumber value={paymentDetails[column.id]}/>)
                      :
                      ((['allow_partial_payment'].includes(column.id)) ?
                        <FormattedMessage id={`PaymentHeader.paymentAllowPartial.${paymentDetails[column.id]}`}/> :
                        ((['payment_min_amount', 'payment_max_amount'].includes(column.id)) ?
                          <FormattedNumber value={paymentDetails[column.id]}/> :
                          ((['tax_period_from', 'tax_period_to', 'claim_expire_date', 'created_at', 'payment_claim_expire_date',
                            'payment_tax_period_from', 'payment_tax_period_to', 'expire_date'].includes(column.id)) ?
                            <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                    format="Do MMMM YYYY h:mm a">{paymentDetails[column.id]}</Moment>
                            : paymentDetails[column.id] || 'N/A')))
                  }/>
                ))}
              </div>
              }
            </>
          )}
        </PaymentOrderConsumer>
      )}
    </LanguageConsumer>
  )
}

export default PaymentOrderStep1
