import React from 'react'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import CompanyPaymentRequests from './CompanyPaymentRequests'
import { SEO } from '../../../Seo/seo'
import { payments } from '../../../../utils/pages-meta'
import { useIntl } from 'react-intl'
import InActiveCompanyContent from '../../../InActiveCompanyContent'


const PaymentRequestsContent = ({ checkCompanyData, cid }) => {
  const intl = useIntl()
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO
                title={intl.formatMessage({ id: 'PageTitle.PaymentRequests' }) + ' - ' + selectedCompany.taxpayer_name}
                pathname={window.location.origin}
                titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                image={payments.image}
                description={intl.formatMessage({ id: 'PageTitle.PaymentRequests' }) + ' - ' + selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                <CompanyConsumer>
                  {({ getPaymentRequests, setBreadCrumbLinks, setBreadCrumbCurrent }) => (
                    <CompanyPaymentRequests selectedCompany={selectedCompany}
                                            getPaymentRequests={getPaymentRequests}
                                            setBreadCrumbLinks={setBreadCrumbLinks}
                                            setBreadCrumbCurrent={setBreadCrumbCurrent}/>
                  )}
                </CompanyConsumer>
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default PaymentRequestsContent
