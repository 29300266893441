import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Dialog, DialogContent, DialogActions, Slide } from '@material-ui/core'
import { useStyles } from '../Styles'
import { Alert, AlertTitle } from '@material-ui/lab'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const HintDialog = ({ openHintDialog, handleHintDialog }) => {
  const dialogClasses = useStyles()
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth={'sm'}
      open={openHintDialog}
      classes={{ paper: dialogClasses.DialogHintPaper }}
      TransitionComponent={Transition}
      onClose={handleHintDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogContent className={dialogClasses.DialogHintContent}>
        <Alert severity="warning">
          <AlertTitle><FormattedMessage id='registerCompanyForm.registrationPlan.simple'/></AlertTitle>
          <FormattedMessage id='registerCompanyForm.registrationPlan.simple.description'/>
          <DialogActions className={dialogClasses.DialogHintFooter}>
            <Button color="primary" variant={'text'} size="small" onClick={handleHintDialog}>
              <FormattedMessage id='registerCompanyForm.registrationPlan.simple.confirm'/>
            </Button>
          </DialogActions>
        </Alert>
      </DialogContent>
    </Dialog>
  )
}

export default HintDialog
