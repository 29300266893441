import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    position: 'relative',
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  },
  PaymentOrderStepMessage: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.7,
    '&.center': {
      textAlign: 'center'
    },
    '& p': {
      marginBottom: theme.spacing(1)
    }
  },
  termsConditionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(20),
      '&.not-label': {
        padding: theme.spacing(0, 1),
        marginBottom: theme.spacing(1)
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12)
      }
    },
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      textDecoration: 'underline',
      marginLeft: theme.spacing(0.75),
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16)
      }
    }
  },
  PaymentFirstStepActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      minWidth: '20%'
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  PaymentFirstStepAlert: {
    marginTop: theme.spacing(2),
    '& .MuiAlert-message': {
      fontSize: theme.typography.pxToRem(16)
    }, '& .MuiAlert-icon': {
      fontSize: theme.typography.pxToRem(28)
    }
  },
  NewOrderPayButton: {
    minWidth: '20%'
  },
  PayOptionsCustomMenu: {
    padding: 0,
    '& li': {
      padding: 0
    },
    '& button': {
      width: '100%',
      padding: theme.spacing(1.25, 2.25),
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main
      }
    }
  },

  formWrapper: {
    position: 'relative'
  },

  AdditionalInfoRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      width: 380,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '&.column': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  buttonAction: {
    borderRadius: theme.spacing(0),
    border: `none`,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43,
    textAlign: 'left',
    padding: theme.spacing(1.5, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '& i, & svg': {
      marginRight: theme.spacing(1)
    },
    '& .MuiFormHelperText-root': {
      marginTop: theme.spacing(0)
    },
    '&:hover': {
      // color: theme.palette.secondary.main,
      // backgroundColor: 'transparent'
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& .MuiFormHelperText-root': {
        color: 'inherit'
      }
    }
  }
}))
