import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  languageSwitcherWrapper: {
    position: 'relative'
    //    margin-left: 2px;
  },
  languageSwitcherButton: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 1),
    display: 'flex !important',
    alignItems: 'center',
    border: 0,
    transition: 'all 0.3s ease-in-out',
    '&:hover, &:focus': {
      color: theme.palette.primary.light,
      outline: 'none'
    },
    '&:not(.mobile)': {
      flexDirection: 'row-reverse',
      '& svg': {
        fontSize: theme.typography.pxToRem(18),
        marginLeft: theme.spacing(0.25)
      }
    }
  }
}))
