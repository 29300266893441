/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'
import { Container } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'

const TopHeader = () => {
  const classes = useStyles()
  const { keycloak, initialized } = useKeycloak()

  return (
    !!initialized && !(keycloak?.authenticated) &&
    <div className={classes.topHeader}>
      <Container maxWidth="lg" className={classes.topHeaderContainer}>
        <a href="#" className={classes.topHeaderLink}><FormattedMessage id='TopHeader.link.company'/></a>
        <a href="#" className={classes.topHeaderLink}><FormattedMessage id='TopHeader.link.people'/></a>
      </Container>
    </div>
  )
}

export default TopHeader
