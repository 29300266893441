import React from 'react'
import { Container } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import OldCompaniesContacts from './OldCompaniesContacts'
import NewCompaniesContacts from './NewCompaniesContacts'
import { useStyles } from './Styles'

const EditCompaniesContacts = ({ resetSelectedCompany, getConfiguredUnlinkedCompaniesContactsCount, getCompaniesContacts }) => {
  const classes = useStyles()
  React.useEffect(() => {
      getConfiguredUnlinkedCompaniesContactsCount()
      getCompaniesContacts()
      resetSelectedCompany()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <Container maxWidth="lg" className={classes.EditProfileContentContainer}>
      <div className={classes.EditProfileContent}>
        <h2 className={classes.EditProfileContentTitle}><FormattedMessage id='profile.CompaniesProfileTitle'/></h2>
        <NewCompaniesContacts/>
        <OldCompaniesContacts/>
      </div>
    </Container>
  )
}

export default EditCompaniesContacts
