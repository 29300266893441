import React from 'react'
import { md } from '../../../utils/ResponsiveUtility'
import { FormattedMessage } from 'react-intl'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Button } from '@material-ui/core'
import { useStyles } from './Styles'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import Item from './Item'
import { portalMenu, workspaceCompanyMenu, workspaceMenu } from './Menus'
import HeaderAuth from '../Auth'
import { useKeycloak } from '@react-keycloak/web'
import { Fade } from 'react-reveal'
import LanguageSwitcher from '../../LanguageSwitcher'
import { LanguageConsumer } from '../../../LanguageContext'
import Configuration from '../../../Services/Api/Configuration'

const MainNavigation = ({ isWorkspace, toggleMobileMenuDrawer }) => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < md)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <nav className={classes.mainNavigation}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany, checkIncentivesTaxIdValidity, checkTaxServiceLink }) => (
          <LanguageConsumer>
            {({ locale }) => (
              <>
                {!!Mobile &&
                <HeaderAuth mobileMenu={true} toggleMobileMenuDrawer={toggleMobileMenuDrawer}
                            isWorkspace={isWorkspace}/>}
                {(!!isWorkspace ? workspaceMenu : portalMenu).map((item, index) => (
                  <Item key={item.key} index={index} item={item} Mobile={Mobile} preAppendIndex={0} locale={locale}
                        toggleMobileMenuDrawer={toggleMobileMenuDrawer}/>
                ))}
                {(!!isWorkspace && !!selectedCompany && !!Object.keys(selectedCompany).length) &&
                  workspaceCompanyMenu(locale).map((item, index) => (
                    !(
                      (!!selectedCompany.is_ecommerce && !!item.notForECommerce) ||
                      (!!item.isEcommerce && !selectedCompany.is_ecommerce)
                    ) &&
                    <Item key={item.key} index={index} item={item} Mobile={Mobile}
                          preAppendIndex={workspaceMenu.length}
                          checkIncentivesTaxIdValidity={checkIncentivesTaxIdValidity}
                          checkTaxServiceLink={checkTaxServiceLink}
                          locale={locale} toggleMobileMenuDrawer={toggleMobileMenuDrawer}
                          selectedCompany={selectedCompany}/>
                  ))  
                }
                {!!keycloak?.authenticated && !!Mobile &&
                <Fade bottom delay={0}>
                  <Button className={`${classes.signOutMobile} signOut`} onClick={() => keycloak.logout()}>
                    <ExitToAppIcon/>
                    <FormattedMessage id='UserMenu.logout'/></Button>
                </Fade>}
                {!!Mobile && !Configuration.HIDE_LANGUAGE_SWITCHER &&
                <Fade bottom delay={0}>
                  <LanguageSwitcher otherClasses={`${classes.signOutMobile} signOut`} isMobile/>
                </Fade>}
              </>
            )}
          </LanguageConsumer>
        )}
      </AuthenticatedUserConsumer>
    </nav>
  )
}

export default MainNavigation
