import React from 'react'
import WorkspaceTable from '../../Table'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import { CompanyConsumer } from '../../../../CompanyContext'
import { isEmpty } from '../../../../utils/helpers'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../../../../routes'
import ReceiptsFilter from './ReceiptsFilter'
import { Box } from '@material-ui/core'
import PaymentReceiptValidity from './PaymentReceiptValidity'
import UnAuthorize from '../../../UnAuthorize'
import { NavLink } from 'react-router-dom'

const CompanyPaymentReceipts = ({ selectedCompany, getPaymentReceipts, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  React.useEffect(() => {
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          },
          {
            key: 'BreadcrumbLink-3',
            name: <FormattedMessage id='workspaceMenu.link.item63'/>,
            link: formatRoute(NamedRoutes.workspace.company.payments, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='PaymentsContent.ActionLinks.PaymentReceipts'/>)
      }
      if (!!getPaymentReceipts) {
        getPaymentReceipts(true)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <>
      <CompanyConsumer>
        {({
            paymentReceiptsLoading, paymentReceiptsFailure, paymentReceiptsFailure403, paymentReceipts,
            PaymentReceiptHeaderColumns, handleSubmitReceiptsFilter, hasMorePaymentReceipts,
            openPaymentReceiptValidity, viewPaymentReceiptValidity, handlePaymentReceiptValidityDialog, receiptValidityCode,
            isCompanyUnderage, companyFailureUnderage, companyFailureUnderageMessage
          }) => (
          <Box p={2} className={'back-white'}>


            {!!paymentReceiptsFailure403 ? <UnAuthorize/> :
              (!!isCompanyUnderage && !!companyFailureUnderage ?
                <Alert severity="error">
                  {companyFailureUnderageMessage}
                  <FormattedMessage id='Underage.ErrorAlert' values={{
                    a: chunks => <NavLink to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
                  }}/></Alert>
                : (!!paymentReceiptsFailure &&
                  <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                ))
            }
            {!paymentReceiptsFailure403 && !companyFailureUnderage && !(!!isCompanyUnderage && !!paymentReceiptsLoading) &&
            <ReceiptsFilter submitForm={handleSubmitReceiptsFilter}/>
            }
            {!!paymentReceiptsLoading && <SearchLoader loading={true}/>}
            {(!!paymentReceipts && !!paymentReceipts.length) ?
              <>
                <WorkspaceTable
                  loadRows={getPaymentReceipts}
                  hasMoreRows={hasMorePaymentReceipts}
                  rows={paymentReceipts}
                  columns={PaymentReceiptHeaderColumns.filter(column => !column.singleOnly)}
                  receiptAction={true}
                  // isOldPayments={true}
                  size={'small'}
                />
                <PaymentReceiptValidity
                  open={openPaymentReceiptValidity}
                  view={viewPaymentReceiptValidity}
                  handleClose={handlePaymentReceiptValidityDialog}
                  validityCode={receiptValidityCode}/>
              </>
              :
              !paymentReceiptsLoading && !paymentReceiptsFailure403 && !companyFailureUnderage &&
              <EmptyResult isPaymentReceipts={true}/>
            }
          </Box>
        )}
      </CompanyConsumer>
    </>
  )
}

export default CompanyPaymentReceipts
