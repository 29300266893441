import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
  Button, Tooltip
} from '@material-ui/core'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import PaymentNewOrder from './CompanyPage/CompaniesPayments/PaymentNewOrder'
import { PaymentOrderConsumer, PaymentOrderProvider } from '../../PaymentOrderContext'
import { CompanyConsumer } from '../../CompanyContext'
import PaymentOldPaymentOrders from './CompanyPage/CompaniesPayments/PaymentOldPaymentOrders'
import InfiniteScroll from 'react-infinite-scroll-component'
import { SearchLoader } from '../Loader/SearchLoader'
import PaymentReceiptView from './CompanyPage/PaymentReceiptsContent/PaymentReceiptView'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import moment from 'moment'
import Configuration from '../../Services/Api/Configuration'
import { DelegateConsumer, DelegateProvider } from '../../DelegateContext'
import { EcommerceConsumer, EcommerceProvider } from '../../EcommerceContext'
import DelegateMenu from './CompanyPage/DelegatesContent/DelegateMenu'
import EcommerceMenu from './CompanyPage/EcommerceClientContent/EcommerceClientMenu'
import HelpIcon from '@material-ui/icons/Help'
import { LanguageConsumer } from '../../LanguageContext'
import InsuranceOrganizationBranchActions from './InsuranceSevices/InsuranceOrganizationBranches/Actions'
import { useStyles } from './TableStyles'
import { md } from '../../utils/ResponsiveUtility'
import { CompanyDownPaymentConsumer } from '../../CompanyDownPaymentContext'
import UpdateCompanyRegistrationHint from './Update'
import { NamedRoutes } from '../../routes'
import { AuthenticatedUserConsumer } from '../../AuthenticatedUserContext'

let { Subscription_Config } = Configuration

export default function WorkspaceTable ({ scrollableTarget, loadRows, hasMoreRows, columns, rows, noFilter, size, paymentActions, receiptAction, delegatesActions, claimId, isOldPayments = false, isPaymentClaim = false, isDownPayment = false, companyID, companyIsAccountant, ecommerceActions }) {
  const classes = useStyles()
  const intl = useIntl()
  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < md)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])


  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500
    }
  }))(Tooltip)
  return (
    <InfiniteScroll
      className={classes.infiniteScroll}
      dataLength={rows.length} //This is important field to render the next data
      next={loadRows}
      hasMore={hasMoreRows}
      loader={<SearchLoader/>}
      scrollableTarget={scrollableTarget || null}
      /*endMessage={<p style={{ textAlign: 'center', padding: 24 }}><b><FormattedMessage id='NoMoreRequests'/></b>
      </p>}*/
    >
      <Paper className={classes.root}>
        <TableContainer className={classes.container + (!!noFilter ? ' noFilter' : '')}>
          <Table size={size || 'small'} aria-label="Payment table">
            {!Mobile && <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align='center'
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {(!!paymentActions || !!receiptAction || !!isDownPayment) &&
                <TableCell
                  align='center'
                  style={{ minWidth: 170 }}
                >
                  <FormattedMessage id='PaymentHeader.actions'/>
                </TableCell>}
                {(!!delegatesActions) &&
                <TableCell align='center'>
                  <FormattedMessage id='DelegateHeader.actions'/>
                </TableCell>}
                {(!!ecommerceActions) &&
                <TableCell align='center'>
                  <FormattedMessage id='DelegateHeader.actions'/>
                </TableCell>}
              </TableRow>
            </TableHead>}
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow className={classes.dataRow} role="checkbox" tabIndex={-1} key={(row.id || index)}>
                    {columns.map((column, columnIndex) => (
                      <TableCell className={classes.dataCell}
                                 key={(row.id || index) + '-' + (column.id || columnIndex)}
                                 align='center'>
                        {!!Mobile && <label className={classes.dataLabel}>{column.label}: </label>}
                        <LanguageConsumer>
                          {({ locale }) => (
                            <span className={classes.dataValue}>
                              {(['insuranceActions'].includes(column.id)) ?
                                <InsuranceOrganizationBranchActions currentBranch={row}/> :
                                ((['coretaxtion_delegate_roles'].includes(column.id)) ?
                                  Array.isArray(row[column.id]) && !!row[column.id].length ?
                                    ((row[column.id].length > 1) ?
                                        <ul>
                                          {row[column.id].map((role) => <li key={role.id}>{role.name}</li>)}
                                        </ul>
                                        : row[column.id][0].name
                                    )
                                    : '-'
                                  :
                                  ((['payment_item'].includes(column.id)) ?
                                    <span className={classes.spanLTR}>{row[column.id]}</span>
                                    :
                                    ((['claim_amount', 'payment_claim_amount'].includes(column.id)) ?
                                      (('claim_amount' === column.id && !!row['allow_partial_payment'] && !!row['due_amount'] && row['due_amount'] !== row[column.id]) ?
                                        <div className={classes.partialWrapper}>
                                          <div className={classes.partialAmountsWrapper}>
                                            <div className={classes.partialAmount}><FormattedNumber
                                              value={row[column.id]}/>
                                            </div>
                                            <div className={classes.partialDueAmount}><FormattedNumber
                                              value={row['due_amount']}/></div>
                                          </div>
                                          <CustomTooltip title={intl.formatMessage({ id: 'partialPayment.Amount.Hint' },
                                            {
                                              amount: <FormattedNumber value={row[column.id]}/>,
                                              dueAmount: <FormattedNumber value={row['due_amount']}/>,
                                              currency: <FormattedMessage
                                                id={`partialPayment.Amount.Currency.${row?.currency?.key?.toLowerCase() || ''}`}/>
                                            })}
                                                         PopperProps={{
                                                           disablePortal: true
                                                         }}
                                                         arrow placement="top">
                                            <HelpIcon/>
                                          </CustomTooltip>
                                        </div>
                                        :
                                        <FormattedNumber value={row[column.id]}/>)
                                      :
                                      ((['linkedTaxpayerName'].includes(column.id) && !!row['has_active_update_request']) ?
                                        <div className={classes.updateCompanyHint}>
                                          {row[column.id]}
                                          <UpdateCompanyRegistrationHint/>
                                        </div> :
                                        ((['subscription_end_date'].includes(column.id)) ?
                                          <>
                                            {(!!row['company_user_active'] && (!row[column.id] || (moment(row[column.id]).diff(moment(), 'days', true) < 1))) ?
                                              (!!row['tax_id'] ?
                                                  <><Button size="medium" variant="contained" color="primary"
                                                            target="_blank"
                                                            href={`${Subscription_Config.url}/subscription/service/${Subscription_Config.serviceID}?tax_id=${row['tax_id']}&locale=${locale}`}>
                                                    <FormattedMessage id='subscription.Renew.Btn.Text'/>
                                                  </Button>
                                                    {!!row['is_ecommerce'] && !Configuration.HIDE_ECOMMERCE &&
                                                    <Button className={classes.ecommerceBtn} size="medium"
                                                            variant="contained"
                                                            color="primary"
                                                            href={NamedRoutes.workspace.company.ecommerceClients.replace(':id', row.id)}
                                                    >
                                                      <FormattedMessage id='Ecommerce.Btn.Text'/>
                                                    </Button>} </>
                                                  :
                                                  <>
                                                    <FormattedMessage id='registerCompany.notYet'/>
                                                    <Button size="large" variant="contained" color="primary"
                                                            href={`${Configuration.DashBoardActions_CreatTaxLink}&sap-language=${locale}`}
                                                            target={'_blank'} className={classes.topOffset}
                                                    >
                                                      <FormattedMessage id='registerCompany.notYet.Btn.Text'/>
                                                    </Button>
                                                  </>
                                              )
                                              :
                                              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                      format="Do MMMM YYYY h:mm a">{row[column.id]}</Moment>
                                            }
                                            {!!row['company_user_active'] && !!row['tax_id'] && !!row?.user_uptcp_enrollment_eligibility
                                            && (!!row?.user_uptcp_enrolled_at ?
                                                <Button className={classes.uptcpBtn}
                                                        size="medium" variant="contained"
                                                        color="primary"
                                                        target={'_blank'}
                                                        href={Configuration.UPTCP.URL[locale]}
                                                >
                                                  <FormattedMessage id='workspaceMenu.link.item101'/>
                                                </Button>
                                                : <AuthenticatedUserConsumer>
                                                  {({ checkCompanyUPTCPRegistration, companyUPTCPRegistration }) => (
                                                    <Button className={classes.uptcpBtn}
                                                            size="medium" variant="contained"
                                                            color="primary"
                                                            disabled={!!companyUPTCPRegistration}
                                                            onClick={() => checkCompanyUPTCPRegistration(row?.id, (!row['subscription_end_date'] || (!!row['subscription_end_date'] && moment(row['subscription_end_date']).diff(moment(), 'days', true) < 1)))}>
                                                      <FormattedMessage id='UPTCPUser.ButtonLink'/>
                                                    </Button>
                                                  )}
                                                </AuthenticatedUserConsumer>
                                            )}
                                          </>
                                          :
                                          ((['tax_period_from', 'tax_period_to', 'claim_expire_date',
                                            'payment_tax_period_from', 'payment_tax_period_to', 'payment_claim_expire_date', 'expire_date',
                                            'create_at', 'created_at'].includes(column.id) && row[column.id]) ?
                                            <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                    format="Do MMMM YYYY h:mm a">{row[column.id]}</Moment>
                                            : row[column.id] || '-'))))))}</span>
                          )}
                        </LanguageConsumer>
                      </TableCell>
                    ))}
                    {!!receiptAction &&
                    <TableCell className={classes.dataCell + ' center'} align='center'>
                      {!!Mobile &&
                      <label className={classes.dataLabel}><FormattedMessage id='PaymentHeader.actions'/>: </label>}
                      <div className={classes.actionsCell + ' ' + classes.dataValue}>
                        <CompanyConsumer>
                          {({ requestIdToOpen, PaymentReceiptHeaderColumns }) => (
                            <PaymentOrderProvider
                              paymentDetails={row}
                              PaymentOrderColumns={PaymentReceiptHeaderColumns}>
                              <PaymentReceiptView
                                receiptNumber={row['receipt_number']}
                                requestIdToOpen={requestIdToOpen}
                                currentRequestId={row['request_number']}/>
                            </PaymentOrderProvider>
                          )}
                        </CompanyConsumer>
                      </div>
                    </TableCell>
                    }
                    {!!paymentActions &&
                    <TableCell className={classes.dataCell} align='center'>
                      {!!Mobile &&
                      <label className={classes.dataLabel}><FormattedMessage id='PaymentHeader.actions'/>: </label>}
                      <div className={classes.actionsCell + ' ' + classes.dataValue}>
                        {(!!isOldPayments && !row['can_proceed_to_pay']) ?
                          row['request_state']
                          // <FormattedMessage id='PaymentHeader.actions.expired'/>
                          :
                          <PaymentOrderProvider
                            claimId={!!isPaymentClaim ? row['id'] : row['claim_number']}
                            isOld={isOldPayments}
                            paymentDetails={row}
                            isPaymentClaim={isPaymentClaim}
                            PaymentOrderColumns={columns}>
                            {!isOldPayments && row?.currency?.key === 'egp' &&
                            <CompanyConsumer>
                              {({ removeCurrentRequestsClaimId, refreshPaymentsForDeletedRecords }) => (
                                <PaymentOldPaymentOrders claimId={!!isPaymentClaim ? row['id'] : row['claim_number']}
                                                         isPaymentClaim={isPaymentClaim}
                                                         refreshPaymentsForDeletedRecords={refreshPaymentsForDeletedRecords}
                                                         removeCurrentRequestsClaimId={removeCurrentRequestsClaimId}/>
                              )}
                            </CompanyConsumer>
                            }
                            {((!isOldPayments && !row['is_paid'] && !row['is_expired']) || !!isOldPayments) &&
                            <CompanyConsumer>
                              {({ removePaymentRequest, updatePaymentRequestsAfterValidity, refreshPaymentsForDeletedRecords }) => (
                                <PaymentOrderConsumer>
                                  {({ initPaymentOrderData, handleRequestValidityDialogClose, resetPaymentOrderStatus }) => (
                                    <LanguageConsumer>
                                      {({ locale }) => (
                                        <PaymentNewOrder
                                          handleRequestValidityDialogClose={() => handleRequestValidityDialogClose(updatePaymentRequestsAfterValidity)}
                                          shouldRefreshPayments={!isOldPayments}
                                          canCreate={row['can_create_payment_request']}
                                          isOld={isOldPayments}
                                          isForeign={row?.currency?.key !== 'egp'}
                                          currentPaymentData={row}
                                          refreshPaymentsAfterLinking={refreshPaymentsForDeletedRecords}
                                          isPaymentClaim={isPaymentClaim}
                                          locale={locale}
                                          claimId={!!claimId ? claimId : (!!isPaymentClaim ? row['id'] : row['claim_number'])}
                                          initPaymentOrderData={initPaymentOrderData}
                                          removePaymentRequest={removePaymentRequest}
                                          resetPaymentOrderStatus={resetPaymentOrderStatus}/>
                                      )}
                                    </LanguageConsumer>
                                  )}
                                </PaymentOrderConsumer>
                              )}
                            </CompanyConsumer>
                            }
                          </PaymentOrderProvider>
                        }
                      </div>
                    </TableCell>}

                    {!!isDownPayment &&
                    <TableCell className={classes.dataCell} align='center'>
                      {!!Mobile &&
                      <label className={classes.dataLabel}><FormattedMessage id='PaymentHeader.actions'/>: </label>}
                      <div className={classes.actionsCell + ' ' + classes.dataValue}>
                        {!row['can_proceed_to_pay'] ?
                          row['request_state']
                          // <FormattedMessage id='PaymentHeader.actions.expired'/>
                          :
                          <CompanyDownPaymentConsumer>
                            {({ removeDownPaymentRequest, updateDownPaymentRequestsAfterValidity, DownPaymentRequestsLabels }) => (
                              <PaymentOrderProvider
                                claimId={row['payment_claim_number'] || ''}
                                isDownPayment={true}
                                forceInit={true}
                                paymentDetails={row}
                                PaymentOrderColumns={DownPaymentRequestsLabels}>
                                <PaymentOrderConsumer>
                                  {({ initPaymentOrderData, handleRequestValidityDialogClose, resetPaymentOrderStatus }) => (
                                    <LanguageConsumer>
                                      {({ locale }) => (
                                        <PaymentNewOrder
                                          handleRequestValidityDialogClose={() => handleRequestValidityDialogClose(updateDownPaymentRequestsAfterValidity)}
                                          canCreate={row['can_create_payment_request']}
                                          isOld={true}
                                          isDownPayment={true}
                                          locale={locale}
                                          claimId={row['payment_claim_number'] || ''}
                                          initPaymentOrderData={initPaymentOrderData}
                                          removePaymentRequest={removeDownPaymentRequest}
                                          resetPaymentOrderStatus={resetPaymentOrderStatus}/>
                                      )}
                                    </LanguageConsumer>
                                  )}
                                </PaymentOrderConsumer>
                              </PaymentOrderProvider>
                            )}
                          </CompanyDownPaymentConsumer>
                        }
                      </div>
                    </TableCell>}
                    {(!!delegatesActions) &&
                    <TableCell className={classes.dataCell + ' center'} align='center'>
                      {!!Mobile &&
                      <label className={classes.dataLabel}><FormattedMessage id='DelegateHeader.actions'/>: </label>}
                      <span className={classes.dataValue}>
                          <AuthenticatedUserConsumer>
                            {({ checkCompanyData }) => (
                              <CompanyConsumer>
                                {({ handleSubmitDelegatesFilter }) => (
                                  <DelegateProvider cid={companyID} delegateData={row} pageAction={'Edit'}
                                                    checkCompanyData={checkCompanyData}
                                                    handleSubmitDelegatesFilter={handleSubmitDelegatesFilter}>
                                    <DelegateConsumer>
                                      {({ resetDelegateStatus }) => (
                                        <DelegateMenu resetDelegateStatus={resetDelegateStatus}
                                                      companyIsAccountant={companyIsAccountant}/>
                                      )}
                                    </DelegateConsumer>
                                  </DelegateProvider>
                                )}
                              </CompanyConsumer>
                            )}
                          </AuthenticatedUserConsumer>
                        </span>
                    </TableCell>}
                    {(!!ecommerceActions) &&
                    <TableCell className={classes.dataCell + ' center'} align='center'>
                      {!!Mobile &&
                      <label className={classes.dataLabel}><FormattedMessage id='DelegateHeader.actions'/>: </label>}
                      <span className={classes.dataValue}>
                          <CompanyConsumer>
                            {({ handleSubmitEcommerceFilter }) => (
                              <EcommerceProvider cid={companyID} ecommerceData={row} pageAction={'Edit'}
                                                 handleSubmitEcommerceFilter={handleSubmitEcommerceFilter}>
                                <EcommerceConsumer>
                                  {({ resetEcommerceStatus }) => (
                                    <EcommerceMenu resetEcommerceStatus={resetEcommerceStatus}/>
                                  )}
                                </EcommerceConsumer>
                              </EcommerceProvider>
                            )}
                          </CompanyConsumer>
                        </span>
                    </TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </InfiniteScroll>
  )
}
