import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { useStyles } from './Styles'
import { FormLoader } from '../../../../Loader/FormLoader'
import { EcommerceConsumer } from '../../../../../EcommerceContext'
import Alert from '@material-ui/lab/Alert'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DeleteEcommerce = ({ viewDeleteEcommerceDialog, openDeleteEcommerceDialog, handleDeleteEcommerceDialog }) => {
  const classes = useStyles()

  return (
    <EcommerceConsumer>
      {({
          ecommerceData, deleteEcommerceLoading, handleDeleteEcommerce,
          ecommerceDataFailure, ecommerceDataFailureMessage, ecommerceDataSuccess
        }) => (
        <>
          {!!viewDeleteEcommerceDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'sm'}
            open={openDeleteEcommerceDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleDeleteEcommerceDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <IconButton
              edge={false}
              className={classes.NewOrderDialogClose}
              color="inherit"
              aria-label="close dialog"
              onClick={handleDeleteEcommerceDialog}
            >
              <CloseIcon/>
            </IconButton>
            <DialogContent className={classes.NewOrderDialogContent}>
              <Box px={4} pt={4} pb={1}>
                {!!ecommerceDataFailure && !!ecommerceDataFailureMessage &&
                <Alert severity="error">{ecommerceDataFailureMessage}</Alert>
                }

                {!!ecommerceDataSuccess &&
                <Alert severity="success">
                  <FormattedMessage
                    id='Ecommerce.Delete.FormSuccessAlert' values={{ name_en: ecommerceData.name_en }}/>
                </Alert>
                }
                <div className={classes.DeleteOrderWrapper}>
                  {!!deleteEcommerceLoading && <FormLoader loading={true}/>}
                  <WarningIcon className={classes.DeleteWarningIcon}/>
                  <p><FormattedMessage id='Ecommerce.DeleteMessage' values={{ name_en: ecommerceData.name_en }}/></p>
                </div>
              </Box>
            </DialogContent>
            <DialogActions className={classes.NewOrderDialogFooter}>
              <Button size="large" variant="contained" color="primary" className={classes.DeleteOrderBtn}
                      onClick={() => {
                        handleDeleteEcommerce(handleDeleteEcommerceDialog)
                      }}>
                <FormattedMessage id='Ecommerce.Action.Delete'/>
              </Button>
              <Button onClick={handleDeleteEcommerceDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
          }
        </>
      )}
    </EcommerceConsumer>
  )
}

export default DeleteEcommerce
