import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  CompanyContentContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  uptcpBtn: {
    margin: theme.spacing(1)
  }
}))
