/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  InputLabel,
  Radio,
  TextField,
  FormControlLabel,
  RadioGroup,
  FormHelperText, Select, MenuItem
} from '@material-ui/core'
import { additionalInfoFormValidation } from '../../../../utils/validationSchema'
import ChangePhone from './ChangePhone'
import { FormLoader } from '../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
import { ButtonLoader } from '../../../Loader/ButtonLoader'
import { LanguageConsumer } from '../../../../LanguageContext'

const AdditionalInfo = ({ userData, getGovernments, governments, getCities, cities, submitForm, resetAdditionalInfoStatus }) => {
  const classes = useStyles()
  const intl = useIntl()
  const additionalInfoForm = useFormik({
    initialValues: {
      phone: userData.phone || '',
      newPhone: '',
      VerificationCode: '',
      socialNumber: userData.national_id || '',
      passport_id: userData.passport_id || '',
      address_line1: userData.address_line1 || '',
      address_line2: userData.address_line2 || '',
      country: !!userData.country?.id ? userData.country.id : '68',
      government: !!userData.government?.id ? userData.government.id : '',
      city: !!userData.city?.id ? userData.city.id : '',
      contactChannel: userData.contact_channel || 'email'
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: additionalInfoFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const handleCountryChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    getGovernments(event.target.value)
    additionalInfoForm.setFieldValue('country', event.target.value.toString())
  }

  const handleGovernmentChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    getCities(event.target.value)
    additionalInfoForm.setFieldValue('city', '')
    additionalInfoForm.setFieldValue('government', event.target.value.toString())
  }
  const handleCityChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    additionalInfoForm.setFieldValue('city', event.target.value.toString())
  }

  const [value, setValue] = React.useState(additionalInfoForm.values.contactChannel)
  const handleChange = (event) => {
    setValue(event.target.value)
    additionalInfoForm.setFieldValue('contactChannel', event.target.value)
  }

  React.useEffect(() => {
      getGovernments(!!userData.country?.id ? userData.country.id : '68')
      getCities(!!userData.government?.id ? userData.government.id : '')

      if (!!resetAdditionalInfoStatus) {
        resetAdditionalInfoStatus()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <AuthenticatedUserConsumer>
      {({ updateProfileSuccess, updateProfileFailure, updateProfileFailureMessage, resetPhoneStatus, countriesFailure, countries, governmentsLoader, citiesLoader }) => (
        <>
          <form onSubmit={additionalInfoForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper}>
            {!!countriesFailure &&
            <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
            }
            {!!updateProfileFailure && !!updateProfileFailureMessage &&
            <Alert severity="error">{updateProfileFailureMessage}</Alert>
            }
            {!!updateProfileSuccess &&
            <Alert severity="success"><FormattedMessage id='UpdateProfile.FormSuccessAlert'/></Alert>
            }

            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.phone"> {<FormattedMessage id='Fields.label.phone'/>} </InputLabel>
              <div className={classes.AdditionalInfoPhoneRow}>
                <LanguageConsumer>
                  {({ locale }) => (
                    <div className={classes.PhoneInputWrapper}>
                      <PhoneInput
                        value={additionalInfoForm.values.phone}
                        placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.phone' })}
                        enableSearch={true}
                        disabled={true}
                        country={'eg'}
                        localization={locale === 'ar' ? ar : ''}
                        specialLabel={''}
                        excludeCountries={['il']}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                        searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                      />
                    </div>
                  )}
                </LanguageConsumer>
                <ChangePhone additionalInfoForm={additionalInfoForm} resetPhoneStatus={resetPhoneStatus}/>
              </div>
            </div>

            {additionalInfoForm.values.country?.toString() === '68' ?
              <div className={classes.AdditionalInfoRow}>
                <InputLabel id="AdditionalInfo.socialNumber"><FormattedMessage
                  id='AdditionalInfo.placeholder.socialNumber'/>:</InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.socialNumber' })}
                    id="AdditionalInfo.socialNumber"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='socialNumber'
                    onChange={additionalInfoForm.handleChange}
                    onBlur={additionalInfoForm.handleBlur}
                    value={additionalInfoForm.values.socialNumber}
                    helperText={additionalInfoForm.errors.socialNumber && additionalInfoForm.touched.socialNumber && additionalInfoForm.errors.socialNumber}
                    error={additionalInfoForm.errors.socialNumber && additionalInfoForm.touched.socialNumber !== undefined}
                  />
                </FormControl>
              </div>
              :
              <div className={classes.AdditionalInfoRow}>
                <InputLabel id="AdditionalInfo.passport_id"><FormattedMessage
                  id='registerCompanyForm.placeholder.PassportId'/>:</InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.PassportId' })}
                    id="AdditionalInfo.passport_id"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='passport_id'
                    onChange={additionalInfoForm.handleChange}
                    onBlur={additionalInfoForm.handleBlur}
                    value={additionalInfoForm.values.passport_id}
                    helperText={additionalInfoForm.errors.passport_id && additionalInfoForm.touched.passport_id && additionalInfoForm.errors.passport_id}
                    error={additionalInfoForm.errors.passport_id && additionalInfoForm.touched.passport_id !== undefined}
                  />
                </FormControl>
              </div>
            }
            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.country"><FormattedMessage id='Fields.label.country'/></InputLabel>
              <FormControl variant='outlined'>
                <Select
                  fullWidth
                  labelId="AdditionalInfo.country"
                  id="country"
                  margin="dense"
                  MenuProps={{ disableScrollLock: true }}
                  name="country"
                  value={additionalInfoForm.values.country}
                  onChange={handleCountryChange}
                  onBlur={additionalInfoForm.handleBlur}
                  error={additionalInfoForm.errors.country && additionalInfoForm.touched.country !== undefined}
                >
                  {!!countries.length && countries.map((country) => (
                    <MenuItem key={country.id} value={country.id.toString()}>{country.name}</MenuItem>
                  ))}
                </Select>
                {additionalInfoForm.errors.country && additionalInfoForm.touched.country && (
                  <FormHelperText error={true}>{additionalInfoForm.errors.country}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.government"><FormattedMessage id='Fields.label.government'/></InputLabel>
              <FormControl variant='outlined'>
                {!!governmentsLoader && <ButtonLoader loading={true}/>}
                <Select
                  fullWidth
                  labelId="AdditionalInfo.government"
                  id="government"
                  margin="dense"
                  MenuProps={{ disableScrollLock: true }}
                  name="government"
                  displayEmpty
                  value={!!governments.length ? additionalInfoForm.values.government : ''}
                  onChange={handleGovernmentChange}
                  onBlur={additionalInfoForm.handleBlur}
                  error={additionalInfoForm.errors.government && additionalInfoForm.touched.government !== undefined}
                  disabled={!governments.length}
                >
                  <MenuItem value=""><FormattedMessage id='AdditionalInfo.placeholder.government'/></MenuItem>
                  {!!governments.length && governments.map((government) => (
                    <MenuItem key={government.id} value={government.id.toString()}>{government.name}</MenuItem>
                  ))}
                </Select>
                {additionalInfoForm.errors.government && additionalInfoForm.touched.government && (
                  <FormHelperText error={true}>{additionalInfoForm.errors.government}</FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.city"><FormattedMessage id='Fields.label.city'/></InputLabel>
              <FormControl variant='outlined'>
                {!!citiesLoader && <ButtonLoader loading={true}/>}
                <Select
                  fullWidth
                  labelId="AdditionalInfo.city"
                  id="city"
                  margin="dense"
                  MenuProps={{ disableScrollLock: true }}
                  name="city"
                  displayEmpty
                  value={!!cities.length ? additionalInfoForm.values.city : ''}
                  onChange={handleCityChange}
                  onBlur={additionalInfoForm.handleBlur}
                  error={additionalInfoForm.errors.city && additionalInfoForm.touched.city !== undefined}
                  disabled={!cities.length}
                >
                  <MenuItem value=''><FormattedMessage id='AdditionalInfo.placeholder.city'/></MenuItem>
                  {!!cities.length && cities.map((city) => (
                    <MenuItem key={city.id} value={city.id.toString()}>{city.name}</MenuItem>
                  ))}
                </Select>
                {additionalInfoForm.errors.city && additionalInfoForm.touched.city && (
                  <FormHelperText error={true}>{additionalInfoForm.errors.city}</FormHelperText>
                )}
              </FormControl>
            </div>


            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.address_line1"><FormattedMessage
                id='Fields.label.address_line1'/></InputLabel>
              <FormControl variant="outlined">
                <TextField
                  placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.address_line1' })}
                  id="AdditionalInfo.address_line1"
                  type={'text'}
                  multiline
                  rows={2}
                  margin="dense"
                  variant="outlined"
                  name='address_line1'
                  onChange={additionalInfoForm.handleChange}
                  onBlur={additionalInfoForm.handleBlur}
                  value={additionalInfoForm.values.address_line1}
                  helperText={additionalInfoForm.errors.address_line1 && additionalInfoForm.touched.address_line1 && additionalInfoForm.errors.address_line1}
                  error={additionalInfoForm.errors.address_line1 && additionalInfoForm.touched.address_line1 !== undefined}
                />
              </FormControl>
            </div>


            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.address_line2"><FormattedMessage
                id='Fields.label.address_line2'/></InputLabel>
              <FormControl variant="outlined">
                <TextField
                  placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.address_line2' })}
                  id="AdditionalInfo.address_line2"
                  type={'text'}
                  multiline
                  rows={2}
                  margin="dense"
                  variant="outlined"
                  name='address_line2'
                  onChange={additionalInfoForm.handleChange}
                  onBlur={additionalInfoForm.handleBlur}
                  value={additionalInfoForm.values.address_line2}
                  helperText={additionalInfoForm.errors.address_line2 && additionalInfoForm.touched.address_line2 && additionalInfoForm.errors.address_line2}
                  error={additionalInfoForm.errors.address_line2 && additionalInfoForm.touched.address_line2 !== undefined}
                />
              </FormControl>
            </div>


            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.howToCommunicate"> {<FormattedMessage
                id='Fields.label.howToCommunicate'/>} </InputLabel>
              <FormControl variant="outlined">
                <RadioGroup aria-label="contactChannel" name="contactChannel" value={value} onChange={handleChange}>
                  <FormControlLabel value="email" control={<Radio color={'primary'}/>}
                                    label={<FormattedMessage id='AdditionalInfo.field.contactChannel.email'/>}/>
                  <FormControlLabel value="sms" control={<Radio color={'primary'}/>}
                                    label={<FormattedMessage id='AdditionalInfo.field.contactChannel.sms'/>}/>
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.SubmitRow}>
              <button type="submit" disabled={additionalInfoForm.isSubmitting}
                      className={classes.AdditionalInfoSubmit}>
                <FormattedMessage id='AdditionalInfo.button.submit'/>
              </button>
            </div>
            {additionalInfoForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default AdditionalInfo
