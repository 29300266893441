/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import Configuration from '../Api/Configuration'
import { formatRoute } from 'react-router-named-routes'

const { User_EndPoints } = Configuration

class UserServices {
  static getProfileData (header) {
    return RestClient.getRequest(User_EndPoints.profile, header, true)
  }

  static getCountries (header) {
    return RestClient.getRequest(User_EndPoints.countries, header)
  }

  static getGovernments (country, header) {
    return RestClient.getRequest(`/country/${country}/government`, header)
  }

  static getCities (government, header) {
    return RestClient.getRequest(`/government/${government}/city`, header)
  }

  static getNotifications (header) {
    return RestClient.getRequest(User_EndPoints.notifications, header, true)
  }

  static updateProfileData (data, header, setFormLoading, setFailure, FormikActions) {
    return RestClient.putRequest(User_EndPoints.profile, data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static getCompanies (header) {
    return RestClient.getRequest(User_EndPoints.companiesList, header, true)
  }

  static getCompanyData (cid, header) {
    return RestClient.getRequest(User_EndPoints.companies + `/${cid}`, header, true)
  }

  static registerCompanyUPTCP (cid, header, setFormLoading, setFailure) {
    return RestClient.postRequest(formatRoute(User_EndPoints.companyUPTCPRegistration, { cid: cid }), null, header, setFormLoading, setFailure, null, true)
  }

  static linkCompany (data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(User_EndPoints.linkCompany, data, header, setFormLoading, setFailure, null, true)
  }

  static registerCompany (data, registerPlan, isLegalUpdate, header, setFormLoading, setFailure) {
    if (!!isLegalUpdate) {
      return RestClient.putRequest(User_EndPoints.updateCompanyRegistration, data, header, setFormLoading, setFailure, null, true)
    } else {
      return RestClient.postRequest((registerPlan === 'simple') ? User_EndPoints.simpleRegisterCompany : User_EndPoints.registerCompany, data, header, setFormLoading, setFailure, null, true)
    }
  }

  static updateCompanyRegistration (data, header, setFormLoading, setFailure) {
    return RestClient.putRequest(User_EndPoints.updateCompanyRegistration, data, header, setFormLoading, setFailure, null, true)
  }

  static verifyPhone (phoneNumber, isCompanyContact, header, setFormLoading, setFailure) {
    return RestClient.postRequest(!!isCompanyContact ? User_EndPoints.CompanyContact.verifyPhone : User_EndPoints.verifyPhone, { 'phone': '+' + phoneNumber.toString() }, header, setFormLoading, setFailure, null, true)
  }

  static confirmPhone (data, isCompanyContact, header, setFormLoading, setFailure) {
    return RestClient.putRequest(!!isCompanyContact ? User_EndPoints.CompanyContact.confirmPhone : User_EndPoints.confirmPhone, data, header, setFormLoading, setFailure, null, true)
  }


  static getInsuranceOrganizations (header) {
    return RestClient.getRequest(User_EndPoints.Insurance.organizations, header, true)
  }

  static getInsuranceOrganizationBranches (organization_id, header) {
    return RestClient.getRequest(`${User_EndPoints.Insurance.organizations}/${organization_id}/branches`, header, true)
  }

  static linkInsuranceOrganization (data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(User_EndPoints.Insurance.link, data, header, setFormLoading, setFailure, null, true)
  }

  static insuranceOrganizationBranchDownload (organization_id, type, data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(`${User_EndPoints.Insurance.organizations}/${organization_id}/insurance-form/${type}/download`, data, header, setFormLoading, setFailure, null, true)
  }

  static insuranceOrganizationBranchUpload (organization_id, type, data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(`${User_EndPoints.Insurance.organizations}/${organization_id}/insurance-form/${type}/upload`, data, header, setFormLoading, setFailure, null, true)
  }


  static getCompaniesContacts (header) {
    return RestClient.getRequest(User_EndPoints.CompanyContact.data, header, true)
  }

  static addCompanyContact (data, header, setFormLoading, setFailure, FormikActions) {
    return RestClient.postRequest(User_EndPoints.CompanyContact.data, data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static editCompanyContact (ccId, data, header, setFormLoading, setFailure, FormikActions) {
    return RestClient.putRequest(User_EndPoints.CompanyContact.data + `/${ccId}`, data, header, setFormLoading, setFailure, FormikActions, true)
  }

  static deleteCompanyContact (ccId, header, setFormLoading) {
    return RestClient.deleteRequest(User_EndPoints.CompanyContact.data + `/${ccId}`, header, setFormLoading, null, null, true)
  }

  static resendCompanyContactEmailVerification (ccId, header, setFormLoading, setFailure) {
    return RestClient.postRequest(User_EndPoints.CompanyContact.data + `/${ccId}/resend-email`, null, header, setFormLoading, setFailure, null, true)
  }

  // static resendCompanyContactEmailVerification (ccId, header) {
  //   return RestClient.getRequest(User_EndPoints.CompanyContact.data + `/${ccId}/resend-email`, header,true)
  // }

  static verifyCompanyContactEmail (ccId, header) {
    return RestClient.getRequest(User_EndPoints.CompanyContact.data + `/${ccId}/verify-email`, header, true)
  }

  static incentiveLinkCompany (data, header, setFormLoading, setFailure) {
    return RestClient.postRequest(User_EndPoints.incentiveLinkCompany, data, header, setFormLoading, setFailure, null, true)
  }

  static checkIncentiveTaxIdValidity (trn, header) {
    return RestClient.getRequest(User_EndPoints.incentivesTaxIdValidity, header, true)
  }
}

export default UserServices
