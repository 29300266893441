import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative'
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputBase-marginDense.MuiOutlinedInput-marginDense': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 250,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      width: 340,
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    // '& .MuiTextField-root': {
    //   [theme.breakpoints.up('sm')]: {
    //     maxWidth: 228
    //   }
    // },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },


  dateFormControl: {
    margin: theme.spacing(1, 0, 0.5),
    '& .MuiInput-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 0, 0, 2),
      border: `1px solid ${fade(theme.palette.common.black, 0.23)}`,
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
      display: 'none !important'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(0.75),
      zIndex: 1,
      left: theme.spacing(2)
    },
    '& .MuiInputBase-input': {
      paddingBottom: theme.spacing(0.5)
    }
  },

  SubmitRow: {
    padding: theme.spacing(4, 0),
    textAlign: 'center'
  },
  AdditionalInfoSubmit: {
    color: theme.palette.common.white,
    background: theme.palette.primary.light,
    border: 'none',
    padding: theme.spacing(1, 6),
    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.7
    },
    '&:hover, &:focus': {
      opacity: 0.7
    }
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.common.white,
    '& .MuiPickersToolbarText-toolbarTxt': {
      color: fade(theme.palette.common.white, 0.54)
    },
    '& .MuiPickersToolbarText-toolbarBtnSelected': {
      color: theme.palette.common.white
    }
  },
  amountHint: {
    // marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0.75),
    },
    '& + .MuiTooltip-popper': {
      marginTop: theme.spacing(-1)
    },
    '&.mobile + .MuiTooltip-popper': {
      marginTop: theme.spacing(-2)
    }
  },
  paymentClaimFormOwnerListItem: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.pxToRem(15),
    '& strong': {
      marginRight: theme.spacing(1)
    }
  }
}))
