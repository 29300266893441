import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { Dialog, DialogContent, IconButton, Slide } from '@material-ui/core'
import { useStyles } from './Styles'
import CloseIcon from '@material-ui/icons/Close'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { formatRoute } from 'react-router-named-routes'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const TaxServicesErrorDialog = () => {
  const classes = useStyles()

  return (
    <AuthenticatedUserConsumer>
      {({ selectedCompany, taxServicesError, closeTaxServicesError }) => (
        <Dialog
          fullWidth
          maxWidth={'sm'}
          open={taxServicesError}
          classes={{ paper: classes.ErrorDialogPaper }}
          onClose={closeTaxServicesError}
          TransitionComponent={Transition}
          aria-labelledby="BasicInfoDialog"
          aria-describedby="BasicInfoDialog"
        >
          <DialogContent className={classes.ErrorDialogContent}>
            <IconButton
              edge={false}
              className={classes.ErrorDialogClose}
              color="inherit"
              aria-label="close dialog"
              onClick={closeTaxServicesError}
            >
              <CloseIcon/>
            </IconButton>
            <Alert severity="error" className={classes.linkCompanyErrorMessage}>
              <FormattedMessage id='Underage.taxService.ErrorAlert' values={{
                a: chunks => <NavLink
                  onClick={closeTaxServicesError}
                  to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
              }}/>
            </Alert>
          </DialogContent>
        </Dialog>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default TaxServicesErrorDialog
