import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { Fade } from 'react-reveal'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { useStyles } from './Styles'
import { NamedRoutes } from '../../routes'
import { Link } from 'react-router-dom'

const InActiveCompanyContent = () => {
  const classes = useStyles()
  return (
    <Fade>
      <section>
        <Container maxWidth="lg">
          <Grid container justify='center'>
            <Grid item xs={12}>
              <div className={classes.notAuthorizeWrapper}>
                <NotInterestedIcon/>
                <h1 className={classes.notAuthorizeTitle}><FormattedMessage id='UnAuthorizedCompany.title'/></h1>
                <p><FormattedMessage id='Company.inActive.Message'/></p>
                <div className='item-cta-wrapper'>
                  <Link to={NamedRoutes.workspace.dashboard} className={classes.buttonLink}>
                    <FormattedMessage id='UserMenu.dashboard'/></Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fade>
  )
}

export default InActiveCompanyContent
