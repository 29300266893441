import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from '../Styles'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormLoader } from '../../../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TermsConditions = ({ getTermsConditionsContent }) => {
  const classes = useStyles()
  const [viewTermsConditionsDialog, setViewTermsConditionsDialog] = React.useState(false)
  const [openTermsConditionsDialog, setOpenTermsConditionsDialog] = React.useState(false)
  const handleTermsConditionsDialog = () => {
    if (!openTermsConditionsDialog) {
      getTermsConditionsContent()
      setViewTermsConditionsDialog(true)
    } else {
      setTimeout(() => setViewTermsConditionsDialog(false), 500)
    }
    setOpenTermsConditionsDialog(!openTermsConditionsDialog)
  }

  return (
    <>
      <Button disableRipple size="small" variant="text" color="primary" onClick={handleTermsConditionsDialog}>
        <FormattedMessage id='PaymentOrder.steps.termsConditions.title'/>
      </Button>
      {!!viewTermsConditionsDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'md'}
        open={openTermsConditionsDialog}
        classes={{ paper: classes.NewOrderDialogPaper }}
        TransitionComponent={Transition}
        onClose={handleTermsConditionsDialog}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <PaymentOrderConsumer>
          {({ termsConditionsContentLoading, termsConditionsContentFailure, termsConditionsContent }) => (
            <>
              <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
                <Typography variant="h5">
                  {!Object.keys(termsConditionsContent).length && !!termsConditionsContent.title ?
                    termsConditionsContent.title :
                    <FormattedMessage id='PaymentOrder.steps.termsConditions.title'/>
                  }
                </Typography>

                <IconButton
                  edge={false}
                  className={classes.NewOrderDialogClose}
                  color="inherit"
                  aria-label="close dialog"
                  onClick={handleTermsConditionsDialog}
                >
                  <CloseIcon/>
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.NewOrderDialogContent}>
                <Box p={4} className={classes.NewOrderStepsWrapper}>
                  <div className={classes.PaymentOrderStepMessage}>
                    {!!termsConditionsContentLoading &&
                    <Box p={5}><FormLoader loading={true}/></Box>}
                    {!!termsConditionsContentFailure &&
                    <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>}
                    {!!Object.keys(termsConditionsContent).length && !!termsConditionsContent.content &&
                    <div dangerouslySetInnerHTML={{
                      __html: termsConditionsContent.content
                    }}></div>
                    }
                  </div>
                </Box>
              </DialogContent>
              <DialogActions className={classes.NewOrderDialogFooter}>
                <Button onClick={handleTermsConditionsDialog} color="primary">
                  <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                </Button>
              </DialogActions>
            </>
          )}
        </PaymentOrderConsumer>
      </Dialog>
      }
    </>
  )
}

export default TermsConditions
