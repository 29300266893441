import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {FormattedMessage} from 'react-intl'
import {useStyles} from './Styles'
import {Fade} from 'react-reveal'
import {Link} from 'react-router-dom'
import {NamedRoutes} from '../../routes'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

const NotAuthorize = () => {
  const classes = useStyles()
  return (
    <Fade>
      <section>
        <Container maxWidth="lg">
          <Grid container justify='center'>
            <Grid item xs={12}>
              <div className={classes.notAuthorizeWrapper}>
                <NotInterestedIcon/>
                <h1 className={classes.notAuthorizeTitle}><FormattedMessage id='UnAuthorized.title'/></h1>
                <p><FormattedMessage id='UnAuthorized.message'/></p>
                <div className='item-cta-wrapper'>
                  <Link to={NamedRoutes.home} className={classes.buttonLink}>
                    <FormattedMessage id='UnAuthorize.link.ItemCta'/></Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fade>
  )
}

export default NotAuthorize
