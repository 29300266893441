import React, { createContext } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import CompanyServices from './Services/Consumers/CompanyServices'
import { useKeycloak } from '@react-keycloak/web'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from './routes'
import { useIntl } from 'react-intl'

const PaymentOrderContext = createContext({})


const PaymentOrderProvider = ({ claimId, paymentDetails, PaymentOrderColumns, isOld, isPaymentClaim, isDownPayment, forceInit, children }) => {
  const { keycloak } = useKeycloak()
  let { id } = useParams()
  const intl = useIntl()

  const [paymentOrderIsInitiated, setPaymentOrderIsInitiated] = React.useState(false)
  const [paymentOrderSteps, setPaymentOrderSteps] = React.useState([])
  const [currentPaymentOrderStep, setCurrentPaymentOrderStep] = React.useState(0)
  const [paymentOrderData, setPaymentOrderData] = React.useState({})
  const [paymentOrderDataLabels, setPaymentOrderDataLabels] = React.useState({})

  const [paymentOrderLoading, setPaymentOrderLoading] = React.useState(false)
  const [paymentOrderFailure, setPaymentOrderFailure] = React.useState(false)
  const [paymentOrderFailureMessage, setPaymentOrderFailureMessage] = React.useState('')
  const [paymentOrderSuccess, setPaymentOrderSuccess] = React.useState(false)

  const [paymentOrderDeleting, setPaymentOrderDeleting] = React.useState(false)
  const [paymentOrderDeleteFailure, setPaymentOrderDeleteFailure] = React.useState(false)
  const [paymentOrderDeleteSuccess, setPaymentOrderDeleteSuccess] = React.useState(false)

  const [isAcceptTerms, setIsAcceptTerms] = React.useState(false)
  const [isAcceptValidTime, setIsAcceptValidTime] = React.useState(false)
  const [paymentTransactionData, setPaymentTransactionData] = React.useState({})
  const [paymentTransactionFailure, setPaymentTransactionFailure] = React.useState(false)

  const [termsConditionsContent, setTermsConditionsContent] = React.useState({})
  const [termsConditionsContentLoading, setTermsConditionsContentLoading] = React.useState(false)
  const [termsConditionsContentFailure, setTermsConditionsContentFailure] = React.useState(false)

  const handleAcceptTermsChange = (event) => {
    setIsAcceptTerms(event.target.checked)
  }

  const handleAcceptValidTimeChange = (event) => {
    setIsAcceptValidTime(event.target.checked)
  }

  React.useEffect(() => {
      if (!!isOld) {
        setPaymentOrderData(paymentDetails)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOld])

  React.useEffect(() => {
      if ((!!isPaymentClaim || !!isDownPayment) && !!forceInit) {
        setPaymentOrderData(paymentDetails)
        initPaymentOrderData(false, false, true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPaymentClaim, isDownPayment])

  const deletePaymentOrderData = (paymentOrderId, closeDeleteDialog, closePaymentDialog, removePaymentRequest, setCanCreateRequest, shouldRefreshPayments) => {
    if (!!keycloak?.authenticated) {
      setPaymentOrderDeleteFailure(false)
      setPaymentOrderDeleteSuccess(false)
      setPaymentOrderDeleting(true)
      CompanyServices.deletePaymentOrder(id, paymentOrderId, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setPaymentOrderDeleting, setPaymentOrderDeleteFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setPaymentOrderDeleteFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setPaymentOrderDeleteFailure(false)
            setPaymentOrderDeleteSuccess(true)
            setTimeout(() => {
              closeDeleteDialog()
              if (!!setCanCreateRequest && typeof setCanCreateRequest === 'function') {
                setCanCreateRequest(true)
              }
              setTimeout(() => {
                closePaymentDialog()
                setPaymentOrderData({})
                initPaymentOrderData(true)
                setTimeout(() => {
                  removePaymentRequest(paymentOrderId, shouldRefreshPayments, isPaymentClaim)
                }, 500)
              }, 500)
            }, 1500)
          }
        }
      }).then(() => {
        setPaymentOrderDeleting(false)
      })
    }
  }

  const [requestValidityLoading, setRequestValidityLoading] = React.useState(false)
  const [requestValidityCode, setRequestValidityCode] = React.useState(0)
  const [requestValidityNewData, setRequestValidityNewData] = React.useState({})

  const handleRequestValidityDialogClose = (updatePaymentRequestsAfterValidity) => {
    updatePaymentRequestsAfterValidity(requestValidityCode, paymentOrderData['request_number'], requestValidityNewData)
  }
  const checkRequestValidity = (handleContinue, handleErrors) => {
    if (!!keycloak?.authenticated) {
      setRequestValidityLoading(true)
      CompanyServices.checkPaymentOrderValidity(id, paymentOrderData['request_number'], { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status === 200) || result.status === 200) {
          setRequestValidityCode(1)//Continue
          initPaymentOrderData(false, false, true)
          handleContinue()
        } else if ((!!result.response && result.response.status === 201) || result.status === 201) {
          setRequestValidityCode(2)//Paid
          handleErrors()
        } else if ((!!result.response && result.response.status === 409) || result.status === 409) {
          setRequestValidityCode(3)//Failed
          handleErrors()
        } else {
          setRequestValidityCode(4)//Error
          handleErrors()
        }
        if (!!result && !!result.data && !!result.data.data) {
          let request = result.data.data
          request['taxpayerName'] = <NavLink
            to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.taxpayer_name}</NavLink>
          request['companyName'] = request.company.name ? <NavLink
            to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.name}</NavLink> : 'N/A'
          request['tax_id'] = request.company.tax_id
          setRequestValidityNewData(request)
        } else if (!!result.response && !!result.response.data && !!result.response.data.data) {
          let request = result.response.data.data
          request['taxpayerName'] = <NavLink
            to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.taxpayer_name}</NavLink>
          request['companyName'] = request.company.name ? <NavLink
            to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.name}</NavLink> : 'N/A'
          request['tax_id'] = request.company.tax_id
          setRequestValidityNewData(request)
        }
      }).then(() => {
        setRequestValidityLoading(false)
      })
    }
  }

  const getTermsConditionsContent = () => {
    if (!!keycloak?.authenticated) {
      setTermsConditionsContent({})
      setTermsConditionsContentLoading(true)
      setTermsConditionsContentFailure(false)
      CompanyServices.getPageContent('payment-terms-and-conditions', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setTermsConditionsContentFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setTermsConditionsContent(result.data.data)
          } else {
            setTermsConditionsContentFailure(true)
          }
        }
      }).then(() => {
        setTermsConditionsContentLoading(false)
      })
    }
  }

  const [requestExpirationTime, setRequestExpirationTime] = React.useState({})
  const [requestExpirationTimeLoading, setRequestExpirationTimeLoading] = React.useState(false)
  const [requestExpirationTimeFailure, setRequestExpirationTimeFailure] = React.useState(false)
  const getRequestExpirationTime = () => {
    if (!!keycloak?.authenticated) {
      setTermsConditionsContent({})
      setRequestExpirationTimeLoading(true)
      setRequestExpirationTimeFailure(false)
      CompanyServices.getSettingContent('payment_request_expiration_lifespan', { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setRequestExpirationTimeFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setRequestExpirationTime(result.data.data)
          } else {
            setRequestExpirationTimeFailure(true)
          }
        }
      }).then(() => {
        setRequestExpirationTimeLoading(false)
      })
    }
  }

  const handleNewOrderNext = (setCanCreateRequest, partialValues) => {
    if (!!keycloak?.authenticated) {
      setPaymentOrderFailure(false)
      setPaymentTransactionData({})
      setPaymentOrderDeleteFailure(false)
      setPaymentOrderDeleteSuccess(false)
      setPaymentOrderDeleting(false)
      setPaymentTransactionFailure(false)
      setPaymentOrderLoading(true)
      let partialData = {}
      if (!isPaymentClaim && !!partialValues && !!Object.keys(partialValues).length) {
        partialData['allow_partial_payment'] = partialValues.payPartial
        if (!!partialValues.payPartial) {
          partialData['partial_payment_amount'] = partialValues.amount.toString()
        }
      }
      if (currentPaymentOrderStep === 0) {
        CompanyServices.createPaymentOrder(id, claimId, isPaymentClaim, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setPaymentOrderLoading, setPaymentOrderFailure, partialData).then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            setPaymentOrderFailure(true)
            let message = intl.formatMessage({ id: 'paymentOrderFailure.FormErrorAlert' })
            if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
              if (result.response.data.status.errors) {
                message = result.response.data.status.errors
              } else if (result.response.data.status.message) {
                message = result.response.data.status.message
              }
            }
            setPaymentOrderFailureMessage(message)
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              let order = result.data.data
              order['companyName'] = order.company.name || 'N/A'
              order['taxpayerName'] = order.company.taxpayer_name
              order['tax_id'] = order.company.tax_id
              setPaymentOrderData(order)
              let newSteps = [...paymentOrderSteps]
              newSteps[currentPaymentOrderStep].isSubmitted = true
              setPaymentOrderSteps(newSteps)
              setCurrentPaymentOrderStep(currentPaymentOrderStep + 1)
              if (!!setCanCreateRequest) {
                setCanCreateRequest(false)
              }
            }
          }
        }).then(() => {
          setPaymentOrderLoading(false)
        })

      } else if (currentPaymentOrderStep === 1) {
        //payment action
        //
        CompanyServices.createTransaction(id, paymentOrderData.request_number, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }, setPaymentOrderLoading, setPaymentOrderFailure).then((result) => {
          if ((!!result.response && result.response.status === 422) || result.status === 422) {
            setPaymentTransactionFailure(true)
            setPaymentOrderLoading(false)
          } else if ((!!result.response && result.response.status !== 200) || !result) {
            setPaymentOrderFailure(true)
            setPaymentOrderLoading(false)
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              setPaymentTransactionData(result.data.data)
            } else {
              setPaymentOrderLoading(false)
            }
          }
        })
      }
    }
  }
  const resetPaymentOrderStatus = () => {
    setPaymentOrderFailure(false)
    setPaymentOrderFailureMessage('')
  }

  React.useEffect(() => {
    if (!!paymentTransactionData && !!Object.keys(paymentTransactionData).length) {
      setTimeout(() => {
        document.getElementById('etax_checkout').submit()
      }, 500)
    }
  }, [paymentTransactionData])

  const initPaymentOrderData = (forceInit, asCreatedBefore, isOld) => {
    if (!paymentOrderIsInitiated || !!forceInit || (!!asCreatedBefore && currentPaymentOrderStep > 0) || isOld) {
      setPaymentOrderLoading(true)
      setPaymentOrderSuccess(false)
      setPaymentOrderFailure(false)
      let PaymentOrderSteps = [
        {
          id: 0,
          canAccess: true,
          isSubmitted: false
        },
        {
          id: 1,
          canAccess: false,
          isSubmitted: false
        }
      ]
      if (!!asCreatedBefore || !!isOld) {
        PaymentOrderSteps[0].canAccess = true
        PaymentOrderSteps[0].isSubmitted = true
        PaymentOrderSteps[1].canAccess = true
        setCurrentPaymentOrderStep(1)
      } else {
        setCurrentPaymentOrderStep(0)
      }
      setPaymentOrderLoading(false)
      setPaymentOrderSteps(PaymentOrderSteps)
      setPaymentOrderDataLabels(PaymentOrderColumns)
      setPaymentOrderIsInitiated(true)
    }
  }

  return (
    <PaymentOrderContext.Provider value={{
      paymentOrderSteps: paymentOrderSteps,
      currentPaymentOrderStep: currentPaymentOrderStep,
      paymentDetails: paymentDetails,
      PaymentOrderColumns: PaymentOrderColumns,
      paymentOrderLoading: paymentOrderLoading,
      paymentOrderFailure: paymentOrderFailure,
      paymentOrderFailureMessage: paymentOrderFailureMessage,
      paymentOrderSuccess: paymentOrderSuccess,
      initPaymentOrderData,

      requestValidityLoading: requestValidityLoading,
      requestValidityCode: requestValidityCode,
      requestValidityNewData: requestValidityNewData,
      checkRequestValidity,
      handleRequestValidityDialogClose,

      paymentOrderData: paymentOrderData,
      paymentOrderDataLabels: paymentOrderDataLabels,
      handleNewOrderNext,
      resetPaymentOrderStatus,

      paymentOrderDeleting: paymentOrderDeleting,
      paymentOrderDeleteFailure: paymentOrderDeleteFailure,
      paymentOrderDeleteSuccess: paymentOrderDeleteSuccess,
      deletePaymentOrderData,

      isAcceptTerms: isAcceptTerms,
      isAcceptValidTime: isAcceptValidTime,
      handleAcceptTermsChange,
      handleAcceptValidTimeChange,

      paymentOrderIsInitiated: paymentOrderIsInitiated,
      paymentTransactionData: paymentTransactionData,
      paymentTransactionFailure: paymentTransactionFailure,

      termsConditionsContent: termsConditionsContent,
      termsConditionsContentLoading: termsConditionsContentLoading,
      termsConditionsContentFailure: termsConditionsContentFailure,
      getTermsConditionsContent,

      requestExpirationTime: requestExpirationTime,
      requestExpirationTimeLoading: requestExpirationTimeLoading,
      requestExpirationTimeFailure: requestExpirationTimeFailure,
      getRequestExpirationTime
    }}
    >
      {children}
    </PaymentOrderContext.Provider>
  )
}


const PaymentOrderConsumer = PaymentOrderContext.Consumer

export { PaymentOrderProvider, PaymentOrderConsumer }
