import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { CompaniesNotificationsFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { lg } from '../../../../utils/ResponsiveUtility'

const Filter = () => {
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null)
  const [selectedDateTo, setSelectedDateTo] = React.useState(null)
  const classes = useStyles()
  const intl = useIntl()
  const notificationsFilterForm = useFormik({
    initialValues: {
      dateFrom: '',
      dateTo: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CompaniesNotificationsFilterFormValidation,
    onSubmit: ((values, actions) => {
      console.log(values, actions)
    })
  })

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date)
  }
  const handleDateToChange = (date) => {
    setSelectedDateTo(date)
  }

  const [expanded, setExpanded] = React.useState(false)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper + ' withBorder'}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={notificationsFilterForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper}>

            <KeyboardDatePicker
              fullWidth
              className={classes.dateFormControl}
              disableToolbar
              variant="outlined"
              format="MM/dd/yyyy"
              margin='dense'
              id="dateFrom"
              name='dateFrom'
              label={intl.formatMessage({ id: 'Fields.placeholder.dateFrom' })}
              value={selectedDateFrom}
              onChange={handleDateFromChange}
              KeyboardButtonProps={{
                'aria-label': 'date from'
              }}
            />

            <KeyboardDatePicker
              fullWidth
              className={classes.dateFormControl}
              disableToolbar
              variant="outlined"
              format="MM/dd/yyyy"
              margin='dense'
              id="dateTo"
              name='dateTo'
              label={intl.formatMessage({ id: 'Fields.placeholder.dateTo' })}
              value={selectedDateTo}
              onChange={handleDateToChange}
              KeyboardButtonProps={{
                'aria-label': 'date to'
              }}
            />

            <button type="submit"
              // disabled={companiesNotificationsFilterForm.isSubmitting || !notificationsFilterForm.isValid}
                    disabled={!notificationsFilterForm.isValid}
                    className={classes.submitButton}>
              <FormattedMessage id='Filter.button.submit'/>
            </button>
            {/*{notificationsFilterForm.isSubmitting && <FormLoader loading={true}/>}*/}
          </form>
        </MuiPickersUtilsProvider>
      </AccordionDetails>
    </Accordion>
  )
}

export default Filter
