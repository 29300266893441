import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    //   margin: 0,
    //   maxHeight: '100%'
    // }
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative'
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  },
  PaymentOrderStepMessage: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.7,
    '&.center': {
      textAlign: 'center'
    }
  },
  termsConditionsWrapper: {
    marginTop: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(20)
    },
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      textDecoration: 'underline',
      marginLeft: theme.spacing(0.75),
      padding: 0
    }
  },
  pdfContent: {
    visibility: 'hidden',
    position: 'fixed',
    width: 960,
    top: 0,
    flip: false,
    left: 0,
    zIndex: -1,
    padding: theme.spacing(5),
    '& .first-row': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    '& .dateWrapper': {
      marginTop: theme.spacing(3)
    },
    '& .itemWrapper': {
      display: 'flex',
      marginBottom: theme.spacing(1.5)
    },
    '& .itemLabel': {
      width: 200,
      backgroundColor: FilterBackground,
      padding: theme.spacing(0, 2, 1.5),
      margin: 0,
      border: `1px solid ${FilterBackground}`
    },
    '& .itemValue': {
      minWidth: 250,
      border: `1px solid ${fade(theme.palette.common.black, 0.4)}`,
      padding: theme.spacing(0, 2, 1.5),
      margin: 0,
      flex: '1 1 auto'
      // display: 'flex',
      // alignItems: 'center',
    },
    '& .data-row': {
      marginTop: theme.spacing(6)
    },
    '& .hint': {
      color: theme.palette.error.main,
      fontSize: theme.typography.pxToRem(15),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      '& .icon': {
        marginRight: theme.spacing(0.5),
      },
      '&>div': {
        display: 'flex',
        width: '100%',
        lineHeight: 1.7,
        '&:not(:last-child)': {
          marginBottom: theme.spacing(0.75),
        }
      }
    },
    '& .last-row': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      display: 'flex',
      flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      '& h5': {
        fontSize: theme.typography.pxToRem(20),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary
      }
    }
  },
  receiptHint:{
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& .icon': {
      marginRight: theme.spacing(0.75),
    },
    '&>div': {
      display: 'flex',
      width: '100%',
      lineHeight: 1.7,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(0.75),
      }
    }
  }
}))
