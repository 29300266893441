import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormHelperText,
  AccordionSummary, AccordionDetails, Accordion
} from '@material-ui/core'
import { CompaniesPaymentsFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { lg } from '../../../../utils/ResponsiveUtility'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LanguageConsumer } from '../../../../LanguageContext'

const PaymentFilter = ({ paymentsFiltersData, handleSubmitPaymentsFilter }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null)
  const [selectedDateTo, setSelectedDateTo] = React.useState(null)
  const filterForm = useFormik({
    initialValues: {
      ClaimtStatus: '',
      TaxPaymentType: '',
      TaxPaymentItemType: '',
      RegistrationPaymentType: '',
      Recent: '',
      QuarterNumber: '',
      TaxationPeriod_From: '',
      TaxationPeriod_To: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CompaniesPaymentsFilterFormValidation,
    onSubmit: ((values, actions) => {
      handleSubmitPaymentsFilter(values, actions)
    })
  })

  const [paymentItems, setPaymentItems] = React.useState([])
  const handleSelectFilterChange = (event, fieldName) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue(fieldName, event.target.value.toString())
    if ('TaxPaymentType' === fieldName) {
      setPaymentItems([])
      filterForm.setFieldValue('TaxPaymentItemType', '')
      switch (parseInt(event.target.value)) {
        case 1:
          !!Object.keys(paymentsFiltersData).length &&
          !!paymentsFiltersData['payment_item_types.income'].length &&
          setPaymentItems(paymentsFiltersData['payment_item_types.income'])
          break
        case 2:
          !!Object.keys(paymentsFiltersData).length &&
          !!paymentsFiltersData['payment_item_types.withholding'].length &&
          setPaymentItems(paymentsFiltersData['payment_item_types.withholding'])
          break
        case 3:
          !!Object.keys(paymentsFiltersData).length &&
          !!paymentsFiltersData['payment_item_types.vat'].length &&
          setPaymentItems(paymentsFiltersData['payment_item_types.vat'])
          break
        default:
          setPaymentItems([])
      }

    }
  }

  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date)
    filterForm.setFieldValue('TaxationPeriod_From', date || '')
  }
  const handleDateToChange = (date) => {
    setSelectedDateTo(date)
    filterForm.setFieldValue('TaxationPeriod_To', date || '')
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <LanguageConsumer>
          {({ locale }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
              <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper + ' center'}>
                <Grid container justify={'center'} wrap={'wrap'} className={classes.filtersFieldsWrapper}>
                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="ClaimStatus-label"><FormattedMessage id='PaymentHeader.paymentStatus'/></InputLabel>
                    <Select
                      fullWidth
                      labelId="ClaimStatus-label"
                      id="ClaimtStatus"
                      name="ClaimtStatus"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      value={filterForm.values.ClaimtStatus}
                      label={intl.formatMessage({ id: 'PaymentHeader.paymentStatus' })}
                      onChange={(event) => handleSelectFilterChange(event, 'ClaimtStatus')}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.ClaimtStatus && filterForm.touched.ClaimtStatus !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id='PaymentHeader.paymentStatus'/></MenuItem>
                      {!!Object.keys(paymentsFiltersData).length &&
                      paymentsFiltersData.coretaxation_payment_status.length &&
                      paymentsFiltersData.coretaxation_payment_status.map((item) => (
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.ClaimtStatus && filterForm.touched.ClaimtStatus && (
                      <FormHelperText error={true}>{filterForm.errors.ClaimtStatus}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="TaxPaymentType-label"><FormattedMessage id='PaymentHeader.climeType'/></InputLabel>
                    <Select
                      fullWidth
                      labelId="TaxPaymentType-label"
                      id="TaxPaymentType"
                      name="TaxPaymentType"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      value={filterForm.values.TaxPaymentType}
                      label={intl.formatMessage({ id: 'PaymentHeader.climeType' })}
                      onChange={(event) => handleSelectFilterChange(event, 'TaxPaymentType')}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.TaxPaymentType && filterForm.touched.TaxPaymentType !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id='PaymentHeader.climeType'/></MenuItem>
                      {!!Object.keys(paymentsFiltersData).length &&
                      paymentsFiltersData.payment_tax_types.length &&
                      paymentsFiltersData.payment_tax_types.map((item) => (
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.TaxPaymentType && filterForm.touched.TaxPaymentType && (
                      <FormHelperText error={true}>{filterForm.errors.TaxPaymentType}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="TaxPaymentItemType-label"><FormattedMessage
                      id='PaymentHeader.paymentItem'/></InputLabel>
                    <Select
                      fullWidth
                      labelId="TaxPaymentItemType-label"
                      id="TaxPaymentItemType"
                      name="TaxPaymentItemType"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      value={filterForm.values.TaxPaymentItemType}
                      label={intl.formatMessage({ id: 'PaymentHeader.paymentItem' })}
                      onChange={(event) => handleSelectFilterChange(event, 'TaxPaymentItemType')}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.TaxPaymentItemType && filterForm.touched.TaxPaymentItemType !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id='PaymentHeader.paymentItem'/></MenuItem>
                      {!!Object.keys(paymentsFiltersData).length &&
                      paymentItems.length &&
                      paymentItems.map((item) => (
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.TaxPaymentItemType && filterForm.touched.TaxPaymentItemType && (
                      <FormHelperText error={true}>{filterForm.errors.TaxPaymentItemType}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="RegistrationPaymentType-label"><FormattedMessage
                      id='PaymentHeader.registrationPaymentType'/></InputLabel>
                    <Select
                      fullWidth
                      labelId="RegistrationPaymentType-label"
                      id="RegistrationPaymentType"
                      name="RegistrationPaymentType"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      value={filterForm.values.RegistrationPaymentType}
                      label={intl.formatMessage({ id: 'PaymentHeader.type' })}
                      onChange={(event) => handleSelectFilterChange(event, 'RegistrationPaymentType')}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.RegistrationPaymentType && filterForm.touched.RegistrationPaymentType !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id='PaymentHeader.registrationPaymentType'/></MenuItem>
                      {!!Object.keys(paymentsFiltersData).length &&
                      paymentsFiltersData.registration_payment_types.length &&
                      paymentsFiltersData.registration_payment_types.map((item) => (
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.RegistrationPaymentType && filterForm.touched.RegistrationPaymentType && (
                      <FormHelperText error={true}>{filterForm.errors.RegistrationPaymentType}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id="filterForm.Recent"
                      type={'number'}
                      margin="dense"
                      label={<FormattedMessage id='PaymentHeader.Recent'/>}
                      variant="outlined"
                      name='Recent'
                      inputProps={{
                        min: 0
                      }}
                      onChange={filterForm.handleChange}
                      onBlur={filterForm.handleBlur}
                      value={filterForm.values.Recent}
                      helperText={filterForm.errors.Recent && filterForm.touched.Recent && filterForm.errors.Recent}
                      error={filterForm.errors.Recent && filterForm.touched.Recent !== undefined}
                    />
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="QuarterNumber-label"><FormattedMessage
                      id="PaymentHeader.taxPeriodQuarterَ"/></InputLabel>
                    <Select
                      fullWidth
                      labelId="QuarterNumber-label"
                      id="QuarterNumber"
                      name="QuarterNumber"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      value={filterForm.values.QuarterNumber}
                      label={intl.formatMessage({ id: 'PaymentHeader.taxPeriodQuarterَ' })}
                      onChange={(event) => handleSelectFilterChange(event, 'QuarterNumber')}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.QuarterNumber && filterForm.touched.QuarterNumber !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id="PaymentHeader.taxPeriodQuarterَ"/></MenuItem>
                      {!!Object.keys(paymentsFiltersData).length &&
                      paymentsFiltersData.quarter_numbers.length &&
                      paymentsFiltersData.quarter_numbers.map((item) => (
                        <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.QuarterNumber && filterForm.touched.QuarterNumber && (
                      <FormHelperText error={true}>{filterForm.errors.QuarterNumber}</FormHelperText>
                    )}
                  </FormControl>

                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="TaxationPeriod_From"
                    name='TaxationPeriod_From'
                    label={intl.formatMessage({ id: 'PaymentHeader.taxPeriodFrom' })}
                    value={selectedDateFrom}
                    onChange={handleDateFromChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.TaxationPeriod_From && filterForm.touched.TaxationPeriod_From &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.TaxationPeriod_From && filterForm.touched.TaxationPeriod_From !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'date from'
                    }}
                  />

                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="TaxationPeriod_To"
                    name='TaxationPeriod_To'
                    label={intl.formatMessage({ id: 'PaymentHeader.taxPeriodTo' })}
                    value={selectedDateTo}
                    onChange={handleDateToChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.TaxationPeriod_To && filterForm.touched.TaxationPeriod_To &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.TaxationPeriod_To && filterForm.touched.TaxationPeriod_To !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'date to'
                    }}
                  />
                </Grid>
                <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                        className={classes.submitButton + ' noMargin'}>
                  <FormattedMessage id='Filter.button.submit'/>
                </button>
              </form>
            </MuiPickersUtilsProvider>
          )}
        </LanguageConsumer>
      </AccordionDetails>
    </Accordion>
  )
}

export default PaymentFilter
