import React from 'react'
import { Container } from '@material-ui/core'
import { useStyles } from './Styles'
import WorkspaceTable from '../../Table'
import { InsuranceServicesConsumer } from '../../../../InsuranceServicesContext'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import InsuranceOrganizationBranchesFilter from './InsuranceOrganizationBranchesFilter'
import UnAuthorize from '../../../UnAuthorize'


const InsuranceOrganizationBranches = () => {
  const classes = useStyles()
  return (
    <>
      <Container maxWidth="lg" className={classes.CompaniesContentContainer}>
        <InsuranceServicesConsumer>
          {({
              insuranceOrganizationsLoading, insuranceOrganizationBranchesFiltered, InsuranceOrganizationBranchesHeaderColumns,
              insuranceOrganizationsFailure, insuranceOrganizationsFailure403, getInsuranceOrganizationBranches, handleSubmitFilters
            }) => (
            <>
              {!insuranceOrganizationsFailure403 && <InsuranceOrganizationBranchesFilter submitForm={handleSubmitFilters}/>}
              {!!insuranceOrganizationsLoading ? <SearchLoader loading={true}/> :
                !!insuranceOrganizationsFailure ?
                  (!!insuranceOrganizationsFailure403 ? <UnAuthorize/> :
                    <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>)
                  :
                  <>
                    {(insuranceOrganizationBranchesFiltered === undefined || insuranceOrganizationBranchesFiltered.length === 0) ?
                      <EmptyResult/> :
                      <>
                        <WorkspaceTable
                          loadRows={getInsuranceOrganizationBranches}
                          hasMoreRows={false}
                          rows={insuranceOrganizationBranchesFiltered}
                          columns={InsuranceOrganizationBranchesHeaderColumns}
                          size={'medium'}
                        />
                      </>
                    }
                  </>
              }
            </>
          )}
        </InsuranceServicesConsumer>
      </Container>
    </>
  )
}

export default InsuranceOrganizationBranches
