import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative'
  },
  AdditionalInfoRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      width: 340,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    // '& .MuiTextField-root': {
    //   [theme.breakpoints.up('sm')]: {
    //     maxWidth: 228
    //   }
    // },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    '& .react-tel-input': {
      fontFamily: 'inherit'
    },
    '& .react-tel-input .form-control': {
      width: 340,
      maxWidth: '100%',
      paddingTop: theme.spacing(1.375),
      paddingBottom: theme.spacing(1.125),
      '&[disabled]': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',
      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  AdditionalInfoPhoneRow: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('sm')]: {
      width: 340
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  changePhoneButton: {
    color: theme.palette.primary.light,
    background: 'transparent',
    border: 'none',
    marginLeft: theme.spacing(2)
  },
  ChangePhoneVerifyWrapper: {
    whiteSpace: 'pre-line',
    textAlign: 'center'
  },
  ChangePhoneVerify: {
    display: 'flex',
    // alignItems: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: theme.spacing(1.5),
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  },
  SubmitRow: {
    padding: theme.spacing(4, 0),
    textAlign: 'center'
  },
  AdditionalInfoSubmit: {
    color: theme.palette.common.white,
    background: theme.palette.primary.light,
    border: 'none',
    padding: theme.spacing(1, 6),
    '&:disabled': {
      pointerEvents: 'none',
      opacity: 0.7
    },
    '&:hover, &:focus': {
      opacity: 0.7
    }
  },
  ChangePhoneDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  ChangePhoneDialogPaper: {
    borderRadius: 0,
    overflow: 'visible'
  },
  ChangePhoneDialogContent: {
    padding: '0 !important',
    position: 'relative',
    overflow: 'visible'
  },
  ChangePhoneDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  ChangePhoneDialogFooter: {
    padding: theme.spacing(2)
  }
}))
