import React from 'react'
import { Collapse, Container } from '@material-ui/core'
import DashboardActionLinks from '../DashboardActionLinks'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import CompaniesContent from '../Companies'
import { SearchLoader } from '../../Loader/SearchLoader'
import InActiveUserContent from '../../InActiveUserContent'
import DashboardUserMessage from '../DashboardUserMessage'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './Styles'
import Configuration from '../../../Services/Api/Configuration'

const DashboardContent = () => {
  const classes = useStyles()
  return (
    <>
      <AuthenticatedUserConsumer>
        {({
            userDataLoading, isUserActive, userData, getUserCompanies, userCompanies, getFiltersData,
            resetSelectedCompany, shouldRemoveCompanies, userCompaniesLoading, isUserCompaniesRetrieved,
            isCompaniesFiltered, getAllCompaniesContacts, verifyCompanyContactEmail,
            resetVerifyCompanyContactStatus, ridToSelect, companiesContactsLoading, checkUserPopupAfterLogin,
            verifiedCompaniesContacts, verifiedCompaniesContactsWithoutTaxId
          }) => (
          <>
            <Container maxWidth="lg" className={classes.TitleContainer}>
              <h1><FormattedMessage id='workspaceMenu.link.item1'/></h1>
            </Container>
            {!!userDataLoading && <SearchLoader loading={true}/>}
            {(!!Object.keys(userData).length && !!isUserActive) ?
              <>
                <DashboardUserMessage checkUserPopupAfterLogin={checkUserPopupAfterLogin}/>

                <Collapse
                  in={(!!isUserCompaniesRetrieved && !userCompaniesLoading && (userCompanies === undefined || userCompanies.length === 0)) || !Configuration.HIDE_INCENTIVES}
                  timeout="auto" unmountOnExit>
                  {((!!isUserCompaniesRetrieved && !userCompaniesLoading && (userCompanies === undefined || userCompanies.length === 0)) || !Configuration.HIDE_INCENTIVES) &&
                  <DashboardActionLinks
                    getAllCompaniesContacts={getAllCompaniesContacts}
                    verifyCompanyContactEmail={verifyCompanyContactEmail}
                    resetVerifyCompanyContactStatus={resetVerifyCompanyContactStatus}
                    ridToSelect={ridToSelect}
                    getFiltersData={getFiltersData}
                    companiesContactsLoading={companiesContactsLoading}
                    verifiedCompaniesContacts={verifiedCompaniesContacts}
                    verifiedCompaniesContactsWithoutTaxId={verifiedCompaniesContactsWithoutTaxId}/>
                  }
                </Collapse>
                <CompaniesContent getUserCompanies={getUserCompanies}
                                  userCompanies={userCompanies}
                                  shouldRemoveCompanies={shouldRemoveCompanies}
                                  isCompaniesFiltered={isCompaniesFiltered}
                                  resetSelectedCompany={resetSelectedCompany}/>
              </>
              : (!userDataLoading && <InActiveUserContent/>)
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </>
  )
}

export default DashboardContent
