import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TableCell, TableRow, Collapse, Checkbox } from '@material-ui/core'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { useStyles } from './Styles'
import { useFormik } from 'formik'
import { partialAmountFormValidation } from '../../../../../utils/validationSchema'
import { LanguageConsumer } from '../../../../../LanguageContext'

const BalancePaymentRow = ({ row, columns, allowPay, calculatedBalance, handlePaymentToPay, updatePaymentToPay, isForeign }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [open, setOpen] = React.useState(null)
  const partialAmountForm = useFormik({
    initialValues: {
      amount: row.max_amount,
      minimum: row.min_amount,
      maximum: row.max_amount,
      payPartial: false
    },
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: partialAmountFormValidation,
    onSubmit: ((values, actions) => {
      // submitForm(values, actions)
    })
  })
  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500
    }
  }))(Tooltip)

  React.useEffect(() => {
      updatePaymentToPay(row.claim_number, partialAmountForm.values, partialAmountForm.isValid)
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [partialAmountForm.isValid, partialAmountForm.values])
  return (
    <React.Fragment>
      <TableRow
        className={classes.dataRow + ((!isForeign && !!allowPay && (calculatedBalance >= 0 || !!open)) ? ' clickable' : '')}
        role="checkbox"
        tabIndex={-1}
        onClick={(event) => {
          if (!isForeign && !!allowPay && (calculatedBalance >= 0 || !!open)) {
            handlePaymentToPay(row.claim_number, row, !open)
            setOpen(!open)
          }
        }}>
        {!!allowPay &&
        <TableCell padding="checkbox">
          <Checkbox
            checked={open || false}
            disabled={(calculatedBalance < 0 && !open) || !!isForeign}
            // onChange={(event) => {
            //   handlePaymentToPay(row.claim_number, row, !!event.target.checked)
            //   setOpen(!!event.target.checked)
            // }}
            color="primary"
          />
        </TableCell>
        }
        {columns.map((column, columnIndex) => (
          <TableCell className={classes.dataCell}
                     key={(column.id || columnIndex)}
                     align='center'>
            <LanguageConsumer>
              {({ locale }) => (
                <>
                  {(['payment_item'].includes(column.id)) ?
                    <span className={classes.spanLTR}>{row[column.id]}</span>
                    :
                    ((['claim_amount', 'payment_claim_amount'].includes(column.id)) ?
                      (('claim_amount' === column.id && !!row['allow_partial_payment'] && !!row['due_amount'] && row['due_amount'] !== row[column.id]) ?
                        <div className={classes.partialWrapper}>
                          <div className={classes.partialAmountsWrapper}>
                            <div className={classes.partialAmount}><FormattedNumber value={row[column.id]}/>
                            </div>
                            <div className={classes.partialDueAmount}><FormattedNumber
                              value={row['due_amount']}/></div>
                          </div>
                          <CustomTooltip title={intl.formatMessage({ id: 'partialPayment.Amount.Hint' },
                            {
                              amount: <FormattedNumber value={row[column.id]}/>,
                              dueAmount: <FormattedNumber value={row['due_amount']}/>,
                              currency: <FormattedMessage
                                id={`partialPayment.Amount.Currency.${row?.currency?.key?.toLowerCase() || ''}`}/>
                            })}
                                         PopperProps={{
                                           disablePortal: true
                                         }}
                                         arrow placement="top">
                            <HelpIcon/>
                          </CustomTooltip>
                        </div>
                        :
                        <FormattedNumber value={row[column.id]}/>)
                      :
                      ((['tax_period_from', 'tax_period_to', 'claim_expire_date',
                        'payment_tax_period_from', 'payment_tax_period_to', 'payment_claim_expire_date', 'expire_date',
                        'create_at', 'created_at'].includes(column.id) && row[column.id]) ?
                        <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                format="Do MMMM YYYY h:mm a">{row[column.id]}</Moment>
                        : row[column.id] || 'N/A'))}
                </>
              )}
            </LanguageConsumer>
          </TableCell>
        ))}
      </TableRow>
      {!!allowPay && !!row.allow_partial_payment &&
      <TableRow className={classes.dataRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 1}>
          <Collapse in={!!open} timeout="auto" unmountOnExit>
            <Box p={1}>
              <form onSubmit={partialAmountForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper}>
                <div className={classes.AdditionalInfoRow}>
                  <InputLabel id="paymentRequest.partial.amount"><FormattedMessage
                    id='Fields.label.payPartialSwitch'/></InputLabel>
                  <div className={classes.usePersonalInfoWrapper}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={partialAmountForm.values.payPartial}
                          onChange={(event) => {
                            partialAmountForm.setFieldValue('payPartial', !!event.target.checked)
                          }}
                          name="payPartial"
                          color="primary"
                          onBlur={partialAmountForm.handleBlur}
                          error={partialAmountForm.errors.payPartial && partialAmountForm.touched.payPartial !== undefined}
                        />}
                      label={intl.formatMessage({ id: `partialAmountForm.payPartialSwitch.${!!partialAmountForm.values.payPartial}` })}
                      labelPlacement="end"
                    />
                  </div>
                </div>
                <Collapse in={!!partialAmountForm.values.payPartial} timeout="auto" unmountOnExit>
                  <div className={classes.AdditionalInfoRow + ' column'}>
                    <InputLabel id="partialAmountForm.partialAmount"><FormattedMessage
                      id='Fields.label.partialAmount'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'partialAmountForm.placeholder.partialAmount' })}
                        id="partialAmountForm.partialAmount"
                        type={'number'}
                        margin="dense"
                        variant="outlined"
                        name='amount'
                        inputProps={{
                          min: row.min_amount,
                          max: row.max_amount
                        }}
                        onChange={partialAmountForm.handleChange}
                        onBlur={partialAmountForm.handleBlur}
                        value={partialAmountForm.values.amount}
                        helperText={((partialAmountForm.errors.amount && partialAmountForm.touched.amount) || partialAmountForm.values.amount > row.max_amount) && partialAmountForm.errors.amount}
                        error={((partialAmountForm.errors.amount && partialAmountForm.touched.amount !== undefined) || partialAmountForm.values.amount > row.max_amount)}
                      />
                      <FormHelperText><FormattedMessage id='partialAmount.Hint' values={{
                        min: <FormattedNumber value={row.min_amount}/>,
                        max: <FormattedNumber value={row.max_amount}/>
                      }}/></FormHelperText>
                    </FormControl>
                  </div>
                </Collapse>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      }
    </React.Fragment>

  )
}

export default BalancePaymentRow