import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(10),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 1.5)
    }
  }
}))
