import { filterData, log } from '../../utils/helpers'

export const CompanyFiltersPayload = (page, limit, filtersFormData) => {
  log('CompanyFiltersPayload', filtersFormData)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  return { ...companyParams, ...filtersFormData }
}

export const PaymentRequestsFiltersPayload = (claimId, page, limit, filtersFormData) => {
  log('CompanyFiltersPayload', filtersFormData)
  log('CompanyFiltersPayload', claimId)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  let claimFilter = { 'payment_claim_number': claimId?.toString() ?? '' }
  return { ...companyParams, ...claimFilter, ...filterData(filtersFormData || {}) }
}
export const PaymentsFiltersPayload = (page, limit, filtersFormData) => {
  log('PaymentsFiltersPayload', filtersFormData)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  return { ...companyParams, ...filterData(filtersFormData || {}) }
}
export const PaymentClaimRequestsFiltersPayload = (page, limit, filtersFormData) => {
  log('CompanyFiltersPayload', filtersFormData)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  return { ...companyParams, ...filterData(filtersFormData || {}) }
}
export const EcommerceFiltersPayload = (page, limit, filtersFormData) => {
  log('CompanyFiltersPayload', filtersFormData)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  return { ...companyParams, ...filterData(filtersFormData || {}) }
}
export const PaymentReceiptsFiltersPayload = (page, limit, filtersFormData) => {
  log('CompanyFiltersPayload', filtersFormData)
  let companyParams = {
    'page': page,
    'limit': limit
  }
  return { ...companyParams, ...filterData(filtersFormData || {}) }
}

export const PaymentClaimPayload = (FormData) => {
  log('PaymentClaimPayload', FormData)
  let permanentData = {
    'inquiry_request_number': FormData.inquiry_request_number,
    'tax_type': parseInt(FormData.tax_type),
    'claim_amount': parseInt(FormData.claim_amount),
    'payment_item': parseInt(FormData.payment_item)
  }
  let changedData = {}
  switch (parseInt(FormData.tax_type)) {
    case 1:
      changedData = {
        'owner_tax_registration_number': FormData.owner_tax_registration_number,
        'owner_tax_serial_number': FormData.owner_tax_serial_number,
        'tax_period_from': FormData.tax_period_from,
        'tax_period_to': FormData.tax_period_to
      }
      break
    case 2:
      changedData = {
        'tax_period_year': FormData.tax_period_year,
        'tax_period_quarter_number': parseInt(FormData.tax_period_quarter_number)
      }
      break
    case 3:
      changedData = {
        'tax_period_year': FormData.tax_period_year,
        'tax_period_month': parseInt(FormData.tax_period_month),
        'registration_payment_type': parseInt(FormData.registration_payment_type)
      }
      break

    default:
      changedData = {}
  }
  return { ...permanentData, ...changedData }
}

export const payNonCorePayload = (FormData) => {
  log('payNonCorePayload', FormData)
  let permanentData = {
    'core_payment_tax_payment_type': FormData.core_payment_tax_payment_type,
    'core_payment_tax_payment_item': FormData.core_payment_tax_payment_item,
    'core_payment_claim_number': FormData.core_payment_claim_number,
    'payment_source': FormData.payment_source
  }
  let changedData = {}
  switch (parseInt(FormData.payment_source)) {
    case 1:
      changedData = {
        'claim_id': FormData.claim_id
      }
      break
    case 2:
    case 3:
      changedData = {
        'transaction_number': FormData.transaction_number
      }
      break
    case 4:
      changedData = {
        'payment_method': FormData.payment_method,
        'taxation_period_from': FormData.taxation_period_from,
        'taxation_period_to': FormData.taxation_period_to
      }
      break
    default:
      changedData = {}
  }
  return { ...permanentData, ...changedData }
}

export const DelegatePayload = (FormData, isEdit) => {
  log('DelegatePayload', FormData)
  let userData = {
    email: FormData.email,
    phone: '+' + FormData.phone.replace(/^[+]/g, '')
  }
  let otherData = {
    roles: [parseInt(FormData.roles)],
    is_accountant: !!FormData.is_accountant
  }
  let accountantData = {
    nid: FormData.nid,
    accountant_ref_number: FormData.accountant_ref_number
  }

  return { ...otherData, ...(!!isEdit ? {} : userData), ...(!!FormData.is_accountant ? accountantData : {}) }
}

export const EcommercePayload = (FormData, isEdit) => {
  let userData = {
    name_ar:FormData.name_ar,
    name_en:FormData.name_en,
    url:FormData.url,
    services:FormData.services
  }

  return  userData
}

export const RegisterCompanyPayload = (filtersFormData, registerPlan = 'normal') => {
  let companyChangesParams = {}
  let companyParams = filterData({
    'tax_registration_type': (registerPlan === 'simple') ? '' : filtersFormData.tax_registration_type,
    'tax_registration_category': filtersFormData.tax_registration_category,
    'contact_info_id': filtersFormData.contact_info_id
  })

  if (filtersFormData.tax_registration_type === 'voluntary' || registerPlan === 'simple') {
    switch (filtersFormData.tax_registration_category) {
      case 'natural_person':
        let nationalID = { 'national_id': filtersFormData.national_id || '' }
        if (!!filtersFormData.code_iso3 && filtersFormData.code_iso3 !== 'EGY') {
          nationalID = { 'passport_id': filtersFormData.passport_id || '' }
        }
        companyChangesParams = {
          'full_name': filtersFormData.full_name || '',
          'code_iso3': filtersFormData.code_iso3 || '',
          'resident_country': filtersFormData.resident_country || '',
          ...filterData(nationalID)
        }

        break
      case 'juridical_person':
        if(registerPlan === 'normal' && filtersFormData.tax_registration_type === 'voluntary' && filtersFormData.newOrUpdateStatus === 'legal_update'){
          companyParams = {
            'previous_tax_id': filtersFormData.previous_tax_id,
            'previous_registration_group': filtersFormData.previous_registration_group,
            'contact_info_id': filtersFormData.contact_info_id
          }
        }
        companyChangesParams = {
          'company_name': filtersFormData.company_name || '',
          'foundation_id': filtersFormData.foundation_id || '',
          'foundation_year': filtersFormData.foundation_year || ''
        }
        break
      default:
        break
    }
  } else if (filtersFormData.tax_registration_type === 'suomoto') {
    switch (filtersFormData.tax_registration_category) {
      case 'natural_person':
        companyChangesParams = {
          'full_name': filtersFormData.full_name || ''
        }
        break
      case 'juridical_person':
        companyChangesParams = {
          'company_name': filtersFormData.company_name || ''
        }
        break
      default:
        break
    }
    companyChangesParams = {
      ...companyChangesParams,
      'verification_code1': filtersFormData.verification_code1 || '',
      'verification_code2': filtersFormData.verification_code2 || '',
      'verification_code3': filtersFormData.verification_code3 || ''
    }
  }

  return { ...companyParams, ...filterData(companyChangesParams || {}) }
}
