import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  IconButton, Slide, Tooltip, Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ReceiptIcon from '@material-ui/icons/Receipt'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { Alert, SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab'
import { PaymentOrderConsumer, PaymentOrderProvider } from '../../../../../PaymentOrderContext'
import { useTheme } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { downPaymentFormValidation } from '../../../../../utils/validationSchema'
import DownPaymentForm from './DownPaymentForm'
import PaymentOrderStep1 from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderStep1'
import PaymentClaimOrderStep2 from '../../CompaniesPayments/PaymentNewOrder/PaymentClaimOrderStep2'
import PaymentOrderStep3 from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderStep3'
import PaymentOrderDelete from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderDelete'
import { useStyles } from './Styles'
import { CompanyDownPaymentConsumer } from '../../../../../CompanyDownPaymentContext'
import DownPaymentOtherPaymentDialog from './DownPaymentOtherPaymentDialog'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddNewDownPaymentDialog = ({
                                   submitForm, getDownPaymentAmountRange, downPaymentRange,
                                   newDownPaymentSuccess, resetAllNewDownPaymentStates, getDownPaymentRequests
                                 }) => {
  const classes = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  }

  const downPaymentForm = useFormik({
    initialValues: {
      amount: '',
      minimum: '',
      maximum: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: downPaymentFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const [viewAddNewDownPaymentDialog, setViewAddNewDownPaymentDialog] = React.useState(false)
  const [openAddNewDownPaymentDialog, setOpenAddNewDownPaymentDialog] = React.useState(false)
  const handleAddNewDownPaymentDialog = () => {
    if (!openAddNewDownPaymentDialog) {
      if (!!getDownPaymentAmountRange) {
        getDownPaymentAmountRange(downPaymentForm)
      }
      handleCloseSpeedDial()
      setViewAddNewDownPaymentDialog(true)
    } else {
      downPaymentForm.resetForm()
      if (!!newDownPaymentSuccess) {
        getDownPaymentRequests(true)
      }
      setTimeout(() => {
        setViewAddNewDownPaymentDialog(false)
        resetAllNewDownPaymentStates()
      }, 500)
    }
    setOpenAddNewDownPaymentDialog(!openAddNewDownPaymentDialog)
  }
  const [viewDownPaymentOtherPaymentDialog, setViewDownPaymentOtherPaymentDialog] = React.useState(false)
  const [openDownPaymentOtherPaymentDialog, setOpenDownPaymentOtherPaymentDialog] = React.useState(false)
  const handleDownPaymentOtherPaymentDialog = () => {
    if (!openDownPaymentOtherPaymentDialog) {
      handleCloseSpeedDial()
      setViewDownPaymentOtherPaymentDialog(true)
    } else {
      setTimeout(() => setViewDownPaymentOtherPaymentDialog(false), 500)
    }
    setOpenDownPaymentOtherPaymentDialog(!openDownPaymentOtherPaymentDialog)
  }

  const [openSpeedDial, setOpenSpeedDial] = React.useState(false)

  const handleCloseSpeedDial = () => {
    setOpenSpeedDial(false)
  }
  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }
  return (
    <CompanyDownPaymentConsumer>
      {({ DownPaymentRequestsLabels, downPaymentReturnedRequest, removeDownPaymentRequest }) => (
        <>
          <Tooltip title={intl.formatMessage({ id: 'PaymentsContent.AddDownPayment' })}
                   aria-label={intl.formatMessage({ id: 'PaymentsContent.AddDownPayment' })}
                   placement={'left'}
                   classes={{
                     tooltip: classes.speedDialTooltip
                   }}
                   arrow={true}>
                <span className={classes.fabWrapper}>
                  <SpeedDial
                    ariaLabel={intl.formatMessage({ id: 'PaymentsContent.AddDownPayment' })}
                    icon={<SpeedDialIcon/>}
                    // onClose={handleCloseSpeedDial}
                    // onOpen={handleOpenSpeedDial}
                    onClick={handleToggleSpeedDial}
                    open={openSpeedDial}
                    classes={{
                      root: classes.speedDialWrapper,
                      fab: classes.fab,
                      actions: classes.actionsWrapper
                    }}
                    TransitionProps={{
                      timeout: transitionDuration,
                      style: {
                        transitionDelay: `${transitionDuration.exit * 4}ms`
                      }
                    }}
                  >
                    <SpeedDialAction
                      icon={<AccountBalanceIcon/>}
                      tooltipTitle={intl.formatMessage({ id: 'PaymentsContent.AddDownPayment.payCPS' })}
                      tooltipOpen={true}
                      tooltipPlacement="right"
                      FabProps={{
                        size: 'medium'
                      }}
                      classes={{
                        staticTooltipLabel: classes.speedDialActionTooltip,
                        fab: classes.speedDialActionFab
                      }}
                      onClick={handleDownPaymentOtherPaymentDialog}
                    />
                    <SpeedDialAction
                      icon={<ReceiptIcon/>}
                      tooltipTitle={intl.formatMessage({ id: 'PaymentsContent.AddDownPayment.payOnline' })}
                      tooltipOpen={true}
                      tooltipPlacement="right"
                      FabProps={{
                        size: 'medium'
                      }}
                      classes={{
                        staticTooltipLabel: classes.speedDialActionTooltip,
                        fab: classes.speedDialActionFab
                      }}
                      onClick={handleAddNewDownPaymentDialog}
                    />
                  </SpeedDial>
                </span>
          </Tooltip>
          {!!viewAddNewDownPaymentDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'md'}
            open={openAddNewDownPaymentDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleAddNewDownPaymentDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='PaymentsContent.AddDownPayment'/></Typography>
              <IconButton
                edge={false}
                className={classes.NewOrderDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleAddNewDownPaymentDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>

            {!!newDownPaymentSuccess && !!Object.keys(downPaymentReturnedRequest).length ?


              <PaymentOrderProvider
                claimId={downPaymentReturnedRequest.id}
                paymentDetails={downPaymentReturnedRequest}
                isDownPayment={true}
                forceInit={true}
                PaymentOrderColumns={DownPaymentRequestsLabels}>
                <PaymentOrderConsumer>
                  {({ handleNewOrderNext, paymentOrderData, getRequestExpirationTime }) => (
                    <>
                      <DialogContent className={classes.NewOrderDialogContent}>
                        <Box p={4} className={classes.NewOrderStepsWrapper}>
                          <Alert severity="success"><FormattedMessage
                            id='NewDownPayment.FormSuccessAlert'/></Alert>
                          <PaymentOrderStep1 isOld={true}/>
                          <PaymentClaimOrderStep2 getRequestExpirationTime={getRequestExpirationTime}/>
                          <PaymentOrderStep3/>
                        </Box>
                      </DialogContent>
                      <DialogActions className={classes.NewOrderDialogFooter}>

                        <Button className={classes.NewOrderPayButton} size="large" variant="contained"
                                color="primary"
                                onClick={() => {handleNewOrderNext()}}>
                          <FormattedMessage id='PaymentOrder.ActionLinks.Pay'/>
                        </Button>

                        <PaymentOrderDelete paymentOrderId={paymentOrderData.request_number}
                                            handleNewOrderDialog={handleAddNewDownPaymentDialog}
                                            removePaymentRequest={removeDownPaymentRequest}/>

                        <Button onClick={handleAddNewDownPaymentDialog} color="primary">
                          <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </PaymentOrderConsumer>
              </PaymentOrderProvider>
              :
              <>
                <DialogContent className={classes.NewOrderDialogContent}>
                  <Box p={4} className={classes.NewOrderStepsWrapper}>
                    <DownPaymentForm downPaymentForm={downPaymentForm}/>
                  </Box>
                </DialogContent>
                <DialogActions className={classes.NewOrderDialogFooter}>
                  <Button size="large" variant="contained" color="primary"
                          disabled={downPaymentForm.isSubmitting || !downPaymentForm.isValid ||
                          !Object.keys(downPaymentRange).length}
                          onClick={() => {downPaymentForm.handleSubmit()}}
                  >
                    <FormattedMessage id='AddDownPayment.submit'/>
                  </Button>
                  <Button onClick={handleAddNewDownPaymentDialog} color="primary">
                    <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                  </Button>
                </DialogActions>
              </>
            }

          </Dialog>
          }
          {!!viewDownPaymentOtherPaymentDialog &&
          <DownPaymentOtherPaymentDialog handleDialog={handleDownPaymentOtherPaymentDialog}
                                         open={openDownPaymentOtherPaymentDialog}
          />}
        </>
      )}
    </CompanyDownPaymentConsumer>
  )
}

export default AddNewDownPaymentDialog

