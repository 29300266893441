import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  ErrorDialogPaper: {
    borderRadius: 0
  },
  ErrorDialogContent: {
    padding: '0 !important',
    position: 'relative',
    '& .MuiAlert-root': {
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(6)
      }
    }
  },
  ErrorDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.primary.dark
  },
  linkCompanyErrorMessage: {
    paddingRight: 50,
    whiteSpace: 'pre-line'
  }
}))
