import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import {
  Box,
  Button, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Slide,
  Typography
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { PaymentOrderConsumer, PaymentOrderProvider } from '../../../../../../PaymentOrderContext'
import { CompanyConsumer } from '../../../../../../CompanyContext'
import { FormLoader } from '../../../../../Loader/FormLoader'
import { formatReceipt } from '../../../../../../utils/helpers'
import PaymentReceiptView from '../../../PaymentReceiptsContent/PaymentReceiptView'
import TermsConditions from '../../../CompaniesPayments/PaymentNewOrder/TermsConditions'
import { useStyles } from './Styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentsBalanceDialog = ({ allIsValid, calculatedBalance, paymentsToPay, resetHandlePaymentsBalanceStatues, handleBalanceForceRefresh, handleSubmitBalancePaymentsFilter }) => {
  const classes = useStyles()
  const [isAcceptTerms, setIsAcceptTerms] = React.useState(false)

  const [viewPaymentsBalanceDialog, setViewPaymentsBalanceDialog] = React.useState(false)
  const [openPaymentsBalanceDialog, setOpenPaymentsBalanceDialog] = React.useState(false)
  const handlePaymentsBalanceDialog = () => {
    if (!openPaymentsBalanceDialog) {
      setViewPaymentsBalanceDialog(true)
    } else {
      setTimeout(() => {
        if (!!handleBalanceForceRefresh) {
          handleSubmitBalancePaymentsFilter()
        }
        resetHandlePaymentsBalanceStatues()
        setViewPaymentsBalanceDialog(false)
      }, 500)
    }
    setOpenPaymentsBalanceDialog(!openPaymentsBalanceDialog)
  }
  return (
    <CompanyConsumer>
      {({
          handlePaymentsBalance, handleBalanceLoading, handleBalanceFailure, handleBalanceSuccess,
          handleBalanceSuccessData, PaymentReceiptHeaderColumns, handleBalanceFailureMessage
        }) => (
        <>
          <Button size="large" variant="contained" color="primary"
                  className={classes.NewOrderPayButton}
                  disabled={calculatedBalance < 0 || !allIsValid}
                  onClick={handlePaymentsBalanceDialog}>
            <FormattedMessage id='PaymentOrder.ActionLinks.Pay'/>
          </Button>
          {!!viewPaymentsBalanceDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'md'}
            open={openPaymentsBalanceDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handlePaymentsBalanceDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='workspaceMenu.link.PaymentsBalance'/></Typography>
              <IconButton
                edge={false}
                className={classes.NewOrderDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handlePaymentsBalanceDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <>
              <DialogContent className={classes.NewOrderDialogContent}>
                <Box p={4} className={classes.PaymentOrderStepMessage}>
                  {!!handleBalanceLoading && <FormLoader loading={true}/>}
                  {!!handleBalanceFailure &&
                  <Alert severity="error">{!!handleBalanceFailureMessage ? handleBalanceFailureMessage :
                    <FormattedMessage id='BasicInfo.FormErrorAlert'/>}</Alert>}
                  {!!handleBalanceSuccess ?
                    <>
                      <Alert severity="success">
                        <FormattedMessage id='PaymentsBalanceDialog.SuccessMessage'/>
                      </Alert>
                      {!!handleBalanceSuccessData && !!handleBalanceSuccessData.length &&
                      <>
                        <p><FormattedMessage id='PaymentsBalanceDialog.SuccessDetails'/></p>
                        <ul>
                          {handleBalanceSuccessData.map((payment, index) => (
                            <li key={index}>
                              {!!payment?.taxpayer_balance_payment_response &&
                              !!Object.keys(payment?.taxpayer_balance_payment_response).length &&
                              <div className={classes.PaymentsBalanceResultItem}>
                                <span
                                  className={(!!payment?.taxpayer_balance_payment_response?.success ? 'success' : 'error')}>
                                  <FormattedMessage id='PaymentsBalanceDialog.results.Payment'
                                                    values={{
                                                      strong: chunks => <strong>{chunks}</strong>,
                                                      msg: payment.taxpayer_balance_payment_response.status_message,
                                                      claimNumber: payment.taxpayer_balance_payment_response.claim_number
                                                    }}/>
                                </span>
                                {!!payment?.payment_receipt &&
                                !!Object.keys(payment?.payment_receipt).length &&
                                <PaymentOrderProvider
                                  paymentDetails={formatReceipt(payment?.payment_receipt)}
                                  PaymentOrderColumns={PaymentReceiptHeaderColumns}>
                                  <PaymentReceiptView
                                    receiptNumber={payment?.payment_receipt?.receipt_number}
                                    currentRequestId={payment?.payment_receipt?.receipt_number}/>
                                </PaymentOrderProvider>

                                }
                              </div>
                              }
                            </li>
                          ))}
                        </ul>
                      </>
                      }
                    </>
                    :
                    <>
                      <p><FormattedMessage id='PaymentsBalanceDialog.Message'
                                           values={{
                                             strong: chunks => <strong>{chunks}</strong>,
                                             count: paymentsToPay.length || 0
                                           }}/></p>
                      {!!paymentsToPay && !!paymentsToPay.length &&
                      <ul>
                        {paymentsToPay.map((payment, index) => (
                          <li key={index}>
                            {!!payment.isPartial ?
                              <FormattedMessage id='PaymentsBalanceDialog.Payment.partial'
                                                values={{
                                                  strong: chunks => <strong>{chunks}</strong>,
                                                  amount: <FormattedNumber
                                                    value={payment.partialAmount || 0}/>,
                                                  dueAmount: <FormattedNumber
                                                    value={payment.payment.due_amount || 0}/>,
                                                  claimNumber: payment.id
                                                }}/> :
                              <FormattedMessage id='PaymentsBalanceDialog.Payment'
                                                values={{
                                                  strong: chunks => <strong>{chunks}</strong>,
                                                  dueAmount: <FormattedNumber
                                                    value={payment.payment.due_amount || 0}/>,
                                                  claimNumber: payment.id
                                                }}/>
                            }
                          </li>
                        ))}
                      </ul>}
                      <div className={classes.termsConditionsWrapper}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isAcceptTerms}
                              onChange={(event) => {
                                setIsAcceptTerms(!!event.target.checked)
                              }}
                              name="termsConditions"
                              color="primary"
                            />
                          }
                          label={<>
                            <FormattedMessage id='PaymentOrder.steps.termsConditions.title.firstPart'/>
                            <PaymentOrderProvider>
                              <PaymentOrderConsumer>
                                {({ getTermsConditionsContent }) => (
                                  <TermsConditions getTermsConditionsContent={getTermsConditionsContent}/>
                                )}
                              </PaymentOrderConsumer>
                            </PaymentOrderProvider>
                          </>}
                        />
                      </div>
                    </>
                  }
                </Box>
              </DialogContent>
              <DialogActions className={classes.NewOrderDialogFooter}>
                {!!handleBalanceSuccess ?
                  <Button size="large" variant="contained" color="primary"
                          onClick={handlePaymentsBalanceDialog}>
                    <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                  </Button>
                  :
                  <>
                    <Button size="large" variant="contained" color="primary"
                            className={classes.innerPayButton}
                            disabled={!(!!isAcceptTerms && calculatedBalance >= 0)}
                            onClick={() => handlePaymentsBalance(paymentsToPay)}
                    >
                      <FormattedMessage id='PaymentOrder.ActionLinks.Pay'/>
                    </Button>
                    <Button onClick={handlePaymentsBalanceDialog} color="primary">
                      <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                    </Button>
                  </>
                }
              </DialogActions>
            </>
          </Dialog>
          }
        </>
      )}
    </CompanyConsumer>
  )
}

export default PaymentsBalanceDialog

