import React from 'react'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import CompanyNotifications from './CompanyNotifications'
import { notifications } from '../../../../utils/pages-meta'
import { SEO } from '../../../Seo/seo'
import { useIntl } from 'react-intl'
import InActiveCompanyContent from '../../../InActiveCompanyContent'

const CompanyNotificationsContent = ({ checkCompanyData, cid }) => {
  const intl = useIntl()
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={intl.formatMessage({ id: 'PageTitle.Notifications' }) + ' - ' + selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                   image={notifications.image}
                   description={intl.formatMessage({ id: 'PageTitle.Notifications' }) + ' - ' + selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                <CompanyConsumer>
                  {({ getNotifications, setBreadCrumbLinks, setBreadCrumbCurrent }) => (
                    <CompanyNotifications selectedCompany={selectedCompany}
                                          getNotifications={getNotifications}
                                          setBreadCrumbLinks={setBreadCrumbLinks}
                                          setBreadCrumbCurrent={setBreadCrumbCurrent}/>
                  )}
                </CompanyConsumer>
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default CompanyNotificationsContent
