import React from 'react'
import {
  FormControl, FormControlLabel, FormHelperText,
  InputLabel, MenuItem, Radio, RadioGroup, Select, TextField,
  InputAdornment, Collapse, Button
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { NamedRoutes } from '../../../../routes'
import { formatMobilePhoneNumber } from '../../../../utils/helpers'
import { useStyles } from '../Styles'
import { FormLoader } from '../../../Loader/FormLoader'
import { useFormik } from 'formik'
import { simpleRegisterCompanyFormValidation } from '../../../../utils/validationSchema'
import moment from 'moment'

const SimpleRegisterCompanyForm = ({
                                     verifiedCompaniesContacts, registerCompanySubmitForm, getCountries, ridToSelect,
                                     ridIsSelected, handleRegisterCompanyDialog, resetRegisterCompanyStatus, locale
                                   }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [taxCategory, setTaxCategory] = React.useState('')
  let registerCompanyForm = useFormik({
    initialValues: {
      tax_registration_category: taxCategory,
      full_name: '',
      code_iso3: 'EGY', //Nationality
      resident_country: 'EGY',
      national_id: '',
      passport_id: '',
      company_name: '',
      foundation_id: '',
      foundation_year: '',
      contact_info_id: !!ridIsSelected ? ridToSelect || '' : '',
      locale: locale
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: simpleRegisterCompanyFormValidation,
    onSubmit: ((values, actions) => {
      registerCompanySubmitForm(values, actions, handleRegisterCompanyDialog, 'simple', locale)
    })
  })

  React.useEffect(() => {
      getCountries()
      return () => {
        resetRegisterCompanyStatus()
        registerCompanyForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const [viewNatural, setNatural] = React.useState(false)
  const [viewJuridical, setJuridical] = React.useState(false)

  React.useEffect(() => {
      if (!!registerCompanyForm.values.tax_registration_category) {
        switch (registerCompanyForm.values.tax_registration_category) {
          case 'natural_person':
            setNatural(true)
            setTimeout(() => {
              setJuridical(false)
            }, 100)
            break
          case 'juridical_person':
            setJuridical(true)
            setTimeout(() => {
              setNatural(false)
            }, 100)
            break
          default:
            break
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerCompanyForm.values.tax_registration_category])

  const handleSelectChange = (event, fieldName) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    registerCompanyForm.setFieldValue(fieldName, event.target.value.toString())
    if (fieldName === 'tax_registration_category') {
      setTaxCategory(event.target.value.toString())
      setViewNationalID(true)
      registerCompanyForm.resetForm({
        values: {
          ...registerCompanyForm.initialValues,
          tax_registration_category: event.target.value.toString()
        }
      })
    }
  }

  const [foundationYearDate, setFoundationYearDate] = React.useState(null)
  const handleFoundationYearDateChange = (date) => {
    setFoundationYearDate(date)
    registerCompanyForm.setFieldValue('foundation_year', moment(date).format('YYYY'))
  }

  const [viewNationalID, setViewNationalID] = React.useState(true)
  const handleNationalityChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    registerCompanyForm.setFieldValue('code_iso3', event.target.value.toString())
    setViewNationalID(event.target.value.toString() === 'EGY')
  }
  return (
    <AuthenticatedUserConsumer>
      {({ paymentsFiltersData, countries }) => (
        <form onSubmit={registerCompanyForm.handleSubmit} noValidate autoComplete="off">

          {!!Object.keys(paymentsFiltersData).length && !!paymentsFiltersData['core_taxation_registration_categories'] &&
          !!paymentsFiltersData['core_taxation_registration_categories'].length &&
          <div className={classes.LinkCompanyFormRow}>
            <InputLabel id="registerCompanyForm.tax_registration_category"><FormattedMessage
              id='Fields.label.TaxRegistrationCategory'/></InputLabel>
            <FormControl variant="outlined">
              <RadioGroup aria-label="registerCompanyForm.tax_registration_category"
                          name="tax_registration_category"
                          value={registerCompanyForm.values.tax_registration_category}
                          onChange={(event) => handleSelectChange(event, 'tax_registration_category')}>
                {paymentsFiltersData['core_taxation_registration_categories'].map((cat) => (
                  <FormControlLabel key={cat.key} value={cat.key} control={<Radio color={'primary'}/>}
                                    label={cat.name}/>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          }

          <Collapse
            in={!!registerCompanyForm.values.tax_registration_category}
            timeout="auto" unmountOnExit>
            <Collapse
              in={registerCompanyForm.values.tax_registration_category === 'natural_person'}
              timeout="auto" unmountOnExit>
              {!!viewNatural &&
              <>
                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                  <InputLabel id="registerCompanyForm.full_name"><FormattedMessage
                    id='Fields.label.FullName'/></InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FullName' })}
                      id="registerCompanyForm.full_name"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='full_name'
                      onChange={registerCompanyForm.handleChange}
                      onBlur={registerCompanyForm.handleBlur}
                      value={registerCompanyForm.values.full_name}
                      error={registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name !== undefined}
                    />
                    <FormHelperText><FormattedMessage
                      id='registerCompanyForm.naturalPerson.Hint'/></FormHelperText>
                    {registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name && (
                      <FormHelperText
                        error={true}>{registerCompanyForm.errors.full_name}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                  <InputLabel id="registerCompanyForm.nationality"><FormattedMessage
                    id='Fields.label.Nationality'/></InputLabel>
                  <FormControl variant='outlined'>
                    <Select
                      fullWidth
                      labelId="registerCompanyForm.nationality"
                      id="code_iso3"
                      margin="dense"
                      MenuProps={{ disableScrollLock: true }}
                      name="code_iso3"
                      value={registerCompanyForm.values.code_iso3}
                      onChange={handleNationalityChange}
                      onBlur={registerCompanyForm.handleBlur}
                      error={registerCompanyForm.errors.code_iso3 && registerCompanyForm.touched.code_iso3 !== undefined}
                    >
                      {!!countries.length && countries.map((country) => (
                        <MenuItem key={country.id}
                                  value={country.code_iso3.toString()}>{country.name}</MenuItem>
                      ))}
                    </Select>
                    {registerCompanyForm.errors.code_iso3 && registerCompanyForm.touched.code_iso3 && (
                      <FormHelperText
                        error={true}>{registerCompanyForm.errors.code_iso3}</FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                  <InputLabel id="registerCompanyForm.resident_country"><FormattedMessage
                    id='Fields.label.ResidentCountry'/></InputLabel>
                  <FormControl variant='outlined'>
                    <Select
                      fullWidth
                      labelId="registerCompanyForm.resident_country"
                      id="resident_country"
                      margin="dense"
                      MenuProps={{ disableScrollLock: true }}
                      name="resident_country"
                      value={registerCompanyForm.values.resident_country}
                      onChange={(e) => handleSelectChange(e, 'resident_country')}
                      onBlur={registerCompanyForm.handleBlur}
                      error={registerCompanyForm.errors.resident_country && registerCompanyForm.touched.resident_country !== undefined}
                    >
                      {!!countries.length && countries.map((country) => (
                        <MenuItem key={country.id}
                                  value={country.code_iso3.toString()}>{country.name}</MenuItem>
                      ))}
                    </Select>
                    {registerCompanyForm.errors.resident_country && registerCompanyForm.touched.resident_country && (
                      <FormHelperText
                        error={true}>{registerCompanyForm.errors.resident_country}</FormHelperText>
                    )}
                  </FormControl>
                </div>

                {!!viewNationalID ?
                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="registerCompanyForm.national_id"><FormattedMessage
                      id='Fields.label.NationalId'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.NationalId' })}
                        id="registerCompanyForm.national_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='national_id'
                        onChange={registerCompanyForm.handleChange}
                        onBlur={registerCompanyForm.handleBlur}
                        value={registerCompanyForm.values.national_id}
                        helperText={registerCompanyForm.errors.national_id && registerCompanyForm.touched.national_id && registerCompanyForm.errors.national_id}
                        error={registerCompanyForm.errors.national_id && registerCompanyForm.touched.national_id !== undefined}
                      />
                    </FormControl>
                  </div>
                  :
                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="registerCompanyForm.passport_id"><FormattedMessage
                      id='Fields.label.PassportId'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.PassportId' })}
                        id="registerCompanyForm.passport_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='passport_id'
                        onChange={registerCompanyForm.handleChange}
                        onBlur={registerCompanyForm.handleBlur}
                        value={registerCompanyForm.values.passport_id}
                        helperText={registerCompanyForm.errors.passport_id && registerCompanyForm.touched.passport_id && registerCompanyForm.errors.passport_id}
                        error={registerCompanyForm.errors.passport_id && registerCompanyForm.touched.passport_id !== undefined}
                      />
                    </FormControl>
                  </div>
                }
              </>
              }
            </Collapse>
            <Collapse
              in={registerCompanyForm.values.tax_registration_category === 'juridical_person'}
              timeout="auto" unmountOnExit>
              {!!viewJuridical &&
              <>
                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                  <InputLabel id="registerCompanyForm.company_name"> {<FormattedMessage
                    id='Fields.label.CompanyName'/>} </InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.CompanyName' })}
                      id="registerCompanyForm.company_name"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='company_name'
                      onChange={registerCompanyForm.handleChange}
                      onBlur={registerCompanyForm.handleBlur}
                      value={registerCompanyForm.values.company_name}
                      error={registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name !== undefined}
                    />
                    <FormHelperText><FormattedMessage
                      id='registerCompanyForm.juridicalPerson.Hint'/></FormHelperText>
                    {registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name && (
                      <FormHelperText
                        error={true}>{registerCompanyForm.errors.company_name}</FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className={classes.LinkCompanyFormRow}>
                  <InputLabel id="registerCompanyForm.foundation_id"><FormattedMessage
                    id='registerCompanyForm.placeholder.FoundationId'/>:</InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FoundationId' })}
                      id="registerCompanyForm.foundation_id"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='foundation_id'
                      onChange={registerCompanyForm.handleChange}
                      onBlur={registerCompanyForm.handleBlur}
                      value={registerCompanyForm.values.foundation_id}
                      helperText={registerCompanyForm.errors.foundation_id && registerCompanyForm.touched.foundation_id && registerCompanyForm.errors.foundation_id}
                      error={registerCompanyForm.errors.foundation_id && registerCompanyForm.touched.foundation_id !== undefined}
                    />
                  </FormControl>
                </div>

                <div className={classes.LinkCompanyFormRow}>
                  <InputLabel id="registerCompanyForm.foundation_year"><FormattedMessage
                    id='registerCompanyForm.placeholder.FoundationYear'/>:</InputLabel>
                  <FormControl variant='outlined'>
                    <DatePicker
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FoundationYear' })}
                      value={foundationYearDate}
                      onChange={handleFoundationYearDateChange}
                      cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                      okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                      views={['year']}
                      variant="outlined"
                      format="yyyy"
                      margin='dense'
                      id="foundation_year"
                      name='foundation_year'
                      fullWidth
                      autoOk
                      className={classes.dateFormControl}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <EventIcon/>
                          </InputAdornment>
                      }}
                    />
                  </FormControl>
                </div>
              </>
              }
            </Collapse>


            <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
              <InputLabel id="registerCompanyForm.contact_info_id"><FormattedMessage
                id='Fields.label.ContactInfoId'/></InputLabel>
              <FormControl variant='outlined'>
                <Select
                  fullWidth
                  id="registerCompanyForm.contact_info_id"
                  margin="dense"
                  displayEmpty
                  MenuProps={{ disableScrollLock: true }}
                  name="contact_info_id"
                  value={registerCompanyForm.values.contact_info_id}
                  onChange={(event) => handleSelectChange(event, 'contact_info_id')}
                  onBlur={registerCompanyForm.handleBlur}
                  error={registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id !== undefined}
                >
                  <MenuItem value=''><FormattedMessage
                    id='registerCompanyForm.placeholder.ContactInfoId'/></MenuItem>
                  {!!verifiedCompaniesContacts.length && verifiedCompaniesContacts.map((companyContact) => (
                    <MenuItem key={companyContact.id}
                              value={companyContact.id}>{companyContact.email} ({formatMobilePhoneNumber(companyContact.phone)})</MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  <FormattedMessage id='registerCompanyForm.addCompanyContact'
                                    values={{
                                      a: chunks => <NavLink
                                        to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                    }}/>
                </FormHelperText>
                {registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id && (
                  <FormHelperText
                    error={true}>{registerCompanyForm.errors.contact_info_id}</FormHelperText>
                )}
              </FormControl>
            </div>
          </Collapse>


          <div className={classes.SubmitRow}>
            <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                    disabled={registerCompanyForm.isSubmitting || !registerCompanyForm.isValid}
                    className={classes.LinkCompanyFormSubmit}>
              <FormattedMessage id='registerCompanyForm.button.submit'/>
            </Button>
          </div>
          {registerCompanyForm.isSubmitting && <FormLoader loading={true}/>}
        </form>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default SimpleRegisterCompanyForm
