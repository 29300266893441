import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormHelperText,
  AccordionSummary, AccordionDetails, Accordion
} from '@material-ui/core'
import { DelegatesFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import { lg } from '../../../../utils/ResponsiveUtility'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


const DelegatesFilter = ({ submitForm, paymentsFiltersData }) => {
  const classes = useStyles()

  const filterForm = useFormik({
    initialValues: {
      username: '',
      company_role_id: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: DelegatesFilterFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })
  const handleRoleChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('company_role_id', event.target.value.toString())
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off"
              className={classes.formWrapper + ' ' + classes.formWrapperDense}>
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              id="filterForm.username"
              type={'text'}
              margin="dense"
              label={<FormattedMessage id='DelegateHeader.UserName'/>}
              variant="outlined"
              name='username'
              onChange={filterForm.handleChange}
              onBlur={filterForm.handleBlur}
              value={filterForm.values.username}
              helperText={filterForm.errors.username && filterForm.username.name && filterForm.errors.username}
              error={filterForm.errors.username && filterForm.touched.username !== undefined}
            />
          </FormControl>

          <FormControl fullWidth margin={'dense'} variant='outlined'
                       className={classes.formControl + ' ' + classes.formSelectControl}>
            <InputLabel id="company_role_id"><FormattedMessage id='DelegateHeader.Role'/></InputLabel>
            <Select
              fullWidth
              labelId="company_role_id"
              id="company_role_id"
              margin="dense"
              MenuProps={{ disableScrollLock: !!desktop }}
              name="company_role_id"
              value={filterForm.values.company_role_id}
              onChange={handleRoleChange}
              onBlur={filterForm.handleBlur}
              error={filterForm.errors.company_role_id && filterForm.touched.company_role_id !== undefined}
              label={<FormattedMessage id='DelegateHeader.Role'/>}
            >
              <MenuItem value=""><FormattedMessage id='DelegateHeader.Role'/></MenuItem>
              {!!Object.keys(paymentsFiltersData).length &&
              !!paymentsFiltersData['company_user_roles'].length &&
              paymentsFiltersData['company_user_roles'].map((item) => (
                'owner' !== item.role_name && <MenuItem key={item.id} value={item.id}><FormattedMessage
                  id={`Company.UserRole.${item.role_name}`}/></MenuItem>))}
            </Select>
            {filterForm.errors.company_role_id && filterForm.touched.company_role_id && (
              <FormHelperText error={true}>{filterForm.errors.company_role_id}</FormHelperText>
            )}
          </FormControl>
          <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                  className={classes.submitButton}>
            <FormattedMessage id='Filter.button.submit'/>
          </button>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export default DelegatesFilter
