import React from 'react'
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { TableRowBackground } from '../../../../utils/colors'
import DeleteEcommerce from './DeleteEcommerceClient'
import { useStyles } from './Styles'
import { EcommerceConsumer } from '../../../../EcommerceContext'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import EditEcommerce from './EditEcommerceClient'
import ClientSecret from './ClientSecret'
const EcommerceMenu = ({ resetEcommerceStatus }) => {
  let classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [viewEcommerceMenu, setViewEcommerceMenu] = React.useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setViewEcommerceMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setViewEcommerceMenu(false)
  }


  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${TableRowBackground}`
    }
  })((props) => (
    <Menu
      elevation={4}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableScrollLock
      {...props}
    />
  ))

  const [viewEditEcommerceDialog, setViewEditEcommerceDialog] = React.useState(false)
  const [openEditEcommerceDialog, setOpenEditEcommerceDialog] = React.useState(false)
  const handleEditEcommerceDialog = () => {
    if (!openEditEcommerceDialog) {
      resetEcommerceStatus()
      setViewEditEcommerceDialog(true)
    } else {
      setTimeout(() => setViewEditEcommerceDialog(false), 500)
    }
    setOpenEditEcommerceDialog(!openEditEcommerceDialog)
  }

  const [viewDeleteEcommerceDialog, setViewDeleteEcommerceDialog] = React.useState(false)
  const [openDeleteEcommerceDialog, setOpenDeleteEcommerceDialog] = React.useState(false)
  
  const [viewSecretEcommerceDialog, setViewSecretEcommerceDialog] = React.useState(false)
  const [openViewClientSecret, setOpenViewEcommerceDialog] = React.useState(false)

  const handleDeleteEcommerceDialog = () => {
    if (!openDeleteEcommerceDialog) {
      resetEcommerceStatus()
      setViewDeleteEcommerceDialog(true)
    } else {
      setTimeout(() => setViewDeleteEcommerceDialog(false), 500)
    }
    setOpenDeleteEcommerceDialog(!openDeleteEcommerceDialog)
  }

  const handleClientSecretGeneration = () => {
    if (!openViewClientSecret) {
      setViewSecretEcommerceDialog(true)
    } else {
      setTimeout(() => setViewSecretEcommerceDialog(false), 500)
    }
    setOpenViewEcommerceDialog(!openViewClientSecret)
  }
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      {!!viewEcommerceMenu &&
      <StyledMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classes.UserCustomMenu}
      >
        <MenuItem onClick={handleClose}>
          <Button color="primary" onClick={handleEditEcommerceDialog}>
            <FormattedMessage id='EcommerceMenu.updateEcommerce'/>
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button color="primary" onClick={handleDeleteEcommerceDialog}>
            <FormattedMessage id='EcommerceMenu.deleteEcommerce'/>
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button color="primary" onClick={handleClientSecretGeneration}>
            <FormattedMessage id='EcommerceMenu.viewSecret'/>
          </Button>
        </MenuItem>
      </StyledMenu>
      }
      <DeleteEcommerce
        handleDeleteEcommerceDialog={handleDeleteEcommerceDialog}
        viewDeleteEcommerceDialog={viewDeleteEcommerceDialog}
        openDeleteEcommerceDialog={openDeleteEcommerceDialog}/>

      { !!viewSecretEcommerceDialog && 
        <AuthenticatedUserConsumer>
        {({ paymentsFiltersData }) => (
          !!paymentsFiltersData && !!Object.keys(paymentsFiltersData).length &&
          <EcommerceConsumer>
            {({ loadingClientSecretData,ecommerceSecretFailure, ecommerceSecretFailureMessage ,   handleReGenerationClientSecret,clientSecretData }) => (
               <ClientSecret
               handleClientSecretGeneration={handleClientSecretGeneration}
               viewSecretEcommerceDialog={viewSecretEcommerceDialog}
               openViewClientSecret={openViewClientSecret}
               loadingClientSecretData={loadingClientSecretData}
               ecommerceSecretFailure={ecommerceSecretFailure}
               ecommerceSecretFailureMessage={ecommerceSecretFailureMessage}
               handleReGenerationClientSecret={handleReGenerationClientSecret}
               clientSecretData={clientSecretData}
               />
            )}
          </EcommerceConsumer>
        )}
      </AuthenticatedUserConsumer>
      }

      {!!viewEditEcommerceDialog &&
      <AuthenticatedUserConsumer>
        {({ paymentsFiltersData }) => (
          !!paymentsFiltersData && !!Object.keys(paymentsFiltersData).length &&
          <EcommerceConsumer>
            {({ ecommerceData, handleEcommerceSubmit }) => (
              <EditEcommerce
                openEditEcommerceDialog={openEditEcommerceDialog}
                handleEditEcommerceDialog={handleEditEcommerceDialog}
                ecommerceData={ecommerceData}
                handleEcommerceSubmit={handleEcommerceSubmit}
                paymentsFiltersData={paymentsFiltersData}
              />
            )}
          </EcommerceConsumer>
        )}
      </AuthenticatedUserConsumer>
      }
    </div>
  )
}

export default EcommerceMenu
