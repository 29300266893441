import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {Tooltip, ClickAwayListener} from '@material-ui/core'
import { useIntl } from 'react-intl'
import HelpIcon from '@material-ui/icons/Help'

const UpdateCompanyRegistrationHint = () => {
  const intl = useIntl()

  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const ToggleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500
    }
  }))(Tooltip)
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
       <span>
         <CustomTooltip
           title={intl.formatMessage({ id: 'UpdateCompanyRegistration.Hint' })}
           PopperProps={{
             disablePortal: true
           }}
           open={tooltipOpen}
           onClose={handleTooltipClose}
           disableFocusListener
           disableHoverListener
           disableTouchListener
           arrow>
           <HelpIcon onClick={ToggleTooltipOpen}/>
         </CustomTooltip>
       </span>
    </ClickAwayListener>

  )
}

export default UpdateCompanyRegistrationHint