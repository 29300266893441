import React from 'react'
import Filter from './Filter'
import WorkspaceTable from '../../Table'
import { NavLink, useHistory } from 'react-router-dom'
import { CompanyConsumer } from '../../../../CompanyContext'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Grow, MenuItem,
  MenuList,
  Paper,
  Popper
} from '@material-ui/core'
import { useStyles } from './Styles'
import { NamedRoutes } from '../../../../routes'
import { formatRoute } from 'react-router-named-routes'
import { isEmpty } from '../../../../utils/helpers'
import { FormLoader } from '../../../Loader/FormLoader'
import AddNewPaymentClaimDialog from './AddNewPaymentClaimDialog'
import UnAuthorize from '../../../UnAuthorize'
import { sm } from '../../../../utils/ResponsiveUtility'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const CompanyPaymentClaims = ({ selectedCompany, getPaymentClaims, handleNewPaymentClaimSubmit, getFiltersData, paymentsFiltersData, paymentsFiltersFailure, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  const classes = useStyles()
  let history = useHistory()
  const btnOptions = [
    {
      text: <FormattedMessage id='PaymentsContent.ActionLinks.PaymentRequests'/>,
      action: () => history.push({
        pathname: formatRoute(NamedRoutes.workspace.company.paymentRequests, { id: selectedCompany.id })
      })
    },
    {
      text: <FormattedMessage id='PaymentsContent.ActionLinks.PaymentReceipts'/>,
      action: () => history.push({
        pathname: formatRoute(NamedRoutes.workspace.company.paymentReceipts, { id: selectedCompany.id })
      })
    },
    {
      text: <FormattedMessage id='workspaceMenu.link.item64'/>,
      action: () => history.push({
        pathname: formatRoute(NamedRoutes.workspace.company.payments, { id: selectedCompany.id })
      })
    }
  ]

  React.useEffect(() => {
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='workspaceMenu.link.item65'/>)
      }
      if (!!getPaymentClaims) {
        getPaymentClaims()
      }
      if (!!getFiltersData) {
        getFiltersData()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])
  return (
    <>
      {!!selectedCompany && !isEmpty(selectedCompany) &&
      <Container disableGutters maxWidth="lg" className={classes.paymentsActionLinksContainer}>
        {!!Mobile ?
          <>
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
              <Button onClick={btnOptions[0].action}>{btnOptions[0].text}</Button>
              <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon/>
              </Button>
            </ButtonGroup>
            <Popper open={open} className={classes.btnPopper} anchorEl={anchorRef.current} role={undefined} transition
                    disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {btnOptions.map((btnObj, index) => (
                          <MenuItem
                            key={index}
                            selected={index === 0}
                            onClick={btnOptions[index].action}
                          >
                            {btnObj.text}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </> :
          <>
            <ButtonGroup size="large" variant={'contained'} color="primary"
                         aria-label="large outlined primary button group">
              <Button onClick={btnOptions[0].action}>
                {btnOptions[0].text}
              </Button>
              <Button onClick={btnOptions[1].action}>
                {btnOptions[1].text}
              </Button>
            </ButtonGroup>
            <ButtonGroup size="large" variant={'contained'} color="primary"
                         aria-label="large outlined primary button group">
              <Button onClick={btnOptions[2].action}>
                {btnOptions[2].text}
              </Button>
            </ButtonGroup>
          </>
        }
      </Container>
      }
      <CompanyConsumer>
        {({
            paymentClaimsLoading, paymentClaimsFailure, paymentClaimsFailure403, paymentClaims,
            PaymentClaimHeaderColumns, hasMorePaymentClaims, paymentClaimsRefreshLoading, newPaymentClaimSuccess,
            resetAllNewPaymentClaimStates, handleSubmitClaimsFilter, isCompanyUnderage, companyFailureUnderage,
            companyFailureUnderageMessage
          }) => (
          <Box p={2} className={'back-white'}>

            {!!paymentClaimsFailure403 ? <UnAuthorize/> :
              (!!isCompanyUnderage && !!companyFailureUnderage ?
                <Alert severity="error">
                  {companyFailureUnderageMessage}
                  <FormattedMessage id='Underage.ErrorAlert' values={{
                    a: chunks => <NavLink
                      to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
                  }}/></Alert>
                : ((!!paymentClaimsFailure || !!paymentsFiltersFailure) &&
                  <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                ))
            }
            {!paymentClaimsFailure403 && !companyFailureUnderage && !(!!isCompanyUnderage && !!paymentClaimsLoading) &&
            <Filter paymentsFiltersData={paymentsFiltersData} submitForm={handleSubmitClaimsFilter}/>
            }

            {!!paymentClaimsLoading && <SearchLoader loading={true}/>}
            {(!!paymentClaims && !!paymentClaims.length) ?
              <WorkspaceTable
                loadRows={getPaymentClaims}
                hasMoreRows={hasMorePaymentClaims}
                rows={paymentClaims}
                columns={PaymentClaimHeaderColumns}
                paymentActions={true}
                size={'small'}
                isPaymentClaim={true}/>
              :
              <>
                {!!paymentClaimsRefreshLoading &&
                <div className={classes.refreshWrapper}><FormLoader loading={true}/></div>}
                {!paymentClaimsLoading && !paymentClaimsRefreshLoading && !paymentClaimsFailure403 && !companyFailureUnderage &&
                <EmptyResult isPayments={true}/>}
              </>
            }
            {!paymentClaimsLoading && !paymentClaimsFailure403 && !companyFailureUnderage &&
            <AddNewPaymentClaimDialog submitForm={handleNewPaymentClaimSubmit}
                                      getPaymentClaims={getPaymentClaims}
                                      newPaymentClaimSuccess={newPaymentClaimSuccess}
                                      resetAllNewPaymentClaimStates={resetAllNewPaymentClaimStates}/>}
          </Box>
        )}
      </CompanyConsumer>

    </>
  )
}

export default CompanyPaymentClaims
