import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormHelperText,
  IconButton, InputLabel, TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, useIntl } from 'react-intl'
import { linkInsuranceOrganizationFormValidation } from '../../../../utils/validationSchema'
import { InsuranceServicesConsumer } from '../../../../InsuranceServicesContext'
import { FormLoader } from '../../../Loader/FormLoader'
import PhoneInput from 'react-phone-input-2'
import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../LanguageContext'

const LinkInsuranceOrganizationDialog = ({
                                           openLinkInsuranceOrganizationDialog,
                                           handleLinkInsuranceOrganizationDialog,
                                           linkInsuranceOrganizationSubmitForm,
                                           resetLinkInsuranceOrganizationStatus, Transition
                                         }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [delegatePhoneError, setDelegatePhoneError] = React.useState('')
  const linkInsuranceOrganizationForm = useFormik({
    initialValues: {
      tax_id: '',
      organization_number: '',
      owner_national_id: '',
      delegate_national_id: '',
      delegate_phone: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: linkInsuranceOrganizationFormValidation,
    onSubmit: ((values, actions) => {
      linkInsuranceOrganizationSubmitForm(values, actions, handleLinkInsuranceOrganizationDialog)
    })
  })

  React.useEffect(() => {
      return () => {
        resetLinkInsuranceOrganizationStatus()
        linkInsuranceOrganizationForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={openLinkInsuranceOrganizationDialog}
      classes={{ paper: classes.LinkCompanyDialogPaper }}
      TransitionComponent={Transition}
      onClose={handleLinkInsuranceOrganizationDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <InsuranceServicesConsumer>
        {({
            linkInsuranceOrganizationSuccess, linkInsuranceOrganizationFailure, linkInsuranceOrganizationFailureMessage,
            insuranceOrganization
          }) => (
          <>
            <DialogTitle disableTypography className={classes.LinkCompanyDialogHeader}>
              <Typography variant="h5"><FormattedMessage
                id='InsuranceServicesContent.ActionLinks.LinkOrganization'/></Typography>
              <IconButton
                edge={false}
                className={classes.LinkCompanyDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleLinkInsuranceOrganizationDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.LinkCompanyDialogContent}>
              <Box px={4} pt={4} pb={0} className={classes.LinkCompanyWrapper}>

                {!!linkInsuranceOrganizationFailure && !!linkInsuranceOrganizationFailureMessage &&
                <Alert severity="error">{linkInsuranceOrganizationFailureMessage}</Alert>
                }
                {!!linkInsuranceOrganizationSuccess &&
                <Alert severity="success"><FormattedMessage id='linkInsuranceOrganization.FormSuccessAlert'
                                                            values={{ organizationNumber: insuranceOrganization.organization_number }}/></Alert>
                }

                <form onSubmit={linkInsuranceOrganizationForm.handleSubmit} noValidate autoComplete="off"
                      className={classes.formWrapper}>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkInsuranceOrganizationForm.organization_number">
                      <FormattedMessage id='Fields.label.organizationNumber'/>
                      <br/>
                      <Typography variant="caption"><FormattedMessage
                        id='Fields.label.organizationNumber.caption'/></Typography>
                    </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkInsuranceOrganizationForm.placeholder.organizationNumber' })}
                        id="linkInsuranceOrganizationForm.organization_number"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='organization_number'
                        onChange={linkInsuranceOrganizationForm.handleChange}
                        onBlur={linkInsuranceOrganizationForm.handleBlur}
                        value={linkInsuranceOrganizationForm.values.organization_number}
                        helperText={linkInsuranceOrganizationForm.errors.organization_number && linkInsuranceOrganizationForm.touched.organization_number && linkInsuranceOrganizationForm.errors.organization_number}
                        error={linkInsuranceOrganizationForm.errors.organization_number && linkInsuranceOrganizationForm.touched.organization_number !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkInsuranceOrganizationForm.tax_id"><FormattedMessage
                      id='Fields.label.TaxID'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.TaxID' })}
                        id="linkInsuranceOrganizationForm.tax_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='tax_id'
                        onChange={linkInsuranceOrganizationForm.handleChange}
                        onBlur={linkInsuranceOrganizationForm.handleBlur}
                        value={linkInsuranceOrganizationForm.values.tax_id}
                        helperText={linkInsuranceOrganizationForm.errors.tax_id && linkInsuranceOrganizationForm.touched.tax_id && linkInsuranceOrganizationForm.errors.tax_id}
                        error={linkInsuranceOrganizationForm.errors.tax_id && linkInsuranceOrganizationForm.touched.tax_id !== undefined}
                      />
                    </FormControl>
                  </div>


                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkInsuranceOrganizationForm.owner_national_id"><FormattedMessage
                      id='Fields.label.OwnerNationalId'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkInsuranceOrganizationForm.placeholder.OwnerNationalId' })}
                        id="linkInsuranceOrganizationForm.owner_national_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='owner_national_id'
                        onChange={linkInsuranceOrganizationForm.handleChange}
                        onBlur={linkInsuranceOrganizationForm.handleBlur}
                        value={linkInsuranceOrganizationForm.values.owner_national_id}
                        helperText={linkInsuranceOrganizationForm.errors.owner_national_id && linkInsuranceOrganizationForm.touched.owner_national_id && linkInsuranceOrganizationForm.errors.owner_national_id}
                        error={linkInsuranceOrganizationForm.errors.owner_national_id && linkInsuranceOrganizationForm.touched.owner_national_id !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkInsuranceOrganizationForm.delegate_national_id"><FormattedMessage
                      id='Fields.label.DelegateNationalId'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkInsuranceOrganizationForm.placeholder.DelegateNationalId' })}
                        id="linkInsuranceOrganizationForm.delegate_national_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='delegate_national_id'
                        onChange={linkInsuranceOrganizationForm.handleChange}
                        onBlur={linkInsuranceOrganizationForm.handleBlur}
                        value={linkInsuranceOrganizationForm.values.delegate_national_id}
                        helperText={linkInsuranceOrganizationForm.errors.delegate_national_id && linkInsuranceOrganizationForm.touched.delegate_national_id && linkInsuranceOrganizationForm.errors.delegate_national_id}
                        error={linkInsuranceOrganizationForm.errors.delegate_national_id && linkInsuranceOrganizationForm.touched.delegate_national_id !== undefined}
                      />
                    </FormControl>
                  </div>


                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkInsuranceOrganizationForm.delegate_phone"><FormattedMessage
                      id='Fields.label.DelegatePhone'/></InputLabel>
                    <LanguageConsumer>
                      {({ locale }) => (
                        <div className={classes.PhoneInputWrapper}>
                          <PhoneInput
                            enableSearch={true}
                            countryCodeEditable={false}
                            jumpCursorToEnd={true}
                            country={'eg'}
                            localization={locale === 'ar' ? ar : ''}
                            specialLabel={''}
                            excludeCountries={['il']}
                            inputProps={{
                              name: 'delegate_phone',
                              required: true,
                              autoFocus: false
                            }}
                            searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                            searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                            onBlur={linkInsuranceOrganizationForm.handleBlur}
                            value={linkInsuranceOrganizationForm.values.delegate_phone}
                            helperText={linkInsuranceOrganizationForm.errors.delegate_phone && linkInsuranceOrganizationForm.touched.delegate_phone && linkInsuranceOrganizationForm.errors.delegate_phone}
                            onChange={(phone) => {
                              linkInsuranceOrganizationForm.setFieldValue('delegate_phone', '+' + phone.toString())
                            }}
                            isValid={(value, country) => {
                              if (country.iso2 === 'eg' && value.length > 2 && value[2] && value[2].toString() !== '1') {
                                setDelegatePhoneError(intl.formatMessage({ id: 'validationSchema.phoneStartingError' }))
                                return false
                              }
                              if (country.iso2 === 'eg' && value.length > 12) {
                                setDelegatePhoneError(intl.formatMessage({ id: 'validationSchema.phoneError' }))
                                return false
                              } else {
                                setDelegatePhoneError('')
                                return true
                              }
                            }}
                          />
                          {!!delegatePhoneError ?
                            <FormHelperText margin="dense" variant='outlined'
                                            error={true}>{delegatePhoneError}</FormHelperText>
                            : (linkInsuranceOrganizationForm.errors.delegate_phone && linkInsuranceOrganizationForm.touched.delegate_phone &&
                              <FormHelperText margin="dense" variant='outlined'
                                              error={true}>{linkInsuranceOrganizationForm.errors.delegate_phone}</FormHelperText>)}
                        </div>
                      )}
                    </LanguageConsumer>
                  </div>

                  <div className={classes.SubmitRow}>
                    <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                            disabled={linkInsuranceOrganizationForm.isSubmitting || !linkInsuranceOrganizationForm.isValid}
                            className={classes.LinkCompanyFormSubmit}>
                      <FormattedMessage id='linkInsuranceOrganizationForm.button.submit'/>
                    </Button>
                  </div>
                  {linkInsuranceOrganizationForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
              </Box>
            </DialogContent>
            <DialogActions className={classes.LinkCompanyDialogFooter}>
              <Button onClick={handleLinkInsuranceOrganizationDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </>
        )}
      </InsuranceServicesConsumer>
    </Dialog>
  )
}

export default LinkInsuranceOrganizationDialog
