import React from 'react'
import { useStyles } from './Styles'

const Item = ({ label, value, asTable }) => {
  const classes = useStyles()

  return (
    <div className={classes.itemWrapper + (!!asTable ? ' asTable' : '')}>
      <label className={classes.itemLabel}>{label}</label>
      <span className={classes.itemValue}>{value}</span>
    </div>
  )
}

export default Item
