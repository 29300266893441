import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../../utils/colors'
import { StepConnector } from '@material-ui/core'


export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    position: 'relative',
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative'
  },
  NewOrderPayButton: {
    minWidth: '20%'
  },
  StepperWrapper: {
    backgroundColor: 'transparent',
    padding: theme.spacing(0, 0, 2.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, -1),
      '& .MuiStepContent-root, & .MuiStepConnector-vertical': {
        marginLeft: theme.spacing(2.75)
      }
    }

  }
}))

export const requestStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    minWidth: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    padding: 0,
    alignItems: 'center'
  },
  active: {
    backgroundImage:
      `linear-gradient( 136deg, ${fade(theme.palette.primary.main, 1)} 0%, ${fade(theme.palette.primary.dark, 1)} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  },
  completed: {
    backgroundImage:
      `linear-gradient( 136deg, ${fade(theme.palette.primary.main, 1)} 0%, ${fade(theme.palette.primary.dark, 1)} 100%)`
  }
}))

export const RequestStepsConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line:before': {
      width: '100%'
    }
  },
  completed: {
    '& $line:before': {
      width: '100%'
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    position: 'relative',
    '&:before': {
      content: '""',
      width: 0,
      left: 0,
      top: 0,
      display: 'block',
      height: '100%',
      zIndex: 0,
      transition: 'all 0.3s ease-in-out',
      backgroundImage:
        `linear-gradient( 136deg, ${fade(theme.palette.primary.main, 1)} 0%, ${fade(theme.palette.primary.dark, 1)} 100%)`
    }
  }
}))(StepConnector)