import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  Button, Collapse,
  FormControl, FormHelperText,
  InputAdornment, InputLabel, MenuItem, Select, TextField
} from '@material-ui/core'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, useIntl } from 'react-intl'
import { insuranceOrganizationBranchDownloadFormFormValidation } from '../../../../../utils/validationSchema'
import { InsuranceServicesConsumer } from '../../../../../InsuranceServicesContext'
import { FormLoader } from '../../../../Loader/FormLoader'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event'
import moment from 'moment'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../../LanguageContext'

const InsuranceOrganizationBranchDownloadForm = ({ onSubmit, currentBranch, resetStatus, handleCloseDialog }) => {
  const classes = useStyles()
  const intl = useIntl()
  const downloadForm = useFormik({
    initialValues: {
      type: '',
      insurance_organization_number: currentBranch.organization_number,
      person_insurance_number: '',
      year: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: insuranceOrganizationBranchDownloadFormFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions, handleCloseDialog)
    })
  })

  const handleTypeChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    downloadForm.setFieldValue('type', event.target.value.toString())
  }

  const [yearDate, setYearDate] = React.useState(null)
  const handleFoundationYearDateChange = (date) => {
    setYearDate(date)
    downloadForm.setFieldValue('year', moment(date).format('YYYY'))
  }


  React.useEffect(() => {
      return () => {
        resetStatus()
        downloadForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <InsuranceServicesConsumer>
      {({
          InsuranceOrganizationBranchFormTypes,
          insuranceOrganizationBranchDownloadSuccess, insuranceOrganizationBranchDownloadFailure,
          insuranceOrganizationBranchDownloadFailureMessage
        }) => (
        <>

          {!!insuranceOrganizationBranchDownloadFailure && !!insuranceOrganizationBranchDownloadFailureMessage &&
          <Alert severity="error">{insuranceOrganizationBranchDownloadFailureMessage}</Alert>
          }
          {!!insuranceOrganizationBranchDownloadSuccess &&
          <Alert severity="success">
            <FormattedMessage id='insuranceOrganizationBranchDownload.FormSuccessAlert'
                              values={{
                                organizationNumber: currentBranch.organization_number,
                                type: downloadForm.values.type
                              }}/></Alert>
          }

          <form onSubmit={downloadForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper}>

            <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
              <InputLabel id="downloadForm.type"><FormattedMessage
                id='Fields.label.InsuranceFormType'/></InputLabel>
              <FormControl variant='outlined'>
                <Select
                  fullWidth
                  id="downloadForm.type"
                  margin="dense"
                  displayEmpty
                  MenuProps={{ disableScrollLock: true }}
                  name="type"
                  value={downloadForm.values.type}
                  onChange={handleTypeChange}
                  onBlur={downloadForm.handleBlur}
                  error={downloadForm.errors.type && downloadForm.touched.type !== undefined}
                >
                  {!!InsuranceOrganizationBranchFormTypes.length && InsuranceOrganizationBranchFormTypes.map((type) => (
                    <MenuItem key={type.id}
                              value={type.value}>{type.name}</MenuItem>
                  ))}
                </Select>
                {downloadForm.errors.type && downloadForm.touched.type && (
                  <FormHelperText error={true}>{downloadForm.errors.type}</FormHelperText>
                )}
              </FormControl>
            </div>

            <Collapse
              in={['1', '6'].includes(downloadForm.values.type)}
              timeout="auto" unmountOnExit>
              {['1', '6'].includes(downloadForm.values.type) &&
              <div className={classes.LinkCompanyFormRow}>
                <InputLabel id="downloadForm.person_insurance_number">
                  <FormattedMessage id='Fields.label.personInsuranceNumber'/>
                </InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'downloadForm.placeholder.personInsuranceNumber' })}
                    id="downloadForm.person_insurance_number"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='person_insurance_number'
                    onChange={downloadForm.handleChange}
                    onBlur={downloadForm.handleBlur}
                    value={downloadForm.values.person_insurance_number}
                    helperText={downloadForm.errors.person_insurance_number && downloadForm.touched.person_insurance_number && downloadForm.errors.person_insurance_number}
                    error={downloadForm.errors.person_insurance_number && downloadForm.touched.person_insurance_number !== undefined}
                  />
                </FormControl>
              </div>
              }
            </Collapse>

            <Collapse
              in={downloadForm.values.type === '2'}
              timeout="auto" unmountOnExit>
              {downloadForm.values.type === '2' &&
              <div className={classes.LinkCompanyFormRow}>
                <InputLabel id="downloadForm.year"><FormattedMessage
                  id='Fields.label.InsuranceFormYear'/></InputLabel>
                <FormControl variant='outlined'>
                  <LanguageConsumer>
                    {({ locale }) => (
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'downloadForm.placeholder.InsuranceFormYear' })}
                          value={yearDate}
                          disableFuture
                          onChange={handleFoundationYearDateChange}
                          cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                          okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                          views={['year']}
                          variant="outlined"
                          format="yyyy"
                          margin='dense'
                          id="year"
                          name='year'
                          fullWidth
                          autoOk
                          className={classes.dateFormControl}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position="end">
                                <EventIcon/>
                              </InputAdornment>
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </LanguageConsumer>
                </FormControl>
              </div>
              }
            </Collapse>
            <div className={classes.SubmitRow}>
              <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                      disabled={downloadForm.isSubmitting || !downloadForm.isValid}
                      className={classes.LinkCompanyFormSubmit}>
                <FormattedMessage id='InsuranceOrganizationBranchDownloadForm.button.submit'/>
              </Button>
            </div>
            {downloadForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </>
      )}
    </InsuranceServicesConsumer>
  )
}

export default InsuranceOrganizationBranchDownloadForm
