import React from 'react'
import UnAuthorize from '../../components/UnAuthorize'

const UnAuthorized = () => {
  return (
    <>
      <UnAuthorize/>
    </>
  )
}

export default UnAuthorized
