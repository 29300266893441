/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Fade } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useStyles } from './Styles'
import { PayNonCoreConsumer } from '../../../../../../../PayNoncoreContext'

const PayNonCoreStep3 = () => {
  const classes = useStyles()
  return (
    <PayNonCoreConsumer>
      {({ PayNonCoreStep1Form, tempPayNonCoreData}) => (
        <Fade in={true}>
          <div className={classes.formWrapper}>
            <Alert severity="success" className={classes.successWrapper}>
              <AlertTitle><FormattedMessage id='PayNonCoreStep3.Title'/></AlertTitle>
              <FormattedMessage id='PayNonCoreStep3.Content' values={{
                currentPaymentNumber: PayNonCoreStep1Form.values.core_payment_claim_number,
                paymentSelected: tempPayNonCoreData.payment_number
              }}/>
            </Alert>

          </div>
        </Fade>
      )}
    </PayNonCoreConsumer>
  )
}

export default PayNonCoreStep3
