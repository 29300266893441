import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { useStyles } from './Styles'
import logo from '../../../../../../../images/etax-logo.png'
import eFinance from '../../../../../../../images/e-finance.png'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'

const PaymentRequestPDFContent = ({ requestDetails, claimId, userName, currentDate, locale, isForeign }) => {
  const classes = useStyles()
  return (
    <div id={`pdfContent-${claimId}`} className={classes.pdfContent}>
      <h3 align={'center'}><FormattedMessage id='PayOthersDialog.title'
                                             values={{ 'claimId': claimId }}/></h3>
      <div className='first-row'>
        <div className='dateWrapper'>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeNumber'/>}</h4>
            <span className='itemValue'>{claimId}</span>
          </div>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeExpireDate'/>}</h4>
            <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                format="Do MMMM YYYY h:mm a">{requestDetails.claim_expire_date}</Moment></span>
          </div>
          <div className='itemWrapper'>
            <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.printDate'/>}</h4>
            <span className='itemValue'><Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                                format="Do MMMM YYYY h:mm a">{currentDate}</Moment></span>
          </div>
        </div>
        <img src={logo} alt='e-finance logo' width={150}/>
      </div>
      <div className='data-row'>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.userName'/>}</h4>
          <span className='itemValue'>{userName}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.TaxpayerName'/>}</h4>
          <span className='itemValue'>{requestDetails.company.taxpayer_name}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.CompanyName'/>}</h4>
          <span className='itemValue'>{requestDetails.companyNameString}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='CompanyHeader.UniqueTaxId'/>}</h4>
          <span className='itemValue'>{requestDetails.tax_id}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.registrationPaymentType'/>}</h4>
          <span className='itemValue'>{requestDetails.registration_payment_type || 'N/A'}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeType'/>}</h4>
          <span className='itemValue'>{requestDetails.tax_type}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.paymentItem'/>}</h4>
          <span className='itemValue'>{requestDetails.payment_item}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.paymentStatus'/>}</h4>
          <span className='itemValue'>{requestDetails.payment_status}</span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage
            id={!!isForeign ? 'PaymentHeader.claimAmountNoCur' : 'PaymentHeader.requestAmountPDF'}/>}</h4>
          <span className='itemValue'>
            {(!!requestDetails['allow_partial_payment'] &&
              !!requestDetails['due_amount'] && requestDetails['due_amount'] !== requestDetails.claim_amount) ?
              <div className={classes.partialWrapper}>
                <div className={classes.partialAmount}>
                  <del><FormattedNumber value={requestDetails.claim_amount}/></del>
                </div>
                <div className={classes.partialDueAmount}>
                  <FormattedMessage id={`partialPayment.DueAmount.PDFHint${!!isForeign?'.withCur':''}`} values={{currency: requestDetails.claim_currency}}/> : <strong><FormattedNumber
                  value={requestDetails['due_amount']}/></strong>
                </div>
              </div>
              :
              <FormattedNumber value={requestDetails.claim_amount}/>}</span>
        </div>
        {!!isForeign &&
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.claimCurrency'/>}</h4>
          <span className='itemValue'>{requestDetails.claim_currency}</span>
        </div>
        }
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.taxPeriodFrom'/>}</h4>
          <span className='itemValue'>
            <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                    format="Do MMMM YYYY h:mm a">{requestDetails.tax_period_from}</Moment></span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.taxPeriodTo'/>}</h4>
          <span className='itemValue'>
            <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                    format="Do MMMM YYYY h:mm a">{requestDetails.tax_period_to}</Moment></span>
        </div>
        <div className='itemWrapper'>
          <h4 className='itemLabel'>{<FormattedMessage id='PaymentHeader.climeExpireDate'/>}</h4>
          <span className='itemValue'>
            <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                    format="Do MMMM YYYY h:mm a">{requestDetails.claim_expire_date}</Moment></span>
        </div>
      </div>
      <div className='last-row'>
        <h5><FormattedMessage id='footer.copyrights.poweredBy'/></h5>
        <img src={eFinance} alt='e-finance logo' width={230}/>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  )
}

export default PaymentRequestPDFContent
