import React from 'react'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { SearchLoader } from '../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { Button, Container } from '@material-ui/core'
import { useStyles } from './Styles'
import { CompanyProvider, CompanyConsumer } from '../../../CompanyContext'
import { Redirect } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import NotFound from '../../NotFound'
import BreadCrumb from '../BreadCrumb'
import UnAuthorize from '../../UnAuthorize'
import { LanguageConsumer } from '../../../LanguageContext'

const CompanyContentWrapper = ({ allUserCompaniesList, checkCompanyData, cid, checkUPTCP, children }) => {
  const classes = useStyles()
  React.useEffect(() => {
      if (!!cid && !!checkCompanyData) {
        checkCompanyData(cid)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUserCompaniesList, cid])
  return (
    <AuthenticatedUserConsumer>
      {({
          selectedCompanyLoading, selectedCompanyFailure, selectedCompanyNotFound, selectedCompanyFailure403, selectedCompany,
          notActiveCompany, allUserCompaniesList, checkCompanyUPTCPRegistration, companyUPTCPRegistration
        }) => (
        <CompanyProvider cid={cid} selectedCompany={selectedCompany}>
          <CompanyConsumer>
            {({ breadCrumbLinks, breadCrumbCurrent, breadCrumbButton }) => (
              <LanguageConsumer>
                {({ locale }) => (
                  <BreadCrumb
                    links={breadCrumbLinks}
                    current={breadCrumbCurrent}
                    button={breadCrumbButton}
                    allUserCompaniesList={allUserCompaniesList}
                    locale={locale}
                    UPTCPRegistration={!!checkUPTCP && !!selectedCompany?.user_uptcp_enrollment_eligibility &&
                    !selectedCompany?.user_uptcp_enrolled_at}
                    UPTCPButton={<Button className={classes.uptcpBtn} size="medium" variant="contained" color="primary"
                                         disabled={!!companyUPTCPRegistration}
                                         onClick={() => checkCompanyUPTCPRegistration(selectedCompany?.id)}>
                      <FormattedMessage id='UPTCPUser.ButtonLink'/>
                    </Button>}
                  />
                )}
              </LanguageConsumer>
            )}
          </CompanyConsumer>

          <Container maxWidth="lg" className={classes.CompanyContentContainer}>
            <div className={classes.root}>
              {!!selectedCompanyLoading && <SearchLoader loading={true}/>}
              {!!selectedCompanyFailure403 ? <UnAuthorize/> :
                (!!selectedCompanyNotFound ?
                  <NotFound/>
                  :
                  (!!selectedCompanyFailure &&
                    <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>))
              }
              {!!selectedCompany && !!Object.keys(selectedCompany).length &&
              <>
                {children}
              </>
              }
              {!!notActiveCompany &&
              <Redirect to={{
                pathname: NamedRoutes.workspace.dashboard
              }}/>}
            </div>
          </Container>
        </CompanyProvider>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default CompanyContentWrapper
