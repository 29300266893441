import React from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { BrowserRouter as Router } from 'react-router-dom'
import { createGenerateClassName, createMuiTheme } from '@material-ui/core/styles'
import { StylesProvider, ThemeProvider as MuiThemeProvider, jssPreset } from '@material-ui/styles'
import { LocalStorageService } from './Services/LocalStorageService'
import Configuration from './Services/Api/Configuration'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()
let { DefaultFont, DefaultDirection } = Configuration

const ThemeProvider = ({ children }) => {
  const [font, setFont] = React.useState(DefaultFont)
  const [storageDirection, setStorageDirection] = React.useState(DefaultDirection)

  let isSubscribed = false
  React.useEffect(() => {
    // eslint-disable-next-line
    isSubscribed = true
    LocalStorageService.get('ETaxAppFontName').then((storageFont) => {
      if (isSubscribed) {
        setFont(storageFont)
      }
    })
    LocalStorageService.get('ETaxAppDirection').then((storageDirection) => {
      if (isSubscribed) {
        setStorageDirection(storageDirection)
      }
    })
    return () => isSubscribed = false
  }, [])

  let defaultTheme = createMuiTheme()
  let theme = createMuiTheme({
    direction: storageDirection,
    palette: {
      primary: {
        light: '#438fce',
        hover: '#0062cc',
        main: '#438fce',
        darker: '#0A2F95',
        dark: '#00258c',
        contrastText: '#000'
      },
      secondary: {
        light: '#edf6fe',
        main: '#cfe6fc',
        dark: '#adcbe3',
        contrastText: '#000'
      },
      error: {
        light: '#fff2f4',
        main: '#dc3545',
        dark: '#c32130',
        contrastText: '#000'
      },
      success: {
        main: '#28a745',
        light: '#20c997'
      },
      info: {
        main: '#17a2b8',
        light: '#edf7ff'
      },
      warning: {
        light: '#fff9ec',
        main: '#ffc107',
        dark: '#FF8412'
      },
      text: {
        primary: '#171725',
        secondary: '#92929d',
        hint: '#98a9bc'
      }
    },
    typography: {
      'fontFamily': font,
      'htmlFontSize': 16,
      'fontSize': 14,
      'fontWeightLight': 300,
      'fontWeightRegular': 500,
      'fontWeightBold': 900
    },
    overrides: {
      MuiDialog: {
        paperFullWidth: {
          [defaultTheme.breakpoints.down('xs')]: {
            width: `calc( 100% - ${defaultTheme.spacing(2)}px )`
          }
        },
        paperScrollPaper: {
          [defaultTheme.breakpoints.down('xs')]: {
            maxHeight: `calc( 100% - ${defaultTheme.spacing(2)}px )`
          }
        },
        paper: {
          [defaultTheme.breakpoints.down('xs')]: {
            margin: defaultTheme.spacing(1)
          }
        }
      },
      MuiDialogTitle: {
        root: {
          paddingRight: defaultTheme.spacing(8) }
      }
    }
  })

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <Router>
          {children}
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default ThemeProvider
