import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Slide,
  TextField,
  Typography
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { FormLoader } from '../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import PhoneInput from 'react-phone-input-2'
import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../LanguageContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ChangePhone ({ additionalInfoForm, resetPhoneStatus, isCompanyContact, countryCodeKey }) {
  const classes = useStyles()
  const intl = useIntl()
  const [open, setOpen] = React.useState(false)
  const [newPhoneError, setNewPhoneError] = React.useState('')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    additionalInfoForm.setFieldValue('newPhone', '20')
    if (!!resetPhoneStatus) {
      resetPhoneStatus()
    }
  }

  return (
    <AuthenticatedUserConsumer>
      {({ handleChangePhoneNumber, verifyNewPhoneNumber, verifyNewPhoneSuccess, confirmNewPhoneSuccess, verifyNewPhoneLoading, verifyNewPhoneFailure, verifyNewPhoneFailureMessage }) => (
        <div>
          <button type='button' className={classes.changePhoneButton} onClick={handleClickOpen}>
            <FormattedMessage id='ChangePhone.button'/>
          </button>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            classes={{ paper: classes.ChangePhoneDialogPaper }}
            onClose={handleClose}
            aria-labelledby="ChangePhone-title"
            aria-describedby="ChangePhone-description"
          >
            <DialogTitle id="ChangePhone-title" disableTypography className={classes.ChangePhoneDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='ChangePhone.DialogTitle'/></Typography>
              <IconButton
                edge={false}
                className={classes.ChangePhoneDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleClose}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.ChangePhoneDialogContent}>
              <Box p={4}>
                {!!verifyNewPhoneLoading && <FormLoader loading={true}/>}
                {!!verifyNewPhoneFailure && !!verifyNewPhoneFailureMessage &&
                <Alert severity="error">{verifyNewPhoneFailureMessage}</Alert>
                }
                {!!confirmNewPhoneSuccess &&
                <Alert severity="success"><FormattedMessage id='ChangePhone.FormSuccessAlert'/></Alert>
                }
                {!!verifyNewPhoneSuccess ?
                  <div className={classes.ChangePhoneVerifyWrapper}>
                    <p align={'center'}><strong><FormattedMessage
                      id='AdditionalInfo.VerificationCode.message'/></strong></p>
                    <div className={classes.ChangePhoneVerify}>
                      <InputLabel id="AdditionalInfo.VerificationCode"><FormattedMessage
                        id='Fields.label.VerificationCode'/></InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          fullWidth
                          placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.VerificationCode' })}
                          id="AdditionalInfo.VerificationCode"
                          type='text'
                          margin="dense"
                          variant="outlined"
                          name='VerificationCode'
                          onChange={additionalInfoForm.handleChange}
                          onBlur={additionalInfoForm.handleBlur}
                          value={additionalInfoForm.values.VerificationCode}
                          helperText={additionalInfoForm.errors.VerificationCode && additionalInfoForm.touched.VerificationCode && additionalInfoForm.errors.VerificationCode}
                          error={additionalInfoForm.errors.VerificationCode && additionalInfoForm.touched.VerificationCode !== undefined}
                        />
                      </FormControl>
                    </div>
                    <Button variant={'contained'} onClick={() => {
                      handleChangePhoneNumber(additionalInfoForm.values.VerificationCode, additionalInfoForm, handleClose, !!isCompanyContact)
                    }} color="primary">
                      <FormattedMessage id='ChangePhone.DialogActions.verify'/>
                    </Button>
                  </div>
                  :
                  <div className={classes.ChangePhoneVerifyWrapper}>
                    <div className={classes.ChangePhoneVerify}>
                      <InputLabel id="AdditionalInfo.newPhone">
                        <FormattedMessage id='Fields.label.AdditionalInfo.newPhone'/>
                      </InputLabel>
                      <LanguageConsumer>
                        {({ locale }) => (
                          <div className={classes.PhoneInputWrapper}>
                            <PhoneInput
                              value={additionalInfoForm.values.newPhone}
                              enableSearch={true}
                              countryCodeEditable={false}
                              jumpCursorToEnd={true}
                              country={'eg'}
                              localization={locale === 'ar' ? ar : ''}
                              specialLabel={''}
                              excludeCountries={['il']}
                              inputProps={{
                                name: 'newPhone',
                                required: true,
                                autoFocus: true
                              }}
                              searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                              searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                              onChange={(phone, data) => {
                                if (!!countryCodeKey) {
                                  additionalInfoForm.setFieldValue(countryCodeKey, data.countryCode)
                                }
                                additionalInfoForm.setFieldValue('newPhone', phone.toString())
                              }}
                              isValid={(value, country) => {
                                if (country.iso2 === 'eg' && value.length > 2 && value[2] && value[2].toString() !== '1') {
                                  setNewPhoneError(intl.formatMessage({ id: 'validationSchema.phoneStartingError' }))
                                  return false
                                }
                                if (country.iso2 === 'eg' && value.length > 12) {
                                  setNewPhoneError(intl.formatMessage({ id: 'validationSchema.phoneError' }))
                                  return false
                                } else {
                                  setNewPhoneError('')
                                  return true
                                }
                              }}
                            />
                            {!!newPhoneError && <FormHelperText error={true}>{newPhoneError}</FormHelperText>}
                          </div>
                        )}
                      </LanguageConsumer>
                    </div>
                    <Button variant={'contained'}
                            disabled={!!newPhoneError}
                            onClick={() => verifyNewPhoneNumber(additionalInfoForm.values.newPhone, !!isCompanyContact)}
                            color="primary">
                      <FormattedMessage id='ChangePhone.DialogActions.verify'/>
                    </Button>
                  </div>
                }
              </Box>
            </DialogContent>
            <DialogActions className={classes.ChangePhoneDialogFooter}>
              <Button onClick={handleClose} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </AuthenticatedUserConsumer>
  )
}
