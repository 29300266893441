import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, TableRowBackground } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  BalanceRowWrapper: {
    // display: 'inline-block',
    position: 'sticky',
    zIndex: 30,
    bottom: theme.spacing(0),
    left: theme.spacing(3),
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  availableBalance: {
    padding: theme.spacing(1.25, 2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(18),
    '&.error': {
      borderColor: theme.palette.error.main,
      '& strong': {
        color: theme.palette.error.main
      }
    }
  },
  BalancePaymentsAmountWrapper:{
    display: 'flex',
    alignItems: 'center'
  },


  formWrapper: {
    position: 'relative'
  },

  AdditionalInfoRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 170,
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      width: 380,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '&.column': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },


  root: {
    width: '100%',
    boxShadow: 'none',
    position: 'relative',
  },
  paginationWrapper: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  pager: {
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      color: theme.palette.common.white,
      cursor: 'default',
      '&:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.hover
      }
    }
  },
  container: {
    '& .MuiTableCell-root': {
      borderRight: `1px solid rgba(224,224,244,1)`,
      '&:first-child': {
        borderLeft: '1px solid rgba(224,224,224,1)'
      }
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    '&.noFilter .MuiTableCell-head': {
      background: FilterBackground,
      borderTop: '1px solid rgba(224,224,224,1)'
    }
  },
  dataRow: {
    '&:nth-of-type(4n+1), &:nth-of-type(4n+2)': {
      backgroundColor: TableRowBackground
    },
    '&.clickable': {
      cursor: 'pointer'
    }
  },
  dataCell: {
    '& a': {
      width: '100%',
      display: 'block',
      fontWeight: theme.typography.fontWeightBold
    },
    '& ul': {
      textAlign: 'left'
    }
  },
  spanLTR: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    display: 'inline-block'
  },
  actionsCell: {
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      whiteSpace: 'nowrap',
      '&:not(first-child)': {
        marginTop: theme.spacing(1)
      }
    }
  },
  partialWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  partialAmountsWrapper: {
    marginRight: theme.spacing(1),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  partialAmount: {
    color: theme.palette.grey['600'],
    position: 'relative',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 1,
      top: '50%',
      left: 0,
      backgroundColor: theme.palette.grey['600']

    }
  }
}))
