import React from 'react'
import WorkspaceTable from '../../Table'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import { CompanyConsumer } from '../../../../CompanyContext'
import { isEmpty } from '../../../../utils/helpers'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../../../../routes'
// import DelegatesFilter from './DelegatesFilter'
import { Box } from '@material-ui/core'
import UnAuthorize from '../../../UnAuthorize'
import { FormLoader } from '../../../Loader/FormLoader'
import { useStyles } from './Styles'
import { EcommerceConsumer, EcommerceProvider } from '../../../../EcommerceContext'
import AddEcommerceClient from './AddEcommerceClient'
import { NavLink } from 'react-router-dom'

const CompanyEcommerce = ({
                            selectedCompany, getEcommerceClient, setBreadCrumbLinks, setBreadCrumbCurrent,
                            setBreadCrumbButton, getFiltersData, paymentsFiltersData, paymentsFiltersFailure
                          }) => {
  const classes = useStyles()
  React.useEffect(() => {
      if (!!getFiltersData) {
        getFiltersData()
      }
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='workspaceMenu.link.item12'/>)
        setBreadCrumbButton(
          <CompanyConsumer>
            {({ handleSubmitEcommerceFilter, ecommerceFailure403, ecommerceLoading }) => (
              !ecommerceLoading && !ecommerceFailure403 &&
              <EcommerceProvider cid={selectedCompany.id} ecommerceData={{}} pageAction={'Add'}
                                 handleSubmitEcommerceFilter={handleSubmitEcommerceFilter}>
                <EcommerceConsumer>
                  {({ resetEcommerceStatus }) => (
                    <AddEcommerceClient resetEcommerceStatus={resetEcommerceStatus}
                                        companyIsAccountant={selectedCompany.is_accountant}/>
                  )}
                </EcommerceConsumer>
              </EcommerceProvider>
            )}
          </CompanyConsumer>
        )

      }
      if (!!getEcommerceClient) {
        getEcommerceClient(true)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <>
      <CompanyConsumer>
        {({
            ecommerceLoading, ecommerceFailure, ecommerceFailure403, ecommerceClients, ecommerceRefreshLoading,
            EcommerceHeaderColumns, handleSubmitEcommerceFilter, hasMoreEcommerce, isCompanyUnderage, companyFailureUnderage, companyFailureUnderageMessage
          }) => (
          <Box padding={2}>

            {!!ecommerceFailure403 ? <UnAuthorize/> :
              (!!isCompanyUnderage && !!companyFailureUnderage ?
                <Alert severity="error">
                  {companyFailureUnderageMessage}
                  <FormattedMessage id='Underage.ErrorAlert' values={{
                    a: chunks => <NavLink to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
                  }}/></Alert>
                : ((!!ecommerceFailure || !!paymentsFiltersFailure) &&
                  <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                ))
            }
            {/* {!ecommerceFailure403 && !companyFailureUnderage &&
            <DelegatesFilter
              submitForm={handleSubmitEcommerceFilter}
              paymentsFiltersData={paymentsFiltersData}/>
            } */}
            {!!ecommerceLoading && <SearchLoader loading={true}/>}
            {(!!ecommerceClients && !!ecommerceClients.length) ?
              <div className={classes.tableWrapper}>
                <WorkspaceTable
                  loadRows={getEcommerceClient}
                  hasMoreRows={hasMoreEcommerce}
                  rows={ecommerceClients}
                  columns={EcommerceHeaderColumns}
                  companyID={selectedCompany.id}
                  companyIsAccountant={selectedCompany.is_accountant}
                  ecommerceActions={true}/>
              </div>

              :
              <>
                {!!ecommerceRefreshLoading &&
                <div className={classes.refreshWrapper}><FormLoader loading={true}/></div>}
                {!ecommerceLoading && !ecommerceRefreshLoading && !ecommerceFailure403 && !companyFailureUnderage &&
                <EmptyResult isEcommerce={true}/>
                }
              </>
            }
          </Box>
        )}
      </CompanyConsumer>
    </>
  )
}

export default CompanyEcommerce
