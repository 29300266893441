import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative'
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 250,
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    },
    '&.alignTop': {
      alignItems: 'flex-start'
    },
    '& .MuiFormControl-root': {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: 340
      }
    },
    '&.column': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: 0
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },

  SubmitRow: {
    padding: theme.spacing(4, 0),
    textAlign: 'center'
  },
  amountHint: {
    // marginTop: theme.spacing(0.75),
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.75)
    }
  },
  tooltipPopper: {
    marginTop: theme.spacing(-1)
  },
  tooltipMobilePopper: {
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0.5)
  },
  tooltipWrapper:{
    fontSize: theme.typography.pxToRem(16),
    maxWidth: 500
  }
}))
