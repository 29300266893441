import axios from 'axios'
import Configuration from './Api/Configuration'
import { log } from '../utils/helpers'
import { LocalStorageService } from './LocalStorageService'
import { NamedRoutes } from '../routes'
// import { NamedRoutes } from '../routes'

// LocalstorageService
let { BASE_URL } = Configuration


axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common['apiToken'] = ''
axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 *  Add a request interceptor
 */
axios.interceptors.request.use((config) => {
  LocalStorageService.get('ETaxAppLanguage').then((appLanguage) => {
    config.headers['Accept-Language'] = appLanguage
  })
  return config
}, (error) => { log(error) })

/**
 * Add a response interceptor
 */
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  return new Promise((resolve, reject) => {
    if (error.response && error.response.status === 401) {
      console.log(error.response.status)
      window.location = NamedRoutes.unAuthorized
    }
    // if (error.response && error.response.status === 404) {
    //   console.log(error.response.status)
    //   window.location = NamedRoutes.home
    // }
    return reject(error)
  })
})
