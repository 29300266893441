import React from 'react'
import { Fade } from 'react-reveal'
import { NavLink } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'

const Item = ({ item, index, selectedCompany, checkIncentivesTaxIdValidity, locale, checkTaxServiceLink }) => {
  return (
    <Fade bottom delay={index * 100} key={item.key}>
     {!item.hide && <li>
        {!!item.isIncentive
          ? (!item.hide && <button rel="noopener noreferrer"
                                   onClick={() => checkIncentivesTaxIdValidity(selectedCompany?.tax_id)}>{item.label}</button>)
          : (!!item?.isTaxService ?
            <button rel="noopener noreferrer"
                    onClick={() => checkTaxServiceLink(item.link + (!!item.isSAP ? `&sap-language=${locale}` : ''))}>{item.label}</button>
            : (!!item.target
              ? <a href={item.link} target='_blank' rel="noopener noreferrer">{item.label}</a>
              : (!!item.noLink
                ? <button rel="noopener noreferrer">{item.label}</button>
                : <NavLink exact activeClassName="active"
                           to={(!!selectedCompany?.id) ? formatRoute(item.link, { id: selectedCompany.id }) : item.link}>
                  {item.label}
                </NavLink>)))
        }
        {!!item.subMenu && !!item.subMenu.length && (item.subMenu.length > item.subMenu?.filter(sub => !!sub.isUPTCP)?.length || (!!selectedCompany?.user_uptcp_enrollment_eligibility &&
          !!selectedCompany?.user_uptcp_enrolled_at)) &&
        <ul>
          {item.subMenu.map((subItem) => (
            !subItem.hide &&
            (!!item.isUPTCP ? (!!selectedCompany?.user_uptcp_enrollment_eligibility &&
                !!selectedCompany?.user_uptcp_enrolled_at &&
                <li key={subItem.key}>
                  <a href={subItem.link} target='_blank' rel="noopener noreferrer">{subItem.label}</a>
                </li>) :
                <li key={subItem.key}>
                  {!!item.isIncentive
                    ? (!item.hide && <button rel="noopener noreferrer"
                                             onClick={() => checkIncentivesTaxIdValidity(selectedCompany?.tax_id)}>{item.label}</button>)
                    : (!!item?.isTaxService ?
                      <button rel="noopener noreferrer"
                              onClick={() => checkTaxServiceLink(item.link + (!!item.isSAP ? `&sap-language=${locale}` : ''))}>{item.label}</button>
                      : (!!subItem.target
                        ? <a href={subItem.link} target='_blank' rel="noopener noreferrer">{subItem.label}</a>
                        : (!!item.noLink
                          ? <button rel="noopener noreferrer">{item.label}</button>
                          : <NavLink exact activeClassName="active"
                                     to={(!!selectedCompany?.id) ? formatRoute(subItem.link, { id: selectedCompany.id }) : subItem.link}>
                            {subItem.label}
                          </NavLink>)))
                  }
                </li>
            )))}
        </ul>
        }
      </li>}
    </Fade>
  )
}

export default Item
