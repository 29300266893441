import React from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import CompaniesPaymentsBalanceContent from '../../../../components/Workspace/CompanyPage/CompaniesPaymentsBalance'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import InActiveUserContent from '../../../../components/InActiveUserContent'
import { SearchLoader } from '../../../../components/Loader/SearchLoader'
import { payments } from '../../../../utils/pages-meta'
import { SEO } from '../../../../components/Seo/seo'
import NoMatch from '../../../NoMatch'

const PaymentsBalance = () => {
  let { id } = useParams()
  const intl = useIntl()
  return (
    <>
      {!!id && !!parseInt(id) ? <>
          <SEO title={intl.formatMessage({ id: 'PageTitle.PaymentsBalance' })}
               pathname={window.location.origin}
               titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
               image={payments.image}
               description={intl.formatMessage({ id: 'PageTitle.PaymentsBalance' })}
          />
          <AuthenticatedUserConsumer>
            {({ userDataLoading, isUserActive, userData, checkCompanyData, getFiltersData }) => (
              <>
                {!!userDataLoading && <SearchLoader loading={true}/>}
                {(!!Object.keys(userData).length && !!isUserActive) ?
                  <CompaniesPaymentsBalanceContent getFiltersData={getFiltersData}
                    checkCompanyData={checkCompanyData} cid={id}/>
                  : (!userDataLoading && <InActiveUserContent/>)
                }
              </>
            )}
          </AuthenticatedUserConsumer>
        </> :
        <NoMatch/>
      }
    </>
  )
}

export default PaymentsBalance
