import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground } from '../../../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  pdfContentWrapper: {
    height: 0,
    overflow: 'hidden',
    position: 'relative'
  },
  pdfContent: {
    visibility: 'hidden',
    position: 'fixed',
    width: 960,
    top: 0,
    flip: false,
    left: 0,
    zIndex: -1,
    padding: theme.spacing(5),
    '& .first-row': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    '& .dateWrapper': {
      marginTop: theme.spacing(3)
    },
    '& .itemWrapper': {
      display: 'flex',
      marginBottom: theme.spacing(1.5)
    },
    '& .itemLabel': {
      width: 200,
      backgroundColor: FilterBackground,
      padding: theme.spacing(0.25, 2, 0.75),
      margin: 0,
      border: `1px solid ${FilterBackground}`
    },
    '& .itemValue': {
      minWidth: 250,
      border: `1px solid ${fade(theme.palette.common.black, 0.4)}`,
      padding: theme.spacing(0, 2, 1.5),
      margin: 0,
      flex: '1 1 auto'
      // display: 'flex',
      // alignItems: 'center',
    },
    '& .data-row': {
      marginTop: theme.spacing(6)
    },
    '& .last-row': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
      display: 'flex',
      flexDirection: theme.direction === 'rtl' ? 'row-reverse' : 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
      '& h5': {
        fontSize: theme.typography.pxToRem(20),
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.primary
      }
    }
  },
  partialWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  partialAmount: {
    color: theme.palette.grey['600'],
    position: 'relative',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
    '& del': {
      verticalAlign: 'text-bottom'
    }
  },
}))
