import React from 'react'
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {FormattedMessage} from "react-intl";
import {Fade} from "react-reveal";
import {useStyles} from "../../components/NotFound/Styles";
import Configuration from '../../Services/Api/Configuration'

const UnderMaintenance = () => {
  const classes = useStyles()
  return (
    <>
      <Fade>
        <section>
          <Container maxWidth="lg">
            <Grid container justify='center'>
              <Grid item xs={12}>
                <div className={classes.notFoundWrapper} style={{textAlign: 'center'}}>
                  <h2><FormattedMessage id='under_maintenance_note'/></h2>
                  <a href={Configuration.UNDER_MENTINANCE_LINK}
                     style={{fontSize: 'large'}}> {Configuration.UNDER_MENTINANCE_LINK} </a>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </Fade>
    </>
  )
}

export default UnderMaintenance
