import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent, FormHelperText,
  DialogTitle, FormControl, FormControlLabel,
  IconButton, InputLabel, Radio, RadioGroup,
  Typography, Collapse
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../LanguageContext'
import NormalRegisterCompanyForm from './RegisterCompanyPlans/NormalRegister'
import SimpleRegisterCompanyForm from './RegisterCompanyPlans/SimpleRegister'
import HintDialog from './HintDialog'
import SimpleRegisterQuestionnaireForm from './RegisterCompanyPlans/SimpleRegisterQuestionnaire'

const RegisterCompanyDialog = ({ openRegisterCompanyDialog, handleRegisterCompanyDialog, verifiedCompaniesContacts, Transition }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [registrationPlan, setRegistrationPlan] = React.useState('')
  const [switchedFromSimple, setSwitchedFromSimple] = React.useState(false)
  const [viewNormalPlan, setNormalPlan] = React.useState(false)
  const [viewSimplePlan, setSimplePlan] = React.useState(false)
  const [viewSimplePlanQuestionnaire, setSimplePlanQuestionnaire] = React.useState(false)
  const [currentSimpleView, setCurrentSimpleView] = React.useState('Questionnaire')
  const [viewSimplePlanConfirm, setSimplePlanConfirm] = React.useState(false)
  const [viewSimplePlanHint, setSimplePlanHint] = React.useState(false)
  const handleSimplePlanHintDialog = () => {
    if (!!viewSimplePlanHint) {
      setSimplePlanConfirm(true)
    }
    setSimplePlanHint(!viewSimplePlanHint)
  }

  const switchToNormalRegistration = () => {
    setRegistrationPlan('normal')
    setSwitchedFromSimple(true)
  }
  const handleSimpleRegisterQuestionnaire = (values, actions) => {
    actions.setSubmitting(false)
    setSimplePlan(true)
    setCurrentSimpleView('SimpleForm')
    setTimeout(() => {
      setSimplePlanQuestionnaire(false)
    }, 100)
  }

  React.useEffect(() => {
      if (!!registrationPlan) {
        switch (registrationPlan) {
          case 'normal':
            setNormalPlan(true)
            setTimeout(() => {
              setSimplePlan(false)
              setSimplePlanConfirm(false)
              setSimplePlanQuestionnaire(false)
              setCurrentSimpleView('Questionnaire')
            }, 100)
            break
          case 'simple':
            setSimplePlanQuestionnaire(true)
            handleSimplePlanHintDialog()
            setTimeout(() => {
              setNormalPlan(false)
            }, 100)
            break
          default:
            break
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [registrationPlan])

  const registrationPlans = [
    {
      key: 'normal',
      name: intl.formatMessage({ id: 'registerCompanyForm.registrationPlan.normal' }),
      description: intl.formatMessage({ id: 'registerCompanyForm.registrationPlan.normal.description' })
    },
    {
      key: 'simple',
      name: intl.formatMessage({ id: 'registerCompanyForm.registrationPlan.simple' }),
      description: intl.formatMessage({ id: 'registerCompanyForm.registrationPlan.simple.description' }),
      disabled: !!switchedFromSimple
    }
  ]

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={openRegisterCompanyDialog}
      classes={{ paper: classes.UpdateCompanyRegistrationDialogPaper }}
      TransitionComponent={Transition}
      onClose={handleRegisterCompanyDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <AuthenticatedUserConsumer>
        {({
            registerCompanySuccess, registerCompanyFailure, getCountries, registerCompanySubmitForm, resetRegisterCompanyStatus,
            registerCompanyFailureMessage, emailVerificationSuccess, ridToSelect, updateCompanyRegistrationSuccess
          }) => (
          <>
            <DialogTitle disableTypography className={classes.LinkCompanyDialogHeader}>
              <Typography variant="h5"><FormattedMessage
                id='DashboardContent.ActionLinks.CreateCompany'/></Typography>
              <IconButton
                edge={false}
                className={classes.LinkCompanyDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleRegisterCompanyDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.LinkCompanyDialogContent}>
              <Box px={4} pt={4} pb={0} className={classes.LinkCompanyWrapper}>
                {!!emailVerificationSuccess &&
                <Alert severity="success"><FormattedMessage id='EmailVerification.FormSuccessAlert'/></Alert>
                }

                {!!registerCompanyFailure && !!registerCompanyFailureMessage &&
                <Alert severity="error">{registerCompanyFailureMessage}</Alert>
                }
                {!!registerCompanySuccess ?
                  <Alert severity="success"><FormattedMessage id='registerCompany.FormSuccessAlert'/></Alert>
                  : (!!updateCompanyRegistrationSuccess &&
                    <Alert severity="success"><FormattedMessage
                      id='updateCompanyRegistration.FormSuccessAlert'/></Alert>)
                }
                <LanguageConsumer>
                  {({ locale }) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
                      <div className={classes.formWrapper}>

                        <div className={classes.LinkCompanyFormRow + ' registration-plan'}>
                          <InputLabel id="registerCompanyForm.registration_plan"><FormattedMessage
                            id='Fields.label.RegistrationPlan'/></InputLabel>
                          <FormControl variant="outlined">
                            <RadioGroup aria-label="registerCompanyForm.registration_plan"
                                        name="registration_plan"
                                        value={registrationPlan}
                                        onChange={(event) => setRegistrationPlan(event.target.value)}>
                              {registrationPlans.map((plan) => (
                                <FormControlLabel disabled={!!plan?.disabled} key={plan.key} value={plan.key}
                                                  control={<Radio color={'primary'}/>}
                                                  label={<>
                                                    {plan.name}
                                                    {!!plan.description &&
                                                    <FormHelperText>{plan.description}</FormHelperText>}
                                                  </>}/>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <HintDialog handleHintDialog={handleSimplePlanHintDialog} openHintDialog={viewSimplePlanHint}/>


                        <Collapse in={registrationPlan === 'normal'}
                                  timeout="auto" unmountOnExit>
                          {!!viewNormalPlan &&
                          <NormalRegisterCompanyForm
                            getCountries={getCountries}
                            handleRegisterCompanyDialog={handleRegisterCompanyDialog}
                            locale={locale}
                            ridIsSelected={verifiedCompaniesContacts?.filter((contact) => contact?.id?.toString() === ridToSelect?.toString())?.length}
                            ridToSelect={ridToSelect}
                            registerCompanySubmitForm={registerCompanySubmitForm}
                            resetRegisterCompanyStatus={resetRegisterCompanyStatus}
                            verifiedCompaniesContacts={verifiedCompaniesContacts}/>}
                        </Collapse>

                        <Collapse in={registrationPlan === 'simple' && !!viewSimplePlanConfirm}
                                  timeout="auto" unmountOnExit>
                          <Collapse in={currentSimpleView === 'Questionnaire'} timeout="auto" unmountOnExit>
                            {!!viewSimplePlanQuestionnaire &&
                            <SimpleRegisterQuestionnaireForm
                              handleSimpleRegisterQuestionnaire={handleSimpleRegisterQuestionnaire}
                              switchToNormalRegistration={switchToNormalRegistration}/>}
                          </Collapse>
                          <Collapse in={currentSimpleView === 'SimpleForm'} timeout="auto" unmountOnExit>
                            {!!viewSimplePlan &&
                            <SimpleRegisterCompanyForm
                              getCountries={getCountries}
                              handleRegisterCompanyDialog={handleRegisterCompanyDialog}
                              locale={locale}
                              ridIsSelected={verifiedCompaniesContacts?.filter((contact) => contact?.id?.toString() === ridToSelect?.toString())?.length}
                              ridToSelect={ridToSelect}
                              registerCompanySubmitForm={registerCompanySubmitForm}
                              resetRegisterCompanyStatus={resetRegisterCompanyStatus}
                              verifiedCompaniesContacts={verifiedCompaniesContacts}/>}
                          </Collapse>
                        </Collapse>

                      </div>
                    </MuiPickersUtilsProvider>
                  )}
                </LanguageConsumer>
              </Box>
            </DialogContent>
            <DialogActions className={classes.LinkCompanyDialogFooter}>
              <Button onClick={handleRegisterCompanyDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </>
        )}
      </AuthenticatedUserConsumer>
    </Dialog>
  )
}

export default RegisterCompanyDialog
