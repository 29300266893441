/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, FormControl, InputLabel, TextField } from '@material-ui/core'
import { useStyles } from './Styles'
import { useKeycloak } from '@react-keycloak/web'

const BasicInfo = ({ userData, locale }) => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()
  const accountManagement = useCallback(() => {
      let newUrl = keycloak?.createAccountUrl() + '&kc_locale=' + locale
      window.location.href = newUrl
    }
    , [keycloak, locale])
  const intl = useIntl()
  return (
    <>
      <div className={classes.BasicInfoRow}>
        <InputLabel id="BasicInfo.firstName"> {<FormattedMessage id='Fields.label.firstName'/>} </InputLabel>
        <FormControl variant="outlined">
          <TextField
            disabled={true}
            placeholder={intl.formatMessage({ id: 'BasicInfo.placeholder.firstName' })}
            id="BasicInfo.firstName"
            type={'text'}
            margin="dense"
            variant="outlined"
            value={userData.first_name}
          />
        </FormControl>
      </div>
      <div className={classes.BasicInfoRow}>
        <InputLabel id="BasicInfo.lastName"> {<FormattedMessage id='Fields.label.lastName'/>} </InputLabel>
        <FormControl variant="outlined">
          <TextField
            disabled={true}
            placeholder={intl.formatMessage({ id: 'BasicInfo.placeholder.lastName' })}
            id="BasicInfo.lastName"
            type={'text'}
            margin="dense"
            variant="outlined"
            value={userData.last_name}
          />
        </FormControl>
      </div>
      <div className={classes.BasicInfoRow}>
        <InputLabel id="BasicInfo.username"> {<FormattedMessage id='Fields.label.username'/>} </InputLabel>
        <FormControl variant="outlined">
          <TextField
            disabled={true}
            placeholder={intl.formatMessage({ id: 'BasicInfo.placeholder.username' })}
            id="BasicInfo.username"
            type={'text'}
            margin="dense"
            variant="outlined"
            value={userData.username}
          />
        </FormControl>
      </div>
      <div className={classes.BasicInfoRow}>
        <InputLabel id="BasicInfo.email"> {<FormattedMessage id='Fields.label.email'/>} </InputLabel>
        <FormControl variant="outlined">
          <TextField
            disabled={true}
            placeholder={intl.formatMessage({ id: 'BasicInfo.placeholder.email' })}
            id="BasicInfo.email"
            type='email'
            margin="dense"
            variant="outlined"
            value={userData.email}
          />
        </FormControl>
      </div>
      <div className={classes.BasicInfoRow + ' ' + classes.accountManagementLinkWrapper}>
        <Button disableElevation disableRipple onClick={accountManagement}
                className={classes.accountManagementLink}>
          <FormattedMessage id='BasicInfo.link.edit'/>
        </Button>
      </div>
    </>
  )
}

export default BasicInfo
