import React from 'react'
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { TableRowBackground } from '../../../../utils/colors'
import DeleteDelegate from './DeleteDelegate'
import { useStyles } from './Styles'
import { DelegateConsumer } from '../../../../DelegateContext'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import EditDelegate from './EditDelegate'

const DelegateMenu = ({ resetDelegateStatus, companyIsAccountant }) => {
  let classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [viewDelegateMenu, setViewDelegateMenu] = React.useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setViewDelegateMenu(true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setViewDelegateMenu(false)
  }


  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${TableRowBackground}`
    }
  })((props) => (
    <Menu
      elevation={4}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableScrollLock
      {...props}
    />
  ))

  const [viewEditDelegateDialog, setViewEditDelegateDialog] = React.useState(false)
  const [openEditDelegateDialog, setOpenEditDelegateDialog] = React.useState(false)
  const handleEditDelegateDialog = () => {
    if (!openEditDelegateDialog) {
      resetDelegateStatus()
      setViewEditDelegateDialog(true)
    } else {
      setTimeout(() => setViewEditDelegateDialog(false), 500)
    }
    setOpenEditDelegateDialog(!openEditDelegateDialog)
  }

  const [viewDeleteDelegateDialog, setViewDeleteDelegateDialog] = React.useState(false)
  const [openDeleteDelegateDialog, setOpenDeleteDelegateDialog] = React.useState(false)
  const handleDeleteDelegateDialog = () => {
    if (!openDeleteDelegateDialog) {
      resetDelegateStatus()
      setViewDeleteDelegateDialog(true)
    } else {
      setTimeout(() => setViewDeleteDelegateDialog(false), 500)
    }
    setOpenDeleteDelegateDialog(!openDeleteDelegateDialog)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      {!!viewDelegateMenu &&
      <StyledMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classes.UserCustomMenu}
      >
        <MenuItem onClick={handleClose}>
          <Button color="primary" onClick={handleEditDelegateDialog}>
            <FormattedMessage id='DelegateMenu.updateDelegate'/>
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Button color="primary" onClick={handleDeleteDelegateDialog}>
            <FormattedMessage id='DelegateMenu.deleteDelegate'/>
          </Button>
        </MenuItem>
      </StyledMenu>
      }
      <DeleteDelegate
        handleDeleteDelegateDialog={handleDeleteDelegateDialog}
        viewDeleteDelegateDialog={viewDeleteDelegateDialog}
        openDeleteDelegateDialog={openDeleteDelegateDialog}/>

      {!!viewEditDelegateDialog &&
      <AuthenticatedUserConsumer>
        {({ paymentsFiltersData }) => (
          !!paymentsFiltersData && !!Object.keys(paymentsFiltersData).length &&
          <DelegateConsumer>
            {({ delegateData, handleDelegateSubmit }) => (
              <EditDelegate
                openEditDelegateDialog={openEditDelegateDialog}
                handleEditDelegateDialog={handleEditDelegateDialog}
                delegateData={delegateData}
                companyIsAccountant={companyIsAccountant}
                handleDelegateSubmit={handleDelegateSubmit}
                paymentsFiltersData={paymentsFiltersData}
              />
            )}
          </DelegateConsumer>
        )}
      </AuthenticatedUserConsumer>
      }
    </div>
  )
}

export default DelegateMenu
