import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './Styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DownPaymentOtherPaymentDialog = ({ handleDialog, open }) => {
  const classes = useStyles()
  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'md'}
      open={open}
      classes={{ paper: classes.NewOrderDialogPaper }}
      TransitionComponent={Transition}
      onClose={handleDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
        <Typography variant="h5"><FormattedMessage id='PaymentsContent.AddDownPayment.payCPS'/></Typography>
        <IconButton
          edge={false}
          className={classes.NewOrderDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleDialog}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.NewOrderDialogContent}>
        <Box p={4} className={classes.NewOrderStepsWrapper}>
          <div className={classes.PaymentOrderStepMessage}>
            <FormattedMessage
              id='DownPaymentOtherPaymentDialog.content'
              values={{
                strong: strongChunks => <strong>{strongChunks}</strong>,
                li: liChunks => <li>{liChunks}</li>,
                ul: ulChunks => <ul>{ulChunks}</ul>,
                bankList: <FormattedMessage
                  id={'DownPaymentOtherPayment.bankListContent'}
                  values={{
                    li: liChunks => <li>{liChunks}</li>,
                    ol: ulChunks => <ol>{ulChunks}</ol>
                  }}/>,
                cpsList: <FormattedMessage
                  id={'DownPaymentOtherPayment.CPSListContent'}
                  values={{
                    li: liChunks => <li>{liChunks}</li>,
                    ol: ulChunks => <ol>{ulChunks}</ol>
                  }}/>
              }}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions className={classes.NewOrderDialogFooter}>
        <Button onClick={handleDialog} color="primary">
          <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DownPaymentOtherPaymentDialog

