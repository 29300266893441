/** It checks if the user is authenticated, if they are,
 it renders the "component" prop. If not, it redirects
 the user to /login.
 **/
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { log } from '../utils/helpers'
import { useKeycloak } from '@react-keycloak/web'
import { SearchLoader } from '../components/Loader/SearchLoader'
import { LanguageConsumer } from '../LanguageContext'

const RedirectRoute = ({ redirectURL, ...rest }) => {
  const { keycloak, initialized } = useKeycloak()
  log('Access Token is set:', keycloak?.authenticated)
  return (
    <>
      {!initialized && <SearchLoader loading={true}/>}
      {!!initialized &&
      <LanguageConsumer>
        {({ locale }) => (
          <Route {...rest} render={(props) => {
            if (!keycloak?.authenticated) {
              let refs = keycloak?.createAccountUrl().split('?')[1]
              let newUrl = keycloak?.createLoginUrl({ locale: locale }) + '&' + refs
              window.location.href = newUrl
            }
            return (
              keycloak?.authenticated &&
              <Redirect to={{
                pathname: redirectURL,
                state: { from: props.location }
              }}/>)
          }}/>
        )}
      </LanguageConsumer>
      }
    </>
  )
}
export default RedirectRoute
