import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  EditProfileContentContainer: {
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  EditProfileContent: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 3)
  },
  EditProfileContentTitle: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid #dfdfdf`,
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& h2': {
      color: theme.palette.primary.light,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(1)
    },
    '& button': {
      marginBottom: theme.spacing(1)
    }
  }
}))
