import React from 'react'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import CompanyPayments from './CompanyPayments'
import { SEO } from '../../../Seo/seo'
import { payments } from '../../../../utils/pages-meta'
import { useIntl } from 'react-intl'
import InActiveCompanyContent from '../../../InActiveCompanyContent'

const CompanyPaymentsContent = ({ checkCompanyData, cid }) => {
  const intl = useIntl()
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany, getFiltersData, paymentsFiltersData, paymentsFiltersFailure }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={intl.formatMessage({ id: 'PageTitle.Payments' }) + ' - ' + selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                   image={payments.image}
                   description={intl.formatMessage({ id: 'PageTitle.Payments' }) + ' - ' + selectedCompany.taxpayer_name}
              />
              {!!selectedCompany.active && !!selectedCompany.company_user_active ?
                <CompanyConsumer>
                  {({ getPayments, setBreadCrumbLinks, setBreadCrumbCurrent }) => (
                    <CompanyPayments selectedCompany={selectedCompany}
                                     getPayments={getPayments}
                                     getFiltersData={getFiltersData}
                                     paymentsFiltersData={paymentsFiltersData}
                                     paymentsFiltersFailure={paymentsFiltersFailure}
                                     setBreadCrumbLinks={setBreadCrumbLinks}
                                     setBreadCrumbCurrent={setBreadCrumbCurrent}/>
                  )}
                </CompanyConsumer>
                : <InActiveCompanyContent/>
              }
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default CompanyPaymentsContent
