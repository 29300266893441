import React from 'react'
import { LanguageConsumer } from '../../../LanguageContext'
import { Container, Button } from '@material-ui/core'
import BasicInfo from './BasicInfo'
import { FormattedMessage } from 'react-intl'
import AdditionalInfo from './AdditionalInfo'
import { SearchLoader } from '../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { useStyles } from './Styles'
import { NamedRoutes } from '../../../routes'
import { useHistory } from 'react-router-dom'

const EditProfileContent = ({ resetSelectedCompany, getCountries }) => {
  const classes = useStyles()
  let history = useHistory()
  React.useEffect(() => {
      getCountries()
      resetSelectedCompany()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <Container maxWidth="lg" className={classes.EditProfileContentContainer}>
      <div className={classes.EditProfileContent}>
        <div className={classes.EditProfileContentTitle}>
          <h2><FormattedMessage id='EditProfileContent.title'/></h2>
          <Button color={'primary'} size={'large'} variant={'contained'}
                  onClick={() => history.push({
                    pathname: NamedRoutes.workspace.editCompanyContact
                  })}>
            <FormattedMessage id='profile.CompaniesProfileTitle'/>
          </Button>
        </div>
        <AuthenticatedUserConsumer>
          {({ userDataLoading, userDataFailure, userData, updateAdditionalInfo, getGovernments, governments, getCities, cities, resetAdditionalInfoStatus }) => (
            <>
              {!!userDataLoading && <SearchLoader loading={true}/>}
              {!!userDataFailure &&
              <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
              }
              {!!userData && !!Object.keys(userData).length &&
              <>
                <LanguageConsumer>
                  {({ locale }) => (
                    <BasicInfo userData={userData} locale={locale}/>
                  )}
                </LanguageConsumer>
                <AdditionalInfo
                  userData={userData}
                  getGovernments={getGovernments}
                  governments={governments}
                  getCities={getCities}
                  cities={cities}
                  resetAdditionalInfoStatus={resetAdditionalInfoStatus}
                  submitForm={updateAdditionalInfo}/>
              </>
              }
            </>
          )}
        </AuthenticatedUserConsumer>
      </div>
    </Container>
  )
}

export default EditProfileContent
