import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { CompanyContactConsumer } from '../../../../../CompanyContactContext'
import { FormLoader } from '../../../../Loader/FormLoader'
import { useStyles } from '../Styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CompanyContactDelete = ({ getCompaniesContacts }) => {
  const classes = useStyles()
  const [viewDeleteCompanyContactDialog, setViewDeleteCompanyContactDialog] = React.useState(false)
  const [openDeleteCompanyContactDialog, setOpenDeleteCompanyContactDialog] = React.useState(false)
  const handleDeleteCompanyContactDialog = () => {
    if (!openDeleteCompanyContactDialog) {
      setViewDeleteCompanyContactDialog(true)
    } else {
      setTimeout(() => setViewDeleteCompanyContactDialog(false), 500)
    }
    setOpenDeleteCompanyContactDialog(!openDeleteCompanyContactDialog)
  }
  return (
    <CompanyContactConsumer>
      {({
          companyContact, deleteCompanyContactLoading, deleteCompanyContactFailure,
          deleteCompanyContactFailureMessage, deleteCompanyContactSuccess, handleDeleteCompanyContact
        }) => (
        <>
          <Button size="large" className={classes.DeleteOrderBtn} variant="contained" color="primary"
                  onClick={handleDeleteCompanyContactDialog}>
            <FormattedMessage id='PaymentOrder.ActionLinks.Delete'/>
          </Button>
          {!!viewDeleteCompanyContactDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'sm'}
            open={openDeleteCompanyContactDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleDeleteCompanyContactDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <IconButton
              edge={false}
              className={classes.NewOrderDialogClose}
              color="inherit"
              aria-label="close dialog"
              onClick={handleDeleteCompanyContactDialog}
            >
              <CloseIcon/>
            </IconButton>
            <DialogContent className={classes.NewOrderDialogContent}>
              <Box p={4} className={classes.DeleteOrderWrapper}>
                {!!deleteCompanyContactLoading && <FormLoader loading={true}/>}
                {!!deleteCompanyContactFailure && !!deleteCompanyContactFailureMessage &&
                <Alert severity="error">{deleteCompanyContactFailureMessage}</Alert>
                }
                {!!deleteCompanyContactSuccess &&
                <Alert severity="success"><FormattedMessage id='CompanyContactDelete.FormSuccessAlert'/></Alert>
                }
                <WarningIcon className={classes.DeleteWarningIcon}/>
                <p><FormattedMessage id='CompanyContact.DeleteMessage' values={{ taxId: companyContact.tax_id }}/></p>
              </Box>
            </DialogContent>
            <DialogActions className={classes.NewOrderDialogFooter}>
              <Button size="large" variant="contained" color="primary" className={classes.DeleteOrderBtn}
                      onClick={() => {
                        handleDeleteCompanyContact(getCompaniesContacts, handleDeleteCompanyContactDialog)
                      }}>
                <FormattedMessage id='PaymentOrder.ActionLinks.Delete'/>
              </Button>
              <Button onClick={handleDeleteCompanyContactDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
          }
        </>
      )}
    </CompanyContactConsumer>
  )
}

export default CompanyContactDelete
