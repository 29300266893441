import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  NewOrderStepsWrapper: {
    whiteSpace: 'pre-line',
    position: 'relative'
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  },
  PaymentOrderStepMessage: {
    whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(18),
    lineHeight: 1.7,
    '&.center': {
      textAlign: 'center'
    },
    '& p': {
      marginBottom: theme.spacing(1)
    }
  },
  PaymentFirstStepActionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    '& button': {
      fontSize: theme.typography.pxToRem(22),
      minWidth: '20%'
    }
  },
  PaymentFirstStepAlert: {
    marginTop: theme.spacing(2),
    '& .MuiAlert-message': {
      fontSize: theme.typography.pxToRem(16)
    }, '& .MuiAlert-icon': {
      fontSize: theme.typography.pxToRem(28)
    }
  },
  NewOrderPayButton: {
    minWidth: '20%'
  },
  fabWrapper: {
    display: 'inline-block',
    position: 'sticky',
    zIndex: 10,
    bottom: theme.spacing(3),
    left: theme.spacing(3)
  },
  fab: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover
    }
  }
}))
