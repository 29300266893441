import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  userMenuButton: {
    borderRadius: 0,
    color: theme.palette.primary.main,
    marginRight: 0,
    padding: 0,
    transition: 'all 0.3s ease-in-out',
    '& span': {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: theme.spacing(1.25)
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.hover
    }
  },
  userAvatarWrapper: {
    position: 'relative',
    '& .MuiBadge-badge': {
      backgroundColor: 'transparent',
      padding: 0,
      margin: 0,
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      color: theme.palette.error.main
    }
  },
  userInActiveWrapper:{
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
  },
  userInActive: {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 1,
    position: 'absolute',
    color: theme.palette.error.main,
    opacity: 0.7,
    fontSize: theme.typography.pxToRem(40)
  },
  userAvatar: {
    border: `2px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
      fontSize: theme.typography.pxToRem(56),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  },
  userMenuPaper: {
    marginTop: theme.spacing(7),
    '& ul': {
      '& li': {
        padding: theme.spacing(0)
      },
      '& a, & li.signOut': {
        padding: theme.spacing(2)
      },
      '& li, & a': {
        width: '100%',
        transition: 'all 0.3s ease-in-out',
        color: theme.palette.primary.light,
        minWidth: 175
      },
      '& a.active': {
        color: theme.palette.warning.dark
      }
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: 'transparent',
      '&, & a': {
        color: theme.palette.warning.dark
      }
    }
  },

  MobileUserMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    '& button, & a': {
      textAlign: 'center !important',
      fontSize: theme.typography.pxToRem(20)
    }
  },
  userName: {
    fontSize: theme.typography.pxToRem(24),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white
  },
  userInActiveMessage:{
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'center',
    marginTop: theme.spacing(-2),
  }
}))
