import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  AccordionSummary,
  AccordionDetails, Accordion, TextField, Grid
} from '@material-ui/core'
import { DownPaymentFilterFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import { lg } from '../../../../utils/ResponsiveUtility'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LanguageConsumer } from '../../../../LanguageContext'

const Filter = ({ submitForm }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [taxPeriodDateFrom, setTaxPeriodDateFrom] = React.useState(null)
  const [taxPeriodDateTo, setTaxPeriodDateTo] = React.useState(null)
  const [createdDateFrom, setCreatedDateFrom] = React.useState(null)
  const [createdDateTo, setCreatedDateTo] = React.useState(null)

  const filterForm = useFormik({
    initialValues: {
      request_number: '',
      request_state: '',
      payment_tax_period_from: '',
      payment_tax_period_to: '',
      created_from: '',
      created_to: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: DownPaymentFilterFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const DownPaymentStates = [
    {
      id: 0,
      name: <FormattedMessage id='PaymentHeader.requestState'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='PaymentHeader.requestState.pending'/>,
      value: 'pending'
    },
    {
      id: 2,
      name: <FormattedMessage id='PaymentHeader.requestState.paidAndWaiting'/>,
      value: 'paid_and_waiting_acknowledgment'
    },
    {
      id: 3,
      name: <FormattedMessage id='PaymentHeader.requestState.paid'/>,
      value: 'paid'
    },
    {
      id: 4,
      name: <FormattedMessage id='PaymentHeader.requestState.expired'/>,
      value: 'expired'
    },
    {
      id: 5,
      name: <FormattedMessage id='PaymentHeader.requestState.failed'/>,
      value: 'failed'
    }
  ]
  const handleDownPaymentStateChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('request_state', event.target.value.toString())
  }

  const handleTaxPeriodDateFromChange = (date) => {
    setTaxPeriodDateFrom(date)
    filterForm.setFieldValue('payment_tax_period_from', date || '')
  }
  const handleTaxPeriodDateToChange = (date) => {
    setTaxPeriodDateTo(date)
    filterForm.setFieldValue('payment_tax_period_to', date || '')
  }

  const handleCreatedDateFromChange = (date) => {
    setCreatedDateFrom(date)
    filterForm.setFieldValue('created_from', date || '')
  }
  const handleCreatedDateToChange = (date) => {
    setCreatedDateTo(date)
    filterForm.setFieldValue('created_to', date || '')
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <LanguageConsumer>
          {({ locale }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
              <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper + ' center'}>
                <Grid container justify={'center'} wrap={'wrap'} className={classes.filtersFieldsWrapper}>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id="filterForm.request_number"
                      type={'text'}
                      margin="dense"
                      label={<FormattedMessage id='PaymentHeader.requestNumber'/>}
                      variant="outlined"
                      name='request_number'
                      onChange={filterForm.handleChange}
                      onBlur={filterForm.handleBlur}
                      value={filterForm.values.request_number}
                      helperText={filterForm.errors.request_number && filterForm.touched.name && filterForm.errors.request_number}
                      error={filterForm.errors.request_number && filterForm.touched.request_number !== undefined}
                    />
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="request_state"> {<FormattedMessage id='PaymentHeader.requestState'/>} </InputLabel>
                    <Select
                      fullWidth
                      labelId="request_state"
                      id="request_state"
                      margin="dense"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      name="request_state"
                      value={filterForm.values.request_state}
                      onChange={handleDownPaymentStateChange}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.request_state && filterForm.touched.request_state !== undefined}
                      label={<FormattedMessage id='PaymentHeader.requestState'/>}
                    >
                      {DownPaymentStates.map((item) => (
                        <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.request_state && filterForm.touched.request_state && (
                      <FormHelperText error={true}>{filterForm.errors.request_state}</FormHelperText>
                    )}
                  </FormControl>

                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="payment_tax_period_from"
                    name='payment_tax_period_from'
                    label={intl.formatMessage({ id: 'PaymentHeader.taxPeriodFrom' })}
                    value={taxPeriodDateFrom}
                    onChange={handleTaxPeriodDateFromChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.payment_tax_period_from && filterForm.touched.payment_tax_period_from &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.payment_tax_period_from && filterForm.touched.payment_tax_period_from !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'tax period date from'
                    }}
                  />

                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="payment_tax_period_to"
                    name='payment_tax_period_to'
                    label={intl.formatMessage({ id: 'PaymentHeader.taxPeriodTo' })}
                    value={taxPeriodDateTo}
                    onChange={handleTaxPeriodDateToChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.payment_tax_period_to && filterForm.touched.payment_tax_period_to &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.payment_tax_period_to && filterForm.touched.payment_tax_period_to !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'tax period date to'
                    }}
                  />

                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="created_from"
                    name='created_from'
                    label={intl.formatMessage({ id: 'Fields.placeholder.dateFrom' })}
                    value={createdDateFrom}
                    onChange={handleCreatedDateFromChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.created_from && filterForm.touched.created_from &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.created_from && filterForm.touched.created_from !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'date from'
                    }}
                  />
                  <KeyboardDatePicker
                    fullWidth
                    className={classes.dateFormControl}
                    disableToolbar
                    variant="outlined"
                    format="dd-MM-yyyy"
                    margin='dense'
                    id="created_to"
                    name='created_to'
                    label={intl.formatMessage({ id: 'Fields.placeholder.dateTo' })}
                    value={createdDateTo}
                    onChange={handleCreatedDateToChange}
                    onBlur={filterForm.handleBlur}
                    helperText={filterForm.errors.created_to && filterForm.touched.created_to &&
                    <FormattedMessage id='validationSchema.date'/>}
                    error={filterForm.errors.created_to && filterForm.touched.created_to !== undefined}
                    cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                    okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                    KeyboardButtonProps={{
                      'aria-label': 'date to'
                    }}
                  />
                </Grid>
                <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                        className={classes.submitButton + ' noMargin'}>
                  <FormattedMessage id='Filter.button.submit'/>
                </button>
              </form>
            </MuiPickersUtilsProvider>
          )}
        </LanguageConsumer>
      </AccordionDetails>
    </Accordion>
  )
}

export default Filter
