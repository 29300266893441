import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Typography
} from '@material-ui/core'
import { useNotificationsStyles } from './Styles'
import { useStyles } from '../CompaniesPayments/PaymentNewOrder/Styles'
import CloseIcon from '@material-ui/icons/Close'
import { FormattedMessage } from 'react-intl'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Item = ({ label, value, url }) => {
  const classes = useStyles()
  const notificationClasses = useNotificationsStyles()
  const [viewNotificationDialog, setViewNotificationDialog] = React.useState(false)
  const [openNotificationDialog, setOpenNotificationDialog] = React.useState(false)
  const handleNotificationDialog = () => {
    if (!openNotificationDialog) {
      setViewNotificationDialog(true)
    } else {
      setTimeout(() => {
        setViewNotificationDialog(false)
      }, 500)
    }
    setOpenNotificationDialog(!openNotificationDialog)
  }

  return (
    <>
      <Button disableRipple onClick={handleNotificationDialog} className={notificationClasses.itemWrapper}>
        <h3 className={notificationClasses.itemLabel}>{label}</h3>
        <span className={notificationClasses.itemValue}>{value}</span>
      </Button>
      {!!viewNotificationDialog &&
      <Dialog
        keepMounted
        fullWidth
        maxWidth={'md'}
        open={openNotificationDialog}
        classes={{ paper: classes.NewOrderDialogPaper }}
        TransitionComponent={Transition}
        onClose={handleNotificationDialog}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
          <Typography variant="h5">{label}</Typography>
          <IconButton
            edge={false}
            className={classes.NewOrderDialogClose}
            color="inherit"
            aria-label="close dialog"
            onClick={handleNotificationDialog}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.NewOrderDialogContent}>
          <Box p={4} className={classes.NewOrderStepsWrapper}>
            <span className={notificationClasses.itemValue}>{value}</span>
          </Box>
        </DialogContent>
        <DialogActions className={classes.NewOrderDialogFooter}>
          <Button size="large" variant="contained" color="primary" href={url}>
            <FormattedMessage id='PaymentOrder.ActionLinks.GoLink'/>
          </Button>
          <Button onClick={handleNotificationDialog} color="primary">
            <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
          </Button>
        </DialogActions>
      </Dialog>
      }
    </>
  )
}

export default Item
