import React from 'react'
import { Collapse, Container } from '@material-ui/core'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import InsuranceOrganizationBranches from './InsuranceOrganizationBranches'
import { SearchLoader } from '../../Loader/SearchLoader'
import InActiveUserContent from '../../InActiveUserContent'
import InsuranceServicesActionLinks from './InsuranceServicesActionLinks'
import { InsuranceServicesProvider, InsuranceServicesConsumer } from '../../../InsuranceServicesContext'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { isObject } from 'formik'
import { useStyles } from './Styles'

const InsuranceServicesContent = () => {
  const classes = useStyles()
  return (
    <>
      <AuthenticatedUserConsumer>
        {({ userDataLoading, isUserActive, userData }) => (
          <>
            <Container maxWidth="lg" className={classes.TitleContainer}>
              <h1><FormattedMessage id='workspaceMenu.link.item2'/></h1>
            </Container>
            {!!userDataLoading && <SearchLoader loading={true}/>}
            {(!!Object.keys(userData).length && !!isUserActive) ?
              <InsuranceServicesProvider has_taxation_file={userData.has_taxation_file}>
                <InsuranceServicesConsumer>
                  {({
                      showUserInsuranceRegistration, showUserInsuranceRegistrationFailure, insuranceOrganization,
                      resetLinkInsuranceOrganizationStatus
                    }) => (
                    <>
                      {!!showUserInsuranceRegistrationFailure &&
                      <Container maxWidth="lg" disableGutters>
                        <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
                      </Container>
                      }
                      <Collapse
                        in={!!showUserInsuranceRegistration && !!isObject(insuranceOrganization) && !Object.keys(insuranceOrganization).length}
                        timeout="auto" unmountOnExit>
                        {!!showUserInsuranceRegistration && !!isObject(insuranceOrganization) && !Object.keys(insuranceOrganization).length &&
                        <InsuranceServicesActionLinks
                          resetLinkInsuranceOrganizationStatus={resetLinkInsuranceOrganizationStatus}
                        />
                        }
                      </Collapse>
                      <InsuranceOrganizationBranches/>
                    </>
                  )}
                </InsuranceServicesConsumer>
              </InsuranceServicesProvider>
              : (!userDataLoading && <InActiveUserContent/>)
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </>
  )
}

export default InsuranceServicesContent
