import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  CompaniesContentContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))
