import React from 'react'
import { Button, Container, Slide } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './Styles'
import LinkInsuranceOrganizationDialog from './LinkInsuranceOrganizationDialog'
import { InsuranceServicesConsumer } from '../../../../InsuranceServicesContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const InsuranceServicesActionLinks = ({ resetLinkInsuranceOrganizationStatus }) => {
  const classes = useStyles()
  const [viewLinkInsuranceOrganizationDialog, setViewLinkInsuranceOrganizationDialog] = React.useState(false)
  const [openLinkInsuranceOrganizationDialog, setOpenLinkInsuranceOrganizationDialog] = React.useState(false)
  const handleLinkInsuranceOrganizationDialog = () => {
    if (!openLinkInsuranceOrganizationDialog) {
      setViewLinkInsuranceOrganizationDialog(true)
    } else {
      setTimeout(() => {
        setViewLinkInsuranceOrganizationDialog(false)
        resetLinkInsuranceOrganizationStatus()
      }, 500)
    }
    setOpenLinkInsuranceOrganizationDialog(!openLinkInsuranceOrganizationDialog)
  }

  return (
    <InsuranceServicesConsumer>
      {({ linkInsuranceOrganizationSubmitForm }) => (
        <>
          <div className={classes.DashboardActionLinksWrapper}>
            <Container maxWidth="lg" className={classes.DashboardActionLinksContainer}>

              <div className={classes.DashboardActionLinksItem}>
                <h4><FormattedMessage id='InsuranceServicesContent.ActionLinks.LinkOrganization.title'/></h4>
                <Button size="large" variant="contained" color="primary"
                        onClick={handleLinkInsuranceOrganizationDialog}>
                  <FormattedMessage id='InsuranceServicesContent.ActionLinks.LinkOrganization'/>
                </Button>
                {!!viewLinkInsuranceOrganizationDialog &&
                <LinkInsuranceOrganizationDialog
                  openLinkInsuranceOrganizationDialog={openLinkInsuranceOrganizationDialog}
                  handleLinkInsuranceOrganizationDialog={handleLinkInsuranceOrganizationDialog}

                  linkInsuranceOrganizationSubmitForm={linkInsuranceOrganizationSubmitForm}
                  resetLinkInsuranceOrganizationStatus={resetLinkInsuranceOrganizationStatus}
                  Transition={Transition}
                />
                }
              </div>
            </Container>
          </div>
        </>
      )}
    </InsuranceServicesConsumer>
  )
}

export default InsuranceServicesActionLinks
