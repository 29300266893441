import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  socialIcons: {
    marginRight: theme.spacing(2)
  },
  socialIconsLink: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(12)
    },
    '&.linkedIn': {
      backgroundColor: '#1686b0'
    },
    '&.twitter': {
      backgroundColor: '#1cb7eb'
    },
    '&.youtube': {
      backgroundColor: '#c82f2f'
    },
    '&.facebook': {
      backgroundColor: '#4f71a8'
    }
  }
}))
