import React from 'react'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { CompanyConsumer } from '../../../../CompanyContext'
import CompanyContentWrapper from '../CompanyContentWrapper'
import CompanyEcommerce from './CompanyEcommerceClient'
import { SEO } from '../../../Seo/seo'
import { ecommerce } from '../../../../utils/pages-meta'
import { useIntl } from 'react-intl'
// import InActiveCompanyContent from '../../../InActiveCompanyContent'


const DelegatesContent = ({ checkCompanyData, cid }) => {
  const intl = useIntl()
  return (
    <CompanyContentWrapper checkCompanyData={checkCompanyData} cid={cid}>
      <AuthenticatedUserConsumer>
        {({ selectedCompany, getFiltersData, paymentsFiltersData, paymentsFiltersFailure }) => (
          <>
            {!!selectedCompany && !!Object.keys(selectedCompany).length &&
            <>
              <SEO title={intl.formatMessage({ id: 'PageTitle.EcommerceClients' }) + ' - ' + selectedCompany.taxpayer_name}
                   pathname={window.location.origin}
                   titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
                   image={ecommerce.image}
                   description={intl.formatMessage({ id: 'PageTitle.EcommerceClients' }) + ' - ' + selectedCompany.taxpayer_name}
              />

                  <CompanyConsumer>
                    {({ getEcommerceClient, setBreadCrumbLinks, setBreadCrumbCurrent, setBreadCrumbButton }) => (
                      <CompanyEcommerce selectedCompany={selectedCompany}
                                        getEcommerceClient={getEcommerceClient}
                                        getFiltersData={getFiltersData}
                                        paymentsFiltersData={paymentsFiltersData}
                                        paymentsFiltersFailure={paymentsFiltersFailure}
                                        setBreadCrumbLinks={setBreadCrumbLinks}
                                        setBreadCrumbCurrent={setBreadCrumbCurrent}
                                        setBreadCrumbButton={setBreadCrumbButton}/>
                    )}
                  </CompanyConsumer>
            </>
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </CompanyContentWrapper>
  )
}

export default DelegatesContent
