import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel, FormHelperText,
  IconButton, InputLabel, MenuItem, Select, Switch, TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, useIntl } from 'react-intl'
import { linkCompanyFormValidation } from '../../../utils/validationSchema'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { FormLoader } from '../../Loader/FormLoader'
import PhoneInput from 'react-phone-input-2'
import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { LanguageConsumer } from '../../../LanguageContext'

const LinkCompanyDialog = ({
                             openLinkCompanyDialog, handleLinkCompanyDialog, linkCompanySubmitForm, previousIncentiveCompanies,
                             resetLinkCompanyStatus, verifiedCompaniesContacts, ridToSelect, Transition
                           }) => {
  const classes = useStyles()
  const intl = useIntl()
  let defaultCompanyContact = !!ridToSelect ? verifiedCompaniesContacts.filter((contact) => contact.id.toString() === ridToSelect.toString()) : null

  const linkCompanyForm = useFormik({
    initialValues: {
      tax_id_list: ridToSelect || '',
      tax_id: (!!defaultCompanyContact && !!defaultCompanyContact.length && defaultCompanyContact[0].tax_id) ? defaultCompanyContact[0].tax_id : '',
      email: (!!defaultCompanyContact && !!defaultCompanyContact.length && defaultCompanyContact[0].email) ? defaultCompanyContact[0].email : '',
      mobile: (!!defaultCompanyContact && !!defaultCompanyContact.length && defaultCompanyContact[0].phone) ? defaultCompanyContact[0].phone : '',
      main_partner_name: '',
      verification_code1: '',
      verification_code2: '',
      is_accountant: false
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: linkCompanyFormValidation,
    onSubmit: ((values, actions) => {
      linkCompanySubmitForm(values, actions, handleLinkCompanyDialog)
    })
  })

  React.useEffect(() => {
      return () => {
        resetLinkCompanyStatus()
        linkCompanyForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  React.useEffect(() => {
      if (previousIncentiveCompanies?.length) {
        let contact = verifiedCompaniesContacts?.filter((contact) => contact?.tax_id?.toString() === previousIncentiveCompanies[0]?.tax_id?.toString())
        if (contact?.length) {
          linkCompanyForm.setFieldValue('tax_id_list', contact[0]?.id?.toString())
          linkCompanyForm.setFieldValue('tax_id', contact[0]?.tax_id)
          linkCompanyForm.setFieldValue('email', contact[0]?.email)
          linkCompanyForm.setFieldValue('mobile', contact[0]?.phone)
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [verifiedCompaniesContacts])

  const handleCompanyContactChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    linkCompanyForm.setFieldValue('tax_id_list', event.target.value.toString())
    if (!!event.target.value.toString()) {
      let companyContact = verifiedCompaniesContacts.filter((contact) => contact.id.toString() === event.target.value.toString())[0]
      linkCompanyForm.setFieldValue('tax_id', companyContact.tax_id)
      linkCompanyForm.setFieldValue('email', companyContact.email)
      linkCompanyForm.setFieldValue('mobile', companyContact.phone)
    } else {
      linkCompanyForm.setFieldValue('tax_id', '')
      linkCompanyForm.setFieldValue('email', '')
      linkCompanyForm.setFieldValue('mobile', '20')
    }
    setTimeout(() => linkCompanyForm.setFieldTouched('tax_id', true, true), 50)

  }
  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={openLinkCompanyDialog}
      classes={{ paper: classes.LinkCompanyDialogPaper }}
      TransitionComponent={Transition}
      onClose={handleLinkCompanyDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <AuthenticatedUserConsumer>
        {({ linkCompanySuccess, linkCompanyFailure, linkCompanyFailureMessage, linkCompanyData, emailVerificationSuccess }) => (
          <>
            <DialogTitle disableTypography className={classes.LinkCompanyDialogHeader}>
              <Typography variant="h5"><FormattedMessage
                id='DashboardContent.ActionLinks.LinkCompany'/></Typography>
              <IconButton
                edge={false}
                className={classes.LinkCompanyDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleLinkCompanyDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.LinkCompanyDialogContent}>
              <Box px={4} pt={4} pb={0} className={classes.LinkCompanyWrapper}>
                {!!emailVerificationSuccess &&
                <Alert severity="success"><FormattedMessage id='EmailVerification.FormSuccessAlert'/></Alert>
                }

                {!!linkCompanyFailure && !!linkCompanyFailureMessage &&
                <Alert severity="error">{linkCompanyFailureMessage}</Alert>
                }
                {!!linkCompanySuccess &&
                <Alert severity="success"><FormattedMessage id='LinkCompany.FormSuccessAlert'
                                                            values={{ name: linkCompanyData.taxpayer_name }}/></Alert>
                }

                <form onSubmit={linkCompanyForm.handleSubmit} noValidate autoComplete="off"
                      className={classes.formWrapper}>

                  <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                    <InputLabel id="linkCompanyForm.tax_id"><FormattedMessage
                      id='Fields.label.TaxID'/></InputLabel>
                    <FormControl variant='outlined'>
                      <Select
                        fullWidth
                        id="linkCompanyForm.tax_id"
                        margin="dense"
                        displayEmpty
                        MenuProps={{ disableScrollLock: true }}
                        name="tax_id_list"
                        value={linkCompanyForm.values.tax_id_list}
                        onChange={handleCompanyContactChange}
                        onBlur={linkCompanyForm.handleBlur}
                        error={linkCompanyForm.errors.tax_id && linkCompanyForm.touched.tax_id !== undefined}
                      >
                        <MenuItem value=''><FormattedMessage id='linkCompanyForm.placeholder.TaxID'/></MenuItem>
                        {!!verifiedCompaniesContacts.length && verifiedCompaniesContacts.map((companyContact) => (
                          previousIncentiveCompanies?.length ? ((companyContact?.tax_id?.toString() === previousIncentiveCompanies[0]?.tax_id?.toString()) &&
                            <MenuItem key={companyContact.id}
                                      value={companyContact.id}>{companyContact.tax_id}</MenuItem>) :
                            <MenuItem key={companyContact.id}
                                      value={companyContact.id}>{companyContact.tax_id}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        <FormattedMessage id='linkCompanyForm.addCompanyContact'
                                          values={{
                                            a: chunks => <NavLink
                                              to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                          }}/>
                      </FormHelperText>
                      {linkCompanyForm.errors.tax_id && linkCompanyForm.touched.tax_id && (
                        <FormHelperText error={true}>{linkCompanyForm.errors.tax_id}</FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.email"> {<FormattedMessage
                      id='Fields.label.taxEmail'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.email' })}
                        id="linkCompanyForm.email"
                        type={'email'}
                        disabled={true}
                        margin="dense"
                        variant="outlined"
                        name='email'
                        onChange={linkCompanyForm.handleChange}
                        onBlur={linkCompanyForm.handleBlur}
                        value={linkCompanyForm.values.email}
                        helperText={linkCompanyForm.errors.email && linkCompanyForm.touched.email && linkCompanyForm.errors.email}
                        error={linkCompanyForm.errors.email && linkCompanyForm.touched.email !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.mobile"><FormattedMessage
                      id='Fields.label.mobile'/></InputLabel>
                    <LanguageConsumer>
                      {({ locale }) => (
                        <div className={classes.PhoneInputWrapper}>
                          <PhoneInput
                            disabled={true}
                            enableSearch={true}
                            jumpCursorToEnd={true}
                            country={'eg'}
                            localization={locale === 'ar' ? ar : ''}
                            specialLabel={''}
                            excludeCountries={['il']}
                            inputProps={{
                              name: 'mobile',
                              required: true,
                              autoFocus: false
                            }}
                            searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                            searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                            onChange={linkCompanyForm.handleChange}
                            onBlur={linkCompanyForm.handleBlur}
                            value={linkCompanyForm.values.mobile}
                            helperText={linkCompanyForm.errors.mobile && linkCompanyForm.touched.mobile && linkCompanyForm.errors.mobile}
                            error={linkCompanyForm.errors.mobile && linkCompanyForm.touched.mobile !== undefined}
                          />
                        </div>
                      )}
                    </LanguageConsumer>
                  </div>


                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.main_partner_name"> {<FormattedMessage
                      id='Fields.label.MainPartnerName'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.MainPartnerName' })}
                        id="linkCompanyForm.main_partner_name"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='main_partner_name'
                        onChange={linkCompanyForm.handleChange}
                        onBlur={linkCompanyForm.handleBlur}
                        value={linkCompanyForm.values.main_partner_name}
                        helperText={linkCompanyForm.errors.main_partner_name && linkCompanyForm.touched.main_partner_name && linkCompanyForm.errors.main_partner_name}
                        error={linkCompanyForm.errors.main_partner_name && linkCompanyForm.touched.main_partner_name !== undefined}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.verification_code1"> {<FormattedMessage
                      id='Fields.label.VerificationCode1'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.VerificationCode1' })}
                        id="linkCompanyForm.verification_code1"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='verification_code1'
                        onChange={linkCompanyForm.handleChange}
                        onBlur={linkCompanyForm.handleBlur}
                        value={linkCompanyForm.values.verification_code1}
                        helperText={linkCompanyForm.errors.verification_code1 && linkCompanyForm.touched.verification_code1 && linkCompanyForm.errors.verification_code1}
                        error={linkCompanyForm.errors.verification_code1 && linkCompanyForm.touched.verification_code1 !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.verification_code2"> {<FormattedMessage
                      id='Fields.label.VerificationCode2'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.VerificationCode2' })}
                        id="linkCompanyForm.verification_code2"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='verification_code2'
                        onChange={linkCompanyForm.handleChange}
                        onBlur={linkCompanyForm.handleBlur}
                        value={linkCompanyForm.values.verification_code2}
                        helperText={linkCompanyForm.errors.verification_code2 && linkCompanyForm.touched.verification_code2 && linkCompanyForm.errors.verification_code2}
                        error={linkCompanyForm.errors.verification_code2 && linkCompanyForm.touched.verification_code2 !== undefined}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="linkCompanyForm.is_accountant"><FormattedMessage
                      id='Fields.label.IsAccountant'/></InputLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={linkCompanyForm.values.is_accountant}
                          onChange={(event) => {
                            linkCompanyForm.setFieldValue('is_accountant', !!event.target.checked)
                          }}
                          name="is_accountant"
                          color="primary"
                          onBlur={linkCompanyForm.handleBlur}
                          error={linkCompanyForm.errors.is_accountant && linkCompanyForm.touched.is_accountant !== undefined}
                        />}
                      label={intl.formatMessage({ id: `linkCompanyForm.is_accountant.${!!linkCompanyForm.values.is_accountant}` })}
                      labelPlacement="end"
                    />
                  </div>

                  <div className={classes.SubmitRow}>
                    <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                            disabled={linkCompanyForm.isSubmitting || !linkCompanyForm.isValid}
                            className={classes.LinkCompanyFormSubmit}>
                      <FormattedMessage id='linkCompanyForm.button.submit'/>
                    </Button>
                  </div>
                  {linkCompanyForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
              </Box>
            </DialogContent>
            <DialogActions className={classes.LinkCompanyDialogFooter}>
              <Button onClick={handleLinkCompanyDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </>
        )}
      </AuthenticatedUserConsumer>
    </Dialog>
  )
}

export default LinkCompanyDialog
