import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  logo: {
    '& a': {
      display: 'inline-block',
      paddingTop: theme.spacing(1.5)
    },
    '& img': {
      width: 'auto',
      maxWidth: 280,
      maxHeight: 70
    }
  }
}))
