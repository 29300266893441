import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  PaymentsContentContainer: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '&:not(.withBorder)': {
      marginBottom: '-1px',
      borderBottom: 'none'
    },
    paddingTop: theme.spacing(0.375),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1.5)
    },

    '& .MuiAccordionSummary-root': {
      flex: '0 0 auto',
      '&.Mui-expanded': {
        minHeight: theme.spacing(6)
      },
      '& .MuiAccordionSummary-content': {
        margin: '0 !important'
      },
      [theme.breakpoints.down('md')]: {
        width: '100%'
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0),
        '& .MuiAccordionSummary-expandIcon': {
          display: 'none'
        }
      }
    },
    '& .MuiCollapse-container': {
      flex: '1 1 auto',
      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(0)
    }
  },
  filterTitle: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    margin: 0,
    marginTop: theme.spacing(1.25),
    flex: '0 0 auto',
    '&.noMargin': {
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0)
      }
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: 0
      // textAlign: 'center'
    }
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    flex: '1 1 auto',
    '&.center': {
      alignItems: 'center'
    },
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.common.white,
      borderRadius: theme.spacing(0.5),
      paddingBottom: theme.spacing(1.5)
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      marginBottom: theme.spacing(3)
    }
  },

  formControl: {
    margin: theme.spacing(2, 1, 1),
    '&.has-date-field': {
      marginTop: theme.spacing(0)
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      marginTop: theme.spacing(-0.375)
    }
  },
  formSelectControl: {
    marginTop: theme.spacing(3)
  },
  dateFormControl: {
    margin: theme.spacing(1, 2),
    '& .MuiInput-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 0, 0, 2),
      borderBottom: 0
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(0.75),
      zIndex: 1,
      left: theme.spacing(2)
    },
    '& .MuiInputBase-input': {
      paddingBottom: theme.spacing(0.5)
    }
  },
  formWrapperDense: {
    '& $formControl, & $dateFormControl': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  // formWrapWrapper:{
  //   '& $formControl, & $dateFormControl':{
  //     minWidth: '25%',
  //     maxWidth: '25%'
  //   }
  // },
  submitButton: {
    border: 'none',
    display: 'inline-block',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(0.625, 6),
    '&.noMargin': {
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(0)
      }
    },
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.hover
    },
    '&[disabled]': {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0)
    }
  },
  filtersFieldsWrapper: {
    marginBottom: theme.spacing(1),
    '& $formControl, & $dateFormControl': {
      [theme.breakpoints.up('md')]: {
        width: 'calc( 25% - 32px )',
        margin: theme.spacing(1, 2, 0),
        '&$formSelectControl': {
          marginTop: theme.spacing(2)
        }
      }
    },
    '& $dateFormControl': {
      [theme.breakpoints.up('md')]: {
        marginTop: '0 !important'
      }
    }

  },
  BalanceFilterWrapper: {
    '& $formControl': {
      [theme.breakpoints.up('md')]: {
        width: 'calc( 50% - 32px )',
        '&$formSelectControl': {
          marginTop: theme.spacing(1)
        }
      }
    }
  },
  availableBalanceWrapper: {
    minWidth: '25%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(3)

    }
  },
  availableBalance: {
    padding: theme.spacing(1.25, 2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(18)
  }
}))
