import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Slide,
  DialogTitle,
  Typography,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
  Checkbox, Select, MenuItem,Chip
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import { FormLoader } from '../../../../Loader/FormLoader'
import { EcommerceFormValidation } from '../../../../../utils/validationSchema'
import { EcommerceConsumer } from '../../../../../EcommerceContext'
// import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
// import { LanguageConsumer } from '../../../../../LanguageContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditEcommercePlatform = ({
                        openEditEcommerceDialog, handleEditEcommerceDialog,
                        ecommerceData, companyIsAccountant, handleEcommerceSubmit, paymentsFiltersData
                      }) => {
  const classes = useStyles()
  const intl = useIntl()
  // const [newPhoneError, setNewPhoneError] = React.useState('')
  const EcommerceForm = useFormik({
    initialValues: {
      name_en: ecommerceData?.name_en || '',
      name_ar: ecommerceData?.name_ar || '',
      // is_accountant: ecommerceData.is_accountant || false,
      url: ecommerceData?.url || '',
      services:  (!!ecommerceData?.servicesRaw && ecommerceData?.servicesRaw?.length!==0)? ecommerceData?.servicesRaw?.map((service)=>service.id):[] || []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: EcommerceFormValidation,
    onSubmit: ((values, actions) => {
      handleEcommerceSubmit(values, actions, handleEditEcommerceDialog)
    })
  })

  const handleRolesChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    if(EcommerceForm.values.services.includes(event.target.value)){
      EcommerceForm.values.services.filter((service) => service !== event.target.value);
    }
    else{EcommerceForm.setFieldValue('services', event.target.value)}
  }
  return (
    <EcommerceConsumer>
      {({ pageAction, ecommerceDataFailure, ecommerceDataFailureMessage, ecommerceDataSuccess }) => (
        <>
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'sm'}
            open={openEditEcommerceDialog}
            classes={{ paper: classes.DelegateDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleEditEcommerceDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.DelegateDialogHeader}>
              <Typography variant="h5">
                {(!!pageAction && 'Edit' === pageAction) ?
                  <FormattedMessage id='Ecommerce.Edit.Title' values={{ name_en: ecommerceData.name_en }}/>
                  :
                  <FormattedMessage id='Ecommerce.Add.Title'/>
                }
              </Typography>
              <IconButton
                edge={false}
                className={classes.DelegateDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleEditEcommerceDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.DelegateDialogContent}>
              <Box px={4} pt={4} pb={0}>

                {!!ecommerceDataFailure && !!ecommerceDataFailureMessage &&
                <Alert severity="error">{ecommerceDataFailureMessage}</Alert>
                }

                {!!ecommerceDataSuccess &&
                <Alert severity="success">
                  <FormattedMessage
                    id={(!!pageAction && 'Edit' === pageAction) ? 'Ecommerce.Edit.FormSuccessAlert' : 'Ecommerce.Add.FormSuccessAlert'}
                    values={{ name_en:EcommerceForm.values.name_en }}/>
                </Alert>
                }

                <form onSubmit={EcommerceForm.handleSubmit} noValidate autoComplete="off"
                      className={classes.formWrapper}>
                  <div className={classes.DelegateFormRow}>
                    <InputLabel id="EcommerceForm.Platform.Name.En"> {<FormattedMessage
                      id='EcommerceForm.Platform.Name.En'/>}*</InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'EcommerceForm.Platform.Name.En' })}
                        id="EcommerceForm.name_en"
                        margin="dense"
                        variant="outlined"
                        name='name_en'
                        onChange={EcommerceForm.handleChange}
                        onBlur={EcommerceForm.handleBlur}
                        value={EcommerceForm.values.name_en}
                        helperText={EcommerceForm.errors.name_en && EcommerceForm.touched.name_en && EcommerceForm.errors.name_en}
                        error={EcommerceForm.errors.name_en && EcommerceForm.touched.name_en !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.DelegateFormRow}>
                    <InputLabel id="EcommerceForm.Platform.Name.Ar"> {<FormattedMessage
                      id='EcommerceForm.Platform.Name.Ar'/>}</InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'EcommerceForm.Platform.Name.Ar' })}
                        id="EcommerceForm.name_ar"
                        margin="dense"
                        variant="outlined"
                        name='name_ar'
                        onChange={EcommerceForm.handleChange}
                        onBlur={EcommerceForm.handleBlur}
                        value={EcommerceForm.values.name_ar}
                        // helperText={EcommerceForm.errors.name_ar && EcommerceForm.touched.name_ar && EcommerceForm.errors.name_ar}
                        // error={EcommerceForm.errors.name_ar && EcommerceForm.touched.name_ar !== undefined}
                      />
                    </FormControl>
                  </div>

             

                  <div className={classes.DelegateFormRow}>
                    <InputLabel id="EcommerceForm.Platform.Url"> {<FormattedMessage
                      id='EcommerceForm.Platform.Url'/>}*</InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'EcommerceForm.Platform.Url' })}
                        id="EcommerceForm.url"
                        margin="dense"
                        variant="outlined"
                        name='url'
                        onChange={EcommerceForm.handleChange}
                        onBlur={EcommerceForm.handleBlur}
                        value={EcommerceForm.values.url}
                        helperText={EcommerceForm.errors.url && EcommerceForm.touched.url && EcommerceForm.errors.url}
                        error={EcommerceForm.errors.url && EcommerceForm.touched.url !== undefined}
                      />
                    </FormControl>
                  </div>

                    <div className={classes.DelegateFormRow}>
                      <InputLabel id="EcommerceForm.Platform.Services">
                        <FormattedMessage id='EcommerceForm.Platform.Services'/>*</InputLabel>
                      <FormControl variant='outlined'>
                        <Select
                          fullWidth
                          id="services"
                          margin="dense"
                          multiple
                          displayEmpty
                          MenuProps={{ disableScrollLock: true }}
                          name="services"
                          value={EcommerceForm.values.services}
                          // placeholder={intl.formatMessage({ id: 'EcommerceForm.Platform.Services' })}
                          onChange={handleRolesChange}
                          onBlur={EcommerceForm.handleBlur}
                          error={EcommerceForm.errors.services && EcommerceForm.touched.services !== undefined}
                          renderValue={(selected) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {selected.map((value) => {
                              let service=  paymentsFiltersData['ecommerce_platform_services'].filter((service)=>service.id===value)[0]
                               return <Chip key={service.id} value={service.id} label={service.name} style={{ margin: 2 }} />
                          })}
                            </div>
                          )}
                        >
                          {/* <MenuItem value=''><FormattedMessage id='EcommerceForm.Platform.Services'/></MenuItem> */}
                          {!!paymentsFiltersData['ecommerce_platform_services']?.length &&
                          paymentsFiltersData['ecommerce_platform_services']
                            .map((service) => (
                              <MenuItem key={service.id} value={service.id}>
                                        <Checkbox checked={EcommerceForm.values.services?.includes(service.id)} />
                                {service.name}</MenuItem>
                            ))}
                        </Select>

                        {EcommerceForm.errors.roles && EcommerceForm.touched.roles && (
                          <FormHelperText error={true}>{EcommerceForm.errors.roles}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  {EcommerceForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
               {(!!pageAction && pageAction!=='Edit') && <Alert severity="warning"><FormattedMessage id='Ecommerce.CreateMessage.Warning'/></Alert>}
              </Box>
            </DialogContent>
            <DialogActions className={classes.DelegateDialogFooter}>
              <Button size="large" variant="contained" color="primary"
                      onClick={EcommerceForm.handleSubmit}
                      disabled={EcommerceForm.isSubmitting || !EcommerceForm.isValid}>
                <FormattedMessage id={`Ecommerce.Button.${(!!pageAction && 'Edit' === pageAction) ? 'Edit' : 'Add'}`}/>
              </Button>

              <Button onClick={handleEditEcommerceDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </EcommerceConsumer>
  )
}

export default EditEcommercePlatform
