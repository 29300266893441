import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 0,
    '& svg': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
      outline: 'none'
    }
  },
  navigationDrawerWrapper: {
    '& .MuiPaper-root': {
      background: 'transparent',
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(7.5, 2),
    }
  },
  navigationDrawer: {
    width: 300,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0),
    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
      '& a, & button': {
        fontSize: theme.typography.pxToRem(20),
        margin: theme.spacing(0),
        whiteSpace: 'normal'
      }
    }
  },
  menuCloseButton: {
    color: theme.palette.common.white + ' !important',
    marginTop: theme.spacing(1) + 'px !important',
    position: 'absolute !important',
    top: 0,
    right: theme.spacing(2),
    '& svg': {
      fontSize: theme.typography.pxToRem(42),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: theme.palette.common.white,
      outline: 'none'
    }
  }
}))
