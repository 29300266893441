import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  formWrapper: {
    position: 'relative'
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative',
    minHeight:'200px'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogFooter: {
    alignItems: 'flex-end',
    padding: theme.spacing(2)
  },
  DeleteOrderBtn: {
    backgroundColor: theme.palette.error.main + '!important',
    '&:hover': {
      backgroundColor: theme.palette.error.dark + '!important'
    }
  },
  SuccessWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.success.main
  },
  DeleteOrderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    position: 'relative',
    '& .MuiAlert-root': {
      width: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.warning.main
  },
  contentWrapper:{
    textAlign:'start',
    width:'100%',
    '& p':{
      textAlign:'start',
    }
  },
  contentResult:{
    display:'flex',
    width:'100%',
    alignItems:'center',
    color:theme.palette.primary.main,
    borderRadius:'5px',
    backgroundColor:'#F6FAFD',
    padding:'2px 5px 2px 5px',
    '& span':{
      color:theme.palette.text.primary,
    },
    justifyContent:'space-between',

    '& .MuiSvgIcon-root':{
      color:theme.palette.primary.main,
    }
  },
  failureWrapper:{
    margin: '10px 0'
  }
}))
