import React from 'react'
import {
  FormControl, FormControlLabel, FormHelperText,
  InputLabel, MenuItem, Radio, RadioGroup, Select, TextField,
  InputAdornment, Collapse, Button
} from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { NamedRoutes } from '../../../../routes'
import { formatMobilePhoneNumber } from '../../../../utils/helpers'
import { useStyles } from '../Styles'
import { FormLoader } from '../../../Loader/FormLoader'
import { useFormik } from 'formik'
import { registerCompanyFormValidation } from '../../../../utils/validationSchema'
import moment from 'moment'

const NormalRegisterCompanyForm = ({
                                     verifiedCompaniesContacts, registerCompanySubmitForm, getCountries, ridToSelect,
                                     ridIsSelected, handleRegisterCompanyDialog, resetRegisterCompanyStatus, locale
                                   }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [taxType, setTaxType] = React.useState('')
  const [taxCategory, setTaxCategory] = React.useState('')
  let registerCompanyForm = useFormik({
    initialValues: {
      tax_registration_type: taxType,
      tax_registration_category: taxCategory,
      newOrUpdateStatus: 'new_registration',
      full_name: '',
      code_iso3: 'EGY', //Nationality
      resident_country: 'EGY',
      national_id: '',
      passport_id: '',
      previous_tax_id: '',
      previous_registration_group: '',
      company_name: '',
      foundation_id: '',
      foundation_year: '',
      contact_info_id: !!ridIsSelected ? ridToSelect || '' : '',
      verification_code1: '',
      verification_code2: '',
      verification_code3: '',
      locale: locale
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: registerCompanyFormValidation,
    onSubmit: ((values, actions) => {
      registerCompanySubmitForm(values, actions, handleRegisterCompanyDialog, 'normal', locale, values.tax_registration_type === 'voluntary' && values.newOrUpdateStatus === 'legal_update')
    })
  })

  const newUpdateStatusList = [
    {
      key: 'new_registration',
      name: <FormattedMessage id='Fields.label.newUpdateStatusList.new_registration'/>
    },
    {
      key: 'legal_update',
      name: <FormattedMessage id='Fields.label.newUpdateStatusList.legal_update'/>
    }
  ]

  React.useEffect(() => {
      getCountries()
      return () => {
        resetRegisterCompanyStatus()
        registerCompanyForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const [viewVoluntary, setVoluntary] = React.useState(false)
  const [viewSuomoto, setSuomoto] = React.useState(false)
  const [viewNatural, setNatural] = React.useState(false)
  const [viewJuridical, setJuridical] = React.useState(false)
  const [viewLegalUpdate, setLegalUpdate] = React.useState(false)

  React.useEffect(() => {
      if (!!registerCompanyForm.values.tax_registration_type && !!registerCompanyForm.values.tax_registration_category) {
        switch (registerCompanyForm.values.tax_registration_type) {
          case 'voluntary':
            setVoluntary(true)
            setTimeout(() => {
              setSuomoto(false)
            }, 100)
            break
          case 'suomoto':
            setSuomoto(true)
            setTimeout(() => {
              setVoluntary(false)
            }, 100)
            break
          default:
            break
        }

        switch (registerCompanyForm.values.tax_registration_category) {
          case 'natural_person':
            setNatural(true)
            setTimeout(() => {
              setJuridical(false)
            }, 100)
            break
          case 'juridical_person':
            setJuridical(true)
            setTimeout(() => {
              setNatural(false)
            }, 100)
            break
          default:
            break
        }
        switch (registerCompanyForm.values.newOrUpdateStatus) {
          case 'new_registration':
            setTimeout(() => {
              setLegalUpdate(false)
            }, 100)
            break
          case 'legal_update':
            setLegalUpdate(true)
            break
          default:
            break
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [registerCompanyForm.values.tax_registration_type, registerCompanyForm.values.tax_registration_category, registerCompanyForm.values.newOrUpdateStatus])

  const handleSelectChange = (event, fieldName) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (fieldName !== 'resident_country') {
      registerCompanyForm.setFieldValue(fieldName, event.target.value.toString())
    }
    if (fieldName === 'tax_registration_type') {
      setTaxType(event.target.value.toString())
      setViewNationalID(true)
      setFoundationYearDate(null)
      registerCompanyForm.resetForm({
        values: {
          ...registerCompanyForm.initialValues,
          tax_registration_type: event.target.value.toString()
        }
      })
    } else if (fieldName === 'tax_registration_category') {
      setTaxCategory(event.target.value.toString())
      setViewNationalID(true)
      setFoundationYearDate(null)
      registerCompanyForm.resetForm({
        values: {
          ...registerCompanyForm.initialValues,
          tax_registration_category: event.target.value.toString()
        }
      })
      registerCompanyForm.setFieldTouched('tax_registration_type', true, true)
    }
  }

  const handlePreviousGroupChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    registerCompanyForm.setFieldValue('previous_registration_group', event.target.value.toString())
  }

  const [foundationYearDate, setFoundationYearDate] = React.useState(null)
  const handleFoundationYearDateChange = (date) => {
    setFoundationYearDate(date)
    registerCompanyForm.setFieldValue('foundation_year', moment(date).format('YYYY'))
  }

  const [viewNationalID, setViewNationalID] = React.useState(true)
  const handleNationalityChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    registerCompanyForm.setFieldValue('code_iso3', event.target.value.toString())
    setViewNationalID(event.target.value.toString() === 'EGY')
  }
  return (
    <AuthenticatedUserConsumer>
      {({ paymentsFiltersData, countries }) => (
        <form onSubmit={registerCompanyForm.handleSubmit} noValidate autoComplete="off">

          <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
            <InputLabel id="registerCompanyForm.tax_registration_type"><FormattedMessage
              id='Fields.label.TaxRegistrationType'/></InputLabel>
            <FormControl variant='outlined'>
              <Select
                fullWidth
                id="registerCompanyForm.tax_registration_type"
                margin="dense"
                displayEmpty
                MenuProps={{ disableScrollLock: true }}
                name="tax_registration_type"
                value={registerCompanyForm.values.tax_registration_type}
                onChange={(event) => handleSelectChange(event, 'tax_registration_type')}
                onBlur={registerCompanyForm.handleBlur}
                error={registerCompanyForm.errors.tax_registration_type && registerCompanyForm.touched.tax_registration_type !== undefined}
              >
                <MenuItem value=''><FormattedMessage
                  id='registerCompanyForm.placeholder.TaxRegistrationType'/></MenuItem>
                {!!Object.keys(paymentsFiltersData).length && !!paymentsFiltersData['core_taxation_registration_types'] &&
                !!paymentsFiltersData['core_taxation_registration_types'].length && paymentsFiltersData['core_taxation_registration_types'].map((type) => (
                  <MenuItem key={type.key} value={type.key}>{type.name}</MenuItem>
                ))}
              </Select>
              {registerCompanyForm.errors.tax_registration_type && registerCompanyForm.touched.tax_registration_type && (
                <FormHelperText
                  error={true}>{registerCompanyForm.errors.tax_registration_type}</FormHelperText>
              )}
            </FormControl>
          </div>

          {!!Object.keys(paymentsFiltersData).length && !!paymentsFiltersData['core_taxation_registration_categories'] &&
          !!paymentsFiltersData['core_taxation_registration_categories'].length &&
          <div className={classes.LinkCompanyFormRow}>
            <InputLabel id="registerCompanyForm.tax_registration_category"><FormattedMessage
              id='Fields.label.TaxRegistrationCategory'/></InputLabel>
            <FormControl variant="outlined">
              <RadioGroup aria-label="registerCompanyForm.tax_registration_category"
                          name="tax_registration_category"
                          value={registerCompanyForm.values.tax_registration_category}
                          onChange={(event) => handleSelectChange(event, 'tax_registration_category')}>
                {paymentsFiltersData['core_taxation_registration_categories'].map((cat) => (
                  <FormControlLabel key={cat.key} value={cat.key} control={<Radio color={'primary'}/>}
                                    label={cat.name}/>
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          }

          <Collapse
            in={registerCompanyForm.values.tax_registration_type === 'voluntary' && !!registerCompanyForm.values.tax_registration_category}
            timeout="auto" unmountOnExit>
            {!!viewVoluntary &&
            <>
              <Collapse
                in={registerCompanyForm.values.tax_registration_category === 'natural_person'}
                timeout="auto" unmountOnExit>
                {!!viewNatural &&
                <>
                  <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                    <InputLabel id="registerCompanyForm.full_name"><FormattedMessage
                      id='Fields.label.FullName'/></InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FullName' })}
                        id="registerCompanyForm.full_name"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='full_name'
                        onChange={registerCompanyForm.handleChange}
                        onBlur={registerCompanyForm.handleBlur}
                        value={registerCompanyForm.values.full_name}
                        error={registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name !== undefined}
                      />
                      <FormHelperText><FormattedMessage
                        id='registerCompanyForm.naturalPerson.Hint'/></FormHelperText>
                      {registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name && (
                        <FormHelperText
                          error={true}>{registerCompanyForm.errors.full_name}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                    <InputLabel id="registerCompanyForm.nationality"><FormattedMessage
                      id='Fields.label.Nationality'/></InputLabel>
                    <FormControl variant='outlined'>
                      <Select
                        fullWidth
                        labelId="registerCompanyForm.nationality"
                        id="code_iso3"
                        margin="dense"
                        MenuProps={{ disableScrollLock: true }}
                        name="code_iso3"
                        value={registerCompanyForm.values.code_iso3}
                        onChange={handleNationalityChange}
                        onBlur={registerCompanyForm.handleBlur}
                        error={registerCompanyForm.errors.code_iso3 && registerCompanyForm.touched.code_iso3 !== undefined}
                      >
                        {!!countries.length && countries.map((country) => (
                          <MenuItem key={country.id}
                                    value={country.code_iso3.toString()}>{country.name}</MenuItem>
                        ))}
                      </Select>
                      {registerCompanyForm.errors.code_iso3 && registerCompanyForm.touched.code_iso3 && (
                        <FormHelperText
                          error={true}>{registerCompanyForm.errors.code_iso3}</FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                    <InputLabel id="registerCompanyForm.resident_country"><FormattedMessage
                      id='Fields.label.ResidentCountry'/></InputLabel>
                    <FormControl variant='outlined'>
                      <Select
                        disabled
                        fullWidth
                        labelId="registerCompanyForm.resident_country"
                        id="resident_country"
                        margin="dense"
                        MenuProps={{ disableScrollLock: true }}
                        name="resident_country"
                        value={registerCompanyForm.values.resident_country}
                        onChange={(e) => handleSelectChange(e, 'resident_country')}
                        onBlur={registerCompanyForm.handleBlur}
                        error={registerCompanyForm.errors.resident_country && registerCompanyForm.touched.resident_country !== undefined}
                      >
                        {!!countries.length && countries.map((country) => (
                          <MenuItem key={country.id}
                                    value={country.code_iso3.toString()}>{country.name}</MenuItem>
                        ))}
                      </Select>
                      {registerCompanyForm.errors.resident_country && registerCompanyForm.touched.resident_country && (
                        <FormHelperText
                          error={true}>{registerCompanyForm.errors.resident_country}</FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  {!!viewNationalID ?
                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="registerCompanyForm.national_id"><FormattedMessage
                        id='Fields.label.NationalId'/></InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.NationalId' })}
                          id="registerCompanyForm.national_id"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='national_id'
                          onChange={registerCompanyForm.handleChange}
                          onBlur={registerCompanyForm.handleBlur}
                          value={registerCompanyForm.values.national_id}
                          helperText={registerCompanyForm.errors.national_id && registerCompanyForm.touched.national_id && registerCompanyForm.errors.national_id}
                          error={registerCompanyForm.errors.national_id && registerCompanyForm.touched.national_id !== undefined}
                        />
                      </FormControl>
                    </div>
                    :
                    <div className={classes.LinkCompanyFormRow}>
                      <InputLabel id="registerCompanyForm.passport_id"><FormattedMessage
                        id='Fields.label.PassportId'/></InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.PassportId' })}
                          id="registerCompanyForm.passport_id"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='passport_id'
                          onChange={registerCompanyForm.handleChange}
                          onBlur={registerCompanyForm.handleBlur}
                          value={registerCompanyForm.values.passport_id}
                          helperText={registerCompanyForm.errors.passport_id && registerCompanyForm.touched.passport_id && registerCompanyForm.errors.passport_id}
                          error={registerCompanyForm.errors.passport_id && registerCompanyForm.touched.passport_id !== undefined}
                        />
                      </FormControl>
                    </div>
                  }
                </>
                }
              </Collapse>
              <Collapse
                in={registerCompanyForm.values.tax_registration_category === 'juridical_person'}
                timeout="auto" unmountOnExit>
                {!!viewJuridical &&
                <>
                  <div className={classes.LinkCompanyFormRow + ' update'}>
                    <InputLabel id="registerCompanyForm.newOrUpdateStatus"><FormattedMessage
                      id='Fields.label.newOrUpdateStatus'/></InputLabel>
                    <FormControl variant="outlined">
                      <RadioGroup aria-label="registerCompanyForm.newOrUpdateStatus"
                                  name="newOrUpdateStatus"
                                  value={registerCompanyForm.values.newOrUpdateStatus}
                                  onChange={(event) => handleSelectChange(event, 'newOrUpdateStatus')}>
                        {newUpdateStatusList.map((status) => (
                          <FormControlLabel key={status.key} value={status.key} control={<Radio color={'primary'}/>}
                                            label={status.name}/>
                        ))}
                      </RadioGroup>
                      {!!viewLegalUpdate &&
                      <FormHelperText margin="dense" className={classes.noMarHorizontal}><FormattedMessage id='updateCompanyRegistrationForm.legalUpdate.description'/></FormHelperText>}
                    </FormControl>
                  </div>

                  <Collapse
                    in={registerCompanyForm.values.newOrUpdateStatus === 'legal_update'}
                    timeout="auto" unmountOnExit>
                    {!!viewLegalUpdate &&
                    <>
                      <div className={classes.LinkCompanyFormRow}>
                        <InputLabel id="updateCompanyRegistrationForm.previous_tax_id"><FormattedMessage
                          id='Fields.label.previousTaxId'/></InputLabel>
                        <FormControl variant="outlined">
                          <TextField
                            placeholder={intl.formatMessage({ id: 'updateCompanyRegistrationForm.placeholder.previousTaxId' })}
                            id="updateCompanyRegistrationForm.previous_tax_id"
                            type={'text'}
                            margin="dense"
                            variant="outlined"
                            name='previous_tax_id'
                            onChange={registerCompanyForm.handleChange}
                            onBlur={registerCompanyForm.handleBlur}
                            value={registerCompanyForm.values.previous_tax_id}
                            helperText={registerCompanyForm.errors.previous_tax_id && registerCompanyForm.touched.previous_tax_id && registerCompanyForm.errors.previous_tax_id}
                            error={registerCompanyForm.errors.previous_tax_id && registerCompanyForm.touched.previous_tax_id !== undefined}
                          />
                        </FormControl>
                      </div>

                      <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                        <InputLabel id="updateCompanyRegistrationForm.previous_registration_group"><FormattedMessage
                          id='Fields.label.previousRegistrationGroup'/></InputLabel>
                        <FormControl variant='outlined'>
                          <Select
                            fullWidth
                            id="updateCompanyRegistrationForm.previous_registration_group"
                            margin="dense"
                            displayEmpty
                            MenuProps={{ disableScrollLock: true }}
                            name="previous_registration_group"
                            value={registerCompanyForm.values.previous_registration_group}
                            onChange={handlePreviousGroupChange}
                            onBlur={registerCompanyForm.handleBlur}
                            error={registerCompanyForm.errors.previous_registration_group && registerCompanyForm.touched.previous_registration_group !== undefined}
                          >
                            <MenuItem value=''><FormattedMessage
                              id='updateCompanyRegistrationForm.placeholder.previousRegistrationGroup'/></MenuItem>
                            {!!Object.keys(paymentsFiltersData).length && !!paymentsFiltersData['core_taxation_registration_groups'] &&
                            !!paymentsFiltersData['core_taxation_registration_groups'].length && paymentsFiltersData['core_taxation_registration_groups'].map((type) => (
                              <MenuItem key={type.key} value={type.key}>{type.name}</MenuItem>
                            ))}
                          </Select>
                          {registerCompanyForm.errors.previous_registration_group && registerCompanyForm.touched.previous_registration_group && (
                            <FormHelperText
                              error={true}>{registerCompanyForm.errors.previous_registration_group}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </>
                    }
                  </Collapse>

                  <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                    <InputLabel id="registerCompanyForm.company_name"> {<FormattedMessage
                      id='Fields.label.CompanyName'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.CompanyName' })}
                        id="registerCompanyForm.company_name"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='company_name'
                        onChange={registerCompanyForm.handleChange}
                        onBlur={registerCompanyForm.handleBlur}
                        value={registerCompanyForm.values.company_name}
                        error={registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name !== undefined}
                      />
                      <FormHelperText><FormattedMessage
                        id='registerCompanyForm.juridicalPerson.Hint'/></FormHelperText>
                      {registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name && (
                        <FormHelperText
                          error={true}>{registerCompanyForm.errors.company_name}</FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="registerCompanyForm.foundation_id"> {<FormattedMessage
                      id='Fields.label.FoundationId'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FoundationId' })}
                        id="registerCompanyForm.foundation_id"
                        type={'text'}
                        margin="dense"
                        variant="outlined"
                        name='foundation_id'
                        onChange={registerCompanyForm.handleChange}
                        onBlur={registerCompanyForm.handleBlur}
                        value={registerCompanyForm.values.foundation_id}
                        helperText={registerCompanyForm.errors.foundation_id && registerCompanyForm.touched.foundation_id && registerCompanyForm.errors.foundation_id}
                        error={registerCompanyForm.errors.foundation_id && registerCompanyForm.touched.foundation_id !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.LinkCompanyFormRow}>
                    <InputLabel id="registerCompanyForm.foundation_year"><FormattedMessage
                      id='Fields.label.FoundationYear'/></InputLabel>
                    <FormControl variant='outlined'>
                      <DatePicker
                        placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FoundationYear' })}
                        value={foundationYearDate}
                        onChange={handleFoundationYearDateChange}
                        cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                        okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                        views={['year']}
                        variant="outlined"
                        format="yyyy"
                        margin='dense'
                        id="foundation_year"
                        name='foundation_year'
                        fullWidth
                        autoOk
                        className={classes.dateFormControl}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <EventIcon/>
                            </InputAdornment>
                        }}
                      />
                    </FormControl>
                  </div>
                </>
                }
              </Collapse>


              <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                <InputLabel id="registerCompanyForm.contact_info_id"><FormattedMessage
                  id='Fields.label.ContactInfoId'/></InputLabel>
                <FormControl variant='outlined'>
                  <Select
                    fullWidth
                    id="registerCompanyForm.contact_info_id"
                    margin="dense"
                    displayEmpty
                    MenuProps={{ disableScrollLock: true }}
                    name="contact_info_id"
                    value={registerCompanyForm.values.contact_info_id}
                    onChange={(event) => handleSelectChange(event, 'contact_info_id')}
                    onBlur={registerCompanyForm.handleBlur}
                    error={registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id !== undefined}
                  >
                    <MenuItem value=''><FormattedMessage
                      id='registerCompanyForm.placeholder.ContactInfoId'/></MenuItem>
                    {!!verifiedCompaniesContacts.length && verifiedCompaniesContacts.map((companyContact) => (
                      <MenuItem key={companyContact.id}
                                value={companyContact.id}>{companyContact.email} ({formatMobilePhoneNumber(companyContact.phone)})</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id='registerCompanyForm.addCompanyContact'
                                      values={{
                                        a: chunks => <NavLink
                                          to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                      }}/>
                  </FormHelperText>
                  {registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id && (
                    <FormHelperText
                      error={true}>{registerCompanyForm.errors.contact_info_id}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </>
            }
          </Collapse>


          <Collapse
            in={registerCompanyForm.values.tax_registration_type === 'suomoto' && !!registerCompanyForm.values.tax_registration_category}
            timeout="auto" unmountOnExit>
            {!!viewSuomoto &&
            <>
              <Collapse
                in={registerCompanyForm.values.tax_registration_category === 'natural_person'}
                timeout="auto" unmountOnExit>
                {!!viewNatural &&
                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                  <InputLabel id="registerCompanyForm.full_name"><FormattedMessage
                    id='Fields.label.FullName'/></InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.FullName' })}
                      id="registerCompanyForm.full_name"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='full_name'
                      onChange={registerCompanyForm.handleChange}
                      onBlur={registerCompanyForm.handleBlur}
                      value={registerCompanyForm.values.full_name}
                      error={registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name !== undefined}
                    />
                    <FormHelperText><FormattedMessage
                      id='registerCompanyForm.naturalPerson.Hint'/></FormHelperText>
                    {registerCompanyForm.errors.full_name && registerCompanyForm.touched.full_name && (
                      <FormHelperText error={true}>{registerCompanyForm.errors.full_name}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                }
              </Collapse>

              <Collapse
                in={registerCompanyForm.values.tax_registration_category === 'juridical_person'}
                timeout="auto" unmountOnExit>
                {!!viewJuridical &&
                <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowStart}>
                  <InputLabel id="registerCompanyForm.company_name"> {<FormattedMessage
                    id='Fields.label.CompanyName'/>} </InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.CompanyName' })}
                      id="registerCompanyForm.company_name"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='company_name'
                      onChange={registerCompanyForm.handleChange}
                      onBlur={registerCompanyForm.handleBlur}
                      value={registerCompanyForm.values.company_name}
                      error={registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name !== undefined}
                    />
                    <FormHelperText><FormattedMessage
                      id='registerCompanyForm.juridicalPerson.Hint'/></FormHelperText>
                    {registerCompanyForm.errors.company_name && registerCompanyForm.touched.company_name && (
                      <FormHelperText
                        error={true}>{registerCompanyForm.errors.company_name}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                }
              </Collapse>


              <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
                <InputLabel id="registerCompanyForm.contact_info_id"><FormattedMessage
                  id='Fields.label.ContactInfoId'/></InputLabel>
                <FormControl variant='outlined'>
                  <Select
                    fullWidth
                    id="registerCompanyForm.contact_info_id"
                    margin="dense"
                    displayEmpty
                    MenuProps={{ disableScrollLock: true }}
                    name="contact_info_id"
                    value={registerCompanyForm.values.contact_info_id}
                    onChange={(event) => handleSelectChange(event, 'contact_info_id')}
                    onBlur={registerCompanyForm.handleBlur}
                    error={registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id !== undefined}
                  >
                    <MenuItem value=''><FormattedMessage
                      id='registerCompanyForm.placeholder.ContactInfoId'/></MenuItem>
                    {!!verifiedCompaniesContacts.length && verifiedCompaniesContacts.map((companyContact) => (
                      <MenuItem key={companyContact.id}
                                value={companyContact.id}>{companyContact.email} ({formatMobilePhoneNumber(companyContact.phone)})</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    <FormattedMessage id='registerCompanyForm.addCompanyContact'
                                      values={{
                                        a: chunks => <NavLink
                                          to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                      }}/>
                  </FormHelperText>
                  {registerCompanyForm.errors.contact_info_id && registerCompanyForm.touched.contact_info_id && (
                    <FormHelperText
                      error={true}>{registerCompanyForm.errors.contact_info_id}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className={classes.LinkCompanyFormRow}>
                <InputLabel id="registerCompanyForm.verification_code1"> {<FormattedMessage
                  id='Fields.label.VerificationCode1'/>} </InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.VerificationCode1' })}
                    id="registerCompanyForm.verification_code1"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='verification_code1'
                    onChange={registerCompanyForm.handleChange}
                    onBlur={registerCompanyForm.handleBlur}
                    value={registerCompanyForm.values.verification_code1}
                    helperText={registerCompanyForm.errors.verification_code1 && registerCompanyForm.touched.verification_code1 && registerCompanyForm.errors.verification_code1}
                    error={registerCompanyForm.errors.verification_code1 && registerCompanyForm.touched.verification_code1 !== undefined}
                  />
                </FormControl>
              </div>
              <div className={classes.LinkCompanyFormRow}>
                <InputLabel id="registerCompanyForm.verification_code2"> {<FormattedMessage
                  id='Fields.label.VerificationCode2'/>} </InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.VerificationCode2' })}
                    id="registerCompanyForm.verification_code2"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='verification_code2'
                    onChange={registerCompanyForm.handleChange}
                    onBlur={registerCompanyForm.handleBlur}
                    value={registerCompanyForm.values.verification_code2}
                    helperText={registerCompanyForm.errors.verification_code2 && registerCompanyForm.touched.verification_code2 && registerCompanyForm.errors.verification_code2}
                    error={registerCompanyForm.errors.verification_code2 && registerCompanyForm.touched.verification_code2 !== undefined}
                  />
                </FormControl>
              </div>
              <div className={classes.LinkCompanyFormRow}>
                <InputLabel id="registerCompanyForm.verification_code3"> {<FormattedMessage
                  id='Fields.label.VerificationCode3'/>} </InputLabel>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'registerCompanyForm.placeholder.VerificationCode3' })}
                    id="registerCompanyForm.verification_code3"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='verification_code3'
                    onChange={registerCompanyForm.handleChange}
                    onBlur={registerCompanyForm.handleBlur}
                    value={registerCompanyForm.values.verification_code3}
                    helperText={registerCompanyForm.errors.verification_code3 && registerCompanyForm.touched.verification_code3 && registerCompanyForm.errors.verification_code3}
                    error={registerCompanyForm.errors.verification_code3 && registerCompanyForm.touched.verification_code3 !== undefined}
                  />
                </FormControl>
              </div>
            </>
            }
          </Collapse>
          <div className={classes.SubmitRow}>
            <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                    disabled={registerCompanyForm.isSubmitting || !registerCompanyForm.isValid}
                    className={classes.LinkCompanyFormSubmit}>
              <FormattedMessage id={registerCompanyForm.values.tax_registration_type === 'voluntary' && registerCompanyForm.values.newOrUpdateStatus === 'legal_update'? 'updateCompanyRegistrationForm.button.submit': 'registerCompanyForm.button.submit'}/>
            </Button>
          </div>
          {registerCompanyForm.isSubmitting && <FormLoader loading={true}/>}
        </form>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default NormalRegisterCompanyForm
