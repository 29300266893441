import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Slide
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormLoader } from '../../../../../Loader/FormLoader'
import { useStyles } from '../Styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentOrderDelete = ({ shouldRefreshPayments, paymentOrderId, handleNewOrderDialog ,removePaymentRequest, setCanCreateRequest}) => {
  const classes = useStyles()
  const [viewDeleteOrderDialog, setViewDeleteOrderDialog] = React.useState(false)
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = React.useState(false)
  const handleDeleteOrderDialog = () => {
    if (!openDeleteOrderDialog) {
      setViewDeleteOrderDialog(true)
    } else {
      setTimeout(() => setViewDeleteOrderDialog(false), 500)
    }
    setOpenDeleteOrderDialog(!openDeleteOrderDialog)
  }
  return (
    <PaymentOrderConsumer>
      {({ paymentOrderDeleting, paymentOrderDeleteFailure, paymentOrderDeleteSuccess, deletePaymentOrderData }) => (
        <>
          <Button size="large" className={classes.DeleteOrderBtn} variant="contained" color="primary"
                  onClick={handleDeleteOrderDialog}>
            <FormattedMessage id='PaymentOrder.ActionLinks.Delete'/>
          </Button>
          {!!viewDeleteOrderDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'sm'}
            open={openDeleteOrderDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleDeleteOrderDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <IconButton
              edge={false}
              className={classes.NewOrderDialogClose}
              color="inherit"
              aria-label="close dialog"
              onClick={handleDeleteOrderDialog}
            >
              <CloseIcon/>
            </IconButton>
            <DialogContent className={classes.NewOrderDialogContent}>
              <Box p={4} className={classes.DeleteOrderWrapper}>
                {!!paymentOrderDeleting && <FormLoader loading={true}/>}
                {!!paymentOrderDeleteFailure &&
                <Alert severity="error"><FormattedMessage id='PaymentOrderDelete.FormErrorAlert'/></Alert>
                }
                {!!paymentOrderDeleteSuccess &&
                <Alert severity="success"><FormattedMessage id='PaymentOrderDelete.FormSuccessAlert'/></Alert>
                }
                <WarningIcon className={classes.DeleteWarningIcon}/>
                <p><FormattedMessage id='PaymentOrder.DeleteMessage' values={{ paymentOrderId: paymentOrderId }}/></p>
              </Box>
            </DialogContent>
            <DialogActions className={classes.NewOrderDialogFooter}>
              <Button size="large" variant="contained" color="primary" className={classes.DeleteOrderBtn}
                      onClick={() => {
                        deletePaymentOrderData(paymentOrderId, handleDeleteOrderDialog, handleNewOrderDialog, removePaymentRequest, setCanCreateRequest, shouldRefreshPayments)
                      }}>
                <FormattedMessage id='PaymentOrder.ActionLinks.Delete'/>
              </Button>
              <Button onClick={handleDeleteOrderDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
          }
        </>
      )}
    </PaymentOrderConsumer>
  )
}

export default PaymentOrderDelete
