import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { editProfile } from '../../../utils/pages-meta.json'
import EditProfileContent from '../../../components/Workspace/EditProfile'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { useIntl } from 'react-intl'
import InActiveUserContent from '../../../components/InActiveUserContent'
import { SearchLoader } from '../../../components/Loader/SearchLoader'

const EditProfile = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'PageTitle.ProfileEdit' })}
           pathname={window.location.origin}
           titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
           image={editProfile.image}
           description={intl.formatMessage({ id: 'PageTitle.ProfileEdit' })}
      />
      <AuthenticatedUserConsumer>
        {({ userDataLoading, isUserActive, userData, resetSelectedCompany, getCountries }) => (
          <>
            {!!userDataLoading && <SearchLoader loading={true}/>}
            {(!!Object.keys(userData).length && !!isUserActive) ?
              <EditProfileContent resetSelectedCompany={resetSelectedCompany} getCountries={getCountries}/>
              : (!userDataLoading && <InActiveUserContent/>)
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </>
  )
}

export default EditProfile
