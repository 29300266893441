import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './Styles'
import { PaymentOrderConsumer } from '../../../../../PaymentOrderContext'
import PaymentReceiptViewContent from './PaymentReceiptViewContent'
import PaymentReceiptPDFContent from './PaymentReceiptPDFContent'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { AuthenticatedUserConsumer } from '../../../../../AuthenticatedUserContext'
import { LanguageConsumer } from '../../../../../LanguageContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentReceiptView = ({ receiptNumber, requestIdToOpen, currentRequestId }) => {
  const classes = useStyles()
  let intl = useIntl()
  const [viewReceiptDialog, setViewReceiptDialog] = React.useState(false)
  const [openReceiptDialog, setOpenReceiptDialog] = React.useState(false)
  const handleReceiptDialog = () => {
    if (!openReceiptDialog) {
      setViewReceiptDialog(true)
    } else {
      setTimeout(() => setViewReceiptDialog(false), 500)
    }
    setOpenReceiptDialog(!openReceiptDialog)
  }
  React.useEffect(() => {
      if (requestIdToOpen === currentRequestId) {
        handleReceiptDialog()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const generatePDF = () => {
    html2canvas(document.getElementById('pdfContent'), {
      onclone: function(clonedDoc) {
        clonedDoc.getElementById('pdfContent').style.visibility = 'visible'
      },
      x: 0,
      y: 0,
      scrollX: 0,
      scrollY: 0
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'px', 'a4')
        pdf.setProperties({
          title: intl.formatMessage({ id: 'PaymentReceipt.Dialog.Title' }, { rec_num: receiptNumber }),
          subject: 'Info about PDF',
          author: 'PDFAuthor',
          creator: 'E-Finance'
        })
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.autoPrint({ variant: 'non-conform' })
        window.open(URL.createObjectURL(pdf.output('blob')), 'test')
      })
  }

  return (
    <AuthenticatedUserConsumer>
      {({ userData }) => (
        <PaymentOrderConsumer>
          {({ paymentDetails, PaymentOrderColumns }) => (
            <>
              <Button size="small" variant="contained" color="primary"
                      onClick={handleReceiptDialog}>
                <FormattedMessage id='PaymentOrder.ActionLinks.View'/>
              </Button>
              {!!viewReceiptDialog &&
              <Dialog
                keepMounted
                fullWidth
                maxWidth={'md'}
                open={openReceiptDialog}
                classes={{ paper: classes.NewOrderDialogPaper }}
                TransitionComponent={Transition}
                onClose={handleReceiptDialog}
                aria-labelledby="BasicInfoDialog"
                aria-describedby="BasicInfoDialog"
              >
                <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
                  {!!Object.keys(paymentDetails) &&
                  <Typography variant="h5"><FormattedMessage id='PaymentReceipt.Dialog.Title'
                                                             values={{ rec_num: paymentDetails.receipt_number }}/></Typography>
                  }
                  <IconButton
                    edge={false}
                    className={classes.NewOrderDialogClose}
                    color="inherit"
                    aria-label="close dialog"
                    onClick={handleReceiptDialog}
                  >
                    <CloseIcon/>
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.NewOrderDialogContent}>
                  <Box p={4} className={classes.NewOrderStepsWrapper}>
                    <PaymentReceiptViewContent/>
                  </Box>
                  <LanguageConsumer>
                    {({ locale }) => (
                      <PaymentReceiptPDFContent
                        receiptDetails={paymentDetails}
                        locale={locale}
                        userName={userData.first_name + ' ' + userData.last_name}
                      />
                    )}
                  </LanguageConsumer>
                </DialogContent>
                <DialogActions className={classes.NewOrderDialogFooter}>
                  <Button size="large" variant="contained" color="primary"
                          onClick={generatePDF}>
                    <FormattedMessage id='PaymentOrder.ActionLinks.print'/>
                  </Button>
                  <Button onClick={handleReceiptDialog} color="primary">
                    <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                  </Button>
                </DialogActions>
              </Dialog>
              }
            </>
          )}
        </PaymentOrderConsumer>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default PaymentReceiptView
