import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Button, Container, Menu, MenuItem, Typography } from '@material-ui/core'
import { NavLink, useParams } from 'react-router-dom'
import { useStyles } from './Styles'
import HomeIcon from '@material-ui/icons/Home'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { FormattedMessage } from 'react-intl'
import { Fade } from 'react-reveal'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../../../routes'
import { TableRowBackground } from '../../../utils/colors'

const BreadCrumb = ({ links, current, button, allUserCompaniesList, locale, UPTCPRegistration, UPTCPButton }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  let { id } = useParams()

  React.useEffect(() => {
    setOpen(false)
  }, [id])

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }


  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${TableRowBackground}`
    }
  })((props) => (
    <Menu
      elevation={4}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: locale === 'ar' ? 'right' : 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: locale === 'ar' ? 'right' : 'left'
      }}
      // disableScrollLock
      {...props}
    />
  ))

  return (
    <>
      <Container maxWidth="lg" className={classes.BreadCrumbContainer}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
          {!!links && !!links.length && [...new Map(links.map(item => [item.key, item])).values()].map((navLink, index) => (
            <NavLink to={navLink.link} key={navLink.key}>
              {index === 0 && <HomeIcon/>}
              {index === 0 ? <span className={classes.homeText}>{navLink.name}</span> :
                navLink.name
              }
            </NavLink>
          ))}
          {!!current &&
          <Typography color="textPrimary" className={classes.BreadCrumbActiveLink}>{current}</Typography>
          }
        </Breadcrumbs>
        {!!links && !!links.length && !!allUserCompaniesList && !!allUserCompaniesList.length &&
        <>
          <Button
            className={classes.CompaniesListBtn}
            color="primary"
            variant="contained"
            size="small"
            ref={anchorRef}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <EditIcon/>
            <FormattedMessage id='BreadCrumb.ChangeCompany'/>
          </Button>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorRef.current}
            keepMounted
            open={open}
            onClose={handleClose}
            className={classes.CompaniesSubMenu}
          >
            {allUserCompaniesList.map((company, index) => (
              <MenuItem
                disabled={company.id === parseInt(id)}
                className={company.id === parseInt(id) ? 'active' : ''}
                key={company.id}>
                <Fade bottom delay={index * 50}>
                  <NavLink disabled={company.id === id}
                           to={formatRoute(NamedRoutes.workspace.company.view, { id: company.id })}>
                    {company.taxpayer_name}
                  </NavLink>
                </Fade>
              </MenuItem>
            ))}
          </StyledMenu>
        </>
        }
        {(!!button || !!UPTCPRegistration) &&
        <div className={classes.actionBtnWrapper}>{!!UPTCPRegistration && UPTCPButton}{button}</div>}
      </Container>
    </>
  )
}

export default BreadCrumb
