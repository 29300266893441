import React from 'react'
import { FormattedMessage } from 'react-intl'
import { fade, withStyles, Menu, IconButton, Button, Slide } from '@material-ui/core'
import { useStyles } from './Styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import InsuranceOrganizationBranchActionDialog from './ActionDialog'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(0),
    boxShadow: `0 8px 16px -4px ${fade(theme.palette.common.white, 0.16)}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0),
    textAlign: 'left',
    '& .MuiMenu-list': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 220,
      border: `1px solid ${theme.palette.secondary.main}`
    }
  }
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))
const InsuranceOrganizationBranchActions = ({ currentBranch }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isMenuView, setIsMenuView] = React.useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'

  const [currentActionType, setCurrentActionType] = React.useState(null)

  const handleProfileMenuOpen = (event) => {
    setIsMenuView(true)
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setTimeout(() => setIsMenuView(false), 300)
  }


  const [viewInsuranceOrganizationBranchActionDialog, setViewInsuranceOrganizationBranchActionDialog] = React.useState(false)
  const [openInsuranceOrganizationBranchActionDialog, setOpenInsuranceOrganizationBranchActionDialog] = React.useState(false)
  const handleInsuranceOrganizationBranchActionDialog = () => {
    if (!openInsuranceOrganizationBranchActionDialog) {
      setViewInsuranceOrganizationBranchActionDialog(true)
    } else {
      setTimeout(() => {
        setViewInsuranceOrganizationBranchActionDialog(false)
      }, 500)
    }
    setOpenInsuranceOrganizationBranchActionDialog(!openInsuranceOrganizationBranchActionDialog)
  }


  const openDialog = (type) => {
    handleMenuClose()
    setCurrentActionType(type || null)
    if (!!type) {
      handleInsuranceOrganizationBranchActionDialog()
    }
  }
  return (
    <>
      <IconButton
        aria-label={<FormattedMessage id='ActionsMenu.label'/>}
        aria-controls={menuId}
        aria-haspopup="true"
        className={classes.actionMenuButton}
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <MoreVertIcon color='primary'/>
      </IconButton>
      {!!isMenuView &&
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <Button onClick={() => openDialog('download')}
                className={classes.buttonAction}>
          <CloudDownloadIcon/>
          <FormattedMessage id='InsuranceOrganizationBranchActions.button.download'/>
        </Button>
        <Button onClick={() => openDialog('upload')}
                className={classes.buttonAction}>
          <CloudUploadIcon/>
          <FormattedMessage id='InsuranceOrganizationBranchActions.button.upload'/>
        </Button>
      </StyledMenu>
      }
      {!!viewInsuranceOrganizationBranchActionDialog &&
      <InsuranceOrganizationBranchActionDialog
        openInsuranceOrganizationBranchActionDialog={openInsuranceOrganizationBranchActionDialog}
        handleInsuranceOrganizationBranchActionDialog={handleInsuranceOrganizationBranchActionDialog}
        currentBranch={currentBranch}
        currentActionType={currentActionType}
        Transition={Transition}
      />
      }
    </>
  )
}

export default InsuranceOrganizationBranchActions
