import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative',
    width: '100%'
  },
  formRow: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        minHeight: 40,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        flex: '0 0 auto',
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root': {
      width: 340,
      maxWidth: '100%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  formTable: {
    width: '100%',
    flexWrap: 'wrap',
    '& .MuiInputLabel-root': {
      width: '100%',
      marginBottom: theme.spacing(1.5)
    },
    '& .MuiFormControl-root': {
      width: '100%'
    }
  },
}))
