import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  NewOrderDialogPaper: {
    borderRadius: 0
  },
  NewOrderDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  NewOrderDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  NewOrderDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  NewOrderOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  NewOrderDialogFooter: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 4),
    boxShadow: `0px 0px 24px -14px ${fade(theme.palette.common.black, 0.8)}`,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(0),
      justifyContent: 'center',
      flexWrap: 'wrap'
    }
  },
  userPopupAcceptTitle: {
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(20)
    }
  },
  NewOrderStepsWrapper: {
    position: 'relative',
    // whiteSpace: 'pre-line',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.7,
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3)
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(18)
    },
    '&.center': {
      textAlign: 'center'
    },
    '& p': {
      marginBottom: theme.spacing(1)
    }
  }
}))
