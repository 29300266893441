import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import Layout from '../Layout'
import { FullBodyLoader } from '../Loader/FullBodyLoader'

const LayoutWrapper = ({ locale }) => {
  const { keycloak, initialized } = useKeycloak()
  React.useEffect(() => {
    if (!!initialized && !keycloak?.authenticated) {
      let refs = keycloak?.createAccountUrl().split('?')[1]
      let newUrl = keycloak?.createLoginUrl({ locale: locale }) + '&' + refs
      window.location.href = newUrl
    }
  }, [keycloak, initialized, locale])

  return (
    (!!initialized && keycloak?.authenticated) ?
      <Layout/>
      : <FullBodyLoader loading={true}/>
  )
}
export default LayoutWrapper
