import { fade, makeStyles } from '@material-ui/core/styles'
import { BodyBackground, FilterBackground } from '../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  CompaniesInfo: {
    padding: theme.spacing(2)
  },
  itemWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    '&.asTable': {
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
        backgroundColor: BodyBackground,
        marginBottom: theme.spacing(1),
        boxShadow: `0px 0px 6px 0px ${fade(theme.palette.common.black, 0.4)}`,
        '&:nth-child(odd)': {
          backgroundColor: FilterBackground
        }
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0),
        alignItems: 'stretch',
        marginBottom: theme.spacing(1)
      }
    }
  },
  itemLabel: {
    fontSize: theme.typography.pxToRem(14),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.5)
    },
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      width: 172,
      marginRight: theme.spacing(2),
      '.asTable &': {
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(0),
        backgroundColor: FilterBackground,
        padding: theme.spacing(1, 2),
        width: 185,
        boxShadow: `0px 0px 6px 0px ${fade(theme.palette.common.black, 0.4)}`
      }
    }
  },
  itemValue: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,

    '.asTable &': {
      [theme.breakpoints.up('sm')]: {
        flex: '1 1 auto',
        padding: theme.spacing(1, 2),
        backgroundColor: BodyBackground,
        display: 'flex',
        alignItems: 'center',
        boxShadow: `0px 0px 6px 0px ${fade(theme.palette.common.black, 0.4)}`
      }
    }
  },
  phoneNumFormat: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    display: 'block'
  }
}))
