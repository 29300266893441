import React from 'react'
import {
  TableRow,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody, Typography
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { FormLoader } from '../../../../Loader/FormLoader'
import { FormattedMessage } from 'react-intl'
import BalancePaymentRow from './Row'
import { useStyles } from './Styles'

const PaymentsBalanceTableScroll = ({ rows, columns, availableBalance, size, calculatedBalance, handlePaymentToPay, updatePaymentToPay, Mobile, pagesCount, handleChangePage, page, loading, totalCount }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {!!loading && <FormLoader/>}
        <TableContainer className={classes.container}>
          <Table size={size || 'small'} aria-label="Payment table">
            <TableHead>
              <TableRow>
                {parseFloat(availableBalance?.toString()) > 0 &&
                <TableCell
                  align='center'
                >
                </TableCell>}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align='center'
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <BalancePaymentRow key={index}
                                     isForeign={!!row.is_paid || row.currency.key !== 'egp'}
                                     row={row} columns={columns}
                                     allowPay={parseFloat(availableBalance?.toString()) > 0}
                                     calculatedBalance={calculatedBalance}
                                     handlePaymentToPay={handlePaymentToPay}
                                     updatePaymentToPay={updatePaymentToPay}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.paginationWrapper}>
          <Typography component={'h6'} variant={'h6'} color={'textPrimary'}>
            <FormattedMessage id='payment.resultCount' values={{
              strong: chunks => <strong>{chunks}</strong>,
              count: totalCount
            }}/>
          </Typography>
          <Pagination className={classes.pager} color="primary" count={pagesCount} page={page} siblingCount={1}
                      showFirstButton={!Mobile && pagesCount > 4} showLastButton={!Mobile && pagesCount > 4}
                      onChange={handleChangePage}/>
        </div>
      </Paper>
    </React.Fragment>
  )
}
export default PaymentsBalanceTableScroll