import 'date-fns'
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  FormHelperText,
  AccordionSummary, AccordionDetails, Accordion
} from '@material-ui/core'
import { CompanyRequestsFormValidation } from '../../../../utils/validationSchema'
import { useStyles } from '../../Styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { lg } from '../../../../utils/ResponsiveUtility'
import { LanguageConsumer } from '../../../../LanguageContext'


const RequestsFilter = ({ submitForm, claimId, isPaymentClaim }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null)
  const [selectedDateTo, setSelectedDateTo] = React.useState(null)
  const handleDateFromChange = (date) => {
    setSelectedDateFrom(date)
    filterForm.setFieldValue('created_from', date || '')
  }
  const handleDateToChange = (date) => {
    setSelectedDateTo(date)
    filterForm.setFieldValue('created_to', date || '')
  }

  const filterForm = useFormik({
    initialValues: {
      request_number: '',
      payment_claim_number: '',
      payment_source_type: '',
      request_state: '',
      created_from: '',
      created_to: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: CompanyRequestsFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions, isPaymentClaim)
    })
  })
  const SourceItems = [
    {
      id: 0,
      name: <FormattedMessage id='PaymentHeader.paymentSourceType'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='PaymentHeader.paymentSourceType.internal'/>,
      value: 'internal'
    },
    {
      id: 2,
      name: <FormattedMessage id='PaymentHeader.paymentSourceType.external'/>,
      value: 'external'
    }
  ]
  const handleSourceChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('payment_source_type', event.target.value.toString())
  }
  const statusItems = [
    {
      id: 0,
      name: <FormattedMessage id='PaymentHeader.requestState'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='PaymentHeader.requestState.pending'/>,
      value: 'pending'
    },
    {
      id: 2,
      name: <FormattedMessage id='PaymentHeader.requestState.paidAndWaiting'/>,
      value: 'paid_and_waiting_acknowledgment'
    },
    {
      id: 3,
      name: <FormattedMessage id='PaymentHeader.requestState.paid'/>,
      value: 'paid'
    },
    {
      id: 4,
      name: <FormattedMessage id='PaymentHeader.requestState.expired'/>,
      value: 'expired'
    },
    {
      id: 5,
      name: <FormattedMessage id='PaymentHeader.requestState.failed'/>,
      value: 'failed'
    }
  ]

  const handleStatusChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    filterForm.setFieldValue('request_state', event.target.value.toString())
  }

  const [expanded, setExpanded] = React.useState(false)
  const [width, setWidth] = React.useState(document.body.clientWidth)

  const handleCollapseChange = (event) => {
    setExpanded(!expanded)
  }

  let desktop = (width >= lg)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <Accordion className={classes.filterWrapper}
               expanded={desktop || expanded}
               onChange={handleCollapseChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
        aria-controls="companies-filter"
        id="companies-filter"
      >
        <h3 className={classes.filterTitle}><FormattedMessage id='Filter.Title'/></h3>
      </AccordionSummary>
      <AccordionDetails>
        <LanguageConsumer>
          {({ locale }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
              <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper + ' ' + classes.formWrapperDense}>
                <FormControl fullWidth className={classes.formControl}>
                  <TextField
                    id="filterForm.request_number"
                    type={'text'}
                    margin="dense"
                    label={<FormattedMessage id='PaymentHeader.requestNumber'/>}
                    variant="outlined"
                    name='request_number'
                    onChange={filterForm.handleChange}
                    onBlur={filterForm.handleBlur}
                    value={filterForm.values.request_number}
                    helperText={filterForm.errors.request_number && filterForm.touched.name && filterForm.errors.request_number}
                    error={filterForm.errors.request_number && filterForm.touched.request_number !== undefined}
                  />
                </FormControl>
                {!claimId &&
                <>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id="filterForm.payment_claim_number"
                      type={'text'}
                      margin="dense"
                      label={<FormattedMessage id='PaymentHeader.climeNumber'/>}
                      variant="outlined"
                      name='payment_claim_number'
                      onChange={filterForm.handleChange}
                      onBlur={filterForm.handleBlur}
                      value={filterForm.values.payment_claim_number}
                      helperText={filterForm.errors.payment_claim_number && filterForm.touched.payment_claim_number && filterForm.errors.payment_claim_number}
                      error={filterForm.errors.payment_claim_number && filterForm.touched.payment_claim_number !== undefined}
                    />
                  </FormControl>

                  <FormControl fullWidth margin={'dense'} variant='outlined'
                               className={classes.formControl + ' ' + classes.formSelectControl}>
                    <InputLabel id="payment_source_type"> {<FormattedMessage
                      id='PaymentHeader.paymentSourceType'/>} </InputLabel>
                    <Select
                      fullWidth
                      labelId="payment_source_type"
                      id="payment_source_type"
                      margin="dense"
                      MenuProps={{ disableScrollLock: !!desktop }}
                      name="payment_source_type"
                      value={filterForm.values.payment_source_type}
                      onChange={handleSourceChange}
                      onBlur={filterForm.handleBlur}
                      error={filterForm.errors.payment_source_type && filterForm.touched.payment_source_type !== undefined}
                      label={<FormattedMessage id='PaymentHeader.paymentSourceType'/>}
                    >
                      {SourceItems.map((item) => (<MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
                    </Select>
                    {filterForm.errors.payment_source_type && filterForm.touched.payment_source_type && (
                      <FormHelperText error={true}>{filterForm.errors.payment_source_type}</FormHelperText>
                    )}
                  </FormControl>
                </>
                }

                <FormControl fullWidth margin={'dense'} variant='outlined'
                             className={classes.formControl + ' ' + classes.formSelectControl}>
                  <InputLabel id="request_state"> {<FormattedMessage id='PaymentHeader.requestState'/>} </InputLabel>
                  <Select
                    fullWidth
                    labelId="request_state"
                    id="request_state"
                    margin="dense"
                    MenuProps={{ disableScrollLock: !!desktop }}
                    name="request_state"
                    value={filterForm.values.request_state}
                    onChange={handleStatusChange}
                    onBlur={filterForm.handleBlur}
                    error={filterForm.errors.request_state && filterForm.touched.request_state !== undefined}
                    label={<FormattedMessage id='CompanyHeader.UserStatus'/>}
                  >
                    {statusItems.map((item) => (<MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>))}
                  </Select>
                  {filterForm.errors.request_state && filterForm.touched.request_state && (
                    <FormHelperText error={true}>{filterForm.errors.request_state}</FormHelperText>
                  )}
                </FormControl>
                <KeyboardDatePicker
                  fullWidth
                  className={classes.dateFormControl}
                  disableToolbar
                  variant="outlined"
                  format="dd-MM-yyyy"
                  margin='dense'
                  id="created_from"
                  name='created_from'
                  label={intl.formatMessage({ id: 'Fields.placeholder.dateFrom' })}
                  value={selectedDateFrom}
                  onChange={handleDateFromChange}
                  onBlur={filterForm.handleBlur}
                  helperText={filterForm.errors.created_from && filterForm.touched.created_from &&
                  <FormattedMessage id='validationSchema.date'/>}
                  error={filterForm.errors.created_from && filterForm.touched.created_from !== undefined}
                  KeyboardButtonProps={{
                    'aria-label': 'date from'
                  }}
                />

                <KeyboardDatePicker
                  fullWidth
                  className={classes.dateFormControl}
                  disableToolbar
                  variant="outlined"
                  format="dd-MM-yyyy"
                  margin='dense'
                  id="created_to"
                  name='created_to'
                  label={intl.formatMessage({ id: 'Fields.placeholder.dateTo' })}
                  value={selectedDateTo}
                  onChange={handleDateToChange}
                  onBlur={filterForm.handleBlur}
                  helperText={filterForm.errors.created_to && filterForm.touched.created_to &&
                  <FormattedMessage id='validationSchema.date'/>}
                  error={filterForm.errors.created_to && filterForm.touched.created_to !== undefined}
                  KeyboardButtonProps={{
                    'aria-label': 'date to'
                  }}
                />
                <button type="submit" disabled={filterForm.isSubmitting || !filterForm.isValid}
                        className={classes.submitButton}>
                  <FormattedMessage id='Filter.button.submit'/>
                </button>
              </form>
            </MuiPickersUtilsProvider>
          )}
        </LanguageConsumer>
      </AccordionDetails>
    </Accordion>
  )
}

export default RequestsFilter
