import React from 'react'
import { SEO } from '../../../components/Seo/seo'
import { editCompanyProfile } from '../../../utils/pages-meta.json'
import EditCompaniesContacts from '../../../components/Workspace/EditCompaniesContacts'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { useIntl } from 'react-intl'
import InActiveUserContent from '../../../components/InActiveUserContent'
import { SearchLoader } from '../../../components/Loader/SearchLoader'

const EditCompanyContact = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'PageTitle.CompaniesProfileEdit' })}
           pathname={window.location.origin}
           titleTemplate={intl.formatMessage({ id: 'E-Tax-Workspace' })}
           image={editCompanyProfile.image}
           description={intl.formatMessage({ id: 'PageTitle.CompaniesProfileEdit' })}
      />
      <AuthenticatedUserConsumer>
        {({ userDataLoading, isUserActive, userData, resetSelectedCompany, getCompaniesContacts, getConfiguredUnlinkedCompaniesContactsCount }) => (
          <>
            {!!userDataLoading && <SearchLoader loading={true}/>}
            {(!!Object.keys(userData).length && !!isUserActive) ?
              <EditCompaniesContacts resetSelectedCompany={resetSelectedCompany}
                                     getConfiguredUnlinkedCompaniesContactsCount={getConfiguredUnlinkedCompaniesContactsCount}
                                     getCompaniesContacts={getCompaniesContacts}/>
              : (!userDataLoading && <InActiveUserContent/>)
            }
          </>
        )}
      </AuthenticatedUserConsumer>
    </>
  )
}

export default EditCompanyContact
