import React from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import {useStyles} from './Styles'
import Drawer from '@material-ui/core/Drawer'
import MainNavigation from '../MainNavigation'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import {GeneralConsumer, GeneralProvider} from '../../../GeneralContext'

const MobileMenu = ({isWorkspace}) => {
  const classes = useStyles()

  return (
    <GeneralProvider>
      <GeneralConsumer>{({mobileMenuState, toggleMobileMenuDrawer}) => (
        <>
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleMobileMenuDrawer(true)}
          >
            <MenuIcon/>
          </IconButton>
          <Drawer className={classes.navigationDrawerWrapper} anchor='right' open={mobileMenuState}
                  onClose={() => toggleMobileMenuDrawer(false)}>
            <div className={classes.navigationDrawer}>
              <IconButton
                edge="end"
                className={classes.menuCloseButton}
                color="inherit"
                aria-label="close drawer"
                onClick={() => toggleMobileMenuDrawer(false)}
              >
                <CloseIcon/>
              </IconButton>
              <MainNavigation isWorkspace={isWorkspace} toggleMobileMenuDrawer={toggleMobileMenuDrawer}/>
            </div>
          </Drawer>
        </>
      )}
      </GeneralConsumer>
    </GeneralProvider>
  )
}

export default MobileMenu
