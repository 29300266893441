import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogActions,
  Slide,
  DialogTitle,
  Typography,
  InputLabel,
  FormControl,
  TextField,
  FormHelperText,
  Collapse, Select, MenuItem
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import { FormLoader } from '../../../../Loader/FormLoader'
import { DelegateFormValidation } from '../../../../../utils/validationSchema'
import { DelegateConsumer } from '../../../../../DelegateContext'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import ar from 'react-phone-input-2/lang/ar.json'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../../LanguageContext'
import { CompanyConsumer } from '../../../../../CompanyContext'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditDelegate = ({
                        openEditDelegateDialog, handleEditDelegateDialog,
                        delegateData, companyIsAccountant, handleDelegateSubmit, paymentsFiltersData
                      }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [newPhoneError, setNewPhoneError] = React.useState('')
  const DelegateForm = useFormik({
    initialValues: {
      email: delegateData.email || '',
      phone: delegateData.phone || '',
      roles: (!!delegateData.coretaxtion_delegate_roles && !!delegateData.coretaxtion_delegate_roles.length)
        ? delegateData.coretaxtion_delegate_roles[0].id : '',
      is_accountant: delegateData.is_accountant || false,
      nid: delegateData.nid || '',
      accountant_ref_number: delegateData.accountant_ref_number || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: DelegateFormValidation,
    onSubmit: ((values, actions) => {
      handleDelegateSubmit(values, actions, handleEditDelegateDialog)
    })
  })
  const handleRolesChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    DelegateForm.setFieldValue('roles', event.target.value.toString())
    if (!!event.target.value) {
      let selectedRoleArray = paymentsFiltersData['core_taxation_delegation_roles']
        .filter((role) => role.id.toString() === event.target.value.toString())
      if (!!selectedRoleArray && !!selectedRoleArray.length) {
        DelegateForm.setFieldValue('is_accountant', !!selectedRoleArray[0].is_accountant)
      }
    }
    setTimeout(() => {
      DelegateForm.setFieldTouched('roles', true, true)
    }, 100)
  }
  return (
    <DelegateConsumer>
      {({ pageAction, delegateDataFailure, delegateDataFailureMessage, delegateDataSuccess }) => (
        <>
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'sm'}
            open={openEditDelegateDialog}
            classes={{ paper: classes.DelegateDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleEditDelegateDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.DelegateDialogHeader}>
              <Typography variant="h5">
                {(!!pageAction && 'Edit' === pageAction) ?
                  <FormattedMessage id='Delegate.Edit.Title' values={{ name: delegateData.name }}/>
                  :
                  <FormattedMessage id='Delegate.Add.Title'/>
                }
              </Typography>
              <IconButton
                edge={false}
                className={classes.DelegateDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleEditDelegateDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.DelegateDialogContent}>
              <Box px={4} pt={4} pb={0}>

                {!!delegateDataFailure && !!delegateDataFailureMessage &&
                <Alert severity="error">{delegateDataFailureMessage}</Alert>
                }

                {!!delegateDataSuccess &&
                <Alert severity="success">
                  <FormattedMessage
                    id={(!!pageAction && 'Edit' === pageAction) ? 'Delegate.Edit.FormSuccessAlert' : 'Delegate.Add.FormSuccessAlert'}
                    values={{ name: delegateData.name }}/>
                </Alert>
                }

                <form onSubmit={DelegateForm.handleSubmit} noValidate autoComplete="off"
                      className={classes.formWrapper}>
                  <div className={classes.DelegateFormRow}>
                    <InputLabel id="DelegateForm.email"> {<FormattedMessage
                      id='Fields.label.email'/>} </InputLabel>
                    <FormControl variant="outlined">
                      <TextField
                        placeholder={intl.formatMessage({ id: 'BasicInfo.placeholder.email' })}
                        id="DelegateForm.email"
                        type={'email'}
                        disabled={!!Object.keys(delegateData).length}
                        margin="dense"
                        variant="outlined"
                        name='email'
                        onChange={DelegateForm.handleChange}
                        onBlur={DelegateForm.handleBlur}
                        value={DelegateForm.values.email}
                        helperText={DelegateForm.errors.email && DelegateForm.touched.email && DelegateForm.errors.email}
                        error={DelegateForm.errors.email && DelegateForm.touched.email !== undefined}
                      />
                    </FormControl>
                  </div>

                  <div className={classes.DelegateFormRow}>
                    <InputLabel id="linkCompanyForm.mobile"><FormattedMessage
                      id='Fields.label.mobile'/></InputLabel>
                    <LanguageConsumer>
                      {({ locale }) => (
                        <div className={classes.PhoneInputWrapper}>
                          <PhoneInput
                            enableSearch={true}
                            countryCodeEditable={false}
                            jumpCursorToEnd={true}
                            country={'eg'}
                            localization={locale === 'ar' ? ar : ''}
                            specialLabel={''}
                            disabled={!!Object.keys(delegateData).length}
                            excludeCountries={['il']}
                            inputProps={{
                              name: 'phone',
                              required: true,
                              autoFocus: false
                            }}
                            searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                            searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                            onBlur={DelegateForm.handleBlur}
                            value={DelegateForm.values.phone}
                            helperText={DelegateForm.errors.phone && DelegateForm.touched.phone && DelegateForm.errors.phone}
                            error={DelegateForm.errors.phone && DelegateForm.touched.phone !== undefined}
                            onChange={(phone) => {
                              DelegateForm.setFieldValue('phone', phone.toString())
                            }}
                            isValid={(value, country) => {
                              if (!!pageAction && 'Edit' === pageAction) {
                                return true
                              } else {
                                if (country.iso2 === 'eg' && value.length > 2 && value[2] && value[2].toString() !== '1') {
                                  setNewPhoneError(intl.formatMessage({ id: 'validationSchema.phoneStartingError' }))
                                  return false
                                }
                                if (country.iso2 === 'eg' && value.length > 12) {
                                  setNewPhoneError(intl.formatMessage({ id: 'validationSchema.phoneError' }))
                                  return false
                                } else {
                                  setNewPhoneError('')
                                  return true
                                }
                              }
                            }}
                          />
                          {!!newPhoneError && <FormHelperText error={true}>{newPhoneError}</FormHelperText>}
                        </div>
                      )}
                    </LanguageConsumer>
                  </div>

                  <CompanyConsumer>
                    {({ isCompanyUnderage }) => (
                      <div className={classes.DelegateFormRow}>
                        <InputLabel id="DelegateForm.roles">
                          <FormattedMessage id='DelegateHeader.DelegateRole'/>*:</InputLabel>
                        <FormControl variant='outlined'>
                          <Select
                            fullWidth
                            id="roles"
                            margin="dense"
                            displayEmpty
                            MenuProps={{ disableScrollLock: true }}
                            name="roles"
                            value={DelegateForm.values.roles}
                            onChange={handleRolesChange}
                            onBlur={DelegateForm.handleBlur}
                            error={DelegateForm.errors.roles && DelegateForm.touched.roles !== undefined}
                          >
                            <MenuItem value=''><FormattedMessage id='DelegateHeader.DelegateRole'/></MenuItem>
                            {!!paymentsFiltersData['core_taxation_delegation_roles'].length &&
                            paymentsFiltersData['core_taxation_delegation_roles']
                              .filter((role) => !companyIsAccountant ? role.is_accountant === false : true)
                              .filter((role) => !isCompanyUnderage ? role.is_guardian === false : true)
                              .map((role) => (
                                <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                              ))}
                          </Select>
                          {DelegateForm.errors.roles && DelegateForm.touched.roles && (
                            <FormHelperText error={true}>{DelegateForm.errors.roles}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    )}
                  </CompanyConsumer>

                  <Collapse in={!!DelegateForm.values.is_accountant} timeout="auto" unmountOnExit>
                    <div className={classes.DelegateFormRow}>
                      <InputLabel id="DelegateForm.nid"><FormattedMessage
                        id='Fields.label.socialNumber'/></InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.socialNumber' })}
                          id="DelegateForm.nid"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='nid'
                          onChange={DelegateForm.handleChange}
                          onBlur={DelegateForm.handleBlur}
                          value={DelegateForm.values.nid}
                          helperText={DelegateForm.errors.nid && DelegateForm.touched.nid && DelegateForm.errors.nid}
                          error={DelegateForm.errors.nid && DelegateForm.touched.nid !== undefined}
                        />
                      </FormControl>
                    </div>

                    <div className={classes.DelegateFormRow}>
                      <InputLabel id="DelegateForm.accountantRefNumber"><FormattedMessage
                        id='Fields.label.accountantRefNumber'/></InputLabel>
                      <FormControl variant="outlined">
                        <TextField
                          placeholder={intl.formatMessage({ id: 'DelegateForm.placeholder.accountantRefNumber' })}
                          id="DelegateForm.accountantRefNumber"
                          type={'text'}
                          margin="dense"
                          variant="outlined"
                          name='accountant_ref_number'
                          // onChange={DelegateForm.handleChange}
                          onChange={(event) => {
                            const re = /^[0-9\b]+$/
                            if (event.target.value === '' || re.test(event.target.value)) {
                              DelegateForm.setFieldValue('accountant_ref_number', event.target.value)
                            }
                          }}
                          onBlur={DelegateForm.handleBlur}
                          value={DelegateForm.values.accountant_ref_number}
                          helperText={DelegateForm.errors.accountant_ref_number && DelegateForm.touched.accountant_ref_number && DelegateForm.errors.accountant_ref_number}
                          error={DelegateForm.errors.accountant_ref_number && DelegateForm.touched.accountant_ref_number !== undefined}
                        />
                      </FormControl>
                    </div>
                  </Collapse>

                  {DelegateForm.isSubmitting && <FormLoader loading={true}/>}
                </form>
              </Box>
            </DialogContent>
            <DialogActions className={classes.DelegateDialogFooter}>
              <Button size="large" variant="contained" color="primary"
                      onClick={DelegateForm.handleSubmit}
                      disabled={!!newPhoneError || DelegateForm.isSubmitting || !DelegateForm.isValid}>
                <FormattedMessage id={`Delegate.Button.${(!!pageAction && 'Edit' === pageAction) ? 'Edit' : 'Add'}`}/>
              </Button>

              <Button onClick={handleEditDelegateDialog} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </DelegateConsumer>
  )
}

export default EditDelegate
