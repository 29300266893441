import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'

const EmptyResult = ({ isNotification, isPaymentRequests, isPayments, isDelegates,isEcommerce }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <div className={classes.padding}>
            <h2>{!!isNotification ? <FormattedMessage id='NoResult.notifications'/> :
              (!!isDelegates ? <FormattedMessage id='NoResult.Delegates'/> :
              !!isEcommerce ? <FormattedMessage id='NoResult.Ecommerce'/> :
              (!!isPaymentRequests ? <FormattedMessage id='NoResult.PaymentRequests'/> :
                (!!isPayments ? <FormattedMessage id='NoResult.Payments'/> :
                  <FormattedMessage id='NoResult.Title'/>)))}</h2>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default EmptyResult
