import React from 'react'
import Item from '../../../CompaniesInfo/Item'
import { PaymentOrderConsumer } from '../../../../../../PaymentOrderContext'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { FormLoader } from '../../../../../Loader/FormLoader'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { LanguageConsumer } from '../../../../../../LanguageContext'
import { useStyles } from '../Styles'

const PaymentReceiptViewContent = () => {
  const classes = useStyles()
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <PaymentOrderConsumer>
          {({ paymentDetails, PaymentOrderColumns, paymentOrderLoading }) => (
            <>
              {!!paymentOrderLoading && <FormLoader loading={true}/>}
              {!!paymentDetails && !!Object.keys(paymentDetails).length &&
              <>
                <div>
                  {PaymentOrderColumns.map((column) => (
                    paymentDetails[column.id] &&
                    <Item key={column.id} asTable label={column.label} value={column.id === 'payment_claim_amount' ?
                      <FormattedNumber value={paymentDetails[column.id]}/> :
                      ((['payment_tax_period_from', 'payment_tax_period_to', 'create_at'].includes(column.id)) ?
                        <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                format="Do MMMM YYYY h:mm a">{paymentDetails[column.id]}</Moment>
                        : paymentDetails[column.id] || 'N/A')
                    }/>
                  ))}
                </div>
                <div className={classes.receiptHint}>
                  <div><span className='icon'>★</span> <span><FormattedMessage id='PaymentReceipt.hint1'/></span></div>
                </div>
              </>
              }
            </>
          )}
        </PaymentOrderConsumer>
      )}
    </LanguageConsumer>
  )
}

export default PaymentReceiptViewContent
