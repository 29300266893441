import React from 'react'
import {
  Button,
  FormControl, FormHelperText, LinearProgress,
  InputLabel, MenuItem, Select
} from '@material-ui/core'
import { useFormik } from 'formik'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage, useIntl } from 'react-intl'
import { insuranceOrganizationBranchUploadFormFormValidation } from '../../../../../utils/validationSchema'
import { InsuranceServicesConsumer } from '../../../../../InsuranceServicesContext'
import { FormLoader } from '../../../../Loader/FormLoader'
import { useStyles } from './Styles'
import Configuration from '../../../../../Services/Api/Configuration'

const InsuranceOrganizationBranchUploadForm = ({ onSubmit, currentBranch, resetStatus, handleCloseDialog }) => {
  const classes = useStyles()
  const intl = useIntl()
  const uploadForm = useFormik({
    initialValues: {
      type: '',
      insurance_organization_number: currentBranch.organization_number,
      insurance_form: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: insuranceOrganizationBranchUploadFormFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions, handleCloseDialog)
    })
  })

  const handleTypeChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    uploadForm.setFieldValue('type', event.target.value.toString())
  }

  const handleFileChange = (event, fieldName) => {
    event.preventDefault()
    let files = event.currentTarget.files
    if (files && files.length > 0) {
      if (!!uploadForm.errors[fieldName]) {
        uploadForm.setFieldError(fieldName, null)
      }
      let file = files[0]
      if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
        if (file.size <= Configuration.UPLOAD_FILE_SIZE) {
          uploadForm.setFieldValue(fieldName, file)
          setFilesName(event.target.value.replace(/.*(\/|\\)/, ''))
        } else {
          uploadForm.setFieldTouched(fieldName, true, true)
          setTimeout(() => uploadForm.setFieldError(fieldName, intl.formatMessage({ id: 'validationSchema.largeFile' })), 50)
        }
      } else {
        uploadForm.setFieldTouched(fieldName, true, true)
        setTimeout(() => uploadForm.setFieldError(fieldName, intl.formatMessage({ id: 'validationSchema.unSupported' })), 50)

      }
    }
  }
  const [filesName, setFilesName] = React.useState(intl.formatMessage({ id: 'Fields.placeholder.insurance_form' }))

  React.useEffect(() => {
      return () => {
        resetStatus()
        uploadForm.resetForm()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <InsuranceServicesConsumer>
      {({
          InsuranceOrganizationBranchFormTypes, requestUploadProgress,
          insuranceOrganizationBranchUploadSuccess, insuranceOrganizationBranchUploadFailure,
          insuranceOrganizationBranchUploadFailureMessage
        }) => (
        <>

          {!!insuranceOrganizationBranchUploadFailure && !!insuranceOrganizationBranchUploadFailureMessage &&
          <Alert severity="error">{insuranceOrganizationBranchUploadFailureMessage}</Alert>
          }
          {!!insuranceOrganizationBranchUploadSuccess &&
          <Alert severity="success">
            <FormattedMessage id='insuranceOrganizationBranchUpload.FormSuccessAlert'
                              values={{
                                organizationNumber: currentBranch.organization_number,
                                type: uploadForm.values.type
                              }}/></Alert>
          }
          <form onSubmit={uploadForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper}>

            <div className={classes.LinkCompanyFormRow + ' ' + classes.LinkCompanyFormRowSelect}>
              <InputLabel id="downloadForm.type"><FormattedMessage
                id='Fields.label.InsuranceFormType'/></InputLabel>
              <FormControl variant='outlined'>
                <Select
                  fullWidth
                  id="downloadForm.type"
                  margin="dense"
                  displayEmpty
                  MenuProps={{ disableScrollLock: true }}
                  name="type"
                  value={uploadForm.values.type}
                  onChange={handleTypeChange}
                  onBlur={uploadForm.handleBlur}
                  error={uploadForm.errors.type && uploadForm.touched.type !== undefined}
                >
                  {!!InsuranceOrganizationBranchFormTypes.length && InsuranceOrganizationBranchFormTypes.map((type) => (
                    <MenuItem key={type.id}
                              value={type.value}>{type.name}</MenuItem>
                  ))}
                </Select>
                {uploadForm.errors.type && uploadForm.touched.type && (
                  <FormHelperText error={true}>{uploadForm.errors.type}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.LinkCompanyFormRow}>
              <InputLabel htmlFor="insurance_form"><FormattedMessage
                id='Fields.Labels.insuranceFormFile'/></InputLabel>
              <FormControl variant="outlined" margin="dense">
                <div className={classes.fileUploadWrapper} data-text={filesName}>
                  <input
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className={classes.fileUpload}
                    id="insurance_form"
                    name="insurance_form"
                    type="file"
                    onChange={(event) => handleFileChange(event, 'insurance_form')}
                  />
                </div>
                {uploadForm.errors.insurance_form && uploadForm.touched.insurance_form && (
                  <FormHelperText error={true}>{uploadForm.errors.insurance_form}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className={classes.SubmitRow}>
              <Button type="submit" color={'primary'} size={'large'} variant={'contained'}
                      disabled={uploadForm.isSubmitting || !uploadForm.isValid}
                      className={classes.LinkCompanyFormSubmit}>
                <FormattedMessage id='InsuranceOrganizationBranchUploadForm.button.submit'/>
              </Button>
            </div>
            {uploadForm.isSubmitting &&
            <>
              <FormLoader loading={true}/>
              <div className={classes.uploadProgressWrapper}>
                <LinearProgress className={classes.uploadProgress} variant="determinate"
                                value={requestUploadProgress}/>
              </div>
            </>
            }
          </form>
        </>
      )}
    </InsuranceServicesConsumer>
  )
}

export default InsuranceOrganizationBranchUploadForm
