import { makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative'
  },
  DelegateDialogPaper: {
    borderRadius: 0
  },
  DelegateDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  DelegateDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  DelegateDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  DelegateOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DelegateDialogFooter: {
    padding: theme.spacing(2)
  },
  DelegateFormRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      width: '36%',
      lineHeight: 1.6,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right'
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: 'right',
    flex: '1 1 auto',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      '&[disabled]': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  }
}))
