import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { FormattedMessage, useIntl } from 'react-intl'
import CompanyServices from './Services/Consumers/CompanyServices'
import { NavLink } from 'react-router-dom'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from './routes'
import Configuration from './Services/Api/Configuration'
import { filterData } from './utils/helpers'
import { PaymentClaimRequestsFiltersPayload } from './Services/Payloads/AuthenticatedUserPayload'

let { User_Config } = Configuration

const CompanyDownPaymentContext = createContext({})

const CompanyDownPaymentProvider = ({ selectedCompany, cid, children }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()

  const DownPaymentRequestsHeaderColumns = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 140 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmountPayed'/>, minWidth: 250 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]

  const DownPaymentRequestsLabels = [
    { id: 'taxpayerName', label: <FormattedMessage id='CompanyHeader.TaxpayerName'/>, minWidth: 200 },
    { id: 'companyName', label: <FormattedMessage id='CompanyHeader.CompanyName'/>, minWidth: 200 },
    { id: 'tax_id', label: <FormattedMessage id='CompanyHeader.UniqueTaxId'/>, minWidth: 180 },
    { id: 'request_number', label: <FormattedMessage id='PaymentHeader.requestNumber'/>, minWidth: 200 },
    { id: 'request_state', label: <FormattedMessage id='PaymentHeader.requestState'/>, minWidth: 150 },
    { id: 'payment_claim_number', label: <FormattedMessage id='PaymentHeader.climeNumber'/>, minWidth: 140 },
    { id: 'payment_claim_amount', label: <FormattedMessage id='PaymentHeader.climeAmountPayed'/>, minWidth: 250 },
    { id: 'payment_min_amount', label: <FormattedMessage id='PaymentHeader.climeAmountMin'/>, minWidth: 250 },
    { id: 'payment_max_amount', label: <FormattedMessage id='PaymentHeader.climeAmountMax'/>, minWidth: 250 },
    { id: 'allow_partial_payment', label: <FormattedMessage id='PaymentHeader.paymentAllowPartial'/>, minWidth: 130 },
    { id: 'payment_tax_type', label: <FormattedMessage id='PaymentHeader.climeType'/>, minWidth: 140 },
    { id: 'payment_item', label: <FormattedMessage id='PaymentHeader.paymentItem'/>, minWidth: 130 },
    { id: 'payment_type', label: <FormattedMessage id='PaymentHeader.paymentType'/>, minWidth: 140 },
    { id: 'payment_tax_period_from', label: <FormattedMessage id='PaymentHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'payment_tax_period_to', label: <FormattedMessage id='PaymentHeader.taxPeriodTo'/>, minWidth: 140 },
    { id: 'expire_date', label: <FormattedMessage id='PaymentHeader.requestExpireDate'/>, minWidth: 160 }
  ]

  const [companyFailureUnderageMessage, setCompanyFailureUnderageMessage] = React.useState('')
  const [companyFailureUnderage, setCompanyFailureUnderage] = React.useState(false)

  const [downPaymentRequestsLoading, setDownPaymentRequestsLoading] = React.useState(false)
  const [downPaymentRequestsRefreshLoading, setDownPaymentRequestsRefreshLoading] = React.useState(false)
  const [downPaymentRequestsFailure, setDownPaymentRequestsFailure] = React.useState(false)
  const [downPaymentRequestsFailure403, setDownPaymentRequestsFailure403] = React.useState(false)
  const [downPaymentRequests, setDownPaymentRequests] = React.useState([])
  const [hasMoreDownPaymentRequests, setHasMoreDownPaymentRequests] = React.useState(false)
  const [downPaymentRequestsPage, setDownPaymentRequestsPage] = React.useState(1)
  const [downPaymentRequestsFiltersBackup, setDownPaymentRequestsFiltersBackup] = React.useState(null)


  const handleSubmitDownPaymentRequestsFilter = (values, FormikActions) => {
    setDownPaymentRequests([])
    setHasMoreDownPaymentRequests(false)
    getDownPaymentRequests(true, values, FormikActions)
  }

  const getDownPaymentRequests = (forceReload, filters, FormikActions) => {
    if (!!keycloak?.authenticated) {
      setDownPaymentRequestsFailure(false)
      setDownPaymentRequestsFailure403(false)
      setCompanyFailureUnderage(false)
      setCompanyFailureUnderageMessage('')
      if (downPaymentRequestsPage === 1 && !forceReload) {
        setDownPaymentRequestsLoading(true)
      }
      if (!!forceReload) {
        setDownPaymentRequestsPage(1)
        setDownPaymentRequestsRefreshLoading(true)
      }
      CompanyServices.getDownPaymentRequests(cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(PaymentClaimRequestsFiltersPayload(!!forceReload ? 1 : downPaymentRequestsPage, User_Config.userPaymentClaimsLimit, filters || downPaymentRequestsFiltersBackup))
      }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          if (!!result.response && result.response.status === 403) {
            setDownPaymentRequestsFailure403(true)
          }
          if (!!result.response && result.response.status === 405) {
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            setCompanyFailureUnderage(true)
            if (result.response?.data?.status?.message) {
              message = result.response.data.status.message
            }
            setCompanyFailureUnderageMessage(message)
          }
          setDownPaymentRequestsFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = []
            result.data.data.forEach((request) => {
              request['taxpayerName'] = <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.taxpayer_name}</NavLink>
              request['companyName'] = request.company.name ? <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: request.company.id })}>{request.company.name}</NavLink> : 'N/A'
              request['tax_id'] = request.company.tax_id
              newData.push(request)
            })
            setDownPaymentRequests((prevState) =>
              result.data.meta.current_page === 1 ? newData :
                [...prevState, ...newData])

            if (downPaymentRequestsPage < result.data.meta.last_page) {
              setDownPaymentRequestsPage(downPaymentRequestsPage + 1)
              if (!!filters && !!Object.keys(filters).length) {
                setDownPaymentRequestsFiltersBackup(filters)
              }
              setHasMoreDownPaymentRequests(true)
            } else {
              setDownPaymentRequestsPage(1)
              setDownPaymentRequestsFiltersBackup(null)
              setHasMoreDownPaymentRequests(false)
              // setPaymentRequestsClaimId(null)
            }
          }
        }
      }).then(() => {
        if (!!forceReload) {
          setDownPaymentRequestsRefreshLoading(false)
        } else {
          setDownPaymentRequestsLoading(false)
        }
        if (!!FormikActions) {
          FormikActions.setSubmitting(false)
        }
      })
    }
  }

  const [downPaymentRange, setDownPaymentRange] = React.useState({})
  const [downPaymentRangeLoading, setDownPaymentRangeLoading] = React.useState(false)
  const [downPaymentRangeFailure, setDownPaymentRangeFailure] = React.useState(false)
  const getDownPaymentAmountRange = (FormikActions) => {
    if (!!keycloak?.authenticated) {
      setDownPaymentRangeFailure(false)
      setDownPaymentRangeLoading(true)
      CompanyServices.getDownPaymentAmountRange(cid, { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } }).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setDownPaymentRangeFailure(true)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            FormikActions.setFieldValue('minimum', parseInt(result.data.data.min_amount || 0))
            FormikActions.setFieldValue('maximum', parseInt(result.data.data.max_amount || 0))
            setDownPaymentRange({
              minimum: parseInt(result.data.data.min_amount || 0),
              maximum: parseInt(result.data.data.max_amount || 0)
            })
            setTimeout(() => {
              FormikActions.setFieldTouched('amount', true, true)
              FormikActions.setFieldValue('amount', parseInt(result.data.data.max_amount || 0))
            }, 100)
          }
        }
      }).then(() => {
        setDownPaymentRangeLoading(false)
      })
    } else {
      setDownPaymentRange({})
    }
  }


  const [downPaymentReturnedRequest, setDownPaymentReturnedRequest] = React.useState({})
  const [newDownPaymentFailure, setNewDownPaymentFailure] = React.useState(false)
  const [newDownPaymentFailureMessage, setNewDownPaymentFailureMessage] = React.useState('')
  const [newDownPaymentSuccess, setNewDownPaymentSuccess] = React.useState(false)

  const resetAllNewDownPaymentStates = () => {
    setNewDownPaymentFailure(false)
    setNewDownPaymentFailureMessage('')
    setNewDownPaymentSuccess(false)
    setDownPaymentReturnedRequest({})
  }

  const handleNewDownPaymentSubmit = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      setNewDownPaymentFailure(false)
      setNewDownPaymentSuccess(false)
      FormikActions.setSubmitting(true)
      CompanyServices.createDownPayment(cid, filterData(values), { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
        FormikActions.setSubmitting, setNewDownPaymentFailure, FormikActions)
        .then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            setNewDownPaymentFailure(true)
            let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
            if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
              if (result.response.data.status.errors) {
                message = result.response.data.status.errors
              } else if (result.response.data.status.message) {
                message = result.response.data.status.message
              }
            }
            setNewDownPaymentFailureMessage(message)
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              let newData = result.data.data
              newData['id'] = result.data.data.request_number
              newData['taxpayerName'] = <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: result.data.data.company.id })}>{result.data.data.company.taxpayer_name}</NavLink>
              newData['companyName'] = result.data.data.company.name ? <NavLink
                to={formatRoute(NamedRoutes.workspace.company.view, { id: result.data.data.company.id })}>{result.data.data.company.name}</NavLink> : 'N/A'
              newData['tax_id'] = result.data.data.company.tax_id

              setDownPaymentReturnedRequest(newData)
              setNewDownPaymentSuccess(true)
            }
          }
        }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const removeDownPaymentRequest = (paymentOrderId, shouldRefreshPayments, isPaymentClaim) => {
    setDownPaymentRequestsLoading(true)
    setDownPaymentRequests((prevResent) => [
      ...prevResent.filter((recent) => ![paymentOrderId].some((current) => recent.request_number === current))])
    setDownPaymentRequestsLoading(false)

  }

  const updateDownPaymentRequestsAfterValidity = (requestValidityCode, rid, requestValidityNewData) => {
    setDownPaymentRequestsRefreshLoading(true)
    let allRequests = downPaymentRequests
    let TargetIndex = allRequests.findIndex(request => request['request_number'] === rid)
    if (requestValidityCode === 2) {
      setDownPaymentRequests([])
      allRequests[TargetIndex] = requestValidityNewData
      setDownPaymentRequests(allRequests)
    } else if (requestValidityCode === 3) {
      setDownPaymentRequests([])
      allRequests[TargetIndex] = requestValidityNewData
      setDownPaymentRequests(allRequests)
    }
    setTimeout(() => setDownPaymentRequestsRefreshLoading(false), 100)
  }

  return (
    <CompanyDownPaymentContext.Provider value={{

      downPaymentRequestsLoading: downPaymentRequestsLoading,
      downPaymentRequestsRefreshLoading: downPaymentRequestsRefreshLoading,
      downPaymentRequestsFailure: downPaymentRequestsFailure,
      downPaymentRequestsFailure403: downPaymentRequestsFailure403,
      downPaymentRequests: downPaymentRequests,
      hasMoreDownPaymentRequests: hasMoreDownPaymentRequests,
      DownPaymentRequestsHeaderColumns: DownPaymentRequestsHeaderColumns,
      DownPaymentRequestsLabels: DownPaymentRequestsLabels,
      getDownPaymentRequests,
      handleSubmitDownPaymentRequestsFilter,

      downPaymentReturnedRequest: downPaymentReturnedRequest,
      newDownPaymentFailure: newDownPaymentFailure,
      newDownPaymentFailureMessage: newDownPaymentFailureMessage,
      newDownPaymentSuccess: newDownPaymentSuccess,
      handleNewDownPaymentSubmit,
      resetAllNewDownPaymentStates,


      downPaymentRange: downPaymentRange,
      downPaymentRangeLoading: downPaymentRangeLoading,
      downPaymentRangeFailure: downPaymentRangeFailure,
      getDownPaymentAmountRange,

      removeDownPaymentRequest,
      updateDownPaymentRequestsAfterValidity,

      companyFailureUnderage,
      companyFailureUnderageMessage

    }}>
      {children}
    </CompanyDownPaymentContext.Provider>
  )
}
const CompanyDownPaymentConsumer = CompanyDownPaymentContext.Consumer

export { CompanyDownPaymentProvider, CompanyDownPaymentConsumer }
