import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  mainNavigation: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1, 0),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.primary.light
    },
    '& button': {
      WebkitAppearance: 'none',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left'
      }
    },
    '& a, & button': {
      color: theme.palette.common.white,
      padding: theme.spacing(0.875, theme.direction === 'ltr' ? 1.25 : 1.5),
      margin: theme.spacing(0, theme.direction === 'ltr' ? 0.625 : 1),
      display: 'inline-block',
      textTransform: 'capitalize',
      textDecoration: 'none',
      lineHeight: 1.36,
      border: 0,
      backgroundColor: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.only('md')]: {
        margin: theme.spacing(0, theme.direction === 'ltr' ? 0.125 : 0.375),
        fontSize: theme.typography.pxToRem(10)
      },
      '& svg': {
        marginRight: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
        marginTop: theme.spacing(-0.625)
      },
      '&.active': {
        backgroundColor: fade(theme.palette.common.black, 0.1)
      },
      '&:hover, &:focus': {
        color: theme.palette.common.white,
        backgroundColor: fade(theme.palette.common.black, 0.1)
      }
    }
  },

  mainNavigationSubMenu: {
    '& ul': {
      minWidth: 140
    },
    '& .MuiListItem-button': {
      transition: 'all 0.3s ease-in-out',
      padding: theme.spacing(0),
      '& a, & button': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        fontSize: theme.typography.pxToRem(16),
        color: theme.palette.primary.main,
        textDecoration: 'none',
        padding: theme.spacing(0.75, 2),
        lineHeight: 1.75,
        border: 0,
        backgroundColor: 'transparent',
        '&.active': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white
        }
      },
      '&:hover a, &:hover button': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white
      }
    }
  },
  signOutMobile: {
    '& svg': {
      marginRight: `${theme.spacing(1)}px !important`
    }
  }
}))
