import React from 'react'
import { Container } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import Alert from '@material-ui/lab/Alert'
import Cookies from 'js-cookie'
import UserMessageDialog from './UserMessageDialog'
import { useKeycloak } from '@react-keycloak/web'

const DashboardUserMessage = ({ checkUserPopupAfterLogin }) => {
  const { keycloak } = useKeycloak()
  const [viewUserPopupDialog, setViewUserPopupDialog] = React.useState(false)
  const [openUserPopupDialog, setOpenUserPopupDialog] = React.useState(false)
  const [showUserPopup, setShowUserPopup] = React.useState(undefined === Cookies.get('ShowUserPopup') ||
    (!!Cookies.get('ShowUserPopup') && (keycloak.sessionId.toString() !== Cookies.get('ShowUserPopup').toString())))
  const handleUserPopupDialog = () => {
    if (!openUserPopupDialog) {
      setViewUserPopupDialog(true)
    } else {
      setTimeout(() => {
        setViewUserPopupDialog(false)
      }, 500)
    }
    setOpenUserPopupDialog(!openUserPopupDialog)
  }

  React.useEffect(() => {
      if (undefined === Cookies.get('ShowUserPopup') ||
        (!!Cookies.get('ShowUserPopup') && (keycloak.sessionId.toString() !== Cookies.get('ShowUserPopup').toString()))) {
        setShowUserPopup(true)
        if (!!checkUserPopupAfterLogin) {
          checkUserPopupAfterLogin(handleUserPopupDialog)
        }
        Cookies.set('ShowUserPopup', keycloak.sessionId)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <AuthenticatedUserConsumer>
      {({
          showUserPopupAfterLoginFailure, userPopupAfterLoginContentFailure, showUserPopupAfterLogin,
          userPopupAfterLoginContent, userPopupAfterLoginContentLoading
        }) => (
        <>
          <Container maxWidth="lg" disableGutters>
            {!!showUserPopupAfterLoginFailure && !!userPopupAfterLoginContentFailure &&
            <Alert severity="error">
              <FormattedMessage id='userPopup.FormErrorAlert'/></Alert>
            }
          </Container>

          {!!showUserPopup && !!showUserPopupAfterLogin && !!userPopupAfterLoginContent &&
          <UserMessageDialog
            userPopupAfterLoginContent={userPopupAfterLoginContent}
            userPopupAfterLoginContentLoading={userPopupAfterLoginContentLoading}
            userPopupAfterLoginContentFailure={userPopupAfterLoginContentFailure}
            handleUserPopupDialog={handleUserPopupDialog}
            viewUserPopupDialog={viewUserPopupDialog}
            openUserPopupDialog={openUserPopupDialog}/>
          }
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default DashboardUserMessage
