import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  notAuthorizeWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(5, 1),
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(200),
      color: theme.palette.text.hint
    }
  },
  notAuthorizeTitle: {
    fontSize: theme.typography.pxToRem(24)
  },
  notAuthorizeNumber: {
    color: theme.palette.primary.main,
    display: 'block',
    fontSize: '6rem',
    letterSpacing: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '12rem',
      letterSpacing: '2rem'
    },
    [theme.breakpoints.up('md')]: {
      letterSpacing: '4rem'
    }
  },
  buttonLink: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1, 5),
    color: theme.palette.common.white,
    marginTop: theme.spacing(1),
    display: 'inline-block',
    '&:hover,&:focus': {
      color: theme.palette.common.white,
      opacity: 0.75
    }
  }
}))
