import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useIntl } from 'react-intl'
import CompanyServices from './Services/Consumers/CompanyServices'
import { filterData } from './utils/helpers'
import { EcommercePayload } from './Services/Payloads/AuthenticatedUserPayload'

const EcommerceContext = createContext({})

const EcommerceProvider = ({ children, pageAction, cid, ecommerceData, handleSubmitEcommerceFilter }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const [deleteEcommerceLoading, setDeleteEcommerceLoading] = React.useState(false)
  const [ecommerceDataFailure, setEcommerceDataFailure] = React.useState(false)
  const [ecommerceDataSuccess, setEcommerceDataSuccess] = React.useState(false)
  const [ecommerceDataFailureMessage, setEcommerceDataFailureMessage] = React.useState('')
  // const [needDelegatesToRefresh, setNeedDelegatesToRefresh] = React.useState(false)
 const [confirmSucessDialoge,setConfirmSucessDialoge]=React.useState(false)
 const [responseData,setResponseData]=React.useState('')

 const [clientSecretData,setClientSecretData]=React.useState({})
 const [loadingClientSecretData,setLoadingClientSecretData]=React.useState(false)
 const [ecommerceSecretFailure, setEcommerceSecretFailure] = React.useState(false)
 const [ecommerceSecretFailureMessage, setEcommerceSecretFailureMessage] = React.useState(false)

const handleReGenerationClientSecret=()=>{
  if (!!keycloak?.authenticated) {
    setLoadingClientSecretData(true)
    CompanyServices.viewEcommerceSecret(ecommerceData.id, cid, {headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }}).then((result) => {
      if ((!!result.response && result.response.status !== 200) || !result) {
        setEcommerceSecretFailure(true)
        let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
        if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
          if (result.response.data.status.errors) {
            message = result.response.data.status.errors
          } else if (result.response.data.status.message) {
            message = result.response.data.status.message
          }
        }
        setEcommerceSecretFailureMessage(message)
        setLoadingClientSecretData(false)
      } else {
        if (!!result && !!result.data && !!result.data.data) {
          setClientSecretData(result.data.data)
          setLoadingClientSecretData(false)
        } else {
          setDeleteEcommerceLoading(false)
        }
      }
    })
  }
}

  const resetEcommerceStatus = () => {
    setEcommerceDataSuccess(false)
    setEcommerceDataFailure(false)
    setEcommerceDataFailureMessage('')
    setDeleteEcommerceLoading(false)
  }
  const handleEcommerceSubmit = (values, FormikActions, handleEditEcommerceDialog) => {
    if (!!keycloak?.authenticated) {
      FormikActions.setSubmitting(true)
      CompanyServices.submitEcommerce(ecommerceData.id, cid, pageAction === 'Edit',
        filterData(EcommercePayload(values, pageAction === 'Edit')), {
          headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
        }, FormikActions.setSubmitting, null, FormikActions).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setEcommerceDataFailure(true)

          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setEcommerceDataFailureMessage(message)
          FormikActions.setSubmitting(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setEcommerceDataSuccess(true)
           let  timeout=1200
            setTimeout(() => {
                handleEditEcommerceDialog()
              setTimeout(() => {
                handleSubmitEcommerceFilter()
              }, 200)
            }, timeout)
            if(pageAction==='Add'){
                // console.log('pageAction')
                setResponseData(result.data.data)
                handleConfirmDialog()
            }
          } else {
            FormikActions.setSubmitting(false)
          }
        }
      })
    }
  }

  const handleDeleteEcommerce = (handleDeleteEcommerceDialog) => {
    if (!!keycloak?.authenticated) {
      setDeleteEcommerceLoading(true)
      CompanyServices.deleteEcommerce(ecommerceData.id, cid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, setDeleteEcommerceLoading).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
            setEcommerceDataFailure(true)
          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setEcommerceDataFailureMessage(message)
          setDeleteEcommerceLoading(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setEcommerceDataSuccess(true)
            setTimeout(() => {
                handleDeleteEcommerceDialog()
              setTimeout(() => {
                handleSubmitEcommerceFilter()
              }, 200)
            }, 1200)
          } else {
            setDeleteEcommerceLoading(false)
          }
        }
      })
    }
  }

  const handleConfirmDialog=()=>{
    setConfirmSucessDialoge(!confirmSucessDialoge)
  }
  return (
    <EcommerceContext.Provider value={{

      deleteEcommerceLoading: deleteEcommerceLoading,
      ecommerceDataFailure: ecommerceDataFailure,
      ecommerceDataSuccess: ecommerceDataSuccess,
      ecommerceDataFailureMessage: ecommerceDataFailureMessage,
      ecommerceData: ecommerceData,
      pageAction: pageAction,
      confirmSucessDialoge:confirmSucessDialoge,
      responseData:responseData,
      clientSecretData:clientSecretData,
      loadingClientSecretData:loadingClientSecretData,
      ecommerceSecretFailure:ecommerceSecretFailure,
      ecommerceSecretFailureMessage:ecommerceSecretFailureMessage,

      handleEcommerceSubmit,
      handleDeleteEcommerce,
      resetEcommerceStatus,
      handleConfirmDialog,
      handleReGenerationClientSecret
    }}>
      {children}
    </EcommerceContext.Provider>
  )
}
const EcommerceConsumer = EcommerceContext.Consumer

export { EcommerceProvider, EcommerceConsumer }

