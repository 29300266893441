import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  TitleContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.25,2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
}))
