import React from 'react'
import Filter from './Filter'
import { SearchLoader } from '../../../Loader/SearchLoader'
import EmptyResult from '../../../EmptyResult'
import Item from './Item'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { Box } from '@material-ui/core'
import { CompanyConsumer } from '../../../../CompanyContext'
import { NamedRoutes } from '../../../../routes'
import { isEmpty } from '../../../../utils/helpers'
import { formatRoute } from 'react-router-named-routes'
import { NavLink } from 'react-router-dom'

const CompanyNotifications = ({ selectedCompany, getNotifications, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  React.useEffect(() => {
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='workspaceMenu.link.item7'/>)
      }
      if (!!getNotifications) {
        getNotifications()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <CompanyConsumer>
      {({
          notificationsLoading, notificationsFailure, notifications, isCompanyUnderage, companyFailureUnderage,
          companyFailureUnderageMessage
        }) => (
        <Box p={2} className={'back-white'}>
          {!!isCompanyUnderage && !!companyFailureUnderage ?
            <Alert severity="error">
              {companyFailureUnderageMessage}
              <FormattedMessage id='Underage.ErrorAlert' values={{
                a: chunks => <NavLink to={formatRoute(NamedRoutes.workspace.company.delegates, { id: selectedCompany.id })}>{chunks}</NavLink>
              }}/></Alert>
            : <>
              <Filter/>
              {!!notificationsLoading && <SearchLoader/>}
              {!!notificationsFailure &&
              <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
              }
              {(!!notifications && !!notifications.length) ?
                notifications.map((notification, index) => (
                  <Item
                    key={`notification_${index}`}
                    label={notification.title}
                    value={notification.description}
                    url={notification.url}
                  />
                )) :
                !notificationsLoading &&
                <EmptyResult isNotification={true}/>
              }
            </>}
        </Box>
      )}
    </CompanyConsumer>
  )
}

export default CompanyNotifications
