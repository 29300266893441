import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  headerAuthWrapper: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1, 0),
    '&.hidden': {
      visibility: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& $headerAuthLink':{
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(0),
      }
    }
  },
  headerAuthLink: {
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: 'inline-block',
    color: theme.palette.primary.main,
    position: 'relative',
    backgroundColor: 'transparent !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(24),
      marginBottom: `${theme.spacing(0.75)}px !important`,
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type):before': {
        content: '""',
        height: '60%',
        right: theme.spacing(-0.5),
        top: 0,
        bottom: 0,
        margin: 'auto',
        position: 'absolute',
        borderRight: '1px solid'
      }
    }
  }
}))
