import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Alert from '@material-ui/lab/Alert'
import { PaymentOrderConsumer, PaymentOrderProvider } from '../../../../../PaymentOrderContext'
import AddIcon from '@material-ui/icons/Add'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import { CompanyConsumer } from '../../../../../CompanyContext'
import { paymentClaimFormValidation } from '../../../../../utils/validationSchema'
import PaymentClaimForm from './PaymentClaimForm'
import PaymentOrderStep1 from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderStep1'
import PaymentClaimOrderStep2 from '../../CompaniesPayments/PaymentNewOrder/PaymentClaimOrderStep2'
import PaymentOrderStep3 from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderStep3'
import PaymentOrderDelete from '../../CompaniesPayments/PaymentNewOrder/PaymentOrderDelete'
import { useStyles } from './Styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddNewPaymentClaimDialog = ({ submitForm, getPaymentClaims, newPaymentClaimSuccess, resetAllNewPaymentClaimStates }) => {
  const classes = useStyles()
  const theme = useTheme()
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  }

  const paymentClaimForm = useFormik({
    initialValues: {
      inquiry_request_number: '',
      tax_type: '',
      claim_amount: '',
      payment_item: '',
      tax_period_from: '',
      tax_period_to: '',
      owner_tax_registration_number: '',
      owner_tax_serial_number: '',
      tax_period_year: '',
      tax_period_month: '',
      tax_period_quarter_number: '',
      registration_payment_type: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: paymentClaimFormValidation,
    onSubmit: ((values, actions) => {
      submitForm(values, actions)
    })
  })

  const [viewAddNewPaymentClaimDialog, setViewAddNewPaymentClaimDialog] = React.useState(false)
  const [openAddNewPaymentClaimDialog, setOpenAddNewPaymentClaimDialog] = React.useState(false)
  const handleAddNewPaymentClaimDialog = () => {
    if (!openAddNewPaymentClaimDialog) {
      setViewAddNewPaymentClaimDialog(true)
    } else {
      paymentClaimForm.resetForm()
      if (!!newPaymentClaimSuccess) {
        getPaymentClaims(true)
      }
      setTimeout(() => {
        setViewAddNewPaymentClaimDialog(false)
        resetAllNewPaymentClaimStates()
      }, 500)
    }
    setOpenAddNewPaymentClaimDialog(!openAddNewPaymentClaimDialog)
  }

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16)
    }
  }))(Tooltip)

  return (
    <CompanyConsumer>
      {({ getPaymentClaimItems, paymentClaimReturnedRequest, PaymentClaimOrderHeaderColumns, PaymentClaimOwners }) => (
        <>
          <Zoom
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${transitionDuration.exit * 4}ms`
            }}
            unmountOnExit
          >
            <CustomTooltip title={<FormattedMessage id='PaymentsContent.ActionLinks.AddPaymentClaim'/>} placement="left"
                           arrow>
                <span className={classes.fabWrapper}>
                <Fab aria-label={<FormattedMessage id='PaymentsContent.ActionLinks.AddPaymentClaim'/>}
                     className={classes.fab} color={'primary'}
                     onClick={handleAddNewPaymentClaimDialog}>
                  <AddIcon/>
                </Fab>
                </span>
            </CustomTooltip>
          </Zoom>
          {!!viewAddNewPaymentClaimDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'md'}
            open={openAddNewPaymentClaimDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleAddNewPaymentClaimDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='PaymentsContent.ActionLinks.AddPaymentClaim'/></Typography>
              <IconButton
                edge={false}
                className={classes.NewOrderDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleAddNewPaymentClaimDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>

            {!!newPaymentClaimSuccess && !!Object.keys(paymentClaimReturnedRequest).length ?


              <PaymentOrderProvider
                claimId={paymentClaimReturnedRequest.id}
                isOld={true}
                paymentDetails={paymentClaimReturnedRequest}
                isPaymentClaim={true}
                forceInit={true}
                PaymentOrderColumns={PaymentClaimOrderHeaderColumns}>
                <CompanyConsumer>
                  {({ removePaymentRequest }) => (
                    <PaymentOrderConsumer>
                      {({ handleNewOrderNext, paymentOrderData, getRequestExpirationTime }) => (
                        <>
                          <DialogContent className={classes.NewOrderDialogContent}>
                            <Box p={4} className={classes.NewOrderStepsWrapper}>
                              <Alert severity="success"><FormattedMessage
                                id='NewPaymentClaim.FormSuccessAlert'/></Alert>
                              <PaymentOrderStep1 isOld={true}/>
                              <PaymentClaimOrderStep2 getRequestExpirationTime={getRequestExpirationTime}/>
                              <PaymentOrderStep3/>
                            </Box>
                          </DialogContent>
                          <DialogActions className={classes.NewOrderDialogFooter}>

                            <Button className={classes.NewOrderPayButton} size="large" variant="contained"
                                    color="primary"
                                    onClick={() => {handleNewOrderNext()}}>
                              <FormattedMessage id='PaymentOrder.ActionLinks.Pay'/>
                            </Button>

                            <PaymentOrderDelete paymentOrderId={paymentOrderData.request_number}
                                                handleNewOrderDialog={handleAddNewPaymentClaimDialog}
                                                removePaymentRequest={removePaymentRequest}
                                                shouldRefreshPayments={true}
                                                setCanCreateRequest={true}/>

                            <Button onClick={handleAddNewPaymentClaimDialog} color="primary">
                              <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                            </Button>
                          </DialogActions>
                        </>
                      )}
                    </PaymentOrderConsumer>
                  )}
                </CompanyConsumer>
              </PaymentOrderProvider>
              :
              <>
                <DialogContent className={classes.NewOrderDialogContent}>
                  <Box p={4} className={classes.NewOrderStepsWrapper}>
                    <PaymentClaimForm paymentClaimForm={paymentClaimForm} getPaymentClaimItems={getPaymentClaimItems}
                                      PaymentClaimOwners={PaymentClaimOwners}/>
                  </Box>
                </DialogContent>
                <DialogActions className={classes.NewOrderDialogFooter}>
                  <Button size="large" variant="contained" color="primary"
                          disabled={paymentClaimForm.isSubmitting || !paymentClaimForm.isValid}
                          onClick={() => {paymentClaimForm.handleSubmit()}}
                  >
                    <FormattedMessage id='PaymentOrder.ActionLinks.AddPaymentClaim'/>
                  </Button>
                  <Button onClick={handleAddNewPaymentClaimDialog} color="primary">
                    <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
                  </Button>
                </DialogActions>
              </>
            }

          </Dialog>
          }
        </>
      )}
    </CompanyConsumer>
  )
}

export default AddNewPaymentClaimDialog

