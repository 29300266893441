import { fade, makeStyles } from '@material-ui/core/styles'
import { BreadCrumbLinkActive, FilterBackground, FilterBorder } from '../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  DashboardActionLinksWrapper: {
    marginTop: theme.spacing(2),
    position: 'relative'
  },
  DashboardActionLinksContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  DashboardActionLinksItem: {
    textAlign: 'center',
    maxWidth: '40%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '& h4': {
      marginBottom: theme.spacing(2)
    }
  },
  BreadCrumbActiveLink: {
    padding: theme.spacing(1.25),
    color: BreadCrumbLinkActive,
    fontWeight: theme.typography.fontWeightBold
  },
  dashboardNavigation: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& ul': {
      marginBottom: theme.spacing(0)
    },
    '& a': {
      display: 'inline-block',
      padding: theme.spacing(1, 0),
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(20)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(8, 0)
    }
  },
  LinkCompanyDialogPaper: {
    borderRadius: 0,
    '&.MuiDialog-paperWidthSm': {
      maxWidth: theme.direction === 'ltr' ? 768 : 600
    }
  },
  UpdateCompanyRegistrationDialogPaper: {
    borderRadius: 0,
    '&.MuiDialog-paperWidthSm': {
      maxWidth: theme.direction === 'ltr' ? 768 : 670
    }
  },
  LinkCompanyDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  LinkCompanyDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  LinkCompanyDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  LinkCompanyOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  LinkCompanyDialogFooter: {
    padding: theme.spacing(2)
  },
  formWrapper: {
    position: 'relative'
  },
  formRow: {
    width: '100%'
  },
  questionnaireForm: {
    minHeight: 212,
    '& .MuiAlert-root':{
      width: '100%',
    }
  },
  LinkCompanyFormRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      width: '36%',
      flexShrink: 0,
      lineHeight: 1.6,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },

    '& .MuiFormControl-root': {
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '$questionnaireForm &': {
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiInputLabel-root': {
        width: '100%',
        textAlign: 'center',
        paddingRight: theme.spacing(0),
        justifyContent: 'center'
      },
      '&$LinkCompanyFormRowSelect .MuiFormControl-root': {
        width: '100%'
      }
    },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    '&.update': {
      alignItems: 'flex-start',
      '& .MuiInputLabel-root': {
        [theme.breakpoints.up('sm')]: {
          paddingTop: theme.spacing(1.125)
        }
      }
    },
    '&.registration-plan': {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(0),
      '& .MuiInputLabel-root': {
        paddingTop: theme.spacing(1),
        width: '30%',
        [theme.breakpoints.down('xs')]: {
          width: '100%'
        }
      },
      '& .MuiFormGroup-root[role="radiogroup"]': {
        flexDirection: 'column',
        '& .MuiFormControlLabel-root': {
          alignItems: 'flex-start',
          marginRight: theme.spacing(0)
        },
        '& .MuiFormControlLabel-label': {
          paddingTop: theme.spacing(1.125)
        }
      },
      '& .MuiFormHelperText-root': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0)
      }
    },
    // '& a': {
    //   display: 'block',
    //   width: '100%',
    //   textAlign: 'right'
    // },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  dateFormControl: {
    margin: theme.spacing(1, 0, 0.5),
    '& .MuiInput-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 1.5),
      border: `1px solid ${fade(theme.palette.common.black, 0.23)}`,
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
      display: 'none !important'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(0.75),
      zIndex: 1,
      left: theme.spacing(2)
    },
    '& .MuiInputBase-input': {
      paddingBottom: theme.spacing(0.5)
    }
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: 'right',
    flex: '1 1 auto',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      '&[disabled]': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  LinkCompanyFormRowLabelAuto: {
    '& .MuiInputLabel-root': {
      minWidth: '36%',
      width: 'auto'
    }
  },

  LinkCompanyFormRowSelect: {
    alignItems: 'flex-start',
    '& .MuiInputLabel-root': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2)
      }
    }
  },
  LinkCompanyFormRowStart: {
    alignItems: 'flex-start',
    '& .MuiInputLabel-root': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    }
  },
  SubmitRow: {
    padding: theme.spacing(4, 0, 0),
    textAlign: 'center'
  },
  LinkCompanyFormSubmit: {
    minWidth: 130
  },
  captchaWrapper: {
    '& canvas': {
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.common.black
    }
  },
  ErrorDialogPaper: {
    borderRadius: 0
  },
  ErrorDialogContent: {
    padding: '0 !important',
    position: 'relative',
    '& .MuiAlert-root': {
      [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(6)
      }
    }
  },
  ErrorDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.primary.dark
  },
  linkCompanyErrorMessage: {
    paddingRight: 50,
    whiteSpace: 'pre-line'
  },
  noMarHorizontal: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0)
  },
  incentiveCompanyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      fontSize: theme.typography.pxToRem(14),
      marginTop: theme.spacing(-0.375)
    }
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold
  },

  DialogHintPaper: {
    borderRadius: 0
  },
  DialogHintContent: {
    padding: '0 !important',
    position: 'relative',
    '& .MuiAlert-root': {
      paddingTop: theme.spacing(2)
    }
  },
  DialogHintFooter: {
    padding: 0,
    marginTop: theme.spacing(1.5),
    '& button': {
      fontWeight: theme.typography.fontWeightBold
    }
  }
}))
