import React from 'react'
import LoggedIn from './LoggedIn'
import Anonymous from './Anonymous'
import { useKeycloak } from '@react-keycloak/web'
import { LanguageConsumer } from '../../../LanguageContext'

const HeaderAuth = ({ mobileMenu, toggleMobileMenuDrawer, isWorkspace }) => {
  const { keycloak } = useKeycloak()
  return (
    <LanguageConsumer>
      {({ locale }) => (
        !!keycloak?.authenticated ?
          <LoggedIn mobileMenu={mobileMenu} toggleMobileMenuDrawer={toggleMobileMenuDrawer} isWorkspace={isWorkspace}/> :
          <Anonymous locale={locale}/>
      )}
    </LanguageConsumer>
  )
}

export default HeaderAuth
