import React from 'react'
import WorkspaceTable from '../../Table'
import { SearchLoader } from '../../../Loader/SearchLoader'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import EmptyResult from '../../../EmptyResult'
import { CompanyConsumer } from '../../../../CompanyContext'
import { isEmpty } from '../../../../utils/helpers'
import { formatRoute } from 'react-router-named-routes'
import { NamedRoutes } from '../../../../routes'
import DelegatesFilter from './DelegatesFilter'
import { Box } from '@material-ui/core'
import UnAuthorize from '../../../UnAuthorize'
import { FormLoader } from '../../../Loader/FormLoader'
import { useStyles } from './Styles'
import { DelegateConsumer, DelegateProvider } from '../../../../DelegateContext'
import AddDelegateButton from './AddDelegateButton'

const CompanyDelegates = ({
                            selectedCompany, getDelegates, setBreadCrumbLinks, setBreadCrumbCurrent, checkCompanyData,
                            setBreadCrumbButton, getFiltersData, paymentsFiltersData, paymentsFiltersFailure
                          }) => {
  const classes = useStyles()
  React.useEffect(() => {
      if (!!getFiltersData) {
        getFiltersData()
      }
      if (!!setBreadCrumbLinks && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbLinks((prevLinks) => [...prevLinks,
          {
            key: 'BreadcrumbLink-2',
            name: selectedCompany.taxpayer_name,
            link: formatRoute(NamedRoutes.workspace.company.view, { id: selectedCompany.id })
          }])
        setBreadCrumbCurrent(<FormattedMessage id='workspaceMenu.link.item5'/>)
        setBreadCrumbButton(
          <CompanyConsumer>
            {({ handleSubmitDelegatesFilter, delegatesFailure403, delegatesLoading }) => (
              !delegatesLoading && !delegatesFailure403 &&
              <DelegateProvider cid={selectedCompany.id} delegateData={{}} pageAction={'Add'}
                                checkCompanyData={checkCompanyData}
                                handleSubmitDelegatesFilter={handleSubmitDelegatesFilter}>
                <DelegateConsumer>
                  {({ resetDelegateStatus }) => (
                    <AddDelegateButton resetDelegateStatus={resetDelegateStatus} companyIsAccountant={selectedCompany.is_accountant}/>
                  )}
                </DelegateConsumer>
              </DelegateProvider>
            )}
          </CompanyConsumer>
        )

      }
      if (!!getDelegates) {
        getDelegates(true)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  return (
    <>
      <CompanyConsumer>
        {({
            delegatesLoading, delegatesFailure, delegatesFailure403, delegates, delegatesRefreshLoading,
            DelegatesHeaderColumns, handleSubmitDelegatesFilter, hasMoreDelegates
          }) => (
          <Box p={2} className={'back-white'}>

            {!!delegatesFailure403 ? <UnAuthorize/> :
              <>
                {(!!delegatesFailure || !!paymentsFiltersFailure) &&
                <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>}
              </>
            }
            {!delegatesFailure403 &&
            <DelegatesFilter
              submitForm={handleSubmitDelegatesFilter}
              paymentsFiltersData={paymentsFiltersData}/>
            }
            {!!delegatesLoading && <SearchLoader loading={true}/>}
            {(!!delegates && !!delegates.length) ?
              <WorkspaceTable
                loadRows={getDelegates}
                hasMoreRows={hasMoreDelegates}
                rows={delegates}
                columns={DelegatesHeaderColumns}
                companyID={selectedCompany.id}
                companyIsAccountant={selectedCompany.is_accountant}
                delegatesActions={true}/>
              :
              <>
                {!!delegatesRefreshLoading &&
                <div className={classes.refreshWrapper}><FormLoader loading={true}/></div>}
                {!delegatesLoading && !delegatesRefreshLoading && !delegatesFailure403 &&
                <EmptyResult isDelegates={true}/>
                }
              </>
            }
          </Box>
        )}
      </CompanyConsumer>
    </>
  )
}

export default CompanyDelegates
