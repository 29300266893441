import { makeStyles } from '@material-ui/core/styles'

import { FilterBackground, TableRowBackground } from '../../../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  container: {
    '& .MuiTableCell-root': {
      borderRight: `1px solid rgba(224,224,244,1)`,
      '&:first-child': {
        borderLeft: '1px solid rgba(224,224,224,1)'
      },
      '&:not(.MuiTableCell-paddingCheckbox)': {
        padding: theme.spacing(1)
      }
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.common.white,
      borderTop: '1px solid rgba(224,224,224,1)',
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    '&.noFilter .MuiTableCell-head': {
      background: FilterBackground,
      borderTop: '1px solid rgba(224,224,224,1)'
    }
  },
  dataRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: TableRowBackground
    },
    '&.clickable': {
      cursor: 'pointer'
    }
  },
  dataCell: {
    '& a': {
      width: '100%',
      display: 'block',
      fontWeight: theme.typography.fontWeightBold
    },
    '& ul': {
      textAlign: 'left'
    }
  },
  spanLTR: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    display: 'inline-block'
  },
  mobilePagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1.25)
  },
  noResults: {
    textAlign: 'center'
  }
}))
