import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { InsuranceServicesConsumer } from '../../../../../InsuranceServicesContext'
import InsuranceOrganizationBranchDownloadForm from './downloadForm'
import InsuranceOrganizationBranchUploadForm from './uploadForm'
import { useStyles } from './Styles'

const actionFormSwitcher = (action, currentBranch, handleCloseDialog) => {
  switch (action) {
    case 'download':
      return <InsuranceServicesConsumer>
        {({ handleInsuranceOrganizationBranchDownloadFormSubmit, resetInsuranceOrganizationBranchDownloadStatus }) => (
          <InsuranceOrganizationBranchDownloadForm
            currentBranch={currentBranch}
            onSubmit={handleInsuranceOrganizationBranchDownloadFormSubmit}
            resetStatus={resetInsuranceOrganizationBranchDownloadStatus}
            handleCloseDialog={handleCloseDialog}/>
        )}
      </InsuranceServicesConsumer>
    case 'upload':
      return <InsuranceServicesConsumer>
        {({ handleInsuranceOrganizationBranchUploadFormSubmit, resetInsuranceOrganizationBranchUploadStatus }) => (
          <InsuranceOrganizationBranchUploadForm
            currentBranch={currentBranch}
            onSubmit={handleInsuranceOrganizationBranchUploadFormSubmit}
            resetStatus={resetInsuranceOrganizationBranchUploadStatus}
            handleCloseDialog={handleCloseDialog}/>
        )}
      </InsuranceServicesConsumer>
    default:
      return ''
  }
}

const InsuranceOrganizationBranchActionDialog = ({
                                                   openInsuranceOrganizationBranchActionDialog,
                                                   handleInsuranceOrganizationBranchActionDialog,
                                                   currentBranch, currentActionType, Transition
                                                 }) => {
  const classes = useStyles()
  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth={'sm'}
      open={openInsuranceOrganizationBranchActionDialog}
      classes={{ paper: classes.LinkCompanyDialogPaper }}
      TransitionComponent={Transition}
      onClose={handleInsuranceOrganizationBranchActionDialog}
      aria-labelledby="BasicInfoDialog"
      aria-describedby="BasicInfoDialog"
    >
      <DialogTitle disableTypography className={classes.LinkCompanyDialogHeader}>
        <Typography variant="h6"><FormattedMessage
          id={`InsuranceOrganizationBranchActionDialog.title.${currentActionType}`}
          values={{ organizationName: currentBranch.organization_name }}/></Typography>
        <IconButton
          edge={false}
          className={classes.LinkCompanyDialogClose}
          color="inherit"
          aria-label="close dialog"
          onClick={handleInsuranceOrganizationBranchActionDialog}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.LinkCompanyDialogContent}>
        <Box px={4} pt={4} pb={0} className={classes.LinkCompanyWrapper}>
          {!!currentActionType && actionFormSwitcher(currentActionType, currentBranch, handleInsuranceOrganizationBranchActionDialog)}
        </Box>
      </DialogContent>
      <DialogActions className={classes.LinkCompanyDialogFooter}>
        <Button onClick={handleInsuranceOrganizationBranchActionDialog} color="primary">
          <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InsuranceOrganizationBranchActionDialog
