/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Collapse,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText, Select, MenuItem,
  Fade
} from '@material-ui/core'
import { FormLoader } from '../../../../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { CompanyConsumer } from '../../../../../../../CompanyContext'
import { useStyles } from './Styles'
import { PayNonCoreConsumer } from '../../../../../../../PayNoncoreContext'
import ClaimsListingTable from '../ClaimsListingTable'

const PayNonCoreStep1 = ({ PayNonCoreStep1Form, bootServiceData, getPaymentClaims }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [viewClaims, setViewClaims] = React.useState(false)
  const [viewTransaction, setViewTransaction] = React.useState(false)

  const handlePaymentSourceChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    PayNonCoreStep1Form.setFieldValue('payment_source', event.target.value?.toString() || '')

    if (parseInt(event.target.value) === 1) {
      getPaymentClaims(true)
      setViewClaims(true)
      setTimeout(() => {
        setViewTransaction(false)
        setViewMethods(false)
      }, 500)
    } else if ([2, 3].includes(parseInt(event.target.value))) {
      setViewTransaction(true)
      setTimeout(() => {
        setViewClaims(false)
        setViewMethods(false)
      }, 500)
    } else if (parseInt(event.target.value) === 4) {
      setViewMethods(true)
      setTimeout(() => {
        setViewClaims(false)
        setViewTransaction(false)
      }, 500)
    } else {
      setTimeout(() => {
        setViewClaims(false)
        setViewTransaction(false)
        setViewMethods(false)
      }, 500)
    }
  }

  /*4th option*/
  const [viewMethods, setViewMethods] = React.useState(false)
  const handlePaymentMethodChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    PayNonCoreStep1Form.setFieldValue('payment_method', event.target.value.toString())
  }

  React.useEffect(() => {
      if (!!PayNonCoreStep1Form?.values?.payment_source) {
        switch (parseInt(PayNonCoreStep1Form?.values?.payment_source)) {
          case 1:
            setViewClaims(true)
            break
          case 2:
          case 3:
            setViewTransaction(true)
            break
          case 4:
            setViewMethods(true)
            break
          default:
            break
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <PayNonCoreConsumer>
      {({ payNonCoreStep1Failure, payNonCoreStep1FailureMessage, paymentClaims, paymentClaimsLoading, paymentClaimsFailure }) => (
        <Fade in={true}>
          <form onSubmit={PayNonCoreStep1Form.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper}>

            {!!payNonCoreStep1Failure && !!payNonCoreStep1FailureMessage &&
            <Alert severity="error">{payNonCoreStep1FailureMessage}</Alert>
            }
            <div className={classes.formRow}>
              <InputLabel id="PayNonCoreStep1Form.paymentSource"><FormattedMessage
                id='PayNonCoreStep1.Form.paymentSource'/>*:</InputLabel>
              <FormControl variant='outlined'>
                <Select
                  fullWidth
                  displayEmpty
                  margin="dense"
                  id="payment_source"
                  name="payment_source"
                  MenuProps={{ disableScrollLock: true }}
                  value={PayNonCoreStep1Form.values.payment_source}
                  onChange={handlePaymentSourceChange}
                  onBlur={PayNonCoreStep1Form.handleBlur}
                  error={PayNonCoreStep1Form.errors.payment_source && PayNonCoreStep1Form.touched.payment_source !== undefined}
                >
                  <MenuItem value=""><FormattedMessage id='PayNonCoreStep1.Form.paymentSource'/></MenuItem>

                  {!!bootServiceData?.payment_source?.length && bootServiceData?.payment_source?.map((source) => (
                    <MenuItem key={source.code} value={source.code}>{source.name}</MenuItem>
                  ))}
                </Select>
                {PayNonCoreStep1Form.errors.payment_source && PayNonCoreStep1Form.touched.payment_source && (
                  <FormHelperText error={true}>{PayNonCoreStep1Form.errors.payment_source}</FormHelperText>
                )}
              </FormControl>
            </div>

            <Collapse
              in={!!PayNonCoreStep1Form.values.payment_source && parseInt(PayNonCoreStep1Form.values.payment_source) === 1}
              timeout="auto" unmountOnExit>
              {!!viewClaims &&
              <>
                {!!paymentClaimsFailure &&
                <Alert severity="error"><FormattedMessage
                  id='PayNonCoreStep1.Form.Error.paymentClaimsFailure'/></Alert>
                }
                <div className={classes.formRow + ' ' + classes.formTable}>
                  <InputLabel id="PayNonCoreStep1Form.claimId"><FormattedMessage
                    id='PayNonCoreStep1.Form.claimId'/>*:</InputLabel>
                  <CompanyConsumer>
                    {({ PaymentClaimHeaderColumns }) => (
                      <FormControl variant='outlined'>
                        <ClaimsListingTable rows={paymentClaims}
                                            value={PayNonCoreStep1Form.values.claim_id}
                                            setValue={(newVal) => PayNonCoreStep1Form.setFieldValue('claim_id', newVal)}
                                            columns={PaymentClaimHeaderColumns.filter(col => col.id !== 'claim_state')}/>
                        {!!paymentClaimsLoading && <FormLoader loading={true}/>}
                      </FormControl>
                    )}
                  </CompanyConsumer>
                </div>
              </>
              }
            </Collapse>

            <Collapse
              in={!!PayNonCoreStep1Form.values.payment_source && [2, 3].includes(parseInt(PayNonCoreStep1Form.values.payment_source))}
              timeout="auto" unmountOnExit>
              {!!viewTransaction &&
              <>
                <div className={classes.formRow}>
                  <InputLabel id="PayNonCoreStep1Form.transactionNumber"><FormattedMessage
                    id='PayNonCoreStep1.Form.transactionNumber'/>*:</InputLabel>
                  <TextField
                    className={classes.formText}
                    placeholder={intl.formatMessage({ id: 'PayNonCoreStep1.Form.transactionNumber' })}
                    id="transaction_number"
                    type={'text'}
                    margin="dense"
                    variant="outlined"
                    name='transaction_number'
                    onChange={PayNonCoreStep1Form.handleChange}
                    onBlur={PayNonCoreStep1Form.handleBlur}
                    value={PayNonCoreStep1Form.values.transaction_number}
                    helperText={PayNonCoreStep1Form.errors.transaction_number && PayNonCoreStep1Form.touched.transaction_number && PayNonCoreStep1Form.errors.transaction_number}
                    error={PayNonCoreStep1Form.errors.transaction_number && PayNonCoreStep1Form.touched.transaction_number !== undefined}
                  />
                </div>

              </>
              }
            </Collapse>

            <Collapse
              in={!!PayNonCoreStep1Form.values.payment_source && parseInt(PayNonCoreStep1Form.values.payment_source) === 4}
              timeout="auto" unmountOnExit>
              {!!viewMethods &&
              <>
                <div className={classes.formRow}>
                  <InputLabel id="PayNonCoreStep1Form.paymentMethod"><FormattedMessage
                    id='PayNonCoreStep1.Form.paymentMethod'/>*:</InputLabel>
                  <FormControl variant='outlined'>
                    <Select
                      fullWidth
                      displayEmpty
                      margin="dense"
                      id="payment_method"
                      name="payment_method"
                      MenuProps={{ disableScrollLock: true }}
                      value={PayNonCoreStep1Form.values.payment_method}
                      onChange={handlePaymentMethodChange}
                      onBlur={PayNonCoreStep1Form.handleBlur}
                      error={PayNonCoreStep1Form.errors.payment_method && PayNonCoreStep1Form.touched.payment_method !== undefined}
                    >
                      <MenuItem value=""><FormattedMessage id='PayNonCoreStep1.Form.paymentSource'/></MenuItem>

                      {!!bootServiceData?.payment_method?.length && bootServiceData?.payment_method?.map((method) => (
                        method.code !== 99 &&
                        <MenuItem key={method.code} value={method.code}>{method.name}</MenuItem>
                      ))}
                    </Select>
                    {PayNonCoreStep1Form.errors.payment_method && PayNonCoreStep1Form.touched.payment_method && (
                      <FormHelperText error={true}>{PayNonCoreStep1Form.errors.payment_method}</FormHelperText>
                    )}
                  </FormControl>
                </div>
              </>
              }
            </Collapse>
            {PayNonCoreStep1Form.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </Fade>
      )}
    </PayNonCoreConsumer>
  )
}

export default PayNonCoreStep1
