import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  CompanyContentContainer: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  refreshWrapper: {
    width: '100%',
    height: 200,
    position: 'relative'
  },
  UserCustomMenu: {
    '& li': {
      padding: theme.spacing(0)
    },
    '& a, & button': {
      padding: theme.spacing(1, 3),
      width: '100%',
      textAlign: 'left',
      fontSize: theme.typography.pxToRem(16),
      justifyContent: 'flex-start'
    }
  },
  addDelegateBtn: {
    margin: theme.spacing(1)
  },
  tableWrapper:{
    '& th': {
      borderTop:'1px solid rgba(224,224,224,1)'
    },
  }
}))
