import React from 'react'
import { Button, Container, Slide } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './Styles'
import LinkCompanyDialog from './LinkCompanyDialog'
import LinkCompanyErrorDialog from './LinkCompanyErrorDialog'
import { AuthenticatedUserConsumer } from '../../../AuthenticatedUserContext'
import { FormLoader } from '../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import RegisterCompanyDialog from './RegisterCompanyDialog'
import Configuration from '../../../Services/Api/Configuration'
import IncentivesForm from './IncentivesForm'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const DashboardActionLinks = ({
                                getAllCompaniesContacts, verifyCompanyContactEmail, getFiltersData,
                                resetVerifyCompanyContactStatus, companiesContactsLoading, ridToSelect,
                                verifiedCompaniesContacts, verifiedCompaniesContactsWithoutTaxId
                              }) => {
  const classes = useStyles()
  const [viewLinkCompanyDialog, setViewLinkCompanyDialog] = React.useState(false)
  const [openLinkCompanyDialog, setOpenLinkCompanyDialog] = React.useState(false)
  const handleLinkCompanyDialog = () => {
    if (!openLinkCompanyDialog) {
      setViewLinkCompanyDialog(true)
    } else {
      setTimeout(() => {
        setViewLinkCompanyDialog(false)
        resetVerifyCompanyContactStatus()
      }, 500)
    }
    setOpenLinkCompanyDialog(!openLinkCompanyDialog)
  }

  const [viewRegisterCompanyDialog, setViewRegisterCompanyDialog] = React.useState(false)
  const [openRegisterCompanyDialog, setOpenRegisterCompanyDialog] = React.useState(false)
  const handleRegisterCompanyDialog = () => {
    if (!openRegisterCompanyDialog) {
      setViewRegisterCompanyDialog(true)
    } else {
      setTimeout(() => {
        setViewRegisterCompanyDialog(false)
        resetVerifyCompanyContactStatus()
      }, 500)
    }
    setOpenRegisterCompanyDialog(!openRegisterCompanyDialog)
  }

  React.useEffect(() => {
      if (!!resetVerifyCompanyContactStatus) {
        resetVerifyCompanyContactStatus()
      }
      if (!!getFiltersData) {
        getFiltersData()
      }
      let search = window.location.search
      let params = new URLSearchParams(search)
      let paramObj = {}
      let rid = params.get('request-id')
      let token = params.get('token')
      if (!!rid && !!token) {
        for (let value of params.keys()) {
          paramObj[value] = params.get(value)
        }
        verifyCompanyContactEmail(rid, paramObj)
      } else if (!!getAllCompaniesContacts) {
        getAllCompaniesContacts()
        getAllCompaniesContacts(0)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  React.useEffect(() => {
      if (!!ridToSelect && !companiesContactsLoading) {
        if (!!verifiedCompaniesContactsWithoutTaxId?.length && verifiedCompaniesContactsWithoutTaxId.filter((contact) => contact.id.toString() === ridToSelect.toString())?.length) {
          handleRegisterCompanyDialog()
        } else {
          handleLinkCompanyDialog()
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [ridToSelect, verifiedCompaniesContactsWithoutTaxId])
  return (
    <AuthenticatedUserConsumer>
      {({
          linkCompanySubmitForm, resetLinkCompanyStatus, emailVerificationLoading, emailVerificationFailure,
          isUserCompaniesRetrieved, userCompaniesLoading, userCompanies, resetIncentivesFormStatus,
          userData, allUserCompaniesListIncentives, getAllCompaniesContacts, incentivesSubmitForm,
          incentiveLinkSuccess, selectedCompanyLoading, checkIncentivesTaxIdValidity, companiesContactsLoading
        }) => (
        <>
          <Container maxWidth="lg" disableGutters>
            {!!emailVerificationFailure &&
            <Alert severity="error"><FormattedMessage id='EmailVerification.FormErrorAlert'
                                                      values={{
                                                        a: chunks => <NavLink
                                                          to={NamedRoutes.workspace.editCompanyContact}>{chunks}</NavLink>
                                                      }}/></Alert>
            }
          </Container>
          <div className={classes.DashboardActionLinksWrapper}>
            {!!emailVerificationLoading && <FormLoader loading={true}/>}

            <Container maxWidth="lg" className={classes.DashboardActionLinksContainer}>
              {(!!isUserCompaniesRetrieved && !userCompaniesLoading && (userCompanies === undefined || userCompanies.length === 0)) && <>
                <div className={classes.DashboardActionLinksItem}>
                  <h4><FormattedMessage id='DashboardContent.ActionLinks.LinkCompany.title'/></h4>
                  <Button size="large" variant="contained" color="primary"
                          onClick={handleLinkCompanyDialog}>
                    <FormattedMessage id='DashboardContent.ActionLinks.LinkCompany'/>
                  </Button>
                  {!!viewLinkCompanyDialog &&
                  (!!verifiedCompaniesContacts && !!verifiedCompaniesContacts.length ?
                    <LinkCompanyDialog
                      openLinkCompanyDialog={openLinkCompanyDialog}
                      linkCompanySubmitForm={linkCompanySubmitForm}
                      handleLinkCompanyDialog={handleLinkCompanyDialog}
                      resetLinkCompanyStatus={resetLinkCompanyStatus}
                      verifiedCompaniesContacts={verifiedCompaniesContacts}
                      ridToSelect={ridToSelect}
                      Transition={Transition}
                      previousIncentiveCompanies={userData?.incentive_linking_requests}
                    />
                    :
                    <LinkCompanyErrorDialog
                      openLinkCompanyDialog={openLinkCompanyDialog}
                      handleLinkCompanyDialog={handleLinkCompanyDialog}
                      Transition={Transition}
                    />)
                  }
                </div>
                {!userData?.incentive_linking_requests?.length &&
                <div className={classes.DashboardActionLinksItem}>
                  <h4><FormattedMessage id='DashboardContent.ActionLinks.CreateCompany.title'/></h4>
                  <Button size="large" onClick={handleRegisterCompanyDialog} variant="contained" color="primary">
                    <FormattedMessage id="DashboardContent.ActionLinks.CreateCompany"/>
                  </Button>
                  {!!viewRegisterCompanyDialog &&
                  (!!verifiedCompaniesContactsWithoutTaxId && !!verifiedCompaniesContactsWithoutTaxId.length ?
                    <RegisterCompanyDialog
                      openRegisterCompanyDialog={openRegisterCompanyDialog}
                      handleRegisterCompanyDialog={handleRegisterCompanyDialog}
                      verifiedCompaniesContacts={verifiedCompaniesContactsWithoutTaxId}
                      Transition={Transition}
                    />
                    :
                    <LinkCompanyErrorDialog
                      openLinkCompanyDialog={openRegisterCompanyDialog}
                      handleLinkCompanyDialog={handleRegisterCompanyDialog}
                      Transition={Transition}
                      isRegister
                    />)
                  }
                </div>
                }
              </>}
              {!Configuration.HIDE_INCENTIVES &&
              <IncentivesForm className={classes.DashboardActionLinksItem}
                              resetIncentivesFormStatus={resetIncentivesFormStatus}
                              incentivesSubmitForm={incentivesSubmitForm}
                              checkIncentivesTaxIdValidity={checkIncentivesTaxIdValidity}
                              incentiveLinkSuccess={incentiveLinkSuccess}
                              userCompanies={allUserCompaniesListIncentives}
                              userCompaniesLoading={selectedCompanyLoading}
                              verifiedCompaniesContacts={verifiedCompaniesContacts}
                              companiesContactsLoading={companiesContactsLoading}
                              previousIncentiveCompanies={userData?.incentive_linking_requests}
                              getAllCompaniesContacts={getAllCompaniesContacts}/>}
            </Container>
          </div>
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default DashboardActionLinks
