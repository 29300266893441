import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  footer: {},
  footerMenu: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& ul': {
      marginBottom: theme.spacing(0),
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
        maxHeight: 220
      },
      [theme.breakpoints.up('lg')]: {
        maxHeight: 185
      },
      '& ul': {
        fontSize: theme.typography.pxToRem(12),
        paddingLeft: theme.spacing(2.5),
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(0.5),
        '& li': {
          width: '100%',
          listStyle: 'disc'
        }
      }
    },
    '& li': {
      background: 'none',
      border: 'none',
      color: theme.palette.common.white,
      padding: theme.spacing(0.5),
      display: 'list-item',
      listStyle: 'square',
      textAlign: 'left',
      width: '30%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      '& a, & button': {
        color: theme.palette.common.white,
        border: 0,
        lineHeight: 1.43,
        padding: theme.spacing(0),
        backgroundColor: 'transparent',
        '&:hover, &:focus': {
          opacity: 0.75
        }
      }
    }
  },
  footerIdentity: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    position: 'relative',
    textAlign: 'center',
    '&:after': {
      content: '""',
      width: 1,
      height: `calc(100% - ${theme.spacing(2)}px)`,
      background: theme.palette.common.white,
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translate(0,-50%)',
        marginLeft: theme.spacing(2)
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
      }
    }
  },
  footerIdentityTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold
  },
  footerCopyrights: {
    textAlign: 'center',
    padding: theme.spacing(1),
    '& p': {
      margin: 0
    }
  },
  footerCopyrightsPoweredByWrapper: {
    display: 'flex',
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  footerCopyrightsPoweredBy: {
    fontSize: theme.typography.pxToRem(16),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    flip: false,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary
  }
}))
