import { fade, makeStyles } from '@material-ui/core/styles'
import { FilterBackground, FilterBorder } from '../../../../../utils/colors'

export const useStyles = makeStyles((theme) => ({
  actionMenuButton: {
    padding: theme.spacing(0)
  },
  buttonAction: {
    borderRadius: theme.spacing(0),
    border: `none`,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43,
    textAlign: 'left',
    padding: theme.spacing(1.5, 2.5),
    width: '100%',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    '& i, & svg': {
      marginRight: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14)
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },
  },

  LinkCompanyDialogPaper: {
    borderRadius: 0
  },
  LinkCompanyDialogContent: {
    padding: '0 !important',
    position: 'relative'
  },
  LinkCompanyDialogClose: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.dark
  },
  LinkCompanyDialogHeader: {
    display: 'flex',
    alignItems: 'center',
    background: FilterBackground,
    border: `1px solid ${FilterBorder}`,
    '& .MuiTypography-root': {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  LinkCompanyOkAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  LinkCompanyDialogFooter: {
    padding: theme.spacing(2)
  },
  formWrapper: {
    position: 'relative'
  },
  LinkCompanyFormRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      width: '36%',
      flexShrink: 0,
      lineHeight: 1.6,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        paddingRight: theme.spacing(2)
      }
    },
    '& .MuiFormControl-root': {
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    // '& a': {
    //   display: 'block',
    //   width: '100%',
    //   textAlign: 'right'
    // },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('sm')]: {}
  },
  dateFormControl: {
    margin: theme.spacing(1, 0, 0.5),
    '& .MuiInput-root': {
      backgroundColor: theme.palette.common.white,
      height: 40,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0, 1.5),
      border: `1px solid ${fade(theme.palette.common.black, 0.23)}`,
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
      display: 'none !important'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& .MuiInputLabel-formControl': {
      top: theme.spacing(0.75),
      zIndex: 1,
      left: theme.spacing(2)
    },
    '& .MuiInputBase-input': {
      paddingBottom: theme.spacing(0.5)
    }
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: 'right',
    flex: '1 1 auto',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      '&[disabled]': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  LinkCompanyFormRowSelect: {
    alignItems: 'flex-start',
    '& .MuiInputLabel-root': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2)
      }
    }
  },
  LinkCompanyFormRowStart: {
    alignItems: 'flex-start',
    '& .MuiInputLabel-root': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2)
      }
    }
  },
  SubmitRow: {
    padding: theme.spacing(4, 0, 0),
    textAlign: 'center'
  },
  LinkCompanyFormSubmit: {
    minWidth: 130
  },

  fileUploadWrapper: {
    position: 'relative',
    width: '100%',
    height: 40,
    boxShadow: `0px 0px 5px 2px ${fade(theme.palette.grey[400], 0.6)}`,
    borderRadius: theme.spacing(0.75),

    '&:after': {
      content: 'attr(data-text)',
      fontSize: theme.typography.pxToRem(14),
      position: 'absolute',
      top: 0,
      left: 0,
      background: theme.palette.common.white,
      padding: theme.spacing(1.25, 2),
      display: 'block',
      width: 'calc(100% - 40px)',
      pointerEvents: 'none',
      zIndex: 20,
      height: 40,
      lineHeight: theme.typography.pxToRem(18),
      color: '#999',
      borderRadius: theme.spacing(0.625, 1.25, 1.25, 0.625),
      fontWeight: theme.typography.fontWeightRegular
    },
    '&:before': {
      content: `${theme.direction === 'ltr' ? '\'Upload\'' : '\'إرفاق\''}`,
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'inline-block',
      height: 40,
      transition: 'all 0.3s ease-in-out',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      zIndex: 25,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(38),
      padding: theme.spacing(0, 3),
      textTransform: 'uppercase',
      pointerEvents: 'none',
      borderRadius: theme.spacing(0, 0.625, 0.625, 0)
    },

    '&:hover': {
      '&:before': {
        background: theme.palette.primary.dark
      }
    },
    '& input': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 99,
      height: 40,
      margin: 0,
      padding: 0,
      display: 'block',
      cursor: 'pointer',
      width: '100%'
    }
  },
  uploadProgressWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000
  },
  uploadProgress: {
    width: 200,
    height: 10,
    marginTop: theme.spacing(10),
    borderRadius: theme.spacing(1),
    '& .MuiLinearProgress-bar': {
      borderRadius: theme.spacing(1)
    }
  },

}))
