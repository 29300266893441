/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  InputLabel,
  TextField,
  FormControlLabel,
  IconButton, Collapse,
  FormHelperText, Switch, Button, Tooltip
} from '@material-ui/core'
import { CompanyContactFormValidation } from '../../../../utils/validationSchema'
import { FormLoader } from '../../../Loader/FormLoader'
import RefreshIcon from '@material-ui/icons/Refresh'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import PhoneInput from 'react-phone-input-2'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import 'react-phone-input-2/lib/material.css'
import ar from 'react-phone-input-2/lang/ar.json'
import { NavLink } from 'react-router-dom'
import { NamedRoutes } from '../../../../routes'
import { withStyles } from '@material-ui/core/styles'
import { CompanyContactConsumer } from '../../../../CompanyContactContext'
import ChangePhone from '../../EditProfile/AdditionalInfo/ChangePhone'
import CompanyContactDelete from './CompanyContactDelete'
import { useStyles } from './Styles'
import { LanguageConsumer } from '../../../../LanguageContext'

const CompanyContactForm = ({ companyContact, onSubmit, resetStatus, handleAccordionClose }) => {
  const classes = useStyles()
  const intl = useIntl()
  const companyContactForm = useFormik({
    initialValues: {
      has_tax_id: companyContact.has_tax_id || false,
      tax_id: companyContact.tax_id || '',
      phone: companyContact.phone || '20',
      newPhone: '',
      phoneCountryCode: '',
      VerificationCode: '',
      email: companyContact.email || '',
      use_same_personal_info: companyContact.use_same_personal_info || false
    },
    validateOnBlur: false,
    validateOnChange: true,
    isInitialValid: false,
    validateOnMount: true,
    validationSchema: CompanyContactFormValidation,
    onSubmit: ((values, actions) => {
      onSubmit(values, actions)
    })
  })

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500,
      zIndex: 50
    }
  }))(Tooltip)

  React.useEffect(() => {
      if (!!resetStatus) {
        resetStatus()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  return (
    <AuthenticatedUserConsumer>
      {({ userData, resetPhoneStatus, getCompaniesContacts, showCompaniesContactForm }) => (
        <>
          <form onSubmit={companyContactForm.handleSubmit} noValidate autoComplete="off"
                className={classes.formWrapper + (!showCompaniesContactForm && !Object.keys(companyContact||{}).length ? ' disabled-form' : '')}>

            {!!companyContact && !Object.keys(companyContact).length &&
            <>
              <div className={classes.AdditionalInfoRow}>
                <InputLabel id="linkCompanyForm.hasTaxId"><FormattedMessage
                  id='Fields.label.hasTaxId'/></InputLabel>
                <div className={classes.usePersonalInfoWrapper}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={companyContactForm.values.has_tax_id}
                        onChange={(event) => {
                          companyContactForm.setFieldValue('has_tax_id', !!event.target.checked)
                        }}
                        name="has_tax_id"
                        color="primary"
                        onBlur={companyContactForm.handleBlur}
                        error={companyContactForm.errors.has_tax_id && companyContactForm.touched.has_tax_id !== undefined}
                      />}
                    label={intl.formatMessage({ id: `companyContactForm.hasTaxId.${!!companyContactForm.values.has_tax_id}` })}
                    labelPlacement="end"
                  />
                </div>
              </div>

              <Collapse in={companyContactForm.values.has_tax_id} timeout="auto" unmountOnExit>
                <div className={classes.AdditionalInfoRow}>
                  <InputLabel id="linkCompanyForm.tax_id"><FormattedMessage id='Fields.label.TaxID'/></InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.TaxID' })}
                      id="linkCompanyForm.tax_id"
                      type={'text'}
                      margin="dense"
                      variant="outlined"
                      name='tax_id'
                      disabled={!showCompaniesContactForm}
                      onChange={companyContactForm.handleChange}
                      onBlur={companyContactForm.handleBlur}
                      value={companyContactForm.values.tax_id}
                      helperText={companyContactForm.errors.tax_id && companyContactForm.touched.tax_id && companyContactForm.errors.tax_id}
                      error={companyContactForm.errors.tax_id && companyContactForm.touched.tax_id !== undefined}
                    />
                  </FormControl>
                </div>
              </Collapse>
            </>
            }

            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="AdditionalInfo.phone"><FormattedMessage id='Fields.label.phone2'/></InputLabel>
              <div className={classes.AdditionalInfoPhoneRow}>
                <LanguageConsumer>
                  {({ locale }) => (
                    <div className={classes.PhoneInputWrapper}
                         onClick={() => {
                           companyContactForm.setFieldTouched('phone', true, true)
                         }}>
                      <PhoneInput
                        placeholder={intl.formatMessage({ id: 'AdditionalInfo.placeholder.phone' })}
                        enableSearch={true}
                        disabled={true}
                        country={'eg'}
                        localization={locale === 'ar' ? ar : ''}
                        specialLabel={''}
                        excludeCountries={['il']}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true
                        }}
                        searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                        searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                        onChange={companyContactForm.handleChange}
                        onBlur={companyContactForm.handleBlur}
                        value={companyContactForm.values.phone}
                        helperText={companyContactForm.errors.phone && companyContactForm.touched.phone && companyContactForm.errors.phone}
                        error={companyContactForm.errors.phone && companyContactForm.touched.phone !== undefined}
                      />
                      {!!companyContactForm.errors.phone && companyContactForm.touched.phone && showCompaniesContactForm &&
                      <FormHelperText error={true}>{companyContactForm.errors.phone}</FormHelperText>}
                    </div>
                  )}
                </LanguageConsumer>
                {!companyContactForm.values.use_same_personal_info && (showCompaniesContactForm || !!Object.keys(companyContact||{}).length) &&
                // !(!!companyContact.company && !!Object.keys(companyContact.company).length) &&
                <ChangePhone additionalInfoForm={companyContactForm} resetPhoneStatus={resetPhoneStatus}
                             isCompanyContact={true} countryCodeKey={'phoneCountryCode'}/>
                }
              </div>
            </div>

            <div className={classes.AdditionalInfoRow}>
              <InputLabel id={`companyContactForm.email${companyContact.tax_id || ''}`}><FormattedMessage
                id='Fields.label.taxEmail'/></InputLabel>
              <div className={classes.EmailWrapper}>
                <FormControl variant="outlined">
                  <TextField
                    placeholder={intl.formatMessage({ id: 'linkCompanyForm.placeholder.email' })}
                    id={`companyContactForm.email${companyContact.tax_id || ''}`}
                    type={'email'}
                    // disabled={!showCompaniesContactForm || !!companyContactForm.values.use_same_personal_info || (!!companyContact.company && !!Object.keys(companyContact.company).length)}
                    disabled={(!showCompaniesContactForm  && !Object.keys(companyContact||{}).length) || !!companyContactForm.values.use_same_personal_info}
                    margin="dense"
                    variant="outlined"
                    name='email'
                    onChange={companyContactForm.handleChange}
                    onBlur={companyContactForm.handleBlur}
                    value={companyContactForm.values.email}
                    helperText={companyContactForm.errors.email && companyContactForm.touched.email && companyContactForm.errors.email}
                    error={companyContactForm.errors.email && companyContactForm.touched.email !== undefined}
                  />
                </FormControl>
                {!!companyContact && !!Object.keys(companyContact).length &&
                <div className={classes.EmailActions}>

                  {!!companyContact.email_verified ?
                    <CustomTooltip className={classes.VerifiedEmail}
                                   PopperProps={{
                                     disablePortal: true
                                   }}
                                   title={<FormattedMessage id='CompanyContact.VerifiedEmail'/>}
                                   arrow>
                      <CheckCircleIcon/>
                    </CustomTooltip>
                    :
                    <>
                      <CustomTooltip className={classes.VerifiedEmail + ' notVerified'}
                                     PopperProps={{
                                       disablePortal: true
                                     }}
                                     title={<FormattedMessage id='CompanyContact.NotVerifiedEmail'/>}
                                     arrow>
                        <CancelIcon/>
                      </CustomTooltip>
                      <CompanyContactConsumer>
                        {({ handleResendEmailVerification }) => (
                          <CustomTooltip className={classes.ResendEmail}
                                         PopperProps={{
                                           disablePortal: true
                                         }}
                                         interactive
                                         title={<FormattedMessage id='CompanyContact.ResendEmail'/>}
                                         arrow>
                            <IconButton color="primary" onClick={handleResendEmailVerification}>
                              <RefreshIcon/>
                            </IconButton>
                          </CustomTooltip>
                        )}
                      </CompanyContactConsumer>
                    </>
                  }
                </div>
                }
              </div>
            </div>
            {!!companyContact && !Object.keys(companyContact).length &&
            <div className={classes.AdditionalInfoRow}>
              <InputLabel id="linkCompanyForm.usePersonalInfo"><FormattedMessage
                id='Fields.label.usePersonalInfo'/></InputLabel>
              <div className={classes.usePersonalInfoWrapper}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={companyContactForm.values.use_same_personal_info}
                      onChange={(event) => {
                        companyContactForm.setFieldValue('use_same_personal_info', !!event.target.checked)
                        if (!!event.target.checked) {
                          companyContactForm.setFieldValue('email', userData.email)
                          companyContactForm.setFieldValue('phone', userData.phone)

                        } else {
                          companyContactForm.setFieldValue('phone', companyContact.phone || '20')
                          companyContactForm.setFieldValue('email', companyContact.email || '')
                        }
                        setTimeout(() => {
                          companyContactForm.setFieldTouched('phone', true, true)
                          companyContactForm.setFieldTouched('email', true, true)
                        }, 50)
                      }}
                      disabled={!showCompaniesContactForm || (!!companyContact.company && !!Object.keys(companyContact.company).length) || !(!!userData.phone && !!userData.email)}
                      name="use_same_personal_info"
                      color="primary"
                      onBlur={companyContactForm.handleBlur}
                      error={companyContactForm.errors.use_same_personal_info && companyContactForm.touched.use_same_personal_info !== undefined}
                    />}
                  label={intl.formatMessage({ id: `companyContactForm.usePersonalInfo.${!!companyContactForm.values.use_same_personal_info}` })}
                  labelPlacement="end"
                />
                {!(!!userData.phone && !!userData.email) &&
                <FormHelperText> < FormattedMessage id='CompanyContact.phone.NotExistError' values={{
                  a: chunks => <NavLink to={NamedRoutes.workspace.editProfile}>{chunks}</NavLink>
                }}/></FormHelperText>
                }
              </div>
            </div>
            }

            <div className={classes.SubmitRow}>
              {/*{!(!!companyContact.company && !!Object.keys(companyContact.company).length) &&*/}
              <>
                <Button size="large" variant="contained" color="primary"
                        type="submit" disabled={!companyContactForm.isValid || companyContactForm.isSubmitting}
                        className={classes.AdditionalInfoSubmit}>
                  <FormattedMessage
                    id={(!!companyContact && !!Object.keys(companyContact).length) ? 'DelegateMenu.updateDelegate' : 'AdditionalInfo.button.submit'}/>
                </Button>
                {!!companyContact && !!Object.keys(companyContact).length &&
                <CompanyContactDelete getCompaniesContacts={getCompaniesContacts}/>
                }
              </>
              {/*}*/}
              <Button onClick={() => {
                handleAccordionClose()
                companyContactForm.resetForm()
              }} color="primary">
                <FormattedMessage id='ChangePhone.DialogActions.cancel'/>
              </Button>
            </div>
            {companyContactForm.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default CompanyContactForm
