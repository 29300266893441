import React, { createContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useIntl } from 'react-intl'
import UserServices from './Services/Consumers/UserServices'
import { filterData } from './utils/helpers'

const CompanyContactContext = createContext({})

const CompanyContactProvider = ({ children, ccid, companyContactData, resetAddCompanyContact }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const [deleteCompanyContactLoading, setDeleteCompanyContactLoading] = React.useState(false)
  const [deleteCompanyContactFailure, setDeleteCompanyContactFailure] = React.useState(false)
  const [deleteCompanyContactFailureMessage, setDeleteCompanyContactFailureMessage] = React.useState('')
  const [deleteCompanyContactSuccess, setDeleteCompanyContactSuccess] = React.useState(false)

  const [editCompanyContactFailure, setEditCompanyContactFailure] = React.useState(false)
  const [editCompanyContactSuccess, setEditCompanyContactSuccess] = React.useState(false)
  const [editCompanyContactSuccessFlashForm, setEditCompanyContactSuccessFlashForm] = React.useState(false)
  const [editCompanyContactFailureMessage, setEditCompanyContactFailureMessage] = React.useState('')

  const [companyContact, setCompanyContact] = React.useState(companyContactData)


  const resetCompanyContactStatus = () => {
    setEditCompanyContactFailure(false)
    setEditCompanyContactSuccess(false)
    setEditCompanyContactSuccessFlashForm(false)
    setEditCompanyContactFailureMessage('')

    setDeleteCompanyContactLoading(false)
    setDeleteCompanyContactFailure(false)
    setDeleteCompanyContactSuccess(false)
    setDeleteCompanyContactFailureMessage('')

    setResendEmailVerificationLoading(false)
    setResendEmailVerificationFailure(false)
    setResendEmailVerificationSuccess(false)
    setResendEmailVerificationFailureMessage('')

  }

  const [resendEmailVerificationLoading, setResendEmailVerificationLoading] = React.useState(false)
  const [resendEmailVerificationFailure, setResendEmailVerificationFailure] = React.useState(false)
  const [resendEmailVerificationFailureMessage, setResendEmailVerificationFailureMessage] = React.useState('')
  const [resendEmailVerificationSuccess, setResendEmailVerificationSuccess] = React.useState(false)

  const handleResendEmailVerification = () => {
    if (!!keycloak?.authenticated) {
      resetCompanyContactStatus()
      setResendEmailVerificationLoading(true)
      UserServices.resendCompanyContactEmailVerification(ccid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, setResendEmailVerificationLoading, setResendEmailVerificationFailure).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setResendEmailVerificationFailure(true)

          let message = intl.formatMessage({ id: 'ResendEmailVerification.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setResendEmailVerificationFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setResendEmailVerificationSuccess(true)
          }
        }
      }).then(() => {
        setResendEmailVerificationLoading(false)
      })
    }
  }
  const handleEditCompanyContact = (values, FormikActions) => {
    if (!!keycloak?.authenticated) {
      resetCompanyContactStatus()
      FormikActions.setSubmitting(true)

      let data = filterData({
        'has_tax_id': values.has_tax_id || false,
        'tax_id': !!values.has_tax_id ? values.tax_id.toString() || '' : '',
        'email': values.email.toString(),
        'phone': '+' + values.phone.toString().replace(/ /g, '').replace(/\+/g, ''),
        'use_same_personal_info': values.use_same_personal_info || false
      })

      UserServices.editCompanyContact(ccid, data, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, FormikActions.setSubmitting, setEditCompanyContactFailure, FormikActions).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setEditCompanyContactFailure(true)

          let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setEditCompanyContactFailureMessage(message)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setCompanyContact(result.data.data)
            setEditCompanyContactSuccess(true)
            setEditCompanyContactSuccessFlashForm(true)
            setTimeout(()=> setEditCompanyContactSuccessFlashForm(false),50)
          }
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    }
  }

  const handleDeleteCompanyContact = (getCompaniesContacts, handleDeleteCompanyContactDialog) => {
    if (!!keycloak?.authenticated) {
      resetCompanyContactStatus()
      setDeleteCompanyContactLoading(true)
      UserServices.deleteCompanyContact(ccid, {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` }
      }, setDeleteCompanyContactLoading).then((result) => {
        if ((!!result.response && result.response.status !== 200) || !result) {
          setDeleteCompanyContactFailure(true)
          let message = intl.formatMessage({ id: 'CompanyContactDelete.FormErrorAlert' })
          if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
            if (result.response.data.status.errors) {
              message = result.response.data.status.errors
            } else if (result.response.data.status.message) {
              message = result.response.data.status.message
            }
          }
          setDeleteCompanyContactFailureMessage(message)
          setDeleteCompanyContactLoading(false)
        } else {
          if (!!result && !!result.data && !!result.data.data) {
            setDeleteCompanyContactSuccess(true)
            setTimeout(() => {
              handleDeleteCompanyContactDialog()
              if (!!resetAddCompanyContact) {
                resetAddCompanyContact()
              }
              if (!!getCompaniesContacts) {
                setTimeout(() => {
                  getCompaniesContacts(true, true)
                }, 500)
              }
            }, 1200)
          } else {
            setDeleteCompanyContactLoading(false)
          }
        }
      })
    }
  }

  return (
    <CompanyContactContext.Provider value={{

      deleteCompanyContactLoading: deleteCompanyContactLoading,
      deleteCompanyContactFailure: deleteCompanyContactFailure,
      deleteCompanyContactFailureMessage: deleteCompanyContactFailureMessage,
      deleteCompanyContactSuccess: deleteCompanyContactSuccess,

      editCompanyContactFailure: editCompanyContactFailure,
      editCompanyContactSuccess: editCompanyContactSuccess,
      editCompanyContactSuccessFlashForm: editCompanyContactSuccessFlashForm,
      editCompanyContactFailureMessage: editCompanyContactFailureMessage,
      companyContact: companyContact,

      resendEmailVerificationLoading: resendEmailVerificationLoading,
      resendEmailVerificationFailure: resendEmailVerificationFailure,
      resendEmailVerificationFailureMessage: resendEmailVerificationFailureMessage,
      resendEmailVerificationSuccess: resendEmailVerificationSuccess,

      handleEditCompanyContact,
      handleDeleteCompanyContact,
      resetCompanyContactStatus,
      handleResendEmailVerification
    }}>
      {children}
    </CompanyContactContext.Provider>
  )
}
const CompanyContactConsumer = CompanyContactContext.Consumer

export { CompanyContactProvider, CompanyContactConsumer }
