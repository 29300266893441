import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  // Button,
  Dialog,
  DialogContent,
  IconButton,
  // DialogActions,
  Slide,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useStyles } from "./Styles";
import { EcommerceConsumer } from "../../../../../EcommerceContext";
import CopyClipped from "../CopyClipped";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SucessDialog = () => {
  const classes = useStyles();
  return (
    <EcommerceConsumer>
      {({ confirmSucessDialoge, responseData, handleConfirmDialog }) => (
        <>
          <Dialog
            keepMounted
            fullWidth
            maxWidth={"sm"}
            open={confirmSucessDialoge}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <IconButton
              edge={false}
              className={classes.NewOrderDialogClose}
              color="inherit"
              aria-label="close dialog"
              onClick={handleConfirmDialog}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent className={classes.NewOrderDialogContent}>
              <Box px={2} pt={4}  pb={1}>
                <div className={classes.DeleteOrderWrapper}>
                  <CheckCircleOutlineIcon
                    className={classes.SuccessWarningIcon}
                  />
                  <p>
                    <FormattedMessage id="Ecommerce.ConfirmMessage" />
                  </p>
                  <div className={classes.contentWrapper}>
                    <div>
                      <p> <FormattedMessage id="EcommerceHeader.Success.ClientId" /></p>
                      <div className={classes.contentResult}><span>{responseData?.client_id}</span>
                        <CopyClipped text={responseData?.client_id} />
                      </div>

                    </div>
                    <div>
                      <p><FormattedMessage id="EcommerceHeader.Success.ClientSecret" /></p>
                      <div className={classes.contentResult}><span>{responseData?.client_secret}</span>
                        <CopyClipped text={responseData?.client_secret} />
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </DialogContent>
          </Dialog>
        </>
      )}
    </EcommerceConsumer>
  );
};

export default SucessDialog;
