/**
 * Configuration Class
 * Responsible for All The Api Configuration for the Application
 */
import { ArabicFont } from '../../utils/Fonts'
import arMessages from './../../translations/ar.json'
import { LocalStorageService } from '../LocalStorageService'

class Configuration {
  static BASE_URL = process.env.REACT_APP_BASE_URL
  static BASE_AuthURL = '/'
  static General_EndPoints = {
    SortBy: '/SortBy'
  }
  static User_EndPoints = {
    profile: '/user',
    companyUPTCPRegistration: '/company/:cid/uptcp-enrollment',
    countries: '/country',
    notifications: '/notification',
    companies: '/company',
    companiesList: '/company-listing',
    paymentFilterData: '/boot-services',
    incentiveLinkCompany: '/incentive/link',
    incentivesTaxIdValidity: '/tax-registration/verify',
    linkCompany: '/company/link',
    registerCompany: '/company/register',
    simpleRegisterCompany: '/company/simplified-register',
    updateCompanyRegistration: '/company/tax-registration/update',
    verifyPhone: '/create-sms-otp',
    confirmPhone: '/verify-sms-otp',
    CompanyContact: {
      data: '/company-contact-info',
      verifyPhone: '/create-sms-otp/company_profile',
      confirmPhone: '/verify-sms-otp/company_profile'
    },
    Insurance: {
      organizations: '/insurance-organization',
      link: '/insurance-organization/link'
    }
  }
  static User_Config = {
    userCompaniesLimit: parseInt(process.env.REACT_APP_USER_COMPANIES_LIMIT_PER_PAGE),
    userCompaniesContactsLimit: parseInt(process.env.REACT_APP_USER_CompaniesContacts_LIMIT_PER_PAGE),
    userDelegatesLimit: parseInt(process.env.REACT_APP_USER_Delegates_LIMIT_PER_PAGE),
    userEcommerceLimit:parseInt(process.env.REACT_APP_USER_Ecommerce_LIMIT_PER_PAGE),
    userPaymentClaimsLimit: parseInt(process.env.REACT_APP_USER_PaymentClaims_LIMIT_PER_PAGE),
    userPaymentRequestsLimit: parseInt(process.env.REACT_APP_USER_PaymentRequests_LIMIT_PER_PAGE),
    userPaymentReceiptsLimit: parseInt(process.env.REACT_APP_USER_PaymentReceipts_LIMIT_PER_PAGE)
  }

  static Keycloak_Config = {
    url: process.env.REACT_APP_Keycloak_URL,
    realm: process.env.REACT_APP_Keycloak_realm,
    clientId: process.env.REACT_APP_Keycloak_clientId
  }

  static DashBoardActions_CreatTaxLink = process.env.REACT_APP_DashBoardActions_CreatTaxLink
  static UPLOAD_FILE_SIZE = process.env.REACT_APP_UPLOAD_FILE_SIZE_IN_BYTES

  static Subscription_Config = {
    url: process.env.REACT_APP_SUBSCRIPTION_PORTAL_URL,
    serviceID: process.env.REACT_APP_SUBSCRIPTION_SERVICE_ID
  }

  static CMS_Portal_URL = process.env.REACT_APP_CMS_PORTAL_URL

  static HIDE_INSURANCE_SERVICES = process.env.REACT_APP_HIDE_INSURANCE_SERVICES === 'true'
  static HIDE_LANGUAGE_SWITCHER = process.env.REACT_APP_HIDE_LANGUAGE_SWITCHER === 'true'

  static FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID
  static GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID

  static Local = LocalStorageService.get('ETaxAppLanguage')
  static DefaultLocale = 'ar'
  static DefaultDirection = 'rtl'
  static DefaultFont = ArabicFont
  static DefaultMessage = arMessages

  static UNDER_MENTINANCE_LINK = process.env.REACT_APP_UNDER_MENTINANCE_LINK ? process.env.REACT_APP_UNDER_MENTINANCE_LINK : 'https://www.eta.gov.eg/ar/down-time-action'

  static UPTCP = {
    URL: { en: process.env.REACT_APP_UPTCP_URL_EN, ar: process.env.REACT_APP_UPTCP_URL },
    NotEnrolledURL: process.env.REACT_APP_UPTCP_NOTENROLLED_URL,
    HANDLER_NAME_PARAMETER: process.env.REACT_APP_UPTCP_HANDLER_NAME_PARAMETER
  }

  static INCENTIVE_URL = process.env.REACT_APP_INCENTIVE_URL
  static HIDE_INCENTIVES = process.env.REACT_APP_HIDE_INCENTIVES === 'true'
  static HIDE_ECOMMERCE = process.env.REACT_APP_HIDE_ECOMMERCE === 'true'
  static HIDE_PAYMENT_CLAIMS_MENUITEM = process.env.REACT_APP_HIDE_PAYMENT_CLAIMS_MENUITEM === 'true'

  static isIOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }
}

export default Configuration
