import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Slide,
  Typography,
  withStyles, Menu, FormHelperText
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { PaymentOrderConsumer } from '../../../../../PaymentOrderContext'
import { FormLoader } from '../../../../Loader/FormLoader'
import { useStyles } from './Styles'
import PaymentOrderStep1 from './PaymentOrderStep1'
import PaymentOrderStep2 from './PaymentOrderStep2'
import PaymentOrderStep3 from './PaymentOrderStep3'
import PaymentOrderDelete from './PaymentOrderDelete'
import PaymentOrderCreateError from '../PaymentOrderCreateError'
import PayOthersDialog from './PayOthersDialog'
import { CompanyConsumer } from '../../../../../CompanyContext'
import PayNonCoreDialog from './PayNonCoreDialog'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaymentNewOrder = ({ handleRequestValidityDialogClose, shouldRefreshPayments, canCreate, initPaymentOrderData, isOld, removePaymentRequest, isPaymentClaim, isDownPayment, claimId, resetPaymentOrderStatus, currentPaymentData, refreshPaymentsAfterLinking, locale, isForeign }) => {
  const classes = useStyles()
  const intl = useIntl()
  let [canCreateRequest, setCanCreateRequest] = React.useState(canCreate)
  const [viewNewOrderDialog, setViewNewOrderDialog] = React.useState(false)
  const [openNewOrderDialog, setOpenNewOrderDialog] = React.useState(false)
  const handleNewOrderDialog = () => {
    if (!openNewOrderDialog) {
      setViewNewOrderDialog(true)
      if (!!resetPaymentOrderStatus) {
        resetPaymentOrderStatus()
      }
    } else {
      setTimeout(() => {
        setViewNewOrderDialog(false)
        initPaymentOrderData(false, true)
      }, 500)
    }
    setOpenNewOrderDialog(!openNewOrderDialog)
  }

  const [viewPaymentOrderCreateError, setViewPaymentOrderCreateError] = React.useState(false)
  const [openPaymentOrderCreateError, setOpenPaymentOrderCreateError] = React.useState(false)
  const handlePaymentOrderCreateErrorDialog = () => {
    if (!openPaymentOrderCreateError) {
      setViewPaymentOrderCreateError(true)
    } else {
      setTimeout(() => {
        setViewPaymentOrderCreateError(false)
        if (!!handleRequestValidityDialogClose) {
          handleRequestValidityDialogClose()
        }
      }, 500)
    }
    setOpenPaymentOrderCreateError(!openPaymentOrderCreateError)
  }

  const [viewPayOthersDialog, setViewPayOthersDialog] = React.useState(false)
  const [openPayOthersDialog, setOpenPayOthersDialog] = React.useState(false)
  const handlePayOthersDialog = () => {
    if (!openPayOthersDialog) {
      setViewPayOthersDialog(true)
      handleMenuClose()
    } else {
      setTimeout(() => setViewPayOthersDialog(false), 500)
    }
    setOpenPayOthersDialog(!openPayOthersDialog)
  }

  // Payment menu
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isMenuView, setIsMenuView] = React.useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'
  const handleProfileMenuOpen = (event) => {
    setIsMenuView(true)
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setTimeout(() => setIsMenuView(false), 300)
  }
  const StyledMenu = withStyles((theme) => ({
    paper: {
      borderRadius: theme.spacing(0),
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: `0 4px 16px -2px ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0),
      textAlign: 'left',
      '& .MuiMenu-list': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 210
      }
    }
  }))((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: locale === 'ar' ? 'left' : 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: locale === 'ar' ? 'left' : 'right'
      }}
      {...props}
    />
  ))

  const [viewPayNonCoreDialog, setViewPayNonCoreDialog] = React.useState(false)
  const [openPayNonCoreDialog, setOpenPayNonCoreDialog] = React.useState(false)
  const handlePayNonCoreDialog = (event, callback) => {
    if (!openPayNonCoreDialog) {
      setViewPayNonCoreDialog(true)
      handleMenuClose()
    } else {
      setTimeout(() => {
        setViewPayNonCoreDialog(false)
        if (!!callback) {
          callback(true)
        }
      }, 400)
    }
    setOpenPayNonCoreDialog(!openPayNonCoreDialog)
  }

  return (
    <PaymentOrderConsumer>
      {({
          paymentOrderSteps, currentPaymentOrderStep, handleNewOrderNext, paymentOrderData, paymentOrderIsInitiated, paymentDetails,
          checkRequestValidity, requestValidityCode, requestValidityLoading, getRequestExpirationTime, paymentOrderLoading
        }) => (
        <>
          {(!isOld && !isPaymentClaim && !isDownPayment) ?
            <>
              {!!isForeign ?
                <Button size="medium" variant="contained" color="primary"
                  // className={classes.buttonAction}
                  aria-label={intl.formatMessage({ id: 'ActionsMenu.label' })}
                  className={classes.actionMenuButton + ' wrap'}
                  onClick={handlePayOthersDialog}
                  aria-haspopup="true" startIcon={<AccountBalanceIcon/>}>
                  <FormattedMessage id='PaymentOrder.ActionMenu.FullPayOthers'/>
                </Button>
                :
                <>
                  <Button size="medium" variant="contained" color="primary"
                          className={classes.actionMenuButton}
                          onClick={handleProfileMenuOpen}
                          aria-label={intl.formatMessage({ id: 'ActionsMenu.label' })}
                          aria-controls={menuId}
                          aria-haspopup="true">
                    <FormattedMessage id='PaymentOrder.ActionMenu.btnText'/>
                    <ExpandMoreIcon/>
                  </Button>
                  {!!isMenuView &&
                  <StyledMenu
                    id={menuId}
                    anchorEl={anchorEl}
                    keepMounted
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                  >

                    <Button className={classes.buttonAction}
                            onClick={() => {
                              if (!isOld && (!canCreateRequest || (!!paymentOrderIsInitiated && currentPaymentOrderStep > 0))) {
                                handlePaymentOrderCreateErrorDialog()
                              } else {
                                if (!isOld) {
                                  handleNewOrderDialog()
                                  initPaymentOrderData(false, false, isOld)
                                }
                              }
                              handleMenuClose()
                            }}>
                      <CreditCardIcon/>
                      <FormattedMessage id='PaymentOrder.ActionMenu.PayOnline'/>
                    </Button>
                    <Button className={classes.buttonAction} onClick={handlePayOthersDialog}>
                      <AccountBalanceIcon/>
                      <FormattedMessage id='PaymentOrder.ActionMenu.PayOthers'/>
                    </Button>
                    <Button className={classes.buttonAction} onClick={handlePayNonCoreDialog}>
                      <AccountBalanceWalletIcon/>
                      <span>
                    <FormattedMessage id='PaymentOrder.ActionMenu.PayNonCore'/>
                    <FormHelperText>
                      <FormattedMessage id='PaymentOrder.ActionMenu.PayNonCore.Hint'/>
                    </FormHelperText>
                  </span>
                    </Button>
                  </StyledMenu>
                  }
                </>}
              {!!viewPayOthersDialog &&
              <CompanyConsumer>
                {({ checkPaymentForPayOthers }) => (
                  <PayOthersDialog
                    handlePayOthersDialog={handlePayOthersDialog}
                    viewPayOthersDialog={viewPayOthersDialog}
                    openPayOthersDialog={openPayOthersDialog}
                    checkPaymentForPayOthers={checkPaymentForPayOthers}
                    claimId={claimId}/>
                )}
              </CompanyConsumer>
              }
              {!!viewPayNonCoreDialog &&
              <PayNonCoreDialog
                refreshPaymentsAfterLinking={refreshPaymentsAfterLinking}
                currentPaymentData={currentPaymentData}
                handlePayNonCoreDialog={handlePayNonCoreDialog}
                openPayNonCoreDialog={openPayNonCoreDialog}
                claimId={claimId}
              />
              }
            </>
            :
            <Button size="small" variant="contained" color="primary"
                    onClick={() => {
                      if (!isOld && (!canCreateRequest || (!!paymentOrderIsInitiated && currentPaymentOrderStep > 0))) {
                        handlePaymentOrderCreateErrorDialog()
                      } else {
                        if (!!isOld) {
                          checkRequestValidity(handleNewOrderDialog, handlePaymentOrderCreateErrorDialog, setCanCreateRequest)
                        } else {
                          handleNewOrderDialog()
                          initPaymentOrderData(false, false, isOld)
                        }
                      }
                    }}>
              {!!isOld ?
                <FormattedMessage id='PaymentOrder.ActionLinks.Continue'/>
                :
                <FormattedMessage id='PaymentOrder.ActionLinks.PayOnline'/>
              }
            </Button>
          }
          {!!requestValidityLoading && <FormLoader loading={true}/>}
          <PaymentOrderCreateError
            requestValidityCode={requestValidityCode}
            handleClose={() => handlePaymentOrderCreateErrorDialog()}
            open={openPaymentOrderCreateError}
            view={viewPaymentOrderCreateError}/>
          {!!viewNewOrderDialog &&
          <Dialog
            keepMounted
            fullWidth
            maxWidth={'md'}
            open={openNewOrderDialog}
            classes={{ paper: classes.NewOrderDialogPaper }}
            TransitionComponent={Transition}
            onClose={handleNewOrderDialog}
            aria-labelledby="BasicInfoDialog"
            aria-describedby="BasicInfoDialog"
          >
            <DialogTitle disableTypography className={classes.NewOrderDialogHeader}>
              <Typography variant="h5"><FormattedMessage id='PaymentOrderSteps.title'/></Typography>
              <IconButton
                edge={false}
                className={classes.NewOrderDialogClose}
                color="inherit"
                aria-label="close dialog"
                onClick={handleNewOrderDialog}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.NewOrderDialogContent}>
              <Box p={4} className={classes.NewOrderStepsWrapper}>
                {!!Object.keys(paymentOrderSteps) &&
                <>
                  <PaymentOrderStep1 isOld={isOld}/>
                  {!isOld && <PaymentOrderStep2
                    paymentDetails={paymentDetails}
                    setCanCreateRequest={setCanCreateRequest}
                    getRequestExpirationTime={getRequestExpirationTime}/>}
                  <PaymentOrderStep3/>
                </>
                }
              </Box>
            </DialogContent>
            <DialogActions className={classes.NewOrderDialogFooter}>
              {currentPaymentOrderStep === 1 && !!paymentOrderData && !!Object.keys(paymentOrderData).length &&
              <>
                {!!paymentOrderLoading && <FormLoader loading={false}/>}
                <Button className={classes.NewOrderPayButton} size="large" variant="contained" color="primary"
                        onClick={() => {handleNewOrderNext(setCanCreateRequest)}}>
                  <FormattedMessage id='PaymentOrder.ActionLinks.Pay'/>
                </Button>
                <PaymentOrderDelete paymentOrderId={paymentOrderData.request_number}
                                    handleNewOrderDialog={handleNewOrderDialog}
                                    removePaymentRequest={removePaymentRequest}
                                    shouldRefreshPayments={shouldRefreshPayments}
                                    setCanCreateRequest={setCanCreateRequest}/>
              </>
              }
              <Button onClick={handleNewOrderDialog} color="primary">
                <FormattedMessage id='PaymentOrder.ActionLinks.close'/>
              </Button>
            </DialogActions>
          </Dialog>
          }
        </>
      )}
    </PaymentOrderConsumer>
  )
}

export default PaymentNewOrder
