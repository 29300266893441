import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative',
    width: '100%'
  },
  successWrapper:{
    border: `1px solid ${theme.palette.success.main}`,
    padding: theme.spacing(2),
    '& .MuiAlert-icon': {
      fontSize: theme.typography.pxToRem(30),
      padding: theme.spacing(1.5, 0)
    },
    '& .MuiAlert-message': {
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
      '& .MuiAlertTitle-root': {
        fontSize: '1.5em'
      }
    }
  }
}))
