import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { LanguageConsumer, LanguageProvider } from './LanguageContext'
import ThemeProvider from './ThemeProvider'
import LayoutWrapper from './components/LayoutWrapper'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { KeycloakProvider } from '@react-keycloak/web'
import keycloak from './Services/keycloak'
import { AuthenticatedUserProvider } from './AuthenticatedUserContext'

ReactDOM.render(
  <KeycloakProvider
    keycloak={keycloak}
    initConfig={{
      onLoad: 'check-sso',
      checkLoginIframe: false,
      promiseType: 'native',
      pkceMethod: 'S256',
    }}
    onEvent={(event, error) => {
      // console.log('onKeycloakTokens', tokens)
    }}
    onTokens={(tokens) => {
      // console.log('onKeycloakTokens', tokens)
    }}
  >
    <Router forceRefresh={false}>
      <LanguageProvider>
        <AuthenticatedUserProvider>
          <ThemeProvider>
            <LanguageConsumer>
              {({ locale }) => (
                <LayoutWrapper locale={locale}/>
              )}
            </LanguageConsumer>
          </ThemeProvider>
        </AuthenticatedUserProvider>
      </LanguageProvider>
    </Router>
  </KeycloakProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
