import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  CompaniesContactsWrapper: {
    width: '100%',
    boxShadow: 'none'
  },
  LinkingHint: {
    verticalAlign: 'middle',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    transform: 'rotate(45deg)',
    '& + .MuiTooltip-popper': {
      marginTop: theme.spacing(-1)
    }
  },
  CompaniesContactsAccordion: {
    marginBottom: theme.spacing(3) + 'px !important'
  },
  SingleCompanyContactWrapper: {
    marginBottom: theme.spacing(2) + 'px !important'
  },
  EditProfileContentContainer: {
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  EditProfileContent: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 3)
  },
  EditProfileContentTitle: {
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid #dfdfdf`,
    paddingBottom: theme.spacing(3)
  }
}))
