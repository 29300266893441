import React, { createContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import ReceiptIcon from '@material-ui/icons/Receipt'
import CheckIcon from '@material-ui/icons/Check'

import { filterData } from './utils/helpers'
import { AuthenticatedUserConsumer } from './AuthenticatedUserContext'
import PayNonCoreStep1
  from './components/Workspace/CompanyPage/CompaniesPayments/PaymentNewOrder/PayNonCoreDialog/Step1'
import { PaymentClaimRequestsFiltersPayload, payNonCorePayload } from './Services/Payloads/AuthenticatedUserPayload'
import CompanyServices from './Services/Consumers/CompanyServices'
import { useKeycloak } from '@react-keycloak/web'
import { useFormik } from 'formik'
import { payNonCoreFormValidation } from './utils/validationSchema'
import PayNonCoreStep2
  from './components/Workspace/CompanyPage/CompaniesPayments/PaymentNewOrder/PayNonCoreDialog/Step2'
import PayNonCoreStep3
  from './components/Workspace/CompanyPage/CompaniesPayments/PaymentNewOrder/PayNonCoreDialog/Step3'
import moment from 'moment'

const PayNonCoreContext = createContext({})

const PayNonCoreProvider = ({ children, cid, currentPaymentData, closeDialog }) => {
  const { keycloak } = useKeycloak()
  const intl = useIntl()
  const [isLocked, setIsLocked] = React.useState(false)

  const PayNonCoreHeaderColumns = [
    { id: 'check_number', label: <FormattedMessage id='PayNonCoreHeader.checkNumber'/>, minWidth: 122 },
    { id: 'document_number', label: <FormattedMessage id='PayNonCoreHeader.documentNumber'/>, minWidth: 130 },
    { id: 'payment_number', label: <FormattedMessage id='PayNonCoreHeader.paymentNumber'/>, minWidth: 140 },
    { id: 'receipt_number', label: <FormattedMessage id='PayNonCoreHeader.receiptNumber'/>, minWidth: 140 },
    { id: 'original_amount', label: <FormattedMessage id='PayNonCoreHeader.originalAmount'/>, minWidth: 150 },
    { id: 'remaining_amount', label: <FormattedMessage id='PayNonCoreHeader.remainingAmount'/>, minWidth: 133 },
    { id: 'payment_method', label: <FormattedMessage id='PayNonCoreHeader.paymentMethod'/>, minWidth: 140 },
    { id: 'quarter_number', label: <FormattedMessage id='PayNonCoreHeader.quarterNumber'/>, minWidth: 140 },
    { id: 'tax_payment_item_type', label: <FormattedMessage id='PayNonCoreHeader.taxPaymentItemType'/>, minWidth: 140 },
    { id: 'tax_payment_type', label: <FormattedMessage id='PayNonCoreHeader.taxPaymentType'/>, minWidth: 140 },
    { id: 'payment_date', label: <FormattedMessage id='PayNonCoreHeader.paymentDate'/>, minWidth: 140 },
    { id: 'tax_period_from', label: <FormattedMessage id='PayNonCoreHeader.taxPeriodFrom'/>, minWidth: 140 },
    { id: 'tax_period_to', label: <FormattedMessage id='PayNonCoreHeader.taxPeriodTo'/>, minWidth: 140 }
  ]

  const [tempPayNonCoreData, setTempPayNonCoreData] = React.useState({})
  const [payNonCoreStep2Claims, setPayNonCoreStep2Claims] = React.useState([])

  const PayNonCoreStep1Form = useFormik({
    initialValues: {
      core_payment_tax_payment_type: currentPaymentData?.tax_type_code || '',
      core_payment_tax_payment_item: currentPaymentData?.payment_item_code || '',
      core_payment_claim_number: currentPaymentData?.claim_number || '',
      payment_source: '',
      claim_id: '',
      transaction_number: '',
      payment_method: '',
      taxation_period_from: !!currentPaymentData?.tax_period_from ? moment(currentPaymentData?.tax_period_from).format('DD-MM-YYYY') : '',
      taxation_period_to: !!currentPaymentData?.tax_period_to ? moment(currentPaymentData?.tax_period_to).format('DD-MM-YYYY') : ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: payNonCoreFormValidation,
    onSubmit: ((values, actions) => {
      payNonCoreStep1Submit(values, actions)
    })
  })

  const [payNonCoreStep1Failure, setPayNonCoreStep1Failure] = React.useState(false)
  const [payNonCoreStep1FailureMessage, setPayNonCoreStep1FailureMessage] = React.useState('')
  const resetStep1Status = () => {
    setPayNonCoreStep1Failure(false)
    setPayNonCoreStep1FailureMessage('')
  }
  const payNonCoreStep1Submit = (values, FormikActions) => {
    resetStep1Status()
    CompanyServices.getNonCorePayment(cid,
      {
        headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
        params: filterData(payNonCorePayload(values))
      }).then((result) => {
      if ((!!result.response && result.response.status !== 200) || !result) {
        setPayNonCoreStep1Failure(true)
        let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
        if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
          if (result.response.data.status.errors) {
            message = result.response.data.status.errors
          } else if (result.response.data.status.message) {
            message = result.response.data.status.message
          }
        }
        setPayNonCoreStep1FailureMessage(message)
      } else {
        if (!!result && !!result.data && !!result.data.data) {

          let claims = result.data.data?.none_core_payments || []
          claims.forEach((claim) => claim.id = claim.payment_number)
          setPayNonCoreStep2Claims(claims)

          setTempPayNonCoreData({
            inquiry_id: result.data.data.id,
            payment_number: claims?.length ? claims[0]?.payment_number || '' : ''
          })
          handleNextStep(true)
        }
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  const handleChangePayment = (paymentNumber) => {
    setTempPayNonCoreData((prevState) => ({ ...prevState, payment_number: paymentNumber }))
  }

  const [payNonCoreStep2Loading, setPayNonCoreStep2Loading] = React.useState(false)
  const [payNonCoreStep2Failure, setPayNonCoreStep2Failure] = React.useState(false)
  const [payNonCoreStep2FailureMessage, setPayNonCoreStep2FailureMessage] = React.useState('')
  const resetStep2Status = () => {
    setPayNonCoreStep2Failure(false)
    setPayNonCoreStep2FailureMessage('')
  }
  const payNonCoreStep2Submit = () => {
    resetStep2Status()
    setPayNonCoreStep2Loading(true)
    CompanyServices.submitNonCorePayment(cid, filterData(tempPayNonCoreData)
      , { headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` } },
      setPayNonCoreStep2Failure).then((result) => {
      if ((!!result.response && result.response.status !== 200) || !result) {
        setPayNonCoreStep2Failure(true)
        let message = intl.formatMessage({ id: 'BasicInfo.FormErrorAlert' })
        if (!!result.response && result.response.status === 422 && result.response.data && result.response.data.status) {
          if (result.response.data.status.errors) {
            message = result.response.data.status.errors
          } else if (result.response.data.status.message) {
            message = result.response.data.status.message
          }
        }
        setPayNonCoreStep2FailureMessage(message)
      } else {
        if (!!result && !!result.data && !!result.data.data) {
          handleNextStep()
          setIsLocked(true)
        }
      }
    }).then(() => {
      setPayNonCoreStep2Loading(false)
    })
  }

  const [payNonCoreActiveStep, setPayNonCoreActiveStep] = React.useState(0)
  const [payNonCoreStepsTrail, setPayNonCoreStepsTrail] = React.useState(new Set())
  const [payNonCoreStepsCompleted, setPayNonCoreStepsCompleted] = React.useState(new Set())
  const payNonCoreSteps = [
    {
      key: 0,
      title: <FormattedMessage id='PayNonCore.step1.title'/>,
      icon: <LocalAtmIcon/>,
      content: <AuthenticatedUserConsumer>
        {({ paymentsFiltersData }) => (
          <PayNonCoreStep1
            bootServiceData={paymentsFiltersData}
            getPaymentClaims={getPaymentClaims}
            PayNonCoreStep1Form={PayNonCoreStep1Form}/>
        )}
      </AuthenticatedUserConsumer>
    },
    {
      key: 1,
      title: <FormattedMessage id='PayNonCore.step2.title'/>,
      icon: <ReceiptIcon/>,
      content: <PayNonCoreStep2/>
    },
    {
      key: 2,
      title: <FormattedMessage id='PayNonCore.step3.title'/>,
      icon: <CheckIcon/>,
      content: <PayNonCoreStep3/>
    }
  ]

  const getPayNonCoreStepContent = (stepIndex) => {
    let content = ''
    if (stepIndex >= 0 && stepIndex < payNonCoreSteps.length) {
      content = payNonCoreSteps[stepIndex].content
    }
    return content
  }

  const handleNextStep = (reset) => {
    const newCompleted = new Set(reset ? new Set() : payNonCoreStepsCompleted)
    newCompleted.add(payNonCoreActiveStep)
    if (payNonCoreActiveStep + 2 === payNonCoreSteps.length) {
      newCompleted.add(payNonCoreActiveStep + 1)
    }
    setPayNonCoreStepsCompleted(newCompleted)

    const newStepsTrail = new Set(reset ? new Set() : payNonCoreStepsTrail)
    newStepsTrail.add(payNonCoreActiveStep + 1)
    setPayNonCoreStepsTrail(newStepsTrail)

    setPayNonCoreActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBackStep = () => {
    setPayNonCoreActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleGoToStep = (step) => {
    if (!isLocked) {
      setPayNonCoreActiveStep(step)
    }
  }

  const isPayNonCoreStepComplete = (step) => {
    return payNonCoreStepsCompleted.has(step)
  }

  const inPayNonCoreStepsTrail = (step) => {
    return payNonCoreStepsTrail.has(step)
  }

  const submitPayNonCoreStepForm = () => {
    switch (payNonCoreActiveStep) {
      case 0:
        return PayNonCoreStep1Form.handleSubmit()
      case 1:
        return payNonCoreStep2Submit()
      default:
        closeDialog()
        break
    }
  }

  const [submitPayNonCoreBtnStatus, setSubmitPayNonCoreBtnStatus] = React.useState(false)
  React.useEffect(() => {
      submitPayNonCoreBtnStatusHandle()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [PayNonCoreStep1Form, tempPayNonCoreData])

  const submitPayNonCoreBtnStatusHandle = () => {
    switch (payNonCoreActiveStep) {
      case 0:
        setSubmitPayNonCoreBtnStatus(!PayNonCoreStep1Form.isValid || PayNonCoreStep1Form.isSubmitting)
        break
      case 1:
        return setSubmitPayNonCoreBtnStatus(!payNonCoreStep2Claims.length)
      default:
        setSubmitPayNonCoreBtnStatus(false)
        break
    }
  }

  const [paymentClaimsFailure, setPaymentClaimsFailure] = React.useState(false)
  const [paymentClaimsLoading, setPaymentClaimsLoading] = React.useState(false)
  const [paymentClaims, setPaymentClaims] = React.useState([])
  const [paymentClaimsPage, setPaymentClaimsPage] = React.useState(-1)
  const [paymentClaimsRowsPerPage, setPaymentClaimsRowsPerPage] = React.useState(10)
  const [paymentClaimsCount, setPaymentClaimsCount] = React.useState(0)

  const getPaymentClaims = (firstTime) => {
    if (!!firstTime) {
      setPaymentClaimsPage(0)
    } else {
      if (!!keycloak?.authenticated) {
        setPaymentClaimsFailure(false)
        setPaymentClaimsLoading(true)
        CompanyServices.getPaymentClaims(cid, {
          headers: { 'Authorization': `Bearer ${keycloak?.token ?? ''}` },
          params: filterData(PaymentClaimRequestsFiltersPayload(paymentClaimsPage + 1,
            paymentClaimsRowsPerPage,
            {
              tax_type: currentPaymentData?.tax_type_code || '',
              // payment_item: currentPaymentData?.payment_item_code || '',
              claim_state: 'paid'
            }))
        }).then((result) => {
          if ((!!result.response && result.response.status !== 200) || !result) {
            setPaymentClaimsFailure(true)
          } else {
            if (!!result && !!result.data && !!result.data.data) {
              setPaymentClaims(result.data.data)
              setPaymentClaimsCount(result.data.meta.total)
            }
          }
        }).then(() => {
          setPaymentClaimsLoading(false)
        })
      }
    }
  }

  React.useEffect(() => {
      if (paymentClaimsPage > -1) {
        getPaymentClaims()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentClaimsPage, paymentClaimsRowsPerPage])

  const handleChangePaymentClaimsPage = (event, newPage) => {
    setPaymentClaimsPage(newPage)
  }

  const handleChangePaymentClaimsRowsPerPage = (event) => {
    setPaymentClaimsRowsPerPage(+event.target.value)
    setPaymentClaimsPage(0)
  }

  return (
    <PayNonCoreContext.Provider value={{

      payNonCoreActiveStep: payNonCoreActiveStep,
      payNonCoreSteps: payNonCoreSteps,
      getPayNonCoreStepContent,
      handleNextStep,
      handleBackStep,
      handleGoToStep,
      isPayNonCoreStepComplete,
      inPayNonCoreStepsTrail,
      submitPayNonCoreStepForm,
      submitPayNonCoreBtnStatus: submitPayNonCoreBtnStatus,

      PayNonCoreStep1Form: PayNonCoreStep1Form,
      payNonCoreStep1Failure: payNonCoreStep1Failure,
      payNonCoreStep1FailureMessage: payNonCoreStep1FailureMessage,

      paymentClaims: paymentClaims,
      paymentClaimsFailure: paymentClaimsFailure,
      paymentClaimsLoading: paymentClaimsLoading,
      paymentClaimsPage: paymentClaimsPage,
      paymentClaimsRowsPerPage: paymentClaimsRowsPerPage,
      paymentClaimsCount: paymentClaimsCount,
      handleChangePaymentClaimsPage,
      handleChangePaymentClaimsRowsPerPage,

      payNonCoreStep2Loading: payNonCoreStep2Loading,
      payNonCoreStep2Failure: payNonCoreStep2Failure,
      payNonCoreStep2FailureMessage: payNonCoreStep2FailureMessage,
      PayNonCoreHeaderColumns: PayNonCoreHeaderColumns,
      payNonCoreStep2Claims: payNonCoreStep2Claims,
      handleChangePayment,
      tempPayNonCoreData: tempPayNonCoreData

    }}>
      {children}
    </PayNonCoreContext.Provider>
  )
}
const PayNonCoreConsumer = PayNonCoreContext.Consumer

export { PayNonCoreProvider, PayNonCoreConsumer }
