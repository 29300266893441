import React from 'react'
import { useStyles } from './Styles'
import Item from './Item'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import { formatPhoneNumber, isEmpty } from '../../../../utils/helpers'
import Alert from '@material-ui/lab/Alert'
import { FormattedMessage } from 'react-intl'
import { SearchLoader } from '../../../Loader/SearchLoader'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LanguageConsumer } from '../../../../LanguageContext'

const CompaniesInfo = ({ selectedCompany, setBreadCrumbLinks, setBreadCrumbCurrent }) => {
  const classes = useStyles()
  React.useEffect(() => {
      if (!!setBreadCrumbCurrent && !!selectedCompany && !isEmpty(selectedCompany)) {
        setBreadCrumbCurrent(selectedCompany.taxpayer_name)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCompany])
  return (
    <AuthenticatedUserConsumer>
      {({ selectedCompany, CompanyHeaderColumns, selectedCompanyLoading, selectedCompanyFailure }) => (
        <div className={classes.CompaniesInfo}>
          {!!selectedCompanyFailure &&
          <Alert severity="error"><FormattedMessage id='BasicInfo.FormErrorAlert'/></Alert>
          }
          {!!selectedCompanyLoading && <SearchLoader loading={true}/>}
          {!!selectedCompany && !!Object.keys(selectedCompany).length &&
          <>
            {CompanyHeaderColumns.map((column) => (
              <Item key={column.id} label={column.label}
                    value={(column.id === 'subscription_end_date') ?
                      <LanguageConsumer>
                        {({ locale }) => (
                          <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                                  format="Do MMMM YYYY h:mm a">{selectedCompany[column.id]}</Moment>
                        )}
                      </LanguageConsumer>
                      :
                      selectedCompany[(column.id === 'linkedName') ? 'name' : column.id]}/>
            ))}

            <Accordion defaultExpanded={true} variant={'outlined'}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <Typography component={'h2'} variant={'h6'} color={'primary'}>
                  <FormattedMessage id='CompanyContact.CompanyTitle'/>
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.CompaniesContactsWrapper}>
                  <Item label={<FormattedMessage id='AdditionalInfo.placeholder.phone'/>}
                        value={<a className={classes.phoneNumFormat}
                                  href={`tel:${selectedCompany.phone}`}>{formatPhoneNumber(selectedCompany.phone)}</a>}/>
                  <Item label={<FormattedMessage id='BasicInfo.placeholder.email'/>}
                        value={<a href={`mailto:${selectedCompany.email}`}>{selectedCompany.email}</a>}/>

                </div>

              </AccordionDetails>
              <Divider/>
            </Accordion>
          </>
          }
        </div>
      )}
    </AuthenticatedUserConsumer>
  )
}

export default CompaniesInfo
