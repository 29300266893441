import React from 'react'
import { Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { EcommerceConsumer } from '../../../../EcommerceContext'
import { AuthenticatedUserConsumer } from '../../../../AuthenticatedUserContext'
import EditEcommercePlatform from './EditEcommerceClient'
import { useStyles } from './Styles'
import SucessDialog from './SucessDialog/SucessDialog'

const AddEcommerceButton = ({ resetEcommerceStatus, companyIsAccountant }) => {
  let classes = useStyles()

  const [viewAddEcommerceDialog, setViewAddEcommerceDialog] = React.useState(false)
  const [openAddEcommerceDialog, setOpenAddEcommerceDialog] = React.useState(false)
  const handleAddEcommerceDialog = () => {
    if (!openAddEcommerceDialog) {
      resetEcommerceStatus()
      setViewAddEcommerceDialog(true)
    } else {
      setTimeout(() => setViewAddEcommerceDialog(false), 500)
    }
    setOpenAddEcommerceDialog(!openAddEcommerceDialog)
  }

  return (
    <>
      <Button className={classes.addDelegateBtn} size="medium" variant="contained" color="primary" onClick={handleAddEcommerceDialog}>
        <FormattedMessage id='Ecommerce.AddNew' />
      </Button>
      {!!viewAddEcommerceDialog &&
        <AuthenticatedUserConsumer>
          {({ paymentsFiltersData }) => (
            !!paymentsFiltersData && !!Object.keys(paymentsFiltersData).length &&
            <EcommerceConsumer>
              {({ ecommerceData, handleEcommerceSubmit }) => (
                <EditEcommercePlatform
                  openEditEcommerceDialog={openAddEcommerceDialog}
                  handleEditEcommerceDialog={handleAddEcommerceDialog}
                  ecommerceData={ecommerceData}
                  companyIsAccountant={companyIsAccountant}
                  handleEcommerceSubmit={handleEcommerceSubmit}
                  paymentsFiltersData={paymentsFiltersData}
                />
              )}
            </EcommerceConsumer>
          )}
        </AuthenticatedUserConsumer>
      }
      <SucessDialog />
    </>
  )
}

export default AddEcommerceButton
