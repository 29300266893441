import React from 'react'
import { Container, Dialog, DialogContent, IconButton, Slide } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Alert from '@material-ui/lab/Alert'
import { useStyles } from './Styles'
import Configuration from '../../../Services/Api/Configuration'
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const UPTCPUserMessage = ({
                            selectedCompany, checkCompanyUPTCPRegistration, resetCompanyUPTCPRegistrationStatus,
                            companyUPTCPRegistrationFailure, companyUPTCPRegistrationFailureMessage, companyUPTCPRegistration,
                            incentivesTaxIdValidityFailure, incentivesTaxIdValidityFailureMessage, resetIncentivesFormStatus,
                            locale = 'ar', companyUPTCPRegistrationDialog, closeCompanyUPTCPRegistrationDialog
                          }) => {
  const classes = useStyles()
  const location = useLocation()
  const [timeInterval, setTimeInterval] = React.useState(null)
  const [seconds, setSeconds] = React.useState(5)

  React.useEffect(() => {
      if (!!incentivesTaxIdValidityFailure) {
        resetIncentivesFormStatus()
      }
      if (!!companyUPTCPRegistration || !!companyUPTCPRegistrationFailure) {
        resetCompanyUPTCPRegistrationStatus()
        if (!!timeInterval) {
          clearInterval(timeInterval)
        }
        setSeconds(5)
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [location])

  React.useEffect(() => {
      if (!companyUPTCPRegistrationDialog) {
        if (!!timeInterval) {
          clearInterval(timeInterval)
        }
        setSeconds(5)
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyUPTCPRegistrationDialog])

  React.useEffect(() => {
      if (!!companyUPTCPRegistration?.enrolled) {
        let interval = setInterval(() => {
          setSeconds(prevState => prevState - 1)
        }, 1000)
        setTimeInterval(interval)
      }
    },  // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyUPTCPRegistration])

  React.useEffect(() => {
      if (seconds < 1) {
        clearInterval(timeInterval)
        if (!!companyUPTCPRegistration) {
          window.location.href = Configuration.UPTCP.URL[locale]
        }
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [seconds])
  return (
    <>
      {!!incentivesTaxIdValidityFailure && !!incentivesTaxIdValidityFailureMessage &&
      <Container maxWidth="lg" className={classes.UPTCPUserMessageWrapper} disableGutters>
        <Alert severity="error">{incentivesTaxIdValidityFailureMessage}</Alert>
      </Container>
      }
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={!!companyUPTCPRegistrationDialog}
        classes={{ paper: classes.ErrorDialogPaper }}
        onClose={closeCompanyUPTCPRegistrationDialog}
        TransitionComponent={Transition}
        aria-labelledby="BasicInfoDialog"
        aria-describedby="BasicInfoDialog"
      >
        <DialogContent className={classes.ErrorDialogContent}>
          <IconButton
            edge={false}
            className={classes.ErrorDialogClose}
            color="inherit"
            aria-label="close dialog"
            onClick={closeCompanyUPTCPRegistrationDialog}
          >
            <CloseIcon/>
          </IconButton>
          <Alert
            severity={!!companyUPTCPRegistrationFailure ? 'error' : (!!companyUPTCPRegistration?.enrolled ? 'success' : 'warning')}
            className={classes.linkCompanyErrorMessage}>
            {!!companyUPTCPRegistrationFailure
              ? (companyUPTCPRegistrationFailureMessage ?? <FormattedMessage id='UPTCPUserMessage.FormErrorAlert'/>)
              : (!!companyUPTCPRegistration?.enrolled ?
                  <FormattedMessage id='UPTCPUserMessage.Registered.true' values={{
                    seconds: seconds
                  }}/> : <FormattedMessage id='UPTCPUserMessage.Registered.false' values={{
                    link: <a
                      // onClick={closeCompanyUPTCPRegistrationDialog}
                      target="_blank" rel="noopener noreferrer"
                      href={Configuration?.UPTCP?.NotEnrolledURL}>{Configuration?.UPTCP?.NotEnrolledURL}</a>
                  }}/>

              )}
          </Alert>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UPTCPUserMessage
