import React from 'react'
import { withRouter } from 'react-router-dom'
import {NamedRoutes} from "../routes";

class IsUnderMaintenance extends React.Component {
  componentDidMount() {
    this.onRouteChanged()
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged()
    }
  }
  onRouteChanged() {
    if (process.env.REACT_APP_UNDER_MENTINANCE === 'true' && this.props.location.pathname !== NamedRoutes.underMaintenance) {
      window.location.href = NamedRoutes.underMaintenance
    }
  }

  render () {
    return null
  }
}

export default withRouter(IsUnderMaintenance)
