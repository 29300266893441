import React from 'react'
import { Fade } from 'react-reveal'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './Styles'
import { Collapse, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { formatRoute } from 'react-router-named-routes'
import { TableRowBackground } from '../../../utils/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Item = ({ item, index, Mobile, preAppendIndex, toggleMobileMenuDrawer, selectedCompany, locale, checkIncentivesTaxIdValidity, checkTaxServiceLink }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [expanded, setExpanded] = React.useState(false)
  const classes = useStyles()
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleExpandClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setExpanded(!expanded)
  }
  const StyledMenu = withStyles({
    paper: {
      border: `1px solid ${TableRowBackground}`
    }
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: locale === 'ar' ? 'right' : 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: locale === 'ar' ? 'right' : 'left'
      }}
      disableScrollLock
      {...props}
    />
  ))

  return (

    <Fade bottom delay={!!Mobile ? 0 : (preAppendIndex + index) * 200} key={item.key}>
      {!!item.isIncentive
        ? (!item.hide && <button rel="noopener noreferrer"
                                 onClick={() => checkIncentivesTaxIdValidity(selectedCompany?.tax_id)}>{item.label}</button>)
        : (!!item?.isTaxService ?
          <button rel="noopener noreferrer"
                  onClick={() => checkTaxServiceLink(item.link + (!!item.isSAP ? `&sap-language=${locale}` : ''))}>{item.label}</button>
          :(!!item.target ?
          (!item.hide && <a href={item.link + (!!item.isSAP ? `&sap-language=${locale}` : '')}
                            target='_blank' rel="noopener noreferrer">{item.label}</a>)
          :
          <>
            {!!item.subMenu && !!item.subMenu.length && (item.subMenu.length > item.subMenu?.filter(sub => !!sub.isUPTCP)?.length || (!!selectedCompany?.user_uptcp_enrollment_eligibility &&
              !!selectedCompany?.user_uptcp_enrolled_at)) ?
              <>

                <button key={item.key}
                        onClick={(e) => !!Mobile ? handleExpandClick(e) : handleClick(e)}
                        aria-expanded={expanded}
                        aria-label="show more"
                >
                  {item.label} {!!Mobile ? <ExpandMoreIcon/> : <ArrowDropDownIcon/>}</button>

                {!!Mobile ?
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {item.subMenu.map((item) => (
                      !item.hide &&
                      (!!item.isUPTCP ? (!!selectedCompany?.user_uptcp_enrollment_eligibility &&
                          !!selectedCompany?.user_uptcp_enrolled_at &&
                          <MenuItem onClick={handleClose} key={item.key}>
                            <a href={item.link + (!!item.isSAP ? `&sap-language=${locale}` : '')} target='_blank'
                               rel="noopener noreferrer">{item.label}</a>
                          </MenuItem>) :
                          <MenuItem onClick={handleClose} key={item.key}>
                            {!!item.isIncentive
                              ? (!item.hide && <button rel="noopener noreferrer"
                                                       onClick={() => checkIncentivesTaxIdValidity(selectedCompany?.tax_id)}>{item.label}</button>)
                              : (!!item?.isTaxService ?
                                <button rel="noopener noreferrer"
                                        onClick={() => checkTaxServiceLink(item.link + (!!item.isSAP ? `&sap-language=${locale}` : ''))}>{item.label}</button>
                                :(!!item.target
                                ? (!item.hide && <a href={item.link + (!!item.isSAP ? `&sap-language=${locale}` : '')}
                                                    target='_blank' rel="noopener noreferrer">{item.label}</a>)
                                : (!!item.noLink
                                  ? <button rel="noopener noreferrer">{item.label}</button>
                                  : <NavLink exact activeClassName="active"
                                             to={(!!selectedCompany?.id) ? formatRoute(item.link, { id: selectedCompany.id }) : item.link}
                                             onClick={() => !!Mobile ? toggleMobileMenuDrawer(false) : ''}>
                                    {item.label}
                                  </NavLink>)))
                            }
                          </MenuItem>
                      )))}
                  </Collapse>
                  :
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    className={classes.mainNavigationSubMenu}
                  >
                    {item.subMenu.map((item) => (
                      !item.hide &&
                      (!!item.isUPTCP ? (!!selectedCompany?.user_uptcp_enrollment_eligibility &&
                          !!selectedCompany?.user_uptcp_enrolled_at &&
                          <MenuItem onClick={handleClose} key={item.key}>
                            <a href={item.link + (!!item.isSAP ? `&sap-language=${locale}` : '')} target='_blank'
                               rel="noopener noreferrer">{item.label}</a>
                          </MenuItem>) :
                          <MenuItem onClick={handleClose} key={item.key}>
                            {!!item.isIncentive
                              ? (!item.hide && <button rel="noopener noreferrer"
                                                       onClick={() => checkIncentivesTaxIdValidity(selectedCompany?.tax_id)}>{item.label}</button>)
                              : (!!item?.isTaxService ?
                                <button rel="noopener noreferrer"
                                        onClick={() => checkTaxServiceLink(item.link + (!!item.isSAP ? `&sap-language=${locale}` : ''))}>{item.label}</button>
                                :(!!item.target
                                ? (!item.hide && <a href={item.link + (!!item.isSAP ? `&sap-language=${locale}` : '')}
                                                    target='_blank' rel="noopener noreferrer">{item.label}</a>)
                                : (!!item.noLink
                                  ? <button rel="noopener noreferrer">{item.label}</button>
                                  : <NavLink exact activeClassName="active"
                                             to={(!!selectedCompany?.id) ? formatRoute(item.link, { id: selectedCompany.id }) : item.link}
                                             onClick={() => !!Mobile ? toggleMobileMenuDrawer(false) : ''}>
                                    {item.label}
                                  </NavLink>)))
                            }
                          </MenuItem>
                      )))}
                  </StyledMenu>
                }
              </>
              :
              (!!item.noLink
                ? <button rel="noopener noreferrer">{item.label}</button>
                :!item.hide && <NavLink exact activeClassName="active"
                           to={(!!selectedCompany?.id) ? formatRoute(item.link, { id: selectedCompany.id }) : item.link}
                           onClick={() => !!Mobile ? toggleMobileMenuDrawer(false) : ''}>
                  {item.label}
                </NavLink>)
            }
          </>))
      }
    </Fade>
  )
}

export default Item
