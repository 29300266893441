/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import {
  ClickAwayListener,
  FormControl, FormHelperText,
  InputLabel,
  TextField, Tooltip
} from '@material-ui/core'
import { FormLoader } from '../../../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { CompanyDownPaymentConsumer } from '../../../../../../CompanyDownPaymentContext'
import { useStyles } from './Styles'
import HelpIcon from '@material-ui/icons/Help'
import { sm } from '../../../../../../utils/ResponsiveUtility'

const DownPaymentForm = ({ downPaymentForm }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const ToggleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen)
  }

  return (
    <CompanyDownPaymentConsumer>
      {({ newDownPaymentFailure, newDownPaymentFailureMessage, downPaymentRangeLoading, downPaymentRangeFailure, downPaymentRange }) => (
        <form onSubmit={downPaymentForm.handleSubmit} noValidate autoComplete="off"
              className={classes.formWrapper}>
          {!!newDownPaymentFailure && !!newDownPaymentFailureMessage &&
          <Alert severity="error">{newDownPaymentFailureMessage}</Alert>
          }

          {!!downPaymentRangeFailure &&
          <Alert severity="error">
            <FormattedMessage id='downPaymentForm.FormErrorAlert'/></Alert>
          }
          <div className={classes.formRow}>
            <InputLabel id="paymentClaimForm.claimAmount"><FormattedMessage
              id='AddDownPayment.amount'/>*:
              {!!Mobile &&
              <ClickAwayListener onClickAway={handleTooltipClose}>
                  <span>
                    <Tooltip className={classes.amountHint + ' mobile'}
                                   classes={{
                                     popper: classes.tooltipMobilePopper,
                                     tooltip: classes.tooltipWrapper
                                   }}
                                   open={tooltipOpen}
                                   onClose={handleTooltipClose}
                                   disableFocusListener
                                   disableHoverListener
                                   disableTouchListener
                                   title={intl.formatMessage({ id: 'PaymentHeader.receiptAmount.Hint' })}
                                   arrow>
                        <HelpIcon onClick={ToggleTooltipOpen}/>
                    </Tooltip>
                  </span>
              </ClickAwayListener>
              }
            </InputLabel>
            <FormControl variant="outlined">
              <TextField
                placeholder={intl.formatMessage({ id: 'AddDownPayment.amount' })}
                id="amount"
                type={'number'}
                margin="dense"
                variant="outlined"
                name='amount'
                inputProps={{
                  min: downPaymentRange.minimum,
                  max: downPaymentRange.maximum
                }}
                onChange={downPaymentForm.handleChange}
                onBlur={downPaymentForm.handleBlur}
                value={downPaymentForm.values.amount}
                helperText={downPaymentForm.errors.amount && downPaymentForm.touched.amount && downPaymentForm.errors.amount}
                error={downPaymentForm.errors.amount && downPaymentForm.touched.amount !== undefined}
              />
              <FormHelperText><FormattedMessage id='partialAmount.Hint' values={{
                min: <FormattedNumber value={downPaymentRange.minimum}/>,
                max: <FormattedNumber value={downPaymentRange.maximum}/>
              }}/></FormHelperText>
            </FormControl>
            {!Mobile &&
            <ClickAwayListener onClickAway={handleTooltipClose}>
                  <span>
                    <Tooltip className={classes.amountHint}
                                   classes={{
                                     popper: classes.tooltipPopper,
                                     tooltip: classes.tooltipWrapper
                                   }}
                                   open={tooltipOpen}
                                   placement="bottom"
                                   onClose={handleTooltipClose}
                                   disableFocusListener
                                   disableHoverListener
                                   disableTouchListener
                                   title={intl.formatMessage({ id: 'PaymentHeader.receiptAmount.Hint' })}
                                   arrow>
                        <HelpIcon onClick={ToggleTooltipOpen}/>
                    </Tooltip>
                  </span>
            </ClickAwayListener>
            }
          </div>
          {(downPaymentForm.isSubmitting || !!downPaymentRangeLoading) && <FormLoader loading={true}/>}
        </form>
      )}
    </CompanyDownPaymentConsumer>
  )
}

export default DownPaymentForm
