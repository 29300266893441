/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Collapse,
  ClickAwayListener,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  FormHelperText, Select, MenuItem
} from '@material-ui/core'
import { FormLoader } from '../../../../../Loader/FormLoader'
import Alert from '@material-ui/lab/Alert'
import { CompanyConsumer } from '../../../../../../CompanyContext'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import CustomToolbar from './CustomToolbar'
import CustomToolbarMonth from './CustomToolbarMonth'
import HelpIcon from '@material-ui/icons/Help'
import { useStyles } from './Styles'
import moment from 'moment'
import { ButtonLoader } from '../../../../../Loader/ButtonLoader'
import { sm } from '../../../../../../utils/ResponsiveUtility'
import { withStyles } from '@material-ui/core/styles'
import { LanguageConsumer } from '../../../../../../LanguageContext'

const PaymentClaimForm = ({ paymentClaimForm, getPaymentClaimItems, PaymentClaimOwners }) => {
  const classes = useStyles()
  const intl = useIntl()

  const [taxTypeText, setTaxTypeText] = React.useState('')
  const [viewIncome, setViewIncome] = React.useState(false)
  const [viewVat, setViewVat] = React.useState(false)
  const [viewWithholding, setViewWithholding] = React.useState(false)
  const handleClaimTypeChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    paymentClaimForm.setFieldValue('tax_type', event.target.value.toString())
    if (!!event.target.value.toString()) {
      setTaxTypeText((PaymentClaimTypes.filter((cType) => parseInt(cType.value) === parseInt(event.target.value)))[0].name)
    }
    paymentClaimForm.setFieldValue('payment_item', '')
    getPaymentClaimItems(parseInt(event.target.value), paymentClaimForm)
    if (parseInt(event.target.value) === 1) {
      setViewIncome(true)
      setTimeout(() => {
        setViewVat(false)
        setViewWithholding(false)
      }, 500)
    } else if (parseInt(event.target.value) === 2) {
      setViewWithholding(true)
      setTimeout(() => {
        setViewIncome(false)
        setViewVat(false)
      }, 500)
    } else if (parseInt(event.target.value) === 3) {
      setViewVat(true)
      setTimeout(() => {
        setViewIncome(false)
        setViewWithholding(false)
      }, 500)
    } else {
      setTimeout(() => {
        setViewIncome(false)
        setViewVat(false)
        setViewWithholding(false)
      }, 500)
    }
  }

  const PaymentClaimTypes = [
    {
      id: 0,
      name: <FormattedMessage id='PaymentHeader.climeType'/>,
      value: ''
    },
    {
      id: 1,
      name: <FormattedMessage id='PaymentHeader.claimType.income'/>,
      value: '1'
    },
    {
      id: 2,
      name: <FormattedMessage id='PaymentHeader.claimType.withholding'/>,
      value: '2'
    },
    {
      id: 3,
      name: <FormattedMessage id='PaymentHeader.claimType.vat'/>,
      value: '3'
    }
  ]

  const handlePaymentItemChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    paymentClaimForm.setFieldValue('payment_item', event.target.value.toString())
  }

  const handlePaymentOwnerChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (!!event.target.value.toString()) {
      let ownerObj = PaymentClaimOwners?.filter(paymentOwner => paymentOwner?.registration_no?.toString() === event.target.value.toString())?.shift()
      paymentClaimForm.setFieldValue('owner_tax_serial_number', ownerObj?.serial?.toString())
    } else {
      paymentClaimForm.setFieldValue('owner_tax_serial_number', '')
    }
    paymentClaimForm.setFieldValue('owner_tax_registration_number', event.target.value.toString())
  }
  const handlePaymentRegTypeChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    paymentClaimForm.setFieldValue('registration_payment_type', event.target.value.toString())
  }

  const [taxPeriodFromDate, setTaxPeriodFromDate] = React.useState(null)
  const handleTaxPeriodFromDateChange = (date) => {
    let endDate = moment(date).add(1, 'years').subtract(1, 'days')
    setTaxPeriodFromDate(date)
    setTaxPeriodToDate(endDate)
    paymentClaimForm.setFieldValue('tax_period_to', moment(endDate).format('DD-MM-YYYY'))
    setTimeout(() => paymentClaimForm.setFieldValue('tax_period_from', moment(date).format('DD-MM-YYYY')), 100)
  }
  const [taxPeriodToDate, setTaxPeriodToDate] = React.useState(null)
  const handleTaxPeriodToDateChange = (date) => {
    let startDate = moment(date).subtract(1, 'years').add(1, 'days')
    setTaxPeriodFromDate(startDate)
    setTaxPeriodToDate(date)
    paymentClaimForm.setFieldValue('tax_period_from', moment(startDate).format('DD-MM-YYYY'))
    setTimeout(() => paymentClaimForm.setFieldValue('tax_period_to', moment(date).format('DD-MM-YYYY')), 100)
  }
  const [taxPeriodYearMonthDate, setTaxPeriodYearMonthDate] = React.useState(null)
  const handleTaxPeriodYearMonthDateChange = (date) => {
    setTaxPeriodYearMonthDate(date)
    paymentClaimForm.setFieldValue('tax_period_month', moment(date).format('M'))
    paymentClaimForm.setFieldValue('tax_period_year', moment(date).format('YYYY'))
    // paymentClaimForm.setFieldTouched('tax_period_month', true, false)
    setTimeout(() => {
      paymentClaimForm.setFieldTouched('tax_period_month', true, true)
      paymentClaimForm.setFieldTouched('tax_period_year', true, true)
    }, 100)

  }
  const [taxPeriodYearDate, setTaxPeriodYearDate] = React.useState(null)
  const handleTaxPeriodYearDateChange = (date) => {
    setTaxPeriodYearDate(date)
    paymentClaimForm.setFieldValue('tax_period_year', moment(date).format('YYYY'))
  }
  const handleTaxPeriodQuarterChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    paymentClaimForm.setFieldValue('tax_period_quarter_number', event.target.value.toString())
  }

  const [width, setWidth] = React.useState(document.body.clientWidth)

  let Mobile = (width < sm)
  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const ToggleTooltipOpen = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(16),
      maxWidth: 500
    }
  }))(Tooltip)

  return (
    <CompanyConsumer>
      {({
          PaymentClaimItems, PaymentClaimRegTypes, PaymentClaimItemsLoading,
          PaymentClaimItemsFailure, newPaymentClaimFailure, newPaymentClaimFailureMessage
        }) => (
        <LanguageConsumer>
          {({ locale }) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
              <form onSubmit={paymentClaimForm.handleSubmit} noValidate autoComplete="off"
                    className={classes.formWrapper}>
                {!!newPaymentClaimFailure && !!newPaymentClaimFailureMessage &&
                <Alert severity="error">{newPaymentClaimFailureMessage}</Alert>
                }
                {!!PaymentClaimItemsFailure &&
                <Alert severity="error">
                  <FormattedMessage id='paymentClaimForm.FormErrorAlert'
                                    values={{ type: taxTypeText }}/></Alert>
                }
                <div className={classes.formRow}>
                  <InputLabel id="paymentClaimForm.claimType"><FormattedMessage
                    id='PaymentHeader.climeType'/>*:</InputLabel>
                  <FormControl variant='outlined'>
                    <Select
                      fullWidth
                      id="tax_type"
                      margin="dense"
                      displayEmpty
                      MenuProps={{ disableScrollLock: true }}
                      name="tax_type"
                      value={paymentClaimForm.values.tax_type}
                      onChange={handleClaimTypeChange}
                      onBlur={paymentClaimForm.handleBlur}
                      error={paymentClaimForm.errors.tax_type && paymentClaimForm.touched.tax_type !== undefined}
                    >
                      {!!PaymentClaimTypes.length && PaymentClaimTypes.map((ClaimType) => (
                        <MenuItem key={ClaimType.id} value={ClaimType.value}>{ClaimType.name}</MenuItem>
                      ))}
                    </Select>
                    {paymentClaimForm.errors.tax_type && paymentClaimForm.touched.tax_type && (
                      <FormHelperText error={true}>{paymentClaimForm.errors.tax_type}</FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className={classes.formRow}>
                  <InputLabel id="paymentClaimForm.claimAmount"><FormattedMessage
                    id='PaymentHeader.receiptAmount'/>*:
                    {!!Mobile &&
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                  <span>
                    <CustomTooltip className={classes.amountHint + ' mobile'}
                                   PopperProps={{
                                     disablePortal: true
                                   }}
                                   open={tooltipOpen}
                                   onClose={handleTooltipClose}
                                   disableFocusListener
                                   disableHoverListener
                                   disableTouchListener
                                   title={intl.formatMessage({ id: 'PaymentHeader.receiptAmount.Hint' })}
                                   arrow>
                        <HelpIcon onClick={ToggleTooltipOpen}/>
                    </CustomTooltip>
                  </span>
                    </ClickAwayListener>
                    }
                  </InputLabel>
                  <FormControl variant="outlined">
                    <TextField
                      placeholder={intl.formatMessage({ id: 'PaymentHeader.receiptAmount' })}
                      id="claim_amount"
                      type={'number'}
                      margin="dense"
                      variant="outlined"
                      name='claim_amount'
                      onChange={paymentClaimForm.handleChange}
                      onBlur={paymentClaimForm.handleBlur}
                      value={paymentClaimForm.values.claim_amount}
                      helperText={paymentClaimForm.errors.claim_amount && paymentClaimForm.touched.claim_amount && paymentClaimForm.errors.claim_amount}
                      error={paymentClaimForm.errors.claim_amount && paymentClaimForm.touched.claim_amount !== undefined}
                    />
                  </FormControl>
                  {!Mobile &&
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                  <span>
                    <CustomTooltip className={classes.amountHint}
                                   PopperProps={{
                                     disablePortal: true
                                   }}
                                   open={tooltipOpen}
                                   onClose={handleTooltipClose}
                                   disableFocusListener
                                   disableHoverListener
                                   disableTouchListener
                                   title={intl.formatMessage({ id: 'PaymentHeader.receiptAmount.Hint' })}
                                   arrow>
                        <HelpIcon onClick={ToggleTooltipOpen}/>
                    </CustomTooltip>
                  </span>
                  </ClickAwayListener>
                  }
                </div>

                <div className={classes.formRow}>
                  <InputLabel id="paymentClaimForm.paymentItem"><FormattedMessage
                    id='PaymentHeader.paymentItem'/>*:</InputLabel>
                  <FormControl variant='outlined'>
                    {!!PaymentClaimItemsLoading && <ButtonLoader loading={true}/>}
                    <Select
                      fullWidth
                      id="payment_item"
                      margin="dense"
                      displayEmpty
                      MenuProps={{ disableScrollLock: true }}
                      name="payment_item"
                      value={paymentClaimForm.values.payment_item}
                      onChange={handlePaymentItemChange}
                      onBlur={paymentClaimForm.handleBlur}
                      error={paymentClaimForm.errors.payment_item && paymentClaimForm.touched.payment_item !== undefined}
                    >
                      <MenuItem value=''><FormattedMessage id='PaymentHeader.paymentItem'/></MenuItem>
                      {!!PaymentClaimItems.length && PaymentClaimItems.map((paymentItem) => (
                        <MenuItem key={paymentItem.code} value={paymentItem.code.toString()}><span
                          style={{ 'direction': 'ltr', 'display': 'inline-block' }}>{paymentItem.name}</span></MenuItem>
                      ))}
                    </Select>
                    {paymentClaimForm.errors.payment_item && paymentClaimForm.touched.payment_item && (
                      <FormHelperText error={true}>{paymentClaimForm.errors.payment_item}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <Collapse in={!!paymentClaimForm.values.tax_type && parseInt(paymentClaimForm.values.tax_type) === 1}
                          timeout="auto" unmountOnExit>
                  {!!viewIncome &&
                  <>
                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.owner"><FormattedMessage
                        id='PaymentHeader.owner'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        {!!PaymentClaimItemsLoading && <ButtonLoader loading={true}/>}
                        <Select
                          fullWidth
                          id="owner_tax_registration_number"
                          margin="dense"
                          displayEmpty
                          MenuProps={{ disableScrollLock: true }}
                          name="owner_tax_registration_number"
                          value={paymentClaimForm.values.owner_tax_registration_number}
                          onChange={handlePaymentOwnerChange}
                          onBlur={paymentClaimForm.handleBlur}
                          error={paymentClaimForm.errors.owner_tax_registration_number && paymentClaimForm.touched.owner_tax_registration_number !== undefined}
                        >
                          <MenuItem value=''><FormattedMessage id='PaymentHeader.owner'/></MenuItem>
                          {!!PaymentClaimOwners.length && PaymentClaimOwners.map((paymentOwner) => (
                            <MenuItem key={paymentOwner.registration_no}
                                      value={paymentOwner.registration_no.toString()}>
                              <div className={classes.paymentClaimFormOwnerListItem}>
                                <div><strong><FormattedMessage
                                  id='CompanyHeader.TaxpayerName'/>:</strong>{paymentOwner.name}</div>
                                <div><strong><FormattedMessage
                                  id='CompanyHeader.UniqueTaxId'/>:</strong>{paymentOwner.registration_no}</div>
                              </div>
                            </MenuItem>
                          ))}
                        </Select>
                        {paymentClaimForm.errors.owner_tax_registration_number && paymentClaimForm.touched.owner_tax_registration_number && (
                          <FormHelperText
                            error={true}>{paymentClaimForm.errors.owner_tax_registration_number}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.paymentItem"><FormattedMessage
                        id='PaymentHeader.taxPeriodFrom'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'PaymentHeader.taxPeriodFrom' })}
                          value={taxPeriodFromDate}
                          ToolbarComponent={CustomToolbar}
                          onChange={handleTaxPeriodFromDateChange}
                          cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                          okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                          animateYearScrolling
                          variant="outlined"
                          format="d MMMM yyyy"
                          margin='dense'
                          id="tax_period_from"
                          name='tax_period_from'
                          fullWidth
                          className={classes.dateFormControl}
                        />

                        {paymentClaimForm.errors.tax_period_from && paymentClaimForm.touched.tax_period_from && (
                          <FormHelperText error={true}>{paymentClaimForm.errors.tax_period_from}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.paymentItem"><FormattedMessage
                        id='PaymentHeader.taxPeriodTo'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'PaymentHeader.taxPeriodTo' })}
                          value={taxPeriodToDate}
                          ToolbarComponent={CustomToolbar}
                          onChange={handleTaxPeriodToDateChange}
                          cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                          okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                          animateYearScrolling
                          variant="outlined"
                          format="d MMMM yyyy"
                          margin='dense'
                          id="tax_period_to"
                          name='tax_period_to'
                          fullWidth
                          className={classes.dateFormControl}
                        />
                        {paymentClaimForm.errors.tax_period_to && paymentClaimForm.touched.tax_period_to && (
                          <FormHelperText error={true}>{paymentClaimForm.errors.tax_period_to}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </>
                  }
                </Collapse>

                <Collapse in={!!paymentClaimForm.values.tax_type && parseInt(paymentClaimForm.values.tax_type) === 2}
                          timeout="auto" unmountOnExit>
                  {!!viewWithholding &&
                  <>
                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.taxPeriodYear"><FormattedMessage
                        id='PaymentHeader.taxPeriodYear'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'PaymentHeader.taxPeriodYear' })}
                          value={taxPeriodYearDate}
                          onChange={handleTaxPeriodYearDateChange}
                          cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                          okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                          views={['year']}
                          variant="outlined"
                          format="yyyy"
                          margin='dense'
                          id="tax_period_year"
                          name='tax_period_year'
                          fullWidth
                          className={classes.dateFormControl}
                        />
                      </FormControl>
                    </div>
                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.taxPeriodQuarterَ"><FormattedMessage
                        id='PaymentHeader.taxPeriodQuarterَ'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        <Select
                          fullWidth
                          id="tax_period_quarter_number"
                          margin="dense"
                          displayEmpty
                          MenuProps={{ disableScrollLock: true }}
                          name="tax_period_quarter_number"
                          value={paymentClaimForm.values.tax_period_quarter_number}
                          onChange={handleTaxPeriodQuarterChange}
                          onBlur={paymentClaimForm.handleBlur}
                          error={paymentClaimForm.errors.tax_period_quarter_number && paymentClaimForm.touched.tax_period_quarter_number !== undefined}
                        >
                          <MenuItem value=''><FormattedMessage id='PaymentHeader.taxPeriodQuarterَ'/></MenuItem>
                          <MenuItem value='1'><FormattedMessage id='PaymentHeader.taxPeriodQuarter.1َ'/></MenuItem>
                          <MenuItem value='2'><FormattedMessage id='PaymentHeader.taxPeriodQuarter.2َ'/></MenuItem>
                          <MenuItem value='3'><FormattedMessage id='PaymentHeader.taxPeriodQuarter.3َ'/></MenuItem>
                          <MenuItem value='4'><FormattedMessage id='PaymentHeader.taxPeriodQuarter.4َ'/></MenuItem>
                        </Select>
                        {paymentClaimForm.errors.tax_period_quarter_number && paymentClaimForm.touched.tax_period_quarter_number && (
                          <FormHelperText
                            error={true}>{paymentClaimForm.errors.tax_period_quarter_number}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                  </>
                  }
                </Collapse>

                <Collapse in={!!paymentClaimForm.values.tax_type && parseInt(paymentClaimForm.values.tax_type) === 3}
                          timeout="auto" unmountOnExit>
                  {!!viewVat &&
                  <>
                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.registrationPaymentType"><FormattedMessage
                        id='PaymentHeader.registrationPaymentType'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        {!!PaymentClaimItemsLoading && <ButtonLoader loading={true}/>}
                        <Select
                          fullWidth
                          id="registration_payment_type"
                          margin="dense"
                          displayEmpty
                          MenuProps={{ disableScrollLock: true }}
                          name="registration_payment_type"
                          value={paymentClaimForm.values.registration_payment_type}
                          onChange={handlePaymentRegTypeChange}
                          onBlur={paymentClaimForm.handleBlur}
                          error={paymentClaimForm.errors.registration_payment_type && paymentClaimForm.touched.registration_payment_type !== undefined}
                        >
                          <MenuItem value=''><FormattedMessage id='PaymentHeader.registrationPaymentType'/></MenuItem>
                          {!!PaymentClaimRegTypes.length && PaymentClaimRegTypes.map((paymentRegType) => (
                            <MenuItem key={paymentRegType.code} value={paymentRegType.code.toString()}><span
                              style={{ 'direction': 'ltr' }}>{paymentRegType.name}</span></MenuItem>
                          ))}
                        </Select>
                        {paymentClaimForm.errors.registration_payment_type && paymentClaimForm.touched.registration_payment_type && (
                          <FormHelperText
                            error={true}>{paymentClaimForm.errors.registration_payment_type}</FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <div className={classes.formRow}>
                      <InputLabel id="paymentClaimForm.paymentItem"><FormattedMessage
                        id='PaymentHeader.taxPeriod'/>*:</InputLabel>
                      <FormControl variant='outlined'>
                        <DatePicker
                          placeholder={intl.formatMessage({ id: 'PaymentHeader.taxPeriodFrom' })}
                          value={taxPeriodYearMonthDate}
                          ToolbarComponent={CustomToolbarMonth}
                          onChange={handleTaxPeriodYearMonthDateChange}
                          cancelLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.close' })}
                          okLabel={intl.formatMessage({ id: 'PaymentOrder.ActionLinks.Confirm' })}
                          views={['year', 'month']}
                          variant="outlined"
                          format="MMMM yyyy"
                          margin='dense'
                          id="tax_period_year_month"
                          name='tax_period_year_month'
                          fullWidth
                          className={classes.dateFormControl}
                        />
                        {paymentClaimForm.errors.tax_period_year && paymentClaimForm.touched.tax_period_year && (
                          <FormHelperText error={true}>{paymentClaimForm.errors.tax_period_year}</FormHelperText>
                        )}
                        {paymentClaimForm.errors.tax_period_month && paymentClaimForm.touched.tax_period_month && (
                          <FormHelperText error={true}>{paymentClaimForm.errors.tax_period_month}</FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </>
                  }
                </Collapse>
                {paymentClaimForm.isSubmitting && <FormLoader loading={true}/>}
              </form>
            </MuiPickersUtilsProvider>
          )}
        </LanguageConsumer>
      )}
    </CompanyConsumer>
  )
}

export default PaymentClaimForm
